import React from "react";
import { ImageBackground } from "react-native";
import { createStyleSheet, SPACING } from "~/styles";
import { makeStyles, Image as Image } from "@rneui/themed";
import { Column, Text, View, Row } from "~/components/elements";
import { LinearGradient } from "expo-linear-gradient";
import { A } from "@expo/html-elements";
import CardAuthor from "~/components/CardAuthor";
import { TextSize } from "~/enums";

type FlipboardMagazineCardProps = {
  item: FC.Item;
  isHovered?: boolean;
  isCondensed?: boolean;
};

const FlipboardMagazineCard: React.FC<FlipboardMagazineCardProps> = ({
  item,
  isCondensed,
}) => {
  const FLIPBOARD_LOGO_SIZE = 25;
  const FLIPBOARD_MAGAZINE_TEXT = "Flipboard Magazine";
  const styles = useStyles();
  const ALT_TEXT = "Flipboard Magazine Cover";
  if (!item.card) return null;

  const { flip } = item.card;

  // Magazine titles are coming in the format of
  // "Magazine Title | Flipboard User | Flipboard"
  const flipboardMagazineTitle = (item.card.title || "").split(" | ")[0];

  const flipboardMagazineCover = (
    <ImageBackground
      style={styles.imageBackground}
      source={{
        uri: item.image?.url,
      }}
      resizeMode={"cover"}
      accessibilityLabel={
        flip?.shortExcerpt || item.card?.description || ALT_TEXT
      }
    >
      <LinearGradient
        colors={["#FFFFFF00", "#00000060"]}
        locations={[0.6, 0.9]}
        style={styles.gradientOverlay}
      />
      <Column flex style={styles.articleBody}>
        <View style={styles.flipboardMagazineText}>
          <Row
            style={[
              styles.alignCenter,
              { gap: SPACING.BASE, marginBottom: SPACING.BASE },
            ]}
          >
            <Image
              source={{
                uri: "https://s3.amazonaws.com/s.flipboard.com/assets/webu/images/meta/flipboard-512.png",
              }}
              style={{
                width: FLIPBOARD_LOGO_SIZE,
                height: FLIPBOARD_LOGO_SIZE,
              }}
            />
            <Text overlay>{FLIPBOARD_MAGAZINE_TEXT}</Text>
          </Row>

          <A href={item.card.url} target="_blank">
            <Text header size={TextSize.L} overlay>
              {flipboardMagazineTitle}
            </Text>
          </A>
        </View>
        <CardAuthor item={item} overlay />
      </Column>
    </ImageBackground>
  );

  return (
    <View style={isCondensed ? styles.flipboardCoverContainer : styles.flex1}>
      {flipboardMagazineCover}
    </View>
  );
};

const useStyles = makeStyles(() =>
  createStyleSheet({
    imageBackground: {
      flex: 1,
    },
    flipboardCoverContainer: {
      height: 350,
    },
    flipboardMagazineText: {
      padding: SPACING.BASE,
    },
    gradientOverlay: {
      position: "absolute",
      height: "100%",
      width: "100%",
      zIndex: 1,
    },
    articleBody: {
      position: "absolute",
      zIndex: 2,
      height: "100%",
      width: "100%",
      flexDirection: "column",
      justifyContent: "flex-end",
      gap: SPACING.SMALL,
      padding: SPACING.BASE,
    },
  })
);

export default FlipboardMagazineCard;
