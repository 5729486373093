import groupBy from "lodash-es/groupBy";
import sortBy from "lodash-es/sortBy";
import CatchAllRow from "~/components/pages/CatchAllRow";
import {
  isFeatureableImageItem,
  hasMultipleImages,
  isGifv,
  isYouTube,
} from "~/predicates";

const vigetTwoUpSymmetric: FC.PagePresentationStrategy = ({ items }) => {
  const nonGalleryItemms = items.filter(
    (i) =>
      !isFeatureableImageItem(i) &&
      !hasMultipleImages(i) &&
      !isGifv(i) &&
      !isYouTube(i)
  );
  const symmetricItems = sortBy(
    Object.values(groupBy(nonGalleryItemms, "type")),
    (x) => x.length * -1
  );
  if (symmetricItems[0]?.length > 1) {
    return {
      usedItems: symmetricItems[0].slice(0, 2),
      Component: CatchAllRow,
    };
  }
  return null;
};

export default vigetTwoUpSymmetric;
