import React, { memo } from "react";
import { createStyleSheet, SPACING } from "~/styles";
import { Switch, makeStyles, useTheme } from "@rneui/themed";
import { Row, Text } from "~/components/elements";
import isDevelopment from "~/lib/isDevelopment";
import {
  useGetDebugCookie,
  useGetDevelopmentCookie,
  useSetDevelopmentCookie,
} from "~/hooks/useCookies";
import { ButtonProps } from "~/components/elements/Button";

const DevModeSwitch: React.FC<{
  inverse?: boolean;
  ButtonComponent?: React.JSXElementConstructor<ButtonProps>;
}> = ({ inverse = false, ButtonComponent }) => {
  const { theme } = useTheme();
  const styles = useStyles();

  const devMode = useGetDevelopmentCookie()();
  const setDevelopmentCookie = useSetDevelopmentCookie();
  const getDebugCookie = useGetDebugCookie();
  const debugCookie = getDebugCookie();

  if (!isDevelopment() && !debugCookie) return null;

  const onChange = (value: boolean) => {
    setDevelopmentCookie(value);
  };
  if (ButtonComponent)
    return <ButtonComponent onPress={() => onChange(!devMode)} />;
  return (
    <Row style={[styles.settingsWrapper]} inverse={inverse}>
      <Switch
        value={devMode}
        onValueChange={onChange}
        color={theme.colors.controls}
      />
      <Text inverse={inverse} style={styles.switch}>
        Dev Mode / Feature Flags
      </Text>
    </Row>
  );
};

const useStyles = makeStyles(() => {
  return createStyleSheet({
    settingsWrapper: { gap: SPACING.SMALL },
    switch: {
      fontWeight: "bold",
      alignContent: "center",
      justifyContent: "center",
    },
  });
});

export default memo(DevModeSwitch);
