import React, { memo } from "react";
import AttributionTooltip, {
  AttributionTooltipPopoverComponent,
} from "~/components/elements/AttributionTooltip";
import RSSCard from "~/components/elements/RSSCard";

type RSSPopoverComponentProps = {
  item: FC.Item;
};

const RSSPopoverComponent: AttributionTooltipPopoverComponent<
  RSSPopoverComponentProps
> = ({ popoverComponentProps: { item } }) => <RSSCard item={item} />;

const RSSTooltip: React.FC<
  React.PropsWithChildren<RSSPopoverComponentProps>
> = ({ item, children }) => {
  return (
    <AttributionTooltip<RSSPopoverComponentProps>
      PopoverComponent={RSSPopoverComponent}
      popoverComponentProps={{ item }}
    >
      {children}
    </AttributionTooltip>
  );
};

export default memo(RSSTooltip);
