import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  MutationDefinition,
} from "@reduxjs/toolkit/dist/query";
import { MutationActionCreatorResult } from "@reduxjs/toolkit/dist/query/core/buildInitiate";
import { useEffect, useState } from "react";
import api, { useLazyGetCustomFeedQuery } from "~/api";
import { selectSelectedFeed } from "~/concepts/groups";
import { useAppDispatch, useAppSelector } from "~/hooks";
import { useIsGroupOwner } from "~/hooks/useIsGroupOwner";
import { selectApplicationMode } from "~/concepts/application";
import { customFeedIdFromRouteFeedId } from "~/utils/id";

type UpdateItemRequests = MutationActionCreatorResult<
  MutationDefinition<
    {
      id: string;
      item: string;
    },
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
    never,
    void,
    "api"
  >
>;

export const useUpdateCustomFeed = () => {
  const dispatch = useAppDispatch();
  const [getCustomFeedTrigger, getCustomFeedResponse] =
    useLazyGetCustomFeedQuery();
  const [customFeed, setCustomFeed] = useState<FC.CustomFeed | null>(null);
  const [updateItemsLoading, setUpdateItemsLoading] = useState(false);
  const [isUpdateSuccess, setIsUpdateSuccess] = useState(false);
  const [isUpdateError, setIsUpdateError] = useState(false);

  const applicationMode = useAppSelector(selectApplicationMode);
  const isGroupOwner = useIsGroupOwner();
  const selectedFeed = useAppSelector(selectSelectedFeed);

  useEffect(() => {
    if (selectedFeed?.id && isGroupOwner(selectedFeed) && !customFeed) {
      getCustomFeedTrigger(customFeedIdFromRouteFeedId(selectedFeed?.id));
    }
  }, [
    applicationMode,
    getCustomFeedTrigger,
    isGroupOwner,
    selectedFeed,
    customFeed,
  ]);

  useEffect(() => {
    if (getCustomFeedResponse.data && getCustomFeedResponse.isSuccess) {
      const { data } = getCustomFeedResponse;
      setCustomFeed(data);
    }
  }, [getCustomFeedResponse]);

  const removeFromCustomFeed = (removals: Array<string>) => {
    if (!customFeed) return;
    setUpdateItemsLoading(true);
    const requests: Array<UpdateItemRequests> = [];

    const includesToRemove = customFeed.includes.filter((i) =>
      removals.includes(i)
    );

    const excludesToAdd = removals.filter(
      (e) => !customFeed.excludes.includes(e)
    );

    excludesToAdd.forEach((item) => {
      requests.push(
        dispatch(
          api.endpoints.addCustomFeedItemExclude.initiate({
            id: customFeed.id,
            item,
          })
        )
      );
    });
    includesToRemove.forEach((item) => {
      requests.push(
        dispatch(
          api.endpoints.removeCustomFeedItem.initiate({
            id: customFeed.id,
            item,
          })
        )
      );
    });

    Promise.all(requests).then((result) => {
      setUpdateItemsLoading(false);

      if (
        result.some((r) => {
          return Object.hasOwn(r, "error");
        })
      ) {
        setIsUpdateError(true);
        return;
      } else {
        setIsUpdateSuccess(true);
      }
    });
  };

  return {
    customFeed,
    updateItemsLoading,
    removeFromCustomFeed,
    isUpdateSuccess,
    isUpdateError,
  };
};
