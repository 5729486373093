import React, { memo } from "react";
import { makeStyles } from "@rneui/themed";
import { createStyleSheet } from "~/styles";
import { Colors } from "@rneui/themed/dist/config/colors";
import { Column, Row, Text } from "~/components/elements";
import Image from "~/components/elements/Image";
import { SPACING } from "~/styles";
import { StyleProp, TextStyle } from "react-native";
import { TextAttributionStyle } from "~/enums";

export type DisplayNameProps = {
  author: Pick<FC.Author, "username" | "display_name" | "emojis">;
  color?: keyof Colors;
  overlay?: boolean;
  displayNameStyle?: StyleProp<TextStyle>;
  subText?: boolean;
  flex?: boolean | number;
  emojiSize?: number;
  numberOfLines?: number;
  textSize?: number;
};
const DisplayName: React.FC<DisplayNameProps> = ({
  author,
  overlay,
  color,
  displayNameStyle,
  subText,
  flex = false,
  emojiSize = 14,
  numberOfLines = 1,
  textSize,
}) => {
  // sometimes author.display_name can be an empty string -- fallback to username
  const preferredName = author.display_name || author.username;
  const shortcodes = preferredName.match(/:[^:]+:/g);
  const split = preferredName.split(/:[^:]+:/g);

  const styles = useStyles({});
  const flexStyle = typeof flex === "number" ? { flex } : flex && styles.flex1;

  const attribution = subText
    ? TextAttributionStyle.STANDARD_SUBTEXT
    : TextAttributionStyle.STANDARD_HEADLINE;
  const renderDisplayName = (
    authorDisplayName: FC.Author["display_name"] | FC.Author["username"]
  ) => {
    return (
      <Text
        size={textSize}
        style={[displayNameStyle]}
        color={overlay ? "overlay" : color}
        numberOfLines={numberOfLines}
        key={authorDisplayName}
        attribution={displayNameStyle ? undefined : attribution}
      >
        {authorDisplayName}
      </Text>
    );
  };

  if (split.length === 1) {
    renderDisplayName(preferredName);
  }

  return (
    <Row noGap style={[flexStyle, styles.container]}>
      {split.reduce<Array<React.ReactNode>>((acc, item, index) => {
        acc.push(renderDisplayName(item));
        if (shortcodes?.[index]) {
          const shortcode = shortcodes[index].replace(/:/g, "");
          const replacement = author.emojis?.find(
            (emoji) => emoji.shortcode === shortcode
          );
          const imageUrl = replacement?.static_url || replacement?.url;
          if (imageUrl) {
            acc.push(
              <Column
                key={`emoji_${imageUrl}`}
                style={{
                  height: emojiSize,
                  width: emojiSize,
                  marginLeft: SPACING.BASE,
                }}
              >
                <Image
                  key={imageUrl}
                  url={imageUrl}
                  width={emojiSize}
                  height={emojiSize}
                  wrapperStyles={false}
                />
              </Column>
            );
          }
        }
        return acc;
      }, [])}
    </Row>
  );
};
const useStyles = makeStyles(() => {
  return createStyleSheet({
    container: {
      alignItems: "center",
      maxWidth: "100%",
    },
  });
});
export default memo(DisplayName);
