import React, { memo } from "react";
import { SPACING, createStyleSheet } from "~/styles";
import { Column, Row } from "~/components/elements";
import BaseCard from "~/components/cards/BaseCard";
import BasicImageCard from "~/components/cards/BasicImageCard";
import { isGraphicItem, isImageItem, isPhotoItem } from "~/predicates";
import PhotoCard from "~/components/cards/PhotoCard";
import { CardSize } from "~/enums";
import { StyleProp, ViewStyle } from "react-native";
import { Divider, useTheme } from "@rneui/themed";

const BasicColumn: React.FC<{
  flex?: boolean | number;
  items: Array<FC.Item>;
  width: number;
  padding?: number;
  cardPadding?: number;
  cardSize?: CardSize;
  style?: StyleProp<ViewStyle>;
  overlay?: boolean;
  CardComponent?: React.JSXElementConstructor<FC.CardComponentProps>;
  cellDivider?: boolean;
  noGap?: boolean;
}> = ({
  flex,
  items,
  width,
  padding = SPACING.LARGE,
  style,
  overlay,
  CardComponent,
  cardSize = CardSize.MEDIUM,
  cellDivider = false,
  noGap,
}) => {
  const { theme } = useTheme();

  const flexStyle = typeof flex === "number" ? { flex } : flex && styles.flex1;
  return (
    <Column
      noGap={noGap}
      style={[
        flexStyle,
        style,
        { width: width, ...(!cellDivider && { padding: padding }) },
      ]}
    >
      {items.map((item, i) => {
        let defaultCardComponent;
        if (isImageItem(item) && isPhotoItem(item)) {
          defaultCardComponent = PhotoCard;
        }
        if (isGraphicItem(item)) {
          defaultCardComponent = BasicImageCard;
        }
        return (
          <>
            {cellDivider && i > 0 && (
              <Divider
                color={theme.colors.dividerOverlay}
                orientation={"horizontal"}
              />
            )}
            <Row flex key={item.id} style={cellDivider && { padding: padding }}>
              <BaseCard
                key={item.id}
                item={item}
                width={width - padding * 2}
                overlay={overlay}
                CardComponent={CardComponent || defaultCardComponent}
                cardSize={cardSize}
              />
            </Row>
          </>
        );
      })}
    </Column>
  );
};

const styles = createStyleSheet({});

export default memo(BasicColumn);
