import cloneDeep from "lodash-es/cloneDeep";
import isEqual from "lodash-es/isEqual";
import { usePreferences } from "~/hooks/usePreferences";

export const useFavorite = () => {
  const [preferences, setPreferences] = usePreferences();

  const addFavorite = (feed: FC.Group) => {
    const newPreferences = cloneDeep(preferences);
    if (!newPreferences.feeds.saved.includes(feed.id)) {
      newPreferences.feeds.saved.push(feed.id);
    }
    if (!isEqual(preferences, newPreferences)) {
      setPreferences(newPreferences);
    }
  };

  const removeFavorite = (feed: FC.Group) => {
    const newPreferences = cloneDeep(preferences);
    newPreferences.feeds.saved = newPreferences.feeds.saved.filter(
      (id) => id !== feed.id
    );

    if (!isEqual(preferences, newPreferences)) {
      setPreferences(newPreferences);
    }
  };
  return { addFavorite, removeFavorite };
};
