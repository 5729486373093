import React, { memo } from "react";
import { StyleProp } from "react-native";
import { SPACING } from "~/styles";
import { useTheme } from "@rneui/themed";
import { useFavorite } from "~/hooks/useFavorite";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { useToast } from "react-native-toast-notifications";
import { selectFeedIsFavorited } from "~/concepts/application";
import { useAppSelector } from "~/hooks";
import { selectSelectedFeed } from "~/concepts/groups";
import { Button } from "~/components/elements";

const FeedFavoriteButton: React.FC<{
  feed?: FC.Group;
  overlay?: boolean;
  showTitle?: boolean;
  style?: StyleProp<ViewStyle>;
  type?: "outline" | "solid" | "clear";
  color?: string;
}> = ({
  feed,
  type = "clear",
  overlay = false,
  showTitle = false,
  style,
  color,
}) => {
  const { theme } = useTheme();
  const { addFavorite, removeFavorite } = useFavorite();
  const toast = useToast();
  const selectedFeed = useAppSelector(selectSelectedFeed);
  const toggleFeed = feed || selectedFeed;
  const isFavorite = useAppSelector(selectFeedIsFavorited(toggleFeed));

  if (!toggleFeed) return null;

  const doFavoriteAction = () => {
    isFavorite ? removeFavorite(toggleFeed) : addFavorite(toggleFeed);

    toast.show(
      isFavorite
        ? `Removed ${toggleFeed.title}" from feeds.`
        : `Saved "${toggleFeed.title}" to feeds.`,
      {
        type: "success",
      }
    );
  };
  let iconColor = overlay ? theme.colors.primaryReverse : theme.colors.primary;
  if (color && isFavorite) {
    iconColor = color;
  }
  return (
    <Button
      style={style}
      type={type}
      title={showTitle ? "Favorite" : undefined}
      titleStyle={showTitle ? { marginLeft: SPACING.SMALL } : undefined}
      onPress={doFavoriteAction}
      icon={
        <Icon
          name={isFavorite ? "star" : "star-outline"}
          size={SPACING.BASE3X}
          solid={true}
          color={iconColor}
        />
      }
    />
  );
};
export default memo(FeedFavoriteButton);
