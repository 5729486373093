import { Dialog, makeStyles, useTheme } from "@rneui/themed";
import React, { memo, useState } from "react";
import { FlatList } from "react-native";
import { Row, Button, Text, Divider } from "~/components/elements";
import { TextSize } from "~/enums";
import { createStyleSheet, SPACING } from "~/styles";
import CloseButton from "~/buttons/CloseButton";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { useAppDispatch } from "~/hooks";
import {
  removeCustomFeedBuilderExclude,
  setCustomFeedBuilderTriggerSave,
} from "~/concepts/customFeedBuilder";
import CustomFeedFormOption from "~/components/CustomFeedFormOption";

type CustomFeedFormExcludedProps = {
  formValue: FC.CustomFeedConfiguration;
  setFormValue: (formValue: FC.CustomFeedConfiguration) => void;
};

const CustomFeedFormExcluded: React.FC<CustomFeedFormExcludedProps> = (
  props
) => {
  const { formValue } = props;
  const [visible, setVisible] = useState(false);
  const styles = useStyles();
  const { theme } = useTheme();

  const dispatch = useAppDispatch();

  return (
    <>
      <Button
        title={"Excluded"}
        onPress={() => setVisible(true)}
        containerStyle={{ borderRadius: SPACING.BASE }}
        buttonStyle={{
          gap: SPACING.BASE,
          borderRadius: SPACING.BASE,
        }}
        type={"outline"}
      />
      <Dialog
        isVisible={visible}
        onBackdropPress={() => setVisible(false)}
        overlayStyle={styles.dialogOverlay}
      >
        <CloseButton
          onPress={() => setVisible(false)}
          style={styles.closeButton}
        />
        <Dialog.Title title="Excluded Sources" />
        <Row style={styles.eduWrapper} gap={SPACING.BASE}>
          <Icon name="information-outline" size={SPACING.BASE3X} />
          <Text size={TextSize.M}>
            These sources are currently excluded from your "{formValue.title}"
            custom feed. To see them in your feed again, remove them below.
          </Text>
        </Row>
        <Divider style={styles.divider} />
        <FlatList
          data={formValue.excludes}
          renderItem={({ item: source }) => (
            <Row style={styles.alignCenter} evenlySpaced flex>
              <CustomFeedFormOption arg={source} />
              <Button
                type={"clear"}
                icon={
                  <Icon
                    name="window-close"
                    color={theme.colors.controlsRed}
                    size={24}
                  />
                }
                onPress={() => {
                  dispatch(removeCustomFeedBuilderExclude(source));
                  dispatch(setCustomFeedBuilderTriggerSave(true));
                }}
              />
            </Row>
          )}
        />
      </Dialog>
    </>
  );
};

const useStyles = makeStyles(() =>
  createStyleSheet({
    closeButton: {
      position: "absolute",
      top: SPACING.BASE,
      right: SPACING.BASE,
      zIndex: 1,
    },
    dialogOverlay: { width: "50%", maxWidth: 400 },
    divider: {
      marginTop: SPACING.LARGE,
      marginBottom: SPACING.LARGE,
    },
  })
);

export default memo(CustomFeedFormExcluded);
