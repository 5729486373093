import React, { memo } from "react";
import { Column, Row, Text } from "~/components/elements";
import { useGroupHeaderStyles } from "~/components/GroupHeader";
import { SPACING } from "~/styles";
import { selectIsAuthenticated } from "~/concepts/auth";
import { useAppSelector } from "~/hooks";
import FeedFavoriteButton from "~/buttons/FeedFavoriteButton";
import { Avatar } from "@rneui/themed";

const TopicHeader: React.FC<{ feed: FC.Group }> = ({ feed: topic }) => {
  const styles = useGroupHeaderStyles();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  return (
    <Column flex style={[styles.headerContainer]}>
      <Row style={[styles.headerControls]}>
        {isAuthenticated && (
          <FeedFavoriteButton feed={topic} showTitle type={"outline"} />
        )}
      </Row>
      <Column
        flex
        gap={SPACING.BASE3X}
        style={[
          styles.headerContents,
          styles.alignCenter,
          styles.justifyCenter,
        ]}
      >
        <Text style={[styles.title, styles.headerText]}>{topic?.title}</Text>
        <Row style={[styles.alignCenter, styles.justifyCenter]}>
          <Avatar
            rounded
            size={32}
            source={{
              uri: "https://s3.amazonaws.com/s.flipboard.com/assets/webu/images/meta/flipboard-512.png",
            }}
          />
          <Text>by Flipboard</Text>
        </Row>
        <Text style={styles.textCenter}>
          {`Dive deeper through content related to ${topic?.title}.  We've gathered the most recent headlines, conversations, videos and more on ${topic?.title}.`}
        </Text>
      </Column>
    </Column>
  );
};
export default memo(TopicHeader);
