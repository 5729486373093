import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import uniq from "lodash-es/uniq";
import { RootState } from "~/store";

interface CustomFeedBuilderState {
  triggerSave: boolean;
  formValue: FC.CustomFeedConfiguration;
  previewFeed: FC.Group | null;
  customFeedPreviewFeed: FC.Group | null;
  requestCustomFeedEditSwitchToView: boolean;
}

const initialState: CustomFeedBuilderState = {
  triggerSave: false,
  formValue: {
    title: "",
    description: "",
    includes: [],
    excludes: [],
    image: null,
  },
  previewFeed: null,
  customFeedPreviewFeed: null,
  requestCustomFeedEditSwitchToView: false,
};

export const customFeedBuilderSlice = createSlice({
  name: "customFeedBuilder",
  initialState,
  reducers: {
    setCustomFeedBuilderTriggerSave: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.triggerSave = payload;
    },
    setCustomFeedBuilderFormValue: (
      state,
      { payload }: PayloadAction<CustomFeedBuilderState["formValue"]>
    ) => {
      state.formValue = payload;
    },
    addCustomFeedBuilderInclude: (state) => {
      state.formValue.includes =
        state.previewFeed && state.previewFeed.customFeedValue
          ? uniq([
              state.previewFeed.customFeedValue,
              ...state.formValue.includes,
            ])
          : state.formValue.includes;
    },
    addCustomFeedBuilderIncludeFeed(state, { payload }: PayloadAction<string>) {
      state.formValue.includes = payload
        ? uniq([payload, ...state.formValue.includes])
        : state.formValue.includes;
    },
    addCustomFeedBuilderExclude: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      state.formValue.excludes = uniq([...state.formValue.excludes, payload]);
    },
    removeCustomFeedBuilderExclude: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      state.formValue.excludes = state.formValue.excludes.filter(
        (x) => x !== payload
      );
    },
    setRequestCustomFeedEditSwitchToView: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.requestCustomFeedEditSwitchToView = payload;
    },
    setCustomFeedBuilderPreviewFeed: (
      state,
      { payload }: PayloadAction<FC.Group | null>
    ) => {
      state.previewFeed = payload;
    },
    setCustomFeedBuilderCustomFeedPreviewFeed: (
      state,
      { payload }: PayloadAction<FC.Group | null>
    ) => {
      state.customFeedPreviewFeed = payload;
    },
  },
});

export const {
  setCustomFeedBuilderFormValue,
  addCustomFeedBuilderInclude,
  addCustomFeedBuilderIncludeFeed,
  addCustomFeedBuilderExclude,
  removeCustomFeedBuilderExclude,
  setCustomFeedBuilderPreviewFeed,
  setCustomFeedBuilderCustomFeedPreviewFeed,
  setRequestCustomFeedEditSwitchToView,
  setCustomFeedBuilderTriggerSave,
} = customFeedBuilderSlice.actions;

export const selectCustomFeedBuilderFormValue = (state: RootState) =>
  state.customFeedBuilder.formValue;

export const selectCustomFeedBuilderPreviewFeed = (state: RootState) =>
  state.customFeedBuilder.previewFeed;

export const selectCustomFeedBuilderCustomFeedPreviewFeed = (
  state: RootState
) => state.customFeedBuilder.customFeedPreviewFeed;

export const selectRequestCustomFeedEditSwitchToView = (state: RootState) =>
  state.customFeedBuilder.requestCustomFeedEditSwitchToView;

export const selectCustomFeedBuilderTriggerSave = (state: RootState) =>
  state.customFeedBuilder.triggerSave;

export default customFeedBuilderSlice.reducer;
