import { api } from "~/api";
import { GroupRequestType } from "~/enums";
import { useAppDispatch } from "~/hooks";

const useResolveCompleteSearchResult = () => {
  const dispatch = useAppDispatch();
  return async (searchResult: FC.Group) => {
    if (
      searchResult.requestType === GroupRequestType.YOUTUBE &&
      searchResult.url
    ) {
      const response = await dispatch(
        api.endpoints.searchRss.initiate(searchResult.url)
      );
      const resolvedRSS = response?.data?.[0];
      if (resolvedRSS) {
        return resolvedRSS;
      }
    }
  };
};

export default useResolveCompleteSearchResult;
