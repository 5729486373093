import React from "react";
import {
  Divider as RNEDivider,
  DividerProps as RNEDividerProps,
  useTheme,
} from "@rneui/themed";
import { createStyleSheet } from "~/styles";

type DividerProps = {
  vertical?: boolean;
  color?: string;
} & RNEDividerProps;
const Divider: React.FC<DividerProps> = ({
  vertical = false,
  style,
  color,
  ...rest
}) => {
  const { theme } = useTheme();
  return (
    <RNEDivider
      {...rest}
      style={
        vertical
          ? [styles.verticalDivider, style]
          : [styles.horizontalDivider, style]
      }
      orientation={vertical ? "vertical" : "horizontal"}
      color={color || theme.colors.grey4}
    />
  );
};

const styles = createStyleSheet({
  horizontalDivider: { width: "100%" },
  verticalDivider: { height: "100%" },
});
export default Divider;
