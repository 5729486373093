import React, { memo } from "react";
import { createStyleSheet } from "~/styles";
import { makeStyles } from "@rneui/themed";
import { StyleProp, Pressable as RNPressable } from "react-native";
import Hoverable from "~/components/elements/Hoverable";
import HoverOverlay from "~/components/elements/HoverOverlay";

export type PressableProps = {
  flex?: boolean | number;
  overlay?: boolean;
  style?: StyleProp<ViewStyle>;
  onPress?: () => void;
  children?: React.ReactNode | ((isHovered: boolean) => React.ReactNode);
};

const Pressable: React.FC<PressableProps> = ({
  flex,
  overlay,
  style,
  onPress,
  children,
}) => {
  const styles = useStyles();
  const flexStyle = typeof flex === "number" ? { flex } : flex && styles.flex1;
  return (
    <Hoverable style={[flexStyle, style]}>
      {(isHovered) => {
        return (
          <RNPressable onPress={onPress} style={[styles.flex1]}>
            {typeof children === "function" ? children(isHovered) : children}

            <HoverOverlay isHovered={isHovered} overlay={overlay} />
          </RNPressable>
        );
      }}
    </Hoverable>
  );
};

const useStyles = makeStyles(() => {
  return createStyleSheet({
    solidButton: { borderWidth: 1 },
    defaultButtonStyle: { flex: 1, width: "100%", height: "100%" },
  });
});

export default memo(Pressable);
