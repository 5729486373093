import React, { memo } from "react";
import en from "javascript-time-ago/locale/en";
import TimeAgo from "javascript-time-ago";
import { Text, TextProps } from "~/components/elements";
import { TextAttributionStyle } from "~/enums";

TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo("en");

const TimeAgoText: React.FC<
  { children: Date } & Omit<TextProps, "children">
> = ({ children, ...restProps }) => {
  return (
    <Text attribution={TextAttributionStyle.STANDARD_SUBTEXT} {...restProps}>
      {timeAgo.format(children, "twitter")}
    </Text>
  );
};

export default memo(TimeAgoText);
