import React, { memo, useCallback, useEffect } from "react";
import { useCreateCustomFeedMutation } from "~/api";
import { useAppDispatch } from "~/hooks";
import { addGroups } from "~/concepts/groups";
import { customFeedGroupFromCustomFeed } from "~/utils/groups";

import { useToast } from "react-native-toast-notifications";
import CustomFeedForm from "~/components/CustomFeedForm";
import { CustomFeedFormType } from "~/enums";
import { useFavorite } from "~/hooks/useFavorite";
import { useFeedNavigation } from "~/hooks/useFeedNavigation";
import { useNavigate } from "~/routing";

const CreateCustomFeed: React.FC<{
  formValue: FC.CustomFeedConfiguration;
  setFormValue: (arg: FC.CustomFeedConfiguration) => void;
}> = ({ formValue, setFormValue }) => {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { addFavorite } = useFavorite();
  const navigate = useNavigate();
  const { navigateToGroup } = useFeedNavigation();

  const [trigger, { data: mutatedFeed, isLoading, isSuccess, isError, reset }] =
    useCreateCustomFeedMutation();

  useEffect(() => {
    if (isSuccess && mutatedFeed) {
      reset();
      const customFeedGroup = customFeedGroupFromCustomFeed(mutatedFeed);
      dispatch(addGroups([customFeedGroup]));
      addFavorite(customFeedGroup);
      toast.show(`Created custom feed "${formValue.title}"`, {
        type: "success",
      });
      navigateToGroup(customFeedGroup);
    }
  }, [
    reset,
    mutatedFeed,
    dispatch,
    isSuccess,
    toast,
    formValue.title,
    addFavorite,
    navigateToGroup,
  ]);

  const onSubmit = useCallback(() => {
    trigger({ body: formValue });
  }, [trigger, formValue]);

  return (
    <CustomFeedForm
      type={CustomFeedFormType.CREATE}
      isLoading={isLoading}
      isDisabled={false}
      formValue={formValue}
      setFormValue={setFormValue}
      isSuccess={isSuccess}
      isError={isError}
      onSubmit={onSubmit}
      onCancel={() => navigate("/")}
    />
  );
};

export default memo(CreateCustomFeed);
