import React, { memo } from "react";
import { SPACING, createStyleSheet } from "~/styles";
import { makeStyles, Dialog as RNEDialog } from "@rneui/themed";
import { DialogProps as RNEDialogProps } from "@rneui/base";
import { isPhoneSelector } from "~/concepts/application";
import { useAppSelector } from "~/hooks";
import { Row, Button, Text, TextProps } from "~/components/elements/";
import { DimensionValue } from "react-native";

const DEFAULT_DIALOG_WIDTH = "50%";
const DEFAULT_DIALOG_MAX_WIDTH = 500;
const DEFAULT_DIALOG_HEIGHT = 450;

const Title: React.FC<TextProps> = (props) => {
  const styles = useStyles();
  return (
    <Text
      {...props}
      style={[styles.titleRow, styles.dialogTitle, props.style]}
    />
  );
};

const Actions: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const styles = useStyles();
  return <Row style={styles.actionsRow}>{children}</Row>;
};

export interface DialogProps extends RNEDialogProps {
  width?: DimensionValue;
  height?: number;
  fullScreen?: boolean;
}
interface DialogSubComponents {
  Title: typeof Title;
  Actions: typeof Actions;
  Button: typeof Button;
}

const Dialog: React.FC<DialogProps> & DialogSubComponents = ({
  children,
  style,
  width = DEFAULT_DIALOG_WIDTH,
  height = DEFAULT_DIALOG_HEIGHT,
  fullScreen,
  ...restProps
}) => {
  const isPhone = useAppSelector(isPhoneSelector);
  const styles = useStyles({ isPhone });
  const sizeStyle = {
    width,
    maxWidth: DEFAULT_DIALOG_MAX_WIDTH,
    maxHeight: height,
  };
  return (
    <RNEDialog
      {...restProps}
      overlayStyle={[
        styles.defaultDialog,
        fullScreen ? styles.fullScreenDialog : sizeStyle,
        style,
      ]}
    >
      {children}
    </RNEDialog>
  );
};

const useStyles = makeStyles((theme, { isPhone }: { isPhone: boolean }) => {
  return createStyleSheet({
    fullScreenDialog: { width: "100%", height: " 100%", maxHeight: "100%" },
    defaultDialog: {
      flexShrink: 1,
      backgroundColor: theme.colors.background,
      padding: SPACING.BASE3X,
      paddingTop: isPhone ? SPACING.BASE6X : SPACING.BASE3X,
      borderRadius: SPACING.BASE,
      alignSelf: "center",
      justifySelf: "center",
      gap: SPACING.LARGE,
    },
    titleRow: {
      justifyContent: "flex-start",
      flexWrap: "wrap",
    },
    actionsRow: {
      justifyContent: "flex-end",
    },
  });
});

Dialog.Title = memo(Title);
Dialog.Actions = memo(Actions);
Dialog.Button = memo(Button);
export default Object.assign(memo(Dialog), { Title, Actions, Button });
