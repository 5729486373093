import { useAppSelector } from "~/hooks";
import URL from "~/utils/url";
import { selectCredentials, selectIsAuthenticated } from "~/concepts/auth";

export const useGetAuthorUrl = () => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const credentials = useAppSelector(selectCredentials);
  return (author: FC.Author) => {
    if (!isAuthenticated || !credentials) {
      return author.url;
    }
    const u = new URL(credentials.url);
    return `${u.origin}/@${author.acct}`;
  };
};
