import React, {
  memo,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  FlatList,
  Platform,
  TextInput,
  NativeSyntheticEvent,
  TextInputKeyPressEventData,
} from "react-native";
import { makeStyles, useTheme } from "@rneui/themed";
import { createStyleSheet, LAYOUT, SPACING } from "~/styles";
import {
  Button,
  Column,
  Row,
  Text,
  SearchBar,
  CollapsibleAnimatedView,
} from "~/components/elements";
import { useAppDispatch, useAppSelector } from "~/hooks";

import {
  addGroups,
  selectFeedLoading,
  selectSelectedFeed,
} from "~/concepts/groups";
import {
  customFeedGroupFromCustomFeed,
  searchFeedFromTerm,
} from "~/utils/groups";
import { useSearch } from "~/hooks/useSearch";
import { ApplicationMode, GroupRequestType, SearchResultType } from "~/enums";
import { useNavigate } from "~/routing";
import Author from "~/components/elements/Author";
import {
  isPhoneSelector,
  selectApplicationMode,
  selectHideSearch,
  selectSearchFocused,
  selectTriggerSearch,
  setHideSearch,
  setSearchFocused,
  setTriggerSearch,
} from "~/concepts/application";
import {
  selectCustomFeedBuilderCustomFeedPreviewFeed,
  selectCustomFeedBuilderPreviewFeed,
  setCustomFeedBuilderPreviewFeed,
} from "~/concepts/customFeedBuilder";
import CustomFeedBuilderSearchEdu from "~/components/edu/CustomFeedBuilderSearchEdu";
import SearchHeaderModalControls from "~/components/SearchHeaderModalControls";
import FeedDisplayName from "~/components/elements/FeedDisplayName";
import Loading from "~/components/Loading";
import { api } from "~/api";
import { useFavorite } from "~/hooks/useFavorite";
import useResolveCompleteSearchResult from "~/hooks/useResolveCompleteSearchResult";
import { useAddRecentSearchResults } from "~/hooks/useCookies";
import { SLIDE_IN_DIRECTION } from "~/components/elements/CollapsibleAnimatedView";
import useDebounce from "~/hooks/useDebounce";

const Search: React.FC = () => {
  const isPhone = useAppSelector(isPhoneSelector);
  const styles = useStyles({ isPhone });
  const {
    value: searchValue,
    debouncedValue: debouncedSearchValue,
    setValue: setSearchValue,
  } = useDebounce<string>("", 200);
  const [working, setWorking] = useState(false);
  const [asteriskEntered, setAsteriskEntered] = useState(false);
  const dispatch = useAppDispatch();
  const addRecentSearchResult = useAddRecentSearchResults();
  const selectedFeed = useAppSelector(selectSelectedFeed);
  const feedLoading = useAppSelector(selectFeedLoading);
  const applicationMode = useAppSelector(selectApplicationMode);
  const navigate = useNavigate();
  const previewFeed = useAppSelector(selectCustomFeedBuilderPreviewFeed);
  const customFeedPreviewFeed = useAppSelector(
    selectCustomFeedBuilderCustomFeedPreviewFeed
  );
  const focused = useAppSelector(selectSearchFocused);
  const hideSearch = useAppSelector(selectHideSearch);

  const triggerSearch = useAppSelector(selectTriggerSearch);
  const { addFavorite } = useFavorite();
  const resolveCompleteSearchResult = useResolveCompleteSearchResult();

  const [currentResultSet, setCurrentResultSet] = useState(0);
  const [focusedSearchSuggestionIndex, setFocusedSearchSuggestionIndex] =
    useState<number>(-1);
  const searchBarRef = useRef<PropsWithChildren<TextInput>>(null);
  const searchResultsRef = useRef<FlatList>(null);
  const { theme } = useTheme();

  const isCustomFeedBuilderMode = useCallback(() => {
    return !isPhone && applicationMode === ApplicationMode.CUSTOM_FEED_BUILDER;
  }, [isPhone, applicationMode]);

  const baseSearch = useSearch(
    [
      SearchResultType.DIRECT,
      SearchResultType.RECENT,
      SearchResultType.FAVORITE,
      SearchResultType.FEATURED,
      SearchResultType.ACCOUNT,
      SearchResultType.HASHTAG,
      SearchResultType.TOPIC,
      SearchResultType.RSS,
      SearchResultType.YOUTUBE,
    ],
    { limit: 5, exactMatch: true }
  );
  const customFeedSearch = useSearch(
    [
      SearchResultType.TOPIC,
      SearchResultType.ACCOUNT,
      SearchResultType.HASHTAG,
      SearchResultType.RSS,
      SearchResultType.YOUTUBE,
    ],
    { limit: 5, exactMatch: true }
  );

  const [currentSearchResults, setCurrentSearchResults] = useState<
    Array<{
      key: SearchResultType;
      results: Array<FC.Group>;
    }>
  >([]);

  useEffect(() => {
    if (triggerSearch && searchBarRef?.current) {
      searchBarRef.current?.focus();
      dispatch(setTriggerSearch(false));
    }
  }, [dispatch, searchBarRef, triggerSearch]);

  const getSearchResults = useCallback(
    () =>
      isCustomFeedBuilderMode()
        ? customFeedSearch.searchResults
        : baseSearch.searchResults,
    [
      customFeedSearch.searchResults,
      baseSearch.searchResults,
      isCustomFeedBuilderMode,
    ]
  );
  const getSearchTrigger = useCallback(
    () =>
      isCustomFeedBuilderMode()
        ? customFeedSearch.searchTrigger
        : baseSearch.searchTrigger,
    [
      customFeedSearch.searchTrigger,
      baseSearch.searchTrigger,
      isCustomFeedBuilderMode,
    ]
  );
  const getSearchReset = useCallback(
    () =>
      isCustomFeedBuilderMode()
        ? customFeedSearch.searchReset
        : baseSearch.searchReset,
    [
      customFeedSearch.searchReset,
      baseSearch.searchReset,
      isCustomFeedBuilderMode,
    ]
  );

  const showResults = () => {
    setCurrentResultSet(0);
    setFocusedSearchSuggestionIndex(-1);
    dispatch(setSearchFocused(true));
  };

  useEffect(() => {
    const searchResultSet: Array<{
      key: SearchResultType;
      results: Array<FC.Group>;
    }> = [];
    const searchResults = getSearchResults();
    if (searchResults[SearchResultType.DIRECT]) {
      searchResultSet.push({
        key: SearchResultType.DIRECT,
        results: searchResults[SearchResultType.DIRECT],
      });
    }
    if (searchResults[SearchResultType.RECENT]) {
      searchResultSet.push({
        key: SearchResultType.RECENT,
        results: searchResults[SearchResultType.RECENT],
      });
    }
    if (searchResults[SearchResultType.FAVORITE]) {
      searchResultSet.push({
        key: SearchResultType.FAVORITE,
        results: searchResults[SearchResultType.FAVORITE],
      });
    }
    if (searchResults[SearchResultType.FEATURED]) {
      searchResultSet.push({
        key: SearchResultType.FEATURED,
        results: searchResults[SearchResultType.FEATURED],
      });
    }
    if (searchResults[SearchResultType.TOPIC]) {
      searchResultSet.push({
        key: SearchResultType.TOPIC,
        results: searchResults[SearchResultType.TOPIC],
      });
    }
    if (searchResults[SearchResultType.HASHTAG]) {
      searchResultSet.push({
        key: SearchResultType.HASHTAG,
        results: searchResults[SearchResultType.HASHTAG],
      });
    }
    if (searchResults[SearchResultType.ACCOUNT]) {
      searchResultSet.push({
        key: SearchResultType.ACCOUNT,
        results: searchResults[SearchResultType.ACCOUNT],
      });
    }
    if (searchResults[SearchResultType.YOUTUBE]) {
      searchResultSet.push({
        key: SearchResultType.YOUTUBE,
        results: searchResults[SearchResultType.YOUTUBE],
      });
    }
    if (searchResults[SearchResultType.RSS]) {
      searchResultSet.push({
        key: SearchResultType.RSS,
        results: searchResults[SearchResultType.RSS],
      });
    }
    setCurrentSearchResults(searchResultSet);
  }, [getSearchResults]);

  const reset = useCallback(() => {
    setSearchValue("");
    setFocusedSearchSuggestionIndex(-1);
    setCurrentResultSet(0);
  }, [setSearchValue]);

  const handleKeydown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "/") {
        e.preventDefault();
        dispatch(setHideSearch(false));
        setTimeout(() => searchBarRef.current?.focus());
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (Platform.OS !== "web") {
      return;
    }
    document.body.addEventListener("keydown", handleKeydown);
    return () => {
      document.body.removeEventListener("keydown", handleKeydown);
    };
  }, [handleKeydown]);

  useEffect(() => {
    if (searchValue.indexOf("#") === 0 && focusedSearchSuggestionIndex === -1) {
      const hashtagResultSetIndex = currentSearchResults.findIndex(
        (r) => r.key === SearchResultType.HASHTAG
      );
      if (
        hashtagResultSetIndex > -1 &&
        currentResultSet !== hashtagResultSetIndex
      ) {
        setCurrentResultSet(hashtagResultSetIndex);
      }
    }
  }, [
    searchValue,
    currentResultSet,
    currentSearchResults,
    focusedSearchSuggestionIndex,
  ]);

  useEffect(() => {
    if (debouncedSearchValue) {
      getSearchTrigger()(debouncedSearchValue);
    }
  }, [debouncedSearchValue, getSearchTrigger]);

  const blurInput = () => {
    searchBarRef.current?.blur();
  };

  const closeSearch = () => {
    blurInput();
    setTimeout(reset, 200);
  };

  const onSearchInputChange = (q: string) => {
    setSearchValue(q);
    if (q === "") {
      reset();
      getSearchReset()();
      return;
    }
  };

  const select = async (searchResult: FC.Group) => {
    if (!searchResult.id) {
      setWorking(true);
      blurInput();
      try {
        const resolvedSearchResult = await resolveCompleteSearchResult(
          searchResult
        );
        if (resolvedSearchResult) {
          searchResult = resolvedSearchResult;
        }
      } finally {
        setWorking(false);
      }
    }
    if (isCustomFeedBuilderMode()) {
      dispatch(setCustomFeedBuilderPreviewFeed(searchResult));
    } else {
      addRecentSearchResult(searchResult);
      navigate(`/feed/${searchResult.id}`);
    }
    closeSearch();
  };
  const getCurrentResultSet = () => {
    return currentSearchResults[currentResultSet]?.results || [];
  };

  const getNewResultSetIndex = (desiredIndex: number) => {
    let newIndex = desiredIndex;
    if (desiredIndex > currentSearchResults.length - 1) newIndex = 0;
    if (desiredIndex < 0) newIndex = currentSearchResults.length - 1;
    return newIndex;
  };

  const changeSearchSuggestionIndex = (desiredIndex: number) => {
    const searchResultSet = getCurrentResultSet() || [];
    if (searchResultSet.length <= 0) return;
    let newIndex = desiredIndex;
    let newResultSetIndex = currentResultSet;
    if (desiredIndex < 0) {
      newResultSetIndex = getNewResultSetIndex(currentResultSet - 1);
      const newResultSet = currentSearchResults[newResultSetIndex];
      newIndex = newResultSet.results.length - 1;
    }
    if (desiredIndex > searchResultSet.length - 1) {
      newResultSetIndex = getNewResultSetIndex(currentResultSet + 1);
      newIndex = 0;
    }
    setCurrentResultSet(newResultSetIndex);
    setFocusedSearchSuggestionIndex(newIndex);
    searchResultsRef.current?.scrollToItem({
      animated: false,
      item: currentSearchResults[newResultSetIndex],
      viewPosition: 0.5,
    });
  };

  const onSearchKeyPress = (
    e: NativeSyntheticEvent<TextInputKeyPressEventData>
  ) => {
    const key = e.nativeEvent.key;

    if (["ArrowDown", "ArrowUp", "Enter", "Escape", "Tab", "*"].includes(key)) {
      switch (key) {
        case "*":
          if (searchValue.length < 1) {
            break;
          }
          if (!asteriskEntered) {
            setAsteriskEntered(true);
            break;
          }
          setAsteriskEntered(false);
          const includes = currentSearchResults
            .flatMap((i) => i.results.slice(0, 5))
            .filter((i) => !!i.customFeedValue)
            .map((i) => i.customFeedValue as string);
          closeSearch();
          setWorking(true);
          dispatch(
            api.endpoints.createCustomFeed.initiate({
              body: {
                title: `My ${searchValue} custom feed`,
                description: `Custom feed created from search term: ${searchValue}`,
                image: null,
                includes,
                excludes: [],
              },
            })
          )
            .then((result) => {
              if ("error" in result) {
                throw new Error();
              }
              const customFeedGroup = customFeedGroupFromCustomFeed(
                result.data
              );
              dispatch(addGroups([customFeedGroup]));
              addFavorite(customFeedGroup);
              navigate(`/custom-feed/${customFeedGroup.requestKey}/edit`);
            })
            .finally(() => {
              setWorking(false);
            });
          break;
        case "Escape":
          closeSearch();
          break;
        case "Tab":
        case "ArrowDown":
          changeSearchSuggestionIndex(focusedSearchSuggestionIndex + 1);
          break;
        case "ArrowUp":
          changeSearchSuggestionIndex(focusedSearchSuggestionIndex - 1);
          break;
        case "Enter":
          const loadIndex =
            focusedSearchSuggestionIndex >= 0
              ? focusedSearchSuggestionIndex
              : 0;
          const feed = getCurrentResultSet()[loadIndex];

          // make sure we do realtime term search (if you manage to
          // get more characters in before results have updated,
          // search for exactly what you typed)
          if (feed?.requestType === GroupRequestType.SEARCH) {
            return select(searchFeedFromTerm(searchValue));
          }

          if (feed) {
            select(feed);
          }
          break;
        default:
          return;
      }
      e.preventDefault();
      e.stopPropagation();
    } else {
      setFocusedSearchSuggestionIndex(-1);
      // don't reset if searching for hashtag, will lead to jitter
      if (searchValue.indexOf("#") === -1) {
        setCurrentResultSet(0);
      }
    }
  };

  const getSetTitle = (key: SearchResultType) => {
    switch (key) {
      case SearchResultType.RECENT:
        return "🕙 Recent";
      case SearchResultType.TOPIC:
        return "*️⃣ Topics";
      case SearchResultType.FEATURED:
        return "🏷️ Featured";
      case SearchResultType.FAVORITE:
        return "⭐ Favorites";
      case SearchResultType.HASHTAG:
        return "#️⃣ Hashtags";
      case SearchResultType.ACCOUNT:
        return "👤️ Profiles";
      case SearchResultType.RSS:
        return "📖 RSS Feeds";
      case SearchResultType.YOUTUBE:
        return "▶️ Youtube Channels";
    }
  };

  const getResultDisplay = (feed: FC.Group) => {
    if (feed.requestType === GroupRequestType.ACCOUNT) {
      return (
        <Author
          author={
            {
              display_name: feed.title,
              avatar: feed.image || "",
              username: feed.description || "",
            } as FC.Author
          }
          showUsername
          hideTooltip
          link={false}
        />
      );
    }
    return feed.title;
  };

  const renderResultsList = (
    key: SearchResultType,
    columnIndex: number,
    results?: Array<FC.Group>
  ) => {
    const title = getSetTitle(key);
    return results && results.length > 0 ? (
      <Column
        noGap
        key={`c_${columnIndex}`}
        style={[styles.searchResultColumn]}
      >
        {title && <Text style={styles.searchResultColumnTitle}>{title}</Text>}
        {results.map((searchResult, index) => (
          <Button
            key={searchResult.id}
            type={"clear"}
            onPressIn={() => {
              select(searchResult);
            }}
            titleStyle={styles.searchResultTitle}
            style={styles.flex1}
            buttonStyle={[
              styles.searchResultButton,
              columnIndex === currentResultSet &&
                index === focusedSearchSuggestionIndex &&
                styles.focusedSearchResult,
              focusedSearchSuggestionIndex === -1 &&
                index === 0 &&
                currentResultSet === columnIndex &&
                styles.imFeelingLuckySearchResult,
            ]}
          >
            {getResultDisplay(searchResult)}
          </Button>
        ))}
      </Column>
    ) : null;
  };
  let placeholder = "eg: hiking or #hiking or @Eugen";
  if (!focused) {
    placeholder = selectedFeed?.title ? "" : "What Interests You?";
  }
  if (customFeedPreviewFeed) {
    placeholder = isPhone
      ? customFeedPreviewFeed.title
      : `Previewing: ${customFeedPreviewFeed.title}`;
  }
  if (previewFeed) {
    placeholder = isPhone
      ? previewFeed.title
      : `Previewing: ${previewFeed.title}`;
  }
  return (
    <Column noGap style={[styles.searchWrapper]}>
      <CollapsibleAnimatedView
        show={!hideSearch}
        fade
        flexRange={[0, 1]}
        style={styles.searchBarWrapper}
      >
        <SearchBar
          ref={searchBarRef}
          onFocus={showResults}
          onBlur={() => {
            setTimeout(() => {
              dispatch(setSearchFocused(false));
              reset();
            }, 10);
          }}
          onKeyPress={onSearchKeyPress}
          onChangeText={onSearchInputChange}
          rightIcon={
            <Row noGap style={[styles.alignCenter]}>
              <SearchHeaderModalControls />
            </Row>
          }
          onClear={() => {}}
          inputStyle={[
            { color: theme.colors.primary },
            searchValue === "" && !focused && styles.textCenter,
          ]}
          placeholder={placeholder}
          placeholderTextColor={
            searchValue === "" && !focused
              ? theme.colors.primary
              : theme.colors.grey3
          }
          value={searchValue}
        />
        {!focused && selectedFeed && !previewFeed && !customFeedPreviewFeed && (
          <Row style={styles.placeholderOverlay}>
            {feedLoading || working ? (
              <Loading size={"small"} />
            ) : (
              <FeedDisplayName
                feed={selectedFeed}
                style={[styles.base, { fontSize: SPACING.LARGE }]}
              />
            )}
          </Row>
        )}
      </CollapsibleAnimatedView>
      <CollapsibleAnimatedView
        show={focused}
        fade
        slideInDirection={SLIDE_IN_DIRECTION.TOP}
        flexRange={[0, 1]}
        style={[styles.searchResultsWrapper]}
      >
        <Column flexShrink noGap style={[styles.hidingSearchResults]}>
          {isCustomFeedBuilderMode() && currentSearchResults.length === 0 && (
            <Column style={styles.searchResultColumn}>
              <CustomFeedBuilderSearchEdu />
            </Column>
          )}
          <Column flexShrink noGap style={styles.searchResults}>
            <FlatList
              ref={searchResultsRef}
              data={currentSearchResults}
              renderItem={({ item, index }) =>
                renderResultsList(item.key, index, item.results)
              }
            />
          </Column>
        </Column>
      </CollapsibleAnimatedView>
    </Column>
  );
};

const useStyles = makeStyles((theme, { isPhone }: { isPhone: boolean }) =>
  createStyleSheet({
    searchWrapper: {
      width: LAYOUT.SEARCH_WIDTH,
      justifyContent: "center",
      ...(isPhone
        ? {
            width: "95%",
            paddingHorizontal: SPACING.BASE,
          }
        : {}),
    },
    searchBarWrapper: { zIndex: 20 },
    searchResultsWrapper: {
      position: "absolute",
      top: LAYOUT.HEADER_HEIGHT,
      width: "100%",
      alignItems: "center",
      zIndex: 100,
      justifyContent: "flex-start",
      ...(isPhone
        ? {
            marginHorizontal: SPACING.BASE,
            width: `calc(100% - ${SPACING.BASE * 2}px)`,
            left: 0,
            position: "fixed",
            height: "100%",
            maxHeight: "100%",
            overflowY: "auto",
          }
        : {}),
    },
    hidingSearchResults: {
      backgroundColor: "yellow",
      alignItems: "center",
      ...(isPhone
        ? { width: "100%" }
        : {
            borderBottomLeftRadius: SPACING.BASE,
            borderBottomRightRadius: SPACING.BASE,
          }),
      borderWidth: 1,
      borderColor: theme.colors.dividerPrimary,
      shadowColor: theme.colors.dividerPrimary,
      shadowRadius: SPACING.SMALL,
      shadowOffset: { width: 0, height: 0 },
    },
    searchResults: [
      { overflowY: "auto" },
      isPhone
        ? {
            width: "100%",
          }
        : {
            backgroundColor: "transparent",
            color: theme.colors.primary,
            maxHeight: LAYOUT.SEARCH_HEIGHT,
            width: LAYOUT.SEARCH_WIDTH,
            justifyContent: "flex-start",
          },
    ],
    searchResultButton: {
      justifyContent: "flex-start",
      alignItems: "flex-start",
      width: "100%",
    },
    searchResultTitle: {
      color: theme.colors.primary,
      fontWeight: "bold",
      width: "100%",
      textAlign: "left",
    },
    imFeelingLuckySearchResult: {
      backgroundColor: theme.colors.grey5,
    },
    focusedSearchResult: {
      backgroundColor: theme.colors.grey4,
    },
    searchResultColumn: {
      padding: SPACING.LARGE,
      backgroundColor: theme.colors.background,
      ...(isPhone
        ? {
            borderBottomWidth: 1,
            borderColor: theme.colors.dividerPrimary,
          }
        : {}),
    },
    searchResultColumnTitle: {
      color: theme.colors.primary,
      borderBottomWidth: 1,
      borderBottomColor: theme.colors.dividerTertiary,
      paddingBottom: SPACING.MEDIUM,
      lineHeight: SPACING.LARGE,
    },
    placeholderOverlay: {
      pointerEvents: "none",
      position: "absolute",
      left: 0,
      top: 0,
      width: "100%",
      height: "100%",
      zIndex: 2,
      alignItems: "center",
      justifyContent: "center",
    },
  })
);

export default memo(Search);
