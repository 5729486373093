import React, { useState, useEffect } from "react";
import { useToast } from "react-native-toast-notifications";
import { useTheme, makeStyles, CheckBox } from "@rneui/themed";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { ActivityIndicator, ScrollView } from "react-native";
import { useAppSelector, useAppDispatch } from "~/hooks";
import { parseExpirationDate } from "~/utils/parse-time";
import {
  useVoteOnPollMutation,
  useLazyGetInstanceItemQuery,
  useLazyGetPollQuery,
} from "~/api";
import { setOpenLoginModal } from "~/concepts/auth";

import { View, Button, Text, Column, Row } from "~/components/elements";
import { createStyleSheet, SPACING } from "~/styles";
import { selectCredentials } from "~/concepts/auth";

import { TextAttributionStyle, TextSize } from "~/enums";

type PollProps = {
  item: FC.Item;
  overlay?: boolean;
};

const Poll: React.FC<PollProps> = ({ item, overlay }) => {
  const { theme } = useTheme();
  const styles = useStyles({ overlay });

  const LOADING_ICON_SIZE = 40;
  const authenticated = useAppSelector(selectCredentials);
  const [selectedChoice, setChoice] = useState<number[]>([]);
  const [poll, setPoll] = useState<FC.Poll | undefined>();
  const [pollError, setPollError] = useState<boolean>(false);
  const [getInstanceItemTrigger, getInstanceItemResponse] =
    useLazyGetInstanceItemQuery();
  const [getInstancePoll, getInstancePollResponse] = useLazyGetPollQuery();

  const [voteTrigger, { error: voteError, data: voteData, isLoading }] =
    useVoteOnPollMutation();

  const toast = useToast();
  const dispatch = useAppDispatch();
  const TOAST_POLL_ERROR_MSG =
    "Oops something went wrong. Please try to vote again.";

  useEffect(() => {
    if (authenticated) {
      getInstanceItemTrigger(item.source);
    } else {
      setPoll(item.poll);
    }
  }, [item.source, item.poll, authenticated, getInstanceItemTrigger]);

  useEffect(() => {
    if (getInstanceItemResponse.data && getInstanceItemResponse.isSuccess) {
      const { data: instanceItem } = getInstanceItemResponse;
      if (instanceItem?.poll) {
        getInstancePoll(instanceItem.poll.id);
      }
    } else if (getInstanceItemResponse.isError) {
      setPollError(true);
    }
  }, [getInstancePoll, getInstanceItemResponse]);

  useEffect(() => {
    if (getInstancePollResponse.data && getInstancePollResponse.isSuccess) {
      setPoll(getInstancePollResponse.data);
    }
  }, [getInstancePollResponse]);

  useEffect(() => {
    if (voteData) {
      toast.show("Thanks for voting!", {
        type: "success",
      });
    } else if (voteError) {
      toast.show(TOAST_POLL_ERROR_MSG, {
        type: "fail",
      });
    }
  }, [voteData, voteError, toast, item]);

  if (!poll && !pollError) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size={LOADING_ICON_SIZE} />
      </View>
    );
  }

  const handleChoice = (index: number) => {
    if (poll?.multiple) {
      if (selectedChoice.includes(index)) {
        setChoice((prev) => prev.filter((i) => i !== index));
      } else {
        setChoice((prev) => [...prev, index]);
      }
    } else {
      setChoice([index]);
    }
  };

  const handleSubmitPoll = () => {
    if (authenticated) {
      voteTrigger({
        itemPoll: poll,
        choices: selectedChoice,
      });
    } else {
      dispatch(setOpenLoginModal({ open: true, unauthenticatedAction: false }));
    }
  };

  const expirationDate = poll && parseExpirationDate(poll.expires_at);

  const renderPollOption = (option: any, index: number) => (
    <CheckBox
      title={option.title}
      textStyle={styles.pollOptionText}
      containerStyle={styles.pollOptionContainer}
      checked={selectedChoice.includes(index)}
      onPress={() => handleChoice(index)}
      checkedIcon={
        poll && poll.multiple ? (
          <Icon
            name="checkbox-marked-circle"
            solid
            size={16}
            color={overlay ? theme.colors.overlay : theme.colors.primary}
          />
        ) : (
          <Icon
            name="radiobox-marked"
            solid
            size={16}
            color={overlay ? theme.colors.overlay : theme.colors.primary}
          />
        )
      }
      uncheckedIcon={
        poll && poll.multiple ? (
          <Icon
            name="checkbox-blank-circle-outline"
            size={16}
            color={overlay ? theme.colors.overlay : theme.colors.primary}
          />
        ) : (
          <Icon
            name="radiobox-blank"
            size={16}
            color={overlay ? theme.colors.overlay : theme.colors.primary}
          />
        )
      }
    />
  );

  const userChoicePicked =
    poll?.own_votes &&
    poll.own_votes.map((x) => {
      return poll.options[x].title;
    });

  const renderPollChartDataGraph = (options: FC.Poll["options"]) => {
    if (!poll) return;
    const totalVotes = poll.multiple ? poll.voters_count : poll.votes_count;

    return options.map((choice, index) => {
      const { title, votes_count } = choice;

      const percentage =
        (totalVotes && Math.round((votes_count / totalVotes) * 100)) || 0;
      const optionSelected = userChoicePicked?.includes(choice.title);

      return (
        <Row
          key={index}
          style={{ height: SPACING.BASE5X, padding: SPACING.BASE }}
        >
          <View
            style={[
              styles.bar,
              optionSelected && styles.barSelected,
              { width: `${percentage || 1}%` },
            ]}
          />
          <Row style={[styles.alignCenter, styles.pollBarText]}>
            <Text overlay={overlay}>{optionSelected ? "✅" : ""}</Text>
            <Text style={styles.flex1} overlay={overlay}>
              {title}
            </Text>
            <Text overlay={overlay}>
              {votes_count} vote{votes_count !== 1 && "s"} - {percentage}%
            </Text>
          </Row>
        </Row>
      );
    });
  };

  const isPollCompleted = poll?.voted || poll?.expired;
  if (isLoading) {
    return (
      <>
        <ActivityIndicator size={LOADING_ICON_SIZE} />
      </>
    );
  }

  const renderPollContent = () => {
    if (pollError) {
      return (
        <Text size={TextSize.XL}>
          We're sorry, but this poll is no longer available
        </Text>
      );
    } else if (isPollCompleted && poll) {
      return renderPollChartDataGraph(poll.options);
    } else {
      return (
        poll &&
        poll.options.map((option, index) => renderPollOption(option, index))
      );
    }
  };

  return (
    <Column flex noGap style={styles.pollWrapper}>
      <ScrollView style={styles.pollResultContainer}>
        {renderPollContent()}
      </ScrollView>
      <Row style={styles.pollControls}>
        {!pollError && (
          <>
            {poll?.expired && <View />}
            {!poll?.expired &&
              (poll?.voted ? (
                <Text
                  overlay={overlay}
                  attribution={TextAttributionStyle.STANDARD_HEADLINE}
                  style={styles.votedLabel}
                >
                  Voted!
                </Text>
              ) : (
                <Button
                  style={styles.voteButton}
                  title={"Vote"}
                  onPress={handleSubmitPoll}
                  disabled={!selectedChoice.length}
                  radius={SPACING.BASE}
                  size={"sm"}
                  overlay={overlay}
                  type={"solid"}
                />
              ))}
          </>
        )}
        {!pollError && (
          <Row gap={SPACING.BASE}>
            <Row gap={SPACING.SMALL}>
              <Icon
                name={"account-multiple-outline"}
                size={16}
                color={
                  overlay ? theme.colors.primaryReverse : theme.colors.primary
                }
              />
              <Text
                attribution={TextAttributionStyle.STANDARD_SUBTEXT}
                overlay={overlay}
              >
                {poll && poll.voters_count}
              </Text>
            </Row>
            <Row gap={SPACING.SMALL} style={styles.alignCenter}>
              <Icon
                name={"timer-outline"}
                size={16}
                color={
                  overlay ? theme.colors.primaryReverse : theme.colors.primary
                }
              />
              <Text
                attribution={TextAttributionStyle.STANDARD_SUBTEXT}
                overlay={overlay}
              >
                {poll?.expired && "Closed "}
                {expirationDate}
              </Text>
            </Row>
          </Row>
        )}
      </Row>
    </Column>
  );
};

const useStyles = makeStyles((theme, { overlay }: { overlay?: boolean }) =>
  createStyleSheet({
    pollWrapper: {
      width: "100%",
      justifyContent: "space-evenly",
      borderWidth: 1,
      borderRadius: SPACING.BASE,
      borderColor: theme.colors.dividerPrimary,
      overflow: "hidden",
    },
    pollResultContainer: { padding: SPACING.LARGE },
    pollOptions: {
      flex: 1,
      overflow: "scroll",
    },
    pollOptionText: {
      color: overlay ? theme.colors.overlay : theme.colors.primary,
    },
    pollResultMessage: {
      fontWeight: "bold",
    },
    bar: {
      position: "absolute",
      left: 0,
      top: "50%",
      transform: "translateY(-50%)",
      zIndex: 1,
      height: SPACING.BASE4X,
      borderRadius: SPACING.SMALL,
      backgroundColor: theme.colors.grey5,
    },
    barSelected: {
      backgroundColor: theme.colors.grey3,
    },
    pollBarText: {
      width: "100%",
      zIndex: 2,
    },

    pollControls: {
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      borderTopWidth: 1,
      borderColor: theme.colors.dividerPrimary,
      padding: SPACING.LARGE,
    },
    pollOptionContainer: { padding: 0 },
    voteButton: { width: 100 },
    votedLabel: {
      color: theme.colors.overlay,
      backgroundColor: theme.colors.grey2,
      borderRadius: SPACING.BASE,
      padding: SPACING.BASE,
      width: 100,
      textAlign: "center",
    },
    gradientOverlay: {
      position: "absolute",
      width: "100%",
      bottom: 0,
      height: SPACING.BASE4X,
      zIndex: 1,
      marginRight: SPACING.LARGE,
      pointerEvents: "none",
    },
  })
);
export default Poll;
