import React, { memo } from "react";
import { Dialog } from "~/components/elements";
import { useAppSelector } from "~/hooks";
import { isPhoneSelector } from "~/concepts/application";

const ConfirmDialog: React.FC<{
  title: string;
  onYes: () => void;
  onBackdropPress?: () => void;
  onNo: () => void;
  disabled?: boolean;
  loading?: boolean;
  visible: boolean;
}> = ({ title, onYes, onNo, onBackdropPress, visible, disabled, loading }) => {
  const isPhone = useAppSelector(isPhoneSelector);

  return (
    <Dialog
      isVisible={visible}
      onBackdropPress={onBackdropPress || onNo}
      width={isPhone ? "100%" : "50%"}
    >
      <Dialog.Title>{title}</Dialog.Title>
      <Dialog.Actions>
        <Dialog.Button
          title="No"
          onPress={onNo}
          color={"primary"}
          type="solid"
          disabled={disabled}
          loading={loading}
        />
        <Dialog.Button
          title="Yes"
          onPress={onYes}
          color={"primary"}
          type="outline"
          disabled={disabled}
          loading={loading}
        />
      </Dialog.Actions>
    </Dialog>
  );
};

export default memo(ConfirmDialog);
