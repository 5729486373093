// process.env replacement only works at runtime (in development?), so
// we put them here.  If you try to do something like put
// `process.env.DEPLOY_ENV` inside of a function that will be executed
// later, it probably isn't going to work

export const NODE_ENV = process.env.NODE_ENV;
export const DEPLOY_ENV = process.env.DEPLOY_ENV;
export const API_ROOT = process.env.API_ROOT;
export const SERVER_HOST = process.env.SERVER_HOST;

export const UUID_V5_NAMESPACE_URL = "https://surf.social";
export const MAIN_TITLE = "Surf";
export const MAGIC_WORD = "mavericks";
