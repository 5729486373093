import React, { memo } from "react";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { makeStyles, useTheme } from "@rneui/themed";
import { Column, Row, Text } from "~/components/elements";
import { createStyleSheet, SPACING } from "~/styles";
import { TextSize } from "~/enums";

const EDU_WIDTH = 600;
const CustomFeedBuilderSearchEdu = () => {
  const { theme } = useTheme();
  const styles = useStyles();

  return (
    <Row flex style={styles.eduWrapper} gap={SPACING.BASE3X}>
      <Icon
        name="information-outline"
        color={theme.colors.primary}
        size={SPACING.BASE3X}
      />
      <Column style={styles.textWrapper}>
        <Text size={TextSize.M}>
          Search for topics, hashtags and users to add to your custom feed.
        </Text>
        <Text size={TextSize.M}>
          eg: Try "hiking" or "#hiking" or "@Eugen".
        </Text>
      </Column>
    </Row>
  );
};

const useStyles = makeStyles(() =>
  createStyleSheet({
    eduWrapper: {
      backgroundColor: "transparent",
      margin: SPACING.LARGE,
      justifyContent: "center",
      alignItems: "center",
      width: EDU_WIDTH,
    },
  })
);

export default memo(CustomFeedBuilderSearchEdu);
