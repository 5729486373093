import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "~/store";

type ThreadState = {
  threadItem: FC.Item | null;
  previewItem: FC.Item | null;
  currentAttachmentIndex: number;
  shownReplyItem: FC.Item["id"] | null;
  requestReplyItemChange: FC.Item["id"] | null;
  requestCloseItemFocusDrawer?: boolean;
  followingThreadLink: boolean;
};

const initialState: ThreadState = {
  threadItem: null,
  previewItem: null,
  currentAttachmentIndex: 0,
  shownReplyItem: null,
  requestReplyItemChange: null,
  requestCloseItemFocusDrawer: false,
  followingThreadLink: false,
};

export const threadSlice = createSlice({
  name: "thread",
  initialState,
  reducers: {
    setFollowingThreadLink: (state, { payload }: PayloadAction<boolean>) => {
      state.followingThreadLink = payload;
    },
    setCurrentThreadItem: (
      state,
      { payload: threadItem }: PayloadAction<FC.Item | null>
    ) => {
      return { ...state, threadItem };
    },
    clearCurrentThreadItem: (state) => {
      return { ...state, threadItem: null };
    },
    setPreviewItem: (
      state,
      { payload: previewItem }: PayloadAction<FC.Item | null>
    ) => {
      state.previewItem = previewItem;
    },
    clearPreviewItem: (state) => {
      state.previewItem = null;
    },
    setCurrentAttachmentIndex: (
      state,
      { payload: currentAttachmentIndex }: PayloadAction<number>
    ) => {
      state.currentAttachmentIndex = currentAttachmentIndex;
    },
    setShownReplyItem: (
      state,
      { payload: shownReplyItem }: PayloadAction<FC.Item["id"]>
    ) => {
      return { ...state, shownReplyItem, requestReplyItemChange: null };
    },
    clearShownReplyItem: (state) => {
      return { ...state, shownReplyItem: null, requestReplyItemChange: null };
    },
    setRequestReplyItemChange: (
      state,
      { payload: requestReplyItemChange }: PayloadAction<FC.Item["id"]>
    ) => {
      return { ...state, requestReplyItemChange };
    },
    clearRequestReplyItemChange: (state) => {
      return { ...state, requestReplyItemChange: null };
    },
    setRequestCloseItemFocusDrawer: (
      state,
      { payload: requestCloseItemFocusDrawer }: PayloadAction<boolean>
    ) => {
      return { ...state, requestCloseItemFocusDrawer };
    },
  },
});

export const {
  setCurrentThreadItem,
  clearCurrentThreadItem,
  setPreviewItem,
  clearPreviewItem,
  setCurrentAttachmentIndex,
  setShownReplyItem,
  clearShownReplyItem,
  setRequestReplyItemChange,
  clearRequestReplyItemChange,
  setRequestCloseItemFocusDrawer,
  setFollowingThreadLink,
} = threadSlice.actions;

export const getThreadItem = (state: RootState) => state.thread.threadItem;

export const getPreviewItem = (state: RootState) => state.thread.previewItem;

export const getCurrentAttachmentIndex = (state: RootState) =>
  state.thread.currentAttachmentIndex;

export const getShownReplyItem = (state: RootState) =>
  state.thread.shownReplyItem;

export const getRequestedReplyItemChange = (state: RootState) =>
  state.thread.requestReplyItemChange;

export const getRequestCloseItemFocusDrawer = (state: RootState) =>
  state.thread.requestCloseItemFocusDrawer;

export const selectFollowingThreadLink = (state: RootState) =>
  state.thread.followingThreadLink;

export default threadSlice.reducer;
