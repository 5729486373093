import { useCallback, useEffect } from "react";
import { Platform } from "react-native";

const useEscClose = (close: () => void) => {
  const handleKeydown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        close();
      }
    },
    [close]
  );

  useEffect(() => {
    if (Platform.OS !== "web") {
      return;
    }
    document.body.addEventListener("keydown", handleKeydown);
    return () => {
      document.body.removeEventListener("keydown", handleKeydown);
    };
  }, [handleKeydown]);
};

export default useEscClose;
