import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "~/store";

interface NavigationState {
  navigatedToThreadFrom?: string;
}

const initialState: NavigationState = {};

export const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    setNavigatedToThreadFrom: (state, { payload }: PayloadAction<string>) => {
      state.navigatedToThreadFrom = payload;
    },
    clearNavigatedToThreadFrom: (state) => {
      delete state.navigatedToThreadFrom;
    },
  },
});

export const { setNavigatedToThreadFrom, clearNavigatedToThreadFrom } =
  navigationSlice.actions;

export const selectNavigatedToThreadFrom = (state: RootState) =>
  state.navigation.navigatedToThreadFrom;

export default navigationSlice.reducer;
