import React, { memo, useEffect, useState } from "react";
import { Button, Column, Row, Text } from "~/components/elements";
import { CardSize } from "~/enums";
import ArticlePreview from "~/components/cards/ArticlePreview";
import { SPACING, createStyleSheet } from "~/styles";
import { Avatar, makeStyles, useTheme } from "@rneui/themed";
import SocialArticleCard from "~/components/cards/SocialArticleCard";
import BaseCard from "~/components/cards/BaseCard";
import ItemFocusDrawerButton from "~/components/cardComponents/ItemFocusDrawerButton";
import { ScrollView } from "react-native-gesture-handler";
import { isPhoneSelector } from "~/concepts/application";
import { useAppSelector } from "~/hooks";
import { isEqual } from "lodash-es";
import { BackgroundColorContext } from "~/contexts/BackgroundColorContext";

const INITIAL_DISPLAY_COUNT = 3;
const INITIAL_DISPLAY_COUNT_MOBILE = 2;

const COMMENTARY_CARD_HEIGHT = 200;

const SocialArticleRollup: React.FC<{
  previewItem: FC.Item;
  articleItems: Array<FC.Item>;
  socialArticleItems: Array<FC.Item>;
  contentWidth: number;
  contentHeight: number;
}> = ({
  previewItem,
  articleItems,
  socialArticleItems,
  contentWidth,
  contentHeight,
}) => {
  const isPhone = useAppSelector(isPhoneSelector);
  const styles = useStyles({ isPhone, contentWidth, contentHeight });
  const { theme } = useTheme();

  const reboostItems = socialArticleItems?.filter((i) => !!i.reblog);
  const displayableItems = socialArticleItems?.filter((i) => !i.reblog);
  const initialItems = isPhone
    ? displayableItems
    : displayableItems?.slice(
        0,
        isPhone ? INITIAL_DISPLAY_COUNT_MOBILE : INITIAL_DISPLAY_COUNT
      );

  const [showAll, setShowAll] = useState(false);
  const [showReboosts, setShowReboosts] = useState(false);
  const [displayItems, setDisplayItems] = useState(initialItems);

  useEffect(() => {
    let newDisplayItems = showAll ? displayableItems : initialItems;

    if (showReboosts) {
      newDisplayItems = [...newDisplayItems, ...reboostItems];
    }
    if (!isEqual(displayItems, newDisplayItems)) {
      console.log(
        "%c ~LOG~ SocialArticleRollup.tsx  ~LINE 100 ~ newDisplayItems",
        "color: white; background: #128126; font-size: 16px;",
        { displayItems, newDisplayItems }
      );

      setDisplayItems(newDisplayItems);
    }
  }, [
    displayItems,
    displayableItems,
    initialItems,
    isPhone,
    reboostItems,
    showAll,
    showReboosts,
  ]);

  if (!socialArticleItems) return null;

  return (
    <Column flex style={styles.rollupCard}>
      <Column flex={2}>
        <ArticlePreview item={previewItem} cardSize={CardSize.XXLARGE} />
      </Column>
      <Column flexShrink gap={SPACING.BASE} style={styles.commentaryWrapper}>
        <ScrollView
          horizontal={isPhone}
          pagingEnabled
          snapToInterval={
            isPhone ? undefined : COMMENTARY_CARD_HEIGHT + SPACING.BASE
          }
          style={[isPhone && styles.fullWidth]}
          contentContainerStyle={[{ gap: SPACING.BASE }]}
        >
          {displayItems.map((item, index) => (
            <BackgroundColorContext.Provider
              value={theme.colors.background}
              key={`${item.id}_${index}`}
            >
              <BaseCard
                item={item}
                cardSize={CardSize.SMALL}
                CardComponent={SocialArticleCard}
                componentProps={{ hidePreview: true }}
                style={[
                  styles.socialOverlayCard,
                  isPhone && {
                    height: COMMENTARY_CARD_HEIGHT,
                    marginLeft: index === 0 ? SPACING.BASE3X : SPACING.BASE,
                    marginRight:
                      index === displayItems.length - 1
                        ? SPACING.BASE3X
                        : SPACING.NONE,
                  },
                ]}
              />
            </BackgroundColorContext.Provider>
          ))}
        </ScrollView>
        {!isPhone && displayableItems.length > 4 && (
          <BackgroundColorContext.Provider value={theme.colors.background}>
            <Button
              style={[styles.socialOverlayCard]}
              title={
                showAll ? "Show less" : `${displayableItems.length - 4} more...`
              }
              type="clear"
              onPress={() => {
                setShowAll(!showAll);
              }}
            />
          </BackgroundColorContext.Provider>
        )}
        {reboostItems.length > 0 && (
          <Button
            style={[styles.socialOverlayCard]}
            type="clear"
            onPress={() => {
              setShowReboosts(!showReboosts);
            }}
          >
            {showReboosts ? (
              <Text>
                Hide <Text style={styles.bold}>Boosted</Text> items
              </Text>
            ) : (
              <Text>
                <Text style={styles.bold}>Boosted</Text> {reboostItems.length}{" "}
                time{reboostItems.length > 1 ? "s" : ""}...
              </Text>
            )}
          </Button>
        )}
        {articleItems.length > 0 && (
          <Row
            style={[
              styles.socialOverlayCard,
              styles.alignCenter,
              styles.justifyCenter,
              {
                paddingHorizontal: SPACING.BASE3X,
                paddingVertical: SPACING.BASE,
              },
            ]}
          >
            <Text>
              <Text style={styles.bold}>Shares:</Text> {articleItems.length}
            </Text>
            <Row style={[styles.facePile]}>
              {articleItems.map((item, index) => (
                <ItemFocusDrawerButton
                  key={`avatar_${item.id}`}
                  item={item}
                  style={[
                    styles.faceButton,
                    {
                      left: index * SPACING.BASE3X,
                      zIndex: index,
                    },
                  ]}
                >
                  <Avatar
                    rounded
                    size={SPACING.BASE4X}
                    source={{ uri: item.author.avatar }}
                    containerStyle={styles.avatar}
                  />
                </ItemFocusDrawerButton>
              ))}
            </Row>
          </Row>
        )}
      </Column>
    </Column>
  );
};

const useStyles = makeStyles(
  (
    theme,
    {
      isPhone,
      contentWidth,
      contentHeight,
    }: { isPhone?: boolean; contentWidth?: number; contentHeight?: number }
  ) =>
    createStyleSheet({
      rollupCard: {
        height: contentHeight,
      },
      commentaryWrapper: isPhone
        ? {
            position: "absolute",
            bottom: SPACING.BASE3X,
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }
        : {
            position: "absolute",
            zIndex: 10,
            bottom: SPACING.BASE3X,
            right: SPACING.BASE3X,
            maxHeight: "80%",
            width: contentWidth
              ? Math.min(500, contentWidth && contentWidth / 2 - SPACING.BASE3X)
              : 500,
            alignSelf: "flex-end",
          },

      socialOverlayCard: {
        backgroundColor: theme.colors.background,
        maxHeight: COMMENTARY_CARD_HEIGHT,
        width: isPhone && contentWidth && contentWidth - SPACING.BASE6X,
        maxWidth:
          !isPhone &&
          (contentWidth
            ? Math.min(500, contentWidth && contentWidth / 2 - SPACING.BASE3X)
            : 500),
        borderRadius: SPACING.BASE,
        opacity: 0.9,
      },
      facePile: { height: SPACING.BASE4X },
      faceButton: {
        position: "absolute",
      },
      avatar: {
        borderWidth: 1,
        borderColor: theme.colors.dividerTertiaryReverse,
        shadowColor: theme.colors.dividerTertiaryReverse,
        shadowRadius: SPACING.SMALL,
        shadowOffset: { width: 1, height: 1 },
      },
    })
);

export default memo(SocialArticleRollup);
