import React, { memo } from "react";
import { makeStyles, Button, useTheme } from "@rneui/themed";
import { useAppSelector } from "~/hooks";
import {
  useGetInstanceAuthorQuery,
  useFollowMutation,
  useUnfollowMutation,
  useLazyGetFollowingQuery,
} from "~/api";
import { View } from "react-native";
import ToggleButton from "~/components/ToggleButton";
import { selectCredentials } from "~/concepts/auth";
import ToastMessageWithAuthor from "~/components/ToastMessageWithAuthor";
import { SPACING, createStyleSheet } from "~/styles";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";

type FollowingButtonProps = {
  author: FC.Author;
  overlay?: boolean;
};

const FollowingButton: React.FC<FollowingButtonProps> = ({
  author,
  overlay,
}) => {
  const credentials = useAppSelector(selectCredentials);
  const authenticated = !!credentials;
  const styles = useStyles();
  const { theme } = useTheme();
  const { data: instanceAuthor, isLoading } = useGetInstanceAuthorQuery(author);
  const [getFollowingTrigger, getFollowingResponse] =
    useLazyGetFollowingQuery();

  // you can not follow your own account
  if (credentials?.uri && credentials?.uri === author?.uri) {
    return null;
  }

  if (instanceAuthor && getFollowingResponse.isUninitialized) {
    getFollowingTrigger(instanceAuthor, true);
  }

  if (isLoading || getFollowingResponse.isLoading) {
    return (
      <View style={styles.loadingContainer}>
        <Button type="solid" loading />
      </View>
    );
  }
  if (instanceAuthor && getFollowingResponse.isSuccess) {
    return (
      <ToggleButton
        mutationArgument={instanceAuthor}
        value={getFollowingResponse.data.isFollowing}
        onSuccessToast={
          <ToastMessageWithAuthor author={author} preText="You followed " />
        }
        offSuccessToast={
          <ToastMessageWithAuthor author={author} preText="You unfollowed " />
        }
        onMutation={useFollowMutation}
        offMutation={useUnfollowMutation}
        onMutationTitle={"Follow"}
        offMutationTitle={"Following"}
        offMutationHoverTitle={"Unfollow"}
        disabled={!authenticated}
        overlay={overlay}
        isButton
        hoverColor={theme.colors.controlsRed2}
        onIcon={
          <Icon name="plus" size={12} color={theme.colors.primaryReverse} />
        }
        offIconHover={
          <Icon name="cancel" size={12} color={theme.colors.controlsRed2} />
        }
        iconRight
      />
    );
  }
  return null;
};

const useStyles = makeStyles((theme) => {
  return createStyleSheet({
    loadingContainer: {
      borderRadius: SPACING.BASE,
      overflow: "hidden",
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: theme.colors.primary,
    },
  });
});
export default memo(FollowingButton);
