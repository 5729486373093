interface MastodonInstance {
  title: string;
  description: string;
  domain: string;
  usage: { users: { active_month: number } };
  thumbnail: { url: string; blurhash: string };
  contact: { email: string; account: FC.Author };
}

const mastodonInstanceToServerInstance = (
  mastodonInstance: MastodonInstance
): FC.ServerInstance => {
  const { title, description, domain, usage, thumbnail, contact } =
    mastodonInstance;
  return {
    title,
    description,
    url: `https://${domain}`,
    thumbnail,
    contactEmail: contact.email,
    contactAccount: contact.account,
    usage: { users: { activeMonth: usage.users.active_month } },
  };
};
export default mastodonInstanceToServerInstance;
