import React from "react";
import { makeStyles } from "@rneui/themed";
import { Anchor, Row, Text } from "~/components/elements";
import { createStyleSheet, SPACING } from "~/styles";
import { Column } from "~/components/elements";
import ItemImage from "~/components/ItemImage";
import { TextAttributionStyle, CardSize, OverlayTitlePosition } from "~/enums";
import CardText from "~/components/cardComponents/CardText";
import AutoNumberOfLinesWrapper from "~/components/elements/AutoNumberOfLinesWrapper";
import { StyleProp } from "react-native";
import BlurredOverlayTitle from "~/components/cardComponents/BlurredOverlayTitle";
import { LinearGradient } from "expo-linear-gradient";
import { selectScreenDimensions } from "~/concepts/application";
import { useAppSelector } from "~/hooks";

const PREVIEW_IMAGE_HEIGHT_DEFAULT = 200;
const PREVIEW_IMAGE_HEIGHT_MEDIUM = 100;
const PREVIEW_IMAGE_HEIGHT_LARGE = 250;

type ArticlePreviewProps = {
  flex?: boolean | number;
  flexShrink?: boolean | number;
  item: FC.Item;
  isArticlePreviewCard?: boolean;
  cardSize?: CardSize;
  showExtractText?: boolean;
  isCompact?: boolean;
  style?: StyleProp<ViewStyle>;
};

const ArticlePreview: React.FC<ArticlePreviewProps> = ({
  flex,
  flexShrink,
  item,
  cardSize,
  showExtractText = false,
  isCompact = false,
  style,
}) => {
  const dimensions = useAppSelector(selectScreenDimensions);
  const tallScreen = dimensions?.height > 1000;
  const wideScreen = dimensions.height > 1950;
  const originalImageHeight = item?.image?.height || 1;
  const originalImageWidth = item?.image?.width || 1;
  const originalAspectRatio = originalImageWidth / originalImageHeight;
  const styles = useStyles({
    cardSize,
    tallScreen,
    wideScreen,
    aspectRatio: originalAspectRatio,
  });
  if (!item.card) return null;

  const { flip } = item.card;
  const renderTitle = () => {
    return (
      <AutoNumberOfLinesWrapper
        maxNumberOfLines={cardSize === CardSize.SMALL ? 2 : 3}
      >
        <CardText header cardSize={cardSize} numberOfLines={2}>
          {item.card?.title}
        </CardText>
      </AutoNumberOfLinesWrapper>
    );
  };

  const compactPreview =
    isCompact || cardSize === CardSize.SMALL || cardSize === CardSize.MEDIUM;
  const WrapperComponent = compactPreview ? Row : Column;

  if (cardSize === CardSize.XLARGE || cardSize === CardSize.XXLARGE) {
    return (
      <Row flex style={styles.fullWidth}>
        <ItemImage item={item} contentFit={"cover"} />

        <LinearGradient
          // Background Gradient Overlay
          start={{ x: 0.4, y: 0 }}
          end={{ x: 0.6, y: 1 }}
          colors={["#00000080", "#88888800"]}
          locations={[0.3, 1]}
          style={styles.overlayBackground}
        />
        <BlurredOverlayTitle
          item={item}
          position={
            cardSize === CardSize.XXLARGE
              ? OverlayTitlePosition.TOP_LEFT
              : OverlayTitlePosition.BOTTOM_LEFT
          }
          distanceFromEdge={SPACING.BASE3X}
          cardSize={cardSize}
          style={styles.overlayTitle}
        />
      </Row>
    );
  }
  return (
    <Column
      flex={flex}
      flexShrink={flexShrink}
      style={[styles.articlePreview, style]}
    >
      <Anchor flex href={item.card.url} anchorStyle={styles.fullHeight}>
        <WrapperComponent
          flexShrink
          noGap
          style={
            compactPreview
              ? styles.componentWrapperCompact
              : styles.componentWrapper
          }
        >
          <Row
            flexShrink
            style={
              compactPreview
                ? styles.imageWrapperCompact
                : styles.imageContainer
            }
          >
            <ItemImage
              item={item}
              disableBlurBackground
              contentFit={"cover"}
              style={styles.topBorderRounded}
            />
          </Row>
          <Column flexShrink style={[styles.contentContainer]}>
            {renderTitle()}
            <Text attribution={TextAttributionStyle.STANDARD_SUBTEXT}>
              {item?.card?.flip?.publisherName || item.card?.provider_name}
            </Text>
            {showExtractText && !compactPreview && (
              <AutoNumberOfLinesWrapper flexShrink>
                <Text>{item.card?.description || flip?.shortExcerpt}</Text>
              </AutoNumberOfLinesWrapper>
            )}
          </Column>
        </WrapperComponent>
      </Anchor>
    </Column>
  );
};
const imageContainerHorizontalStyle = (cardSize?: CardSize) => {
  switch (cardSize) {
    case CardSize.SMALL:
      return { minWidth: 50, maxWidth: 75, minHeight: 50, maxHeight: 100 };
    case CardSize.MEDIUM:
      return { minWidth: 75, maxWidth: 100, minHeight: 75, maxHeight: 120 };
    case CardSize.LARGE:
    default:
      return { minWidth: 100, maxWidth: 150, minHeight: 100, maxHeight: 175 };
  }
};

const imageContainerHeight = (cardSize?: CardSize) => {
  switch (cardSize) {
    case CardSize.SMALL:
      return 50;
    case CardSize.MEDIUM:
      return PREVIEW_IMAGE_HEIGHT_MEDIUM;
    case CardSize.LARGE:
      return PREVIEW_IMAGE_HEIGHT_LARGE;
    default:
      return PREVIEW_IMAGE_HEIGHT_DEFAULT;
  }
};

const useStyles = makeStyles(
  (
    theme,
    {
      cardSize,
      tallScreen,
      wideScreen,
      aspectRatio,
    }: {
      cardSize?: CardSize;
      tallScreen: boolean;
      wideScreen: boolean;
      aspectRatio?: number;
    }
  ) => {
    return createStyleSheet({
      articlePreview: { width: "100%" },
      componentWrapper: {
        flexShrink: 1,
        borderColor: theme.colors.dividerPrimary,
        borderStyle: "solid",
        borderRadius: SPACING.BASE,
        borderWidth: 1,
        width: "100%",
        minHeight: "50%",
        maxHeight: "100%",
      },
      imageContainer: {
        width: "100%",
        height: imageContainerHeight(cardSize) + (tallScreen ? 350 : 0),
        maxheight: "60%",
      },
      contentContainer: {
        width: "100%",
        padding: wideScreen ? SPACING.BASE6X : SPACING.LARGE,
      },
      topBorderRounded: {
        borderTopLeftRadius: SPACING.BASE,
        borderTopRightRadius: SPACING.BASE,
      },
      imageWrapperCompact: {
        ...imageContainerHorizontalStyle(cardSize),
        aspectRatio: aspectRatio,
      },
      componentWrapperCompact: {
        borderRadius: SPACING.BASE,
        borderWidth: 1,
        borderColor: theme.colors.dividerPrimary,
        padding: SPACING.LARGE,
        width: "100%",
      },
      overlayBackground: {
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        zIndex: 1,
        backgroundColor: theme.colors.backgroundReverse20,
      },
      overlayTitle: {
        zIndex: 2,
      },
    });
  }
);

export default ArticlePreview;
