import React, { memo } from "react";

import { useNavigate } from "~/routing";

import Icon from "@expo/vector-icons/MaterialCommunityIcons";

import Button, { ButtonProps } from "~/components/elements/Button";
import { SPACING } from "~/styles";
import { useTheme } from "@rneui/themed";

type EditCustomFeedButtonProps = {
  group: FC.Group;
  showIcon?: boolean;
  showLabel?: boolean;
} & ButtonProps;

const EditCustomFeedButton: React.FC<EditCustomFeedButtonProps> = ({
  group,
  showLabel = true,
  showIcon = false,
  ...restProps
}) => {
  const navigate = useNavigate();
  const { theme } = useTheme();

  const onPress = () => {
    if (group.requestKey) {
      navigate(`/custom-feed/${group.requestKey}/edit`);
    }
  };
  return (
    <Button
      title={showLabel ? "Edit" : ""}
      buttonStyle={{ gap: SPACING.SMALL }}
      onPress={onPress}
      type="clear"
      icon={
        showIcon ? (
          <Icon
            name="square-edit-outline"
            color={theme.colors.primary}
            size={SPACING.BASE3X}
          />
        ) : undefined
      }
      {...restProps}
    />
  );
};

export default memo(EditCustomFeedButton);
