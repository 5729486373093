import React, { memo } from "react";
import Text, { TextProps } from "~/components/elements/Text";
import { TextSize } from "~/enums";
import { cardSizeToHeaderSize, cardSizeToTextSize } from "~/utils/fonts";

interface CardTextProps extends TextProps {
  cardSize?: CardSize;
  textSize?: TextSize | number;
}
const CardText: React.FC<CardTextProps> = ({
  cardSize,
  textSize,
  header,
  ...restProps
}) => {
  const sizeFunction = header ? cardSizeToHeaderSize : cardSizeToTextSize;
  return (
    <Text
      {...restProps}
      header={header}
      size={textSize || sizeFunction(cardSize)}
    />
  );
};

export default memo(CardText);
