import { makeStyles } from "@rneui/themed";
import React, { memo } from "react";
import { ActivityIndicator, ActivityIndicatorProps } from "react-native";
import { View } from "react-native";
import { createStyleSheet, SPACING } from "~/styles";
import { Text } from "~/components/elements";

const Loading: React.FC<{
  size?: ActivityIndicatorProps["size"];
  message?: string;
}> = ({ size = "large", message }) => {
  const styles = useStyles();
  return (
    <View style={styles.loadingWrapper}>
      <ActivityIndicator size={size} />
      {message && <Text style={styles.message}>{message}</Text>}
    </View>
  );
};

const useStyles = makeStyles(() => {
  return createStyleSheet({
    loadingWrapper: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    message: {
      marginTop: SPACING.LARGE,
    },
  });
});

export default memo(Loading);
