import React, { memo } from "react";
import { Provider } from "react-redux";
import { ThemeProvider } from "@rneui/themed";
import ToastProvider from "~/components/Toast";
import { CookiesProvider } from "react-cookie";
import theme from "~/theme";

import store from "~/store";

import ApplicationTheme from "~/components/ApplicationTheme";
import KeyboardNav from "~/components/KeyboardNav";
import ClientUniqueId from "~/components/ClientUniqueId";
import Responsive from "~/components/Responsive";
import TemporaryPhoneAuth from "~/components/TemporaryPhoneAuth";
import LoadPrerequisites from "~/components/LoadPrerequisites";
import Application from "~/layouts/Application";

import { Platform } from "react-native";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { Router } from "~/routing";
import FontLoader from "~/components/FontLoader";
import VerifyCredentials from "~/components/elements/VerifyCredentials";

const Root = () => {
  return (
    <FontLoader>
      <ThemeProvider theme={theme}>
        <ToastProvider>
          <SafeAreaProvider>
            <Provider store={store}>
              <CookiesProvider>
                <Responsive />
                {Platform.OS === "web" && <KeyboardNav />}
                {Platform.OS === "web" && <ClientUniqueId />}
                <ApplicationTheme />
                <Router>
                  <TemporaryPhoneAuth>
                    <VerifyCredentials>
                      <LoadPrerequisites>
                        <Application />
                      </LoadPrerequisites>
                    </VerifyCredentials>
                  </TemporaryPhoneAuth>
                </Router>
              </CookiesProvider>
            </Provider>
          </SafeAreaProvider>
        </ToastProvider>
      </ThemeProvider>
    </FontLoader>
  );
};

export default memo(Root);
