import React, { memo } from "react";
import { createStyleSheet, SPACING } from "~/styles";

import { makeStyles } from "@rneui/themed";
import { Column, Row } from "~/components/elements";

import { isImageAttachment } from "~/predicates";
import ItemImage from "~/components/ItemImage";
import { LayoutChangeEvent, Pressable } from "react-native";
import { useAppDispatch, useAppSelector } from "~/hooks";
import {
  getCurrentAttachmentIndex,
  getPreviewItem,
  setCurrentAttachmentIndex,
} from "~/concepts/thread";
import { ImageStyle } from "expo-image";

const BORDER_RADIUS = SPACING.BASE;
type ThreadPhotoCardProps = {
  item: FC.Item;
  onPress: () => void;
};

const ThreadPhotoCard: React.FC<ThreadPhotoCardProps> = ({ item, onPress }) => {
  const dispatch = useAppDispatch();
  const styles = useStyles();
  const [containerWidth, setContainerWidth] = React.useState<number>(0);

  const currentPreviewItem = useAppSelector(getPreviewItem);
  const currentAttachmentIndex = useAppSelector(getCurrentAttachmentIndex);

  const imageAttachments = item.attachments?.filter(isImageAttachment) || [];
  const numImages = imageAttachments.length;
  const aspectRatios = imageAttachments.map((attachment) => {
    const sizeMeta = attachment.meta.original;
    return sizeMeta.aspect;
  });
  let containerHeight = containerWidth / Math.max(1.4, aspectRatios[0]);

  const renderImage = (attachmentNumber: number) => {
    const imageStyle: ImageStyle = {
      overflow: "hidden",
      borderWidth: 2,
      borderColor: "transparent",
    };
    if (attachmentNumber === 0) {
      imageStyle.borderTopLeftRadius = BORDER_RADIUS;
    }
    if (
      (numImages <= 3 && attachmentNumber === 0) ||
      (numImages === 4 && attachmentNumber === 3)
    ) {
      imageStyle.borderBottomLeftRadius = BORDER_RADIUS;
    }
    if ((numImages <= 1 && attachmentNumber === 0) || attachmentNumber === 1) {
      imageStyle.borderTopRightRadius = BORDER_RADIUS;
    }
    if (
      (numImages <= 1 && attachmentNumber === 0) ||
      (numImages <= 2 && attachmentNumber === 1) ||
      (numImages >= 3 && attachmentNumber === 2)
    ) {
      imageStyle.borderBottomRightRadius = BORDER_RADIUS;
    }
    const isPreviewed =
      currentPreviewItem?.id === item.id &&
      currentAttachmentIndex === attachmentNumber;
    return (
      <Row flex style={[imageStyle, isPreviewed && styles.thumbSelected]}>
        <Pressable
          style={[styles.flex1, { borderRadius: BORDER_RADIUS }]}
          onPress={() => {
            if (currentPreviewItem?.id !== item.id) {
              onPress();
            }
            dispatch(setCurrentAttachmentIndex(attachmentNumber));
          }}
        >
          <ItemImage
            attachmentNumber={attachmentNumber}
            item={item}
            contentFit={"cover"}
            darken={currentPreviewItem?.id === item.id && !isPreviewed}
          />
        </Pressable>
      </Row>
    );
  };

  if (!imageAttachments) return null;
  return (
    <Row
      flex
      noGap
      style={[styles.threadImageWrapper, { minHeight: containerHeight }]}
      onLayout={(event: LayoutChangeEvent) => {
        const { width } = event.nativeEvent.layout;
        setContainerWidth(width);
      }}
    >
      <Column flex noGap>
        {renderImage(0)}
        {numImages >= 4 && renderImage(3)}
      </Column>
      {numImages >= 2 && (
        <Column flex noGap>
          {renderImage(1)}
          {numImages >= 3 && renderImage(2)}
        </Column>
      )}
    </Row>
  );
};

const useStyles = makeStyles((theme) =>
  createStyleSheet({
    threadImageWrapper: {
      width: "100%",
      overflow: "hidden",
      borderRadius: BORDER_RADIUS,
    },

    blurhashBackground: {
      position: "absolute",
      top: 0,
      left: 0,
      height: "100%",
      width: "100%",
    },
    thumbSelected: {
      borderColor: theme.colors.controlsBlue,
    },
  })
);
export default memo(ThreadPhotoCard);
