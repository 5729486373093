import React, { memo } from "react";

import shuffleSeed from "shuffle-seed";
import { ScreenSizeType, CardSize } from "~/enums";
import BaseCard from "~/components/cards/BaseCard";
import VigetOneCard from "~/components/cards/VigetOneCard";
import { Row } from "~/components/elements";

const OneUp: FC.PageStrategyResult["Component"] = ({
  items,
  contentWidth,
  contentHeight,
  seed,
  viewableContentSize,
}) => {
  const isImageCoverRight = shuffleSeed.shuffle([true, false], seed);

  const cardSize =
    viewableContentSize === ScreenSizeType.NARROW
      ? CardSize.MEDIUM
      : CardSize.XLARGE;

  return (
    <Row
      flex
      style={{
        width: contentWidth || "100%",
        height: contentHeight || "100%",
      }}
    >
      <BaseCard
        item={items[0]}
        width={contentWidth}
        CardComponent={VigetOneCard}
        cardSize={cardSize}
        componentProps={{ isImageCoverRight: isImageCoverRight[0] }}
      />
    </Row>
  );
};

export default memo(OneUp);
