import React, { memo, useState } from "react";
import { createStyleSheet, SPACING } from "~/styles";
import { makeStyles } from "@rneui/themed";
import { LayoutChangeEvent } from "react-native";
import { Row, Column, Text, Anchor } from "~/components/elements";

import { TextSize, CardSize } from "~/enums";
import ItemImage from "~/components/ItemImage";
import CardAuthor from "~/components/CardAuthor";
import Reboosts from "~/components/cardComponents/Reboosts";

const TEXT_MAX_WIDTH = 400;

export type OneCardProps = {
  item: FC.Item;
  isImageCoverRight?: boolean;
  cardSize?: CardSize;
};

const OneCard: React.FC<OneCardProps> = ({
  item,
  isImageCoverRight = false,
  cardSize,
}) => {
  const [isFullBleedImage, setIsFullBleedImage] = useState(false);
  const [imageContainerHeight, setImageContainerHeight] = useState(0);
  const [imageContainerWidth, setImageContainerWidth] = useState(0);

  const styles = useStyles();
  if (!item.card) return null;

  const TextCard = (
    <Column
      key={`${item.id}-text`}
      style={[
        styles.textContainer,
        imageContainerHeight > 0 && {
          height: imageContainerHeight,
        },
      ]}
    >
      <CardAuthor item={item} justifyRight />
      <Anchor href={item.card.url}>
        <Text
          header
          size={cardSize === CardSize.MEDIUM ? TextSize.L : TextSize.XL}
        >
          {item.card?.title}
        </Text>
      </Anchor>
      <Reboosts item={item} />
    </Column>
  );

  const ImageCover = (
    <Column
      flex
      key={`${item.id}-image`}
      style={[
        styles.imageCoverStyle,
        styles.alignCenter,
        styles.justifyCenter,
        imageContainerWidth > 0 && { maxWidth: imageContainerWidth },
      ]}
      onLayout={(event: LayoutChangeEvent) => {
        const { height, width } = event.nativeEvent.layout;
        if (
          !item.image?.height ||
          !item.image.width ||
          imageContainerHeight > 0
        ) {
          return;
        }

        if (height > item.image.height && width > item.image.width) {
          setImageContainerHeight(item.image.height);
          setImageContainerWidth(item.image.width);
        } else if (height < item.image.height && width < item.image.width) {
          setImageContainerHeight(height - SPACING.BASE10X);
          setImageContainerWidth(width);
          setIsFullBleedImage(true);
        } else if (height < item.image.height) {
          setImageContainerHeight(height - SPACING.BASE10X);
          setImageContainerWidth(
            item.image.width * (height / item.image.height)
          );
        } else if (width < item.image.width) {
          setImageContainerWidth(width);
          setImageContainerHeight(
            (item.image.height * width) / item.image.width
          );
        } else {
          setImageContainerHeight(height);
          setImageContainerWidth(width);
        }
      }}
    >
      <Row style={{ width: imageContainerWidth, height: imageContainerHeight }}>
        <ItemImage
          item={item}
          attachmentNumber={0}
          flex
          disableBlurBackground
          contentFit={isFullBleedImage ? "cover" : "contain"}
        />
      </Row>
    </Column>
  );

  const layout = isImageCoverRight
    ? [TextCard, ImageCover]
    : [ImageCover, TextCard];

  return (
    <Row flex style={[styles.cardContentWrapper]}>
      {layout}
    </Row>
  );
};

const useStyles = makeStyles((_theme) =>
  createStyleSheet({
    cardContentWrapper: {
      alignItems: "center",
      justifyContent: "center",
      gap: SPACING.BASE5X,
      paddingHorizontal: SPACING.BASE5X,
    },
    textContainer: {
      maxWidth: `min(${TEXT_MAX_WIDTH}px, 30%)`,
      justifyContent: "flex-end",
    },
    imageCoverStyle: {
      height: "100%",
      width: "100%",
    },
    contentStyle: {
      paddingBottom: SPACING.BASE5X,
    },
  })
);

export default memo(OneCard);
