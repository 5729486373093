import React, { memo, useRef, useEffect } from "react";
import { View } from "react-native";

const DisableKeyboardNav: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const ref = useRef<View>(null);

  const handler = (e: KeyboardEvent) => {
    e.stopPropagation();
  };

  useEffect(() => {
    const currentRef = ref.current;
    if (currentRef) {
      const castRef = currentRef as unknown as HTMLElement;
      castRef.addEventListener("keydown", handler);
    }
    return () => {
      if (currentRef) {
        const castRef = currentRef as unknown as HTMLElement;
        castRef.removeEventListener("keydown", handler);
      }
    };
  }, [ref]);

  return <View ref={ref}>{children}</View>;
};

export default memo(DisableKeyboardNav);
