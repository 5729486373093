import React, { ReactNode } from "react";
import { StyleProp } from "react-native";
import type { PropsWithChildren } from "react";
import type { ViewProps, ViewStyle } from "react-native";
import { BlurView } from "expo-blur";
import Animated, {
  useAnimatedStyle,
  useDerivedValue,
  withTiming,
} from "react-native-reanimated";

export enum SLIDE_IN_DIRECTION {
  TOP = "TOP",
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

type CollapsibleAnimatedViewProps = PropsWithChildren<{
  style?: StyleProp<ViewStyle>;
  children: ReactNode;
  show?: boolean;
  nullOnHidden?: boolean;
  slideInDirection?: SLIDE_IN_DIRECTION;
  blurBackgroundRange?: number[];
  fade?: boolean;
  tint?: "light" | "dark" | "default";
  flexRange?: number[];
}> &
  ViewProps;

const AnimatedBlurView = Animated.createAnimatedComponent(BlurView);

const slideInScaleRange = [0, 1];

/**
 * For some dumb reason react native has moved translate to be numbers only.
 * To save having to calculate the length, for our purposes here, putting big value
 * for the translate values works well enough.
 */
const slideInTopTranslateYRange = [-1000, 0];
const slideInLeftTranslateXRange = [-2000, 0];
const slideInRightTranslateXRange = [2000, 0];

const CollapsibleAnimatedView = React.forwardRef<
  any,
  CollapsibleAnimatedViewProps
>(
  (
    {
      children,
      style,
      show,
      nullOnHidden = false,
      slideInDirection,
      fade,
      blurBackgroundRange,
      tint,
      flexRange,
    },
    ref
  ) => {
    const showValue = useDerivedValue(() => {
      return show ? 1 : 0;
    });
    const opacity = useDerivedValue(() => {
      return showValue.value;
    });
    const flex = useDerivedValue(() => {
      return flexRange ? flexRange[showValue.value] : 1;
    });

    const scaleY = useDerivedValue(() => {
      if (slideInDirection === SLIDE_IN_DIRECTION.TOP) {
        return slideInScaleRange[showValue.value];
      }
      return 1;
    });
    const translateY = useDerivedValue(() => {
      if (slideInDirection === SLIDE_IN_DIRECTION.TOP) {
        return slideInTopTranslateYRange[showValue.value];
      }
      return 0;
    });

    const scaleX = useDerivedValue(() => {
      if (slideInDirection === SLIDE_IN_DIRECTION.LEFT) {
        return slideInScaleRange[showValue.value];
      }
      if (slideInDirection === SLIDE_IN_DIRECTION.RIGHT) {
        return slideInScaleRange[showValue.value];
      }
      return 1;
    });

    const translateX = useDerivedValue(() => {
      if (slideInDirection === SLIDE_IN_DIRECTION.LEFT) {
        return slideInLeftTranslateXRange[showValue.value];
      }
      if (slideInDirection === SLIDE_IN_DIRECTION.RIGHT) {
        return slideInRightTranslateXRange[showValue.value];
      }
      return 0;
    });

    const blurBackgroundRangeAnim = useDerivedValue(() => {
      return (blurBackgroundRange && blurBackgroundRange[showValue.value]) || 0;
    });

    const timingConfigSlow = { duration: 300 };
    const animatedStyles = useAnimatedStyle(() => ({
      opacity: fade ? withTiming(opacity.value, timingConfigSlow) : 1,
      flex: withTiming(flex.value, timingConfigSlow),
      /**
       * Animating on transform rather than height/width because
       * the latter causes browser re-renders. This "should" be
       * somewhat smoother.
       */
      transform: [
        {
          scaleY: withTiming(scaleY.value, timingConfigSlow),
        },
        {
          translateX: withTiming(translateX.value, timingConfigSlow),
        },
        {
          scaleX: withTiming(scaleX.value, timingConfigSlow),
        },
        {
          translateY: withTiming(translateY.value, timingConfigSlow),
        },
      ],
    }));

    if (!show && nullOnHidden) return null;
    if (!blurBackgroundRange)
      return (
        <Animated.View style={[animatedStyles, style]} ref={ref}>
          {children}
        </Animated.View>
      );

    return (
      <AnimatedBlurView
        intensity={blurBackgroundRangeAnim.value}
        style={[animatedStyles, style]}
        ref={ref}
        tint={tint}
      >
        {children}
      </AnimatedBlurView>
    );
  }
);

export default CollapsibleAnimatedView;
