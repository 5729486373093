import React, { memo } from "react";
import { SPACING } from "~/styles";
import { useTheme } from "@rneui/themed";
import { Button } from "~/components/elements";

import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { ButtonProps } from "~/components/elements/Button";

const ICON_SIZE = SPACING.BASE3X;

type IconButtonProps = {
  icon?: keyof typeof Icon.glyphMap;
} & Omit<ButtonProps, "children" | "icon">;
const IconButton: React.FC<IconButtonProps> = ({
  overlay = false,
  icon,
  ...restProps
}) => {
  const { theme } = useTheme();
  return (
    <Button
      {...restProps}
      type={"clear"}
      icon={
        <Icon
          name={icon}
          size={ICON_SIZE}
          solid={true}
          color={overlay ? theme.colors.primaryReverse : theme.colors.primary}
          raised
        />
      }
      overlay={overlay}
      {...restProps}
    />
  );
};

export default memo(IconButton);
