import React, { memo } from "react";
import { View, Text, Pressable } from "react-native";
import { ToastProps } from "react-native-toast-notifications/lib/typescript/toast";
import { LAYOUT, SPACING, createStyleSheet } from "~/styles";
import { makeStyles } from "@rneui/themed";

import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { Row } from "~/components/elements";
import CloseButton from "~/buttons/CloseButton";

interface TastMessageProps {
  toast: ToastProps;
  success?: boolean;
  fail?: boolean;
  info?: boolean;
  onPressHide?: boolean;
}

const ToastMessage: React.FC<TastMessageProps> = ({
  toast,
  success,
  fail,
  info,
  onPressHide,
}) => {
  const styles = useStyles();

  return (
    <Pressable
      onPress={() => {
        onPressHide && toast.onHide();
      }}
      style={styles.container}
    >
      <Row
        style={[
          styles.toastWrapper,
          fail && styles.failBackground,
          success && styles.successBackground,
          info && styles.infoBackground,
        ]}
      >
        <View style={styles.iconWrapper}>
          {fail && <Icon name="exclamation" size={28} color="white" />}
          {success && <Icon name="check-circle" size={28} color="white" />}
          {info && <Icon name="information" size={28} color="white" />}
        </View>
        <View style={styles.textWrapper}>
          <Text style={styles.text}>{toast.message}</Text>
        </View>
        <CloseButton
          style={styles.closeButton}
          onPress={() => toast.onHide()}
          overlay
        />
      </Row>
    </Pressable>
  );
};

const useStyles = makeStyles((theme) => {
  return createStyleSheet({
    container: {
      zIndex: LAYOUT.TOAST_MESSAGE_Z_INDEX,
    },
    toastWrapper: {
      marginRight: SPACING.BASE3X,
      paddingVertical: SPACING.BASE,
      marginVertical: SPACING.SMALL,
      borderRadius: 8,
      paddingHorizontal: SPACING.LARGE,
      alignItems: "center",
      color: "#fff",
      width: 320,
      gap: SPACING.BASE,
    },
    iconWrapper: {
      marginRight: SPACING.MEDIUM,
    },
    textWrapper: {
      flex: 1,
    },
    successBackground: {
      backgroundColor: theme.colors.successBackground,
    },
    infoBackground: {
      backgroundColor: theme.colors.infoBackground,
    },
    failBackground: {
      backgroundColor: theme.colors.failBackground,
    },
    textHeader: {
      fontWeight: "bold",
      fontSize: 16,
      color: theme.colors.primaryReverse,
      marginBottom: SPACING.SMALL,
    },
    text: {
      color: theme.colors.primaryReverse,
      maxWidth: "95%",
    },
    closeButton: {
      position: "absolute",
      top: 0,
      right: 0,
      zIndex: 1,
    },
  });
});

export default memo(ToastMessage);
