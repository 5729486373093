export const CUSTOM_FEED_ID_SEPARATOR = "~";

export const isRSSFeedId = (id: string) => id.match(/^rss%3A/);

export const isHashtagFeedId = (id: string) => id.match(/^hashtag-/);

export const isUserFeedId = (id: string) => id.match(/^account-https?/);

export const isTopicFeedId = (id: string) => id.match(/^topic-/);

export const isSearchFeedId = (id: string) => id.match(/^search-/);

const customFeedIdRegExp = new RegExp(`${CUSTOM_FEED_ID_SEPARATOR}\\w{26}$`);
export const isCustomFeedId = (id: string) => id.match(customFeedIdRegExp);

export const idFromHashtagName = (name: string) => `hashtag-${name}`;

export const idFromTopicName = (name: string) => `topic-${name}`;

export const idFromAuthorUrl = (uri: string) =>
  `account-${encodeURIComponent(uri)}`;

export const idFromRSSUID = (uid: string) => encodeURIComponent(uid);

export const idFromSearchTerm = (term: string) =>
  `search-${encodeURIComponent(term)}`;

export const customFeedIdFromRouteFeedId = (feedId: string) =>
  feedId.split(CUSTOM_FEED_ID_SEPARATOR)[1];

export const authorRequestKeyFromRouteFeedId = (feedId: string) =>
  decodeURIComponent(feedId.replace(/^account-/, ""));

export const topicRequestKeyFromRouteFeedId = (feedId: string) =>
  feedId.replace(/^topic-/, "");

export const hashtagIdFromRouteFeedId = (feedId: string) =>
  feedId.replace(/^hashtag-/, "");

export const searchIdFromRouteFeedId = (feedId: string) =>
  decodeURIComponent(feedId.replace(/^search-/, ""));
