import React, { memo } from "react";
import BasicRow from "~/components/pages/sublayouts/BasicRow";
import { CardSize, ScreenSizeType } from "~/enums";

const CatchAllRow: FC.PageStrategyResult["Component"] = ({
  items,
  contentWidth,
  viewableContentSize,
}) => {
  return (
    <BasicRow
      items={items}
      width={contentWidth}
      cardSize={
        viewableContentSize === ScreenSizeType.NARROW
          ? CardSize.LARGE
          : CardSize.XLARGE
      }
      noGap
    />
  );
};

export default memo(CatchAllRow);
