import React, { memo, useEffect, useState } from "react";
import { View as ReactNativeView, Text as RNText } from "react-native";
import { createStyleSheet, SPACING } from "~/styles";
import { makeStyles } from "@rneui/themed";
import {
  Text,
  Column,
  Image,
  View,
  CollapsibleAnimatedView,
  HoverOverlay,
} from "~/components/elements";
import { GroupRequestType, TextAttributionStyle } from "~/enums";
import Hoverable from "~/components/elements/Hoverable";
import { Pressable, StyleProp } from "react-native";
import { BlurView } from "expo-blur";
import TileAttribution from "~/components/elements/TileAttribution";
import { LinearGradient } from "expo-linear-gradient";
import { useGetLiveImageQuery } from "~/api";
import { skipToken } from "@reduxjs/toolkit/dist/query";

type TileProps = {
  title?: string;
  subText?: string;
  image?: string | null;
  controls?: React.ReactNode;
  height?: number;
  width: number;
  onPress?: () => void;
  backgroundColor?: string;
  style?: StyleProp<ViewStyle>;
  contentJustify?: "flex-start" | "flex-end" | "center";
  alwaysShowContents?: boolean;
  textSize?: number;
  headerTextSize?: number;
  group?: FC.Group;
  textStyle?: StyleProp<RNText>;
  overlayStyle?: StyleProp<ViewStyle>;
  showGradient?: boolean;
  children?: React.ReactNode;
};

const Tile = React.forwardRef<ReactNativeView, TileProps>(
  (
    {
      group,
      title,
      subText,
      image,
      controls,
      width = 100,
      height,
      backgroundColor,
      onPress,
      style,
      contentJustify = "flex-end",
      alwaysShowContents = false,
      headerTextSize = 20,
      textSize = 12,
      overlayStyle,
      showGradient,
      children,
    },
    ref
  ) => {
    const [liveImage, setLiveImage] = useState<string>();
    const loadLiveImage = !!(
      group &&
      (group.requestType === GroupRequestType.CUSTOM_FEED ||
        group.customFeedValue) &&
      !image
    );
    const liveImageResponse = useGetLiveImageQuery(group ? group : skipToken, {
      skip: !loadLiveImage,
    });

    useEffect(() => {
      if (liveImageResponse.data && liveImageResponse.isSuccess) {
        setLiveImage(liveImageResponse.data);
      }
    }, [setLiveImage, liveImageResponse.data, liveImageResponse.isSuccess]);

    const styles = useStyles({ contentJustify });
    const calculatedHeight = height || width * (4 / 3);

    const imageUrl = liveImage || image;

    const isOverlayed = !!backgroundColor || !!imageUrl;
    return (
      <Column
        ref={ref}
        style={[styles.tile, { width, height: calculatedHeight }, style]}
        noGap
      >
        <Column
          flex
          style={[
            styles.imageWrapper,
            backgroundColor ? { backgroundColor } : {},
          ]}
        >
          {imageUrl ? (
            <Image height={"100%"} url={imageUrl} contentFit={"cover"} />
          ) : (
            <View />
          )}
          <Hoverable style={styles.overlayWrapper}>
            {(isHovered) => (
              <>
                {isHovered && (
                  <BlurView
                    style={styles.blurOverlay}
                    tint={"dark"}
                    intensity={10}
                  />
                )}
                {showGradient && (
                  <LinearGradient
                    // Background Gradient Overlay
                    colors={["#00000000", "#00000080"]}
                    locations={[0.45, 0.9]}
                    style={styles.gradientOverlay}
                  />
                )}
                <Pressable
                  onPress={onPress}
                  style={[styles.overlay, overlayStyle]}
                >
                  {children ? (
                    children
                  ) : (
                    <>
                      <Text
                        size={headerTextSize}
                        overlay={isOverlayed}
                        style={[
                          styles.tileOverlayText,
                          isOverlayed && styles.tileOverlayTextOverlay,
                          styles.tileOverlayTitle,
                        ]}
                      >
                        {title}
                      </Text>

                      <CollapsibleAnimatedView
                        show={alwaysShowContents || !!(isHovered && subText)}
                        nullOnHidden={true}
                        fade
                        style={[styles.noFlex, styles.noBackground]}
                      >
                        <Text
                          size={textSize}
                          overlay={isOverlayed}
                          style={[
                            styles.tileOverlayText,
                            isOverlayed && styles.tileOverlayTextOverlay,
                          ]}
                          numberOfLines={5}
                          attribution={TextAttributionStyle.HOME_TILE}
                        >
                          {subText}
                        </Text>
                      </CollapsibleAnimatedView>
                      {group && <TileAttribution group={group} />}
                    </>
                  )}
                </Pressable>
                <HoverOverlay isHovered={isHovered} overlay={isOverlayed} />
              </>
            )}
          </Hoverable>
        </Column>
        {controls && controls}
      </Column>
    );
  }
);

const useStyles = makeStyles(
  (
    theme,
    {
      contentJustify = "flex-end",
    }: { contentJustify?: "flex-start" | "flex-end" | "center" }
  ) => {
    return createStyleSheet({
      tile: {
        overflow: "hidden",
        borderRadius: SPACING.BASE,
      },
      imageWrapper: {
        backgroundColor: theme.colors.grey2,
      },
      gradientOverlay: { position: "absolute", height: "100%", width: "100%" },
      blurOverlay: {
        flex: 1,
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 1,
      },
      overlayWrapper: {
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 2,
      },
      overlay: {
        backgroundColor: "transparent",
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
        height: "100%",
        alignItems: "flex-start",
        justifyContent: contentJustify,
        zIndex: 3,
        padding: SPACING.LARGE,
        flexDirection: "column",
        gap: SPACING.BASE,
      },
      tileOverlayText: {
        backgroundColor: "transparent",
        overflowWrap: "anywhere",
        textAlign: "left",
        lineHeight: "115%",
      },
      tileOverlayTextOverlay: {
        textShadowColor: theme.colors.dividerPrimaryReverse,
        textShadowOffset: { width: 1, height: 1 },
        textShadowRadius: 2,
      },
      tileOverlayTitle: {
        fontFamily: "RobotoCondensed_700Bold",
        fontWeight: "bold",
      },
    });
  }
);

export default memo(Tile);
