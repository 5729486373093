import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApplicationMode, GroupRequestType, RSSFeedType } from "~/enums";
import { euc } from "~/lib/url";
import flDailyItemToItem, {
  FLDailyItem,
} from "~/apiTransforms/flDailyItemToItem";
import { mastodonSearchResultsToSearchResults } from "~/apiTransforms/mastodonSearchResultsToSearchResults";
import {
  APIPreferences,
  transformPreferencesResponse,
  transformPreferencesRequest,
} from "~/apiTransforms/preferences";
import getGroupItems from "~/apiTransforms/getGroupItems";
import { API_ROOT } from "~/config";
import { idFromTopicName } from "~/utils/id";
import { rssSearchToFeeds, RSSFeed } from "~/apiTransforms/rss";
import { rssFeedfromRSSFeed } from "~/utils/groups";
import { RootState } from "~/store";
import { selectBearerToken } from "~/concepts/auth";

interface StatusContext {
  ancestors: Array<FC.Item>;
  descendants: Array<FC.Item>;
}

const updateStatusContext =
  (itemMutateFn: (item: FC.Item) => void, item: FC.Item) =>
  (context: StatusContext) => {
    const reducer = (acc: Array<FC.Item>, i: FC.Item) => {
      if (i.id === item.id) {
        itemMutateFn(i);
      }
      acc.push(i);
      return acc;
    };
    context.ancestors = context.ancestors.reduce(reducer, []);
    context.descendants = context.descendants.reduce(reducer, []);
  };

export const flipboardFeedPath = (
  { requestType, requestKey }: FC.Group,
  options?: {
    limit?: number;
    applicationMode?: ApplicationMode;
    bustUpstreamCache?: boolean;
  }
) => {
  let prefix: string;
  if (requestType === GroupRequestType.CUSTOM_FEED_PREVIEW) {
    prefix = `flipboard/v1/feed/maestra/custom?${requestKey}&`;
  } else if (
    requestType === GroupRequestType.RSS ||
    requestType === GroupRequestType.YOUTUBE ||
    requestType === GroupRequestType.TOPIC ||
    options?.applicationMode === ApplicationMode.CUSTOM_FEED_BUILDER
  ) {
    prefix = `flipboard/v1/feed/maestra/custom?topics=${requestKey}&`;
  } else if (requestType === GroupRequestType.CUSTOM_FEED) {
    prefix = `flipboard/v1/feed/maestra/custom/${requestKey}?`;
  } else if (requestType === GroupRequestType.SEARCH) {
    prefix = `flipboard/v1/search/maestra/posts/?q=${requestKey}&`;
  } else {
    prefix = `flipboard/v1/feed/maestra/category/${requestKey}?`;
  }

  const bustUpstreamCacheParam = options?.bustUpstreamCache
    ? `&bustUpstreamCache=${new Date().getTime()}&preview=true`
    : "";
  return `${prefix}limit=${
    options?.limit || 100
  }&flip=true&groups=true${bustUpstreamCacheParam}`;
};

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    // in staging and production, should be domain root in development
    // we specify API_ROOT of https://dailytusker.com in
    // docker-compose.yml
    baseUrl: API_ROOT ? API_ROOT : "/",
    credentials: "include",
    prepareHeaders: (headers, { getState }) => {
      const token = selectBearerToken(getState() as RootState);
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["GetGroupItems"],
  endpoints: (builder) => ({
    getAuthorizeRedirectUrl: builder.query({
      query: (host) => ({
        method: "POST",
        url: "flipboard/v1/auth/verify",
        params: { host },
      }),
    }),
    revokeToken: builder.query<null, void>({
      query: () => ({
        method: "POST",
        url: "flipboard/v1/auth/revoke",
      }),
    }),
    getPreferences: builder.query<FC.Preferences, void>({
      query: () => "flipboard/v1/preferences",
      transformResponse: (data: APIPreferences) =>
        transformPreferencesResponse(data),
    }),
    setPreferences: builder.mutation({
      query: (preferences: FC.Preferences) => ({
        method: "POST",
        url: "flipboard/v1/preferences",
        body: transformPreferencesRequest(preferences),
      }),
      onQueryStarted: async (preferences, { dispatch, queryFulfilled }) => {
        const updateAction = api.util.updateQueryData(
          "getPreferences",
          undefined,
          () => preferences
        );
        const updateResult = dispatch(updateAction);
        try {
          await queryFulfilled;
        } catch {
          updateResult.undo();
        }
      },
    }),
    searchInstance: builder.query<Array<{ host: string }>, string>({
      query: (query: string) => ({
        url: `flipboard/v1/feed/instances`,
        params: {
          query,
        },
      }),
    }),
    verifyCredentials: builder.query<FC.Credentials, void>({
      query: () => "api/v1/accounts/verify_credentials",
      extraOptions: { maxRetries: 0 },
    }),
    getGroupItems: builder.query<
      FC.GroupPage,
      {
        group: FC.Group;
        applicationMode: ApplicationMode;
        bustUpstreamCache?: boolean;
      }
    >({
      query: ({ group, applicationMode, bustUpstreamCache }) => {
        const { requestType, requestKey, cursorLink } = group;
        if (cursorLink) {
          return cursorLink;
        }
        if (requestType === GroupRequestType.SEARCH) {
          return flipboardFeedPath(group);
        }
        if (
          requestType === GroupRequestType.RSS ||
          requestType === GroupRequestType.YOUTUBE
        ) {
          return flipboardFeedPath(group, {
            bustUpstreamCache,
          });
        }
        if (applicationMode === ApplicationMode.CUSTOM_FEED_BUILDER) {
          return flipboardFeedPath(group, {
            applicationMode,
          });
        }
        if (
          requestType === GroupRequestType.CUSTOM_FEED_PREVIEW ||
          requestType === GroupRequestType.CUSTOM_FEED
        ) {
          return flipboardFeedPath(group);
        }
        if (requestType === GroupRequestType.ACCOUNT) {
          return `api/v1/accounts/${requestKey}/statuses?exclude_replies=true`;
        }
        if (requestType === GroupRequestType.HOME_TIMELINE) {
          return `api/v1/timelines/home?limit=40`;
        }
        if (requestType === GroupRequestType.HASHTAG) {
          return `api/v1/timelines/tag/${requestKey}?limit=40`;
        }
        return flipboardFeedPath(group);
      },
      providesTags: (_, __, arg) => {
        return [
          {
            type: "GetGroupItems",
            groupRequestKey: arg.group.requestKey,
          },
        ];
      },
      transformResponse: getGroupItems,
    }),
    getInstanceItem: builder.query<FC.Item | undefined, string>({
      query: (source: string) => ({
        url: "api/v2/search",
        params: {
          resolve: true,
          type: "statuses",
          q: source,
        },
      }),
      transformResponse: (data: { statuses: Array<FLDailyItem> }) => {
        const status = data.statuses?.[0];
        if (!status) {
          throw new Error("status not found");
        }
        return flDailyItemToItem(status);
      },
    }),
    getCustomFeedAuthorByUrl: builder.query({
      query: (url: string) => ({
        url: "api/v2/search",
        params: {
          resolve: true,
          type: "accounts",
          q: url,
        },
      }),
      transformResponse: (data: { accounts: Array<FC.Author> }) => {
        const account = data.accounts?.[0];
        return account ? account : undefined;
      },
    }),
    getInstanceAuthor: builder.query({
      query: (author: { acct: FC.Author["acct"] }) => ({
        url: "api/v2/search",
        params: {
          resolve: true,
          type: "accounts",
          q: author.acct,
        },
      }),
      transformResponse: (data: { accounts: Array<FC.Author> }) => {
        const account = data.accounts?.[0];
        return account ? account : undefined;
      },
    }),
    getFollowing: builder.query<
      { isFollowing: boolean; isNotifying: boolean },
      FC.Author
    >({
      query: (author: FC.Author) =>
        `api/v1/accounts/relationships?${euc("id[]")}=${author.id}`,
      transformResponse: (relationships: Array<FC.UserRelationship>) => {
        return {
          isFollowing: relationships[0]?.following,
          isNotifying: relationships[0]?.notifying,
        };
      },
    }),
    getPoll: builder.query<FC.PollItem["poll"], FC.PollItem["poll"]["id"]>({
      query: (id) => `api/v1/polls/${id}`,
    }),
    voteOnPoll: builder.mutation({
      query: ({
        itemPoll,
        choices,
      }: {
        itemPoll: FC.Item["poll"];
        choices: Array<number>;
      }) => ({
        method: "POST",
        url: `api/v1/polls/${itemPoll?.id}/votes`,
        body: {
          choices,
        },
      }),
      onQueryStarted: async ({ itemPoll }, { dispatch, queryFulfilled }) => {
        if (!itemPoll) {
          return;
        }
        const { data: updatedPoll } = await queryFulfilled;
        const updateAction = api.util.updateQueryData(
          "getPoll",
          itemPoll.id,
          () => {
            return updatedPoll;
          }
        );
        dispatch(updateAction);
      },
    }),

    follow: builder.mutation({
      query: (author: FC.Author) => ({
        method: "POST",
        url: `api/v1/accounts/${author.id}/follow`,
      }),
      onQueryStarted: async (author, { dispatch, queryFulfilled }) => {
        const updateAction = api.util.updateQueryData(
          "getFollowing",
          author,
          (relationship) => {
            if (relationship) {
              relationship.isFollowing = true;
            }
          }
        );
        const updateResult = dispatch(updateAction);
        try {
          await queryFulfilled;
        } catch (_) {
          updateResult.undo();
        }
      },
    }),
    unfollow: builder.mutation({
      query: (author: FC.Author) => ({
        method: "POST",
        url: `api/v1/accounts/${author.id}/unfollow`,
      }),
      onQueryStarted: async (author, { dispatch, queryFulfilled }) => {
        const updateAction = api.util.updateQueryData(
          "getFollowing",
          author,
          (relationship) => {
            if (relationship) {
              relationship.isFollowing = false;
            }
          }
        );
        const updateResult = dispatch(updateAction);
        try {
          await queryFulfilled;
        } catch (_) {
          updateResult.undo();
        }
      },
    }),
    notify: builder.mutation({
      query: (author: FC.Author) => ({
        method: "POST",
        url: `api/v1/accounts/${author.id}/follow`,
        params: { notify: true },
      }),
      onQueryStarted: async (author, { dispatch, queryFulfilled }) => {
        const updateAction = api.util.updateQueryData(
          "getFollowing",
          author,
          (relationship) => {
            if (relationship) {
              relationship.isNotifying = true;
            }
          }
        );
        const updateResult = dispatch(updateAction);
        try {
          await queryFulfilled;
        } catch (_) {
          updateResult.undo();
        }
      },
    }),
    unnotify: builder.mutation({
      query: (author: FC.Author) => ({
        method: "POST",
        url: `api/v1/accounts/${author.id}/follow`,
        params: { notify: false },
      }),
      onQueryStarted: async (author, { dispatch, queryFulfilled }) => {
        const updateAction = api.util.updateQueryData(
          "getFollowing",
          author,
          (relationship) => {
            if (relationship) {
              relationship.isNotifying = false;
            }
          }
        );
        const updateResult = dispatch(updateAction);
        try {
          await queryFulfilled;
        } catch (_) {
          updateResult.undo();
        }
      },
    }),
    favoriteStatus: builder.mutation({
      query: (item: FC.Item) => ({
        method: "POST",
        url: `api/v1/statuses/${item.id}/favourite`,
      }),
      onQueryStarted: async (item, { dispatch, queryFulfilled }) => {
        const itemMutateFn = (i?: FC.Item) => {
          if (i) {
            i.isFavorited = true;
          }
        };
        const updateAction = item.statusContext
          ? api.util.updateQueryData(
              "statusContext",
              item.statusContext,
              updateStatusContext(itemMutateFn, item)
            )
          : api.util.updateQueryData(
              "getInstanceItem",
              item.source,
              itemMutateFn
            );
        const updateResult = dispatch(updateAction);
        try {
          await queryFulfilled;
        } catch (_) {
          updateResult.undo();
        }
      },
    }),
    unfavoriteStatus: builder.mutation({
      query: (item: FC.Item) => ({
        method: "POST",
        url: `api/v1/statuses/${item.id}/unfavourite`,
      }),
      onQueryStarted: async (item, { dispatch, queryFulfilled }) => {
        const itemMutateFn = (i?: FC.Item) => {
          if (i) {
            i.isFavorited = false;
          }
        };
        const updateAction = item.statusContext
          ? api.util.updateQueryData(
              "statusContext",
              item.statusContext,
              updateStatusContext(itemMutateFn, item)
            )
          : api.util.updateQueryData(
              "getInstanceItem",
              item.source,
              itemMutateFn
            );
        const updateResult = dispatch(updateAction);
        try {
          await queryFulfilled;
        } catch (_) {
          updateResult.undo();
        }
      },
    }),
    bookmarkStatus: builder.mutation({
      query: (item: FC.Item) => ({
        method: "POST",
        url: `api/v1/statuses/${item.id}/bookmark`,
      }),
      onQueryStarted: async (item, { dispatch, queryFulfilled }) => {
        const itemMutateFn = (i?: FC.Item) => {
          if (i) {
            i.isBookmarked = true;
          }
        };
        const updateAction = item.statusContext
          ? api.util.updateQueryData(
              "statusContext",
              item.statusContext,
              updateStatusContext(itemMutateFn, item)
            )
          : api.util.updateQueryData(
              "getInstanceItem",
              item.source,
              itemMutateFn
            );
        const updateResult = dispatch(updateAction);
        try {
          await queryFulfilled;
        } catch (_) {
          updateResult.undo();
        }
      },
    }),
    unbookmarkStatus: builder.mutation({
      query: (item: FC.Item) => ({
        method: "POST",
        url: `api/v1/statuses/${item.id}/unbookmark`,
      }),
      onQueryStarted: async (item, { dispatch, queryFulfilled }) => {
        const itemMutateFn = (i?: FC.Item) => {
          if (i) {
            i.isBookmarked = false;
          }
        };
        const updateAction = item.statusContext
          ? api.util.updateQueryData(
              "statusContext",
              item.statusContext,
              updateStatusContext(itemMutateFn, item)
            )
          : api.util.updateQueryData(
              "getInstanceItem",
              item.source,
              itemMutateFn
            );
        const updateResult = dispatch(updateAction);
        try {
          await queryFulfilled;
        } catch (_) {
          updateResult.undo();
        }
      },
    }),
    reblogStatus: builder.mutation({
      query: (item: FC.Item) => ({
        method: "POST",
        url: `api/v1/statuses/${item.id}/reblog`,
      }),
      onQueryStarted: async (item, { dispatch, queryFulfilled }) => {
        const itemMutateFn = (i?: FC.Item) => {
          if (i) {
            i.isReblogged = true;
          }
        };
        const updateAction = item.statusContext
          ? api.util.updateQueryData(
              "statusContext",
              item.statusContext,
              updateStatusContext(itemMutateFn, item)
            )
          : api.util.updateQueryData(
              "getInstanceItem",
              item.source,
              itemMutateFn
            );
        const updateResult = dispatch(updateAction);
        try {
          await queryFulfilled;
        } catch (_) {
          updateResult.undo();
        }
      },
    }),
    unreblogStatus: builder.mutation({
      query: (item: FC.Item) => ({
        method: "POST",
        url: `api/v1/statuses/${item.id}/unreblog`,
      }),
      onQueryStarted: async (item, { dispatch, queryFulfilled }) => {
        const itemMutateFn = (i?: FC.Item) => {
          if (i) {
            i.isReblogged = false;
          }
        };
        const updateAction = item.statusContext
          ? api.util.updateQueryData(
              "statusContext",
              item.statusContext,
              updateStatusContext(itemMutateFn, item)
            )
          : api.util.updateQueryData(
              "getInstanceItem",
              item.source,
              itemMutateFn
            );
        const updateResult = dispatch(updateAction);
        try {
          await queryFulfilled;
        } catch (_) {
          updateResult.undo();
        }
      },
    }),
    deleteStatus: builder.mutation({
      query: (item: FC.Item) => ({
        method: "DELETE",
        url: `api/v1/statuses/${item.id}`,
      }),
      onQueryStarted: async (item, { dispatch, queryFulfilled }) => {
        if (!item.statusContext) {
          return;
        }
        const updateAction = api.util.updateQueryData(
          "statusContext",
          item.statusContext,
          (context) => {
            context.ancestors = context.ancestors.filter(
              (i) => i.id !== item.id
            );
            context.descendants = context.descendants.filter(
              (i) => i.id !== item.id
            );
          }
        );
        const updateResult = dispatch(updateAction);
        try {
          await queryFulfilled;
        } catch (_) {
          updateResult.undo();
        }
      },
    }),
    statusContext: builder.query({
      query: (itemId: FC.Item["id"]) => `api/v1/statuses/${itemId}/context`,
      transformResponse: (
        {
          ancestors,
          descendants,
        }: {
          ancestors: Array<FLDailyItem>;
          descendants: Array<FLDailyItem>;
        },
        _queryMeta,
        arg
      ) => ({
        ancestors: ancestors
          .map((s) => flDailyItemToItem(s))
          .map((item) => ({ ...item, statusContext: arg })),
        descendants: descendants
          .map((s) => flDailyItemToItem(s))
          .map((item) => ({ ...item, statusContext: arg })),
      }),
    }),
    reply: builder.query({
      query: ({ itemId, text }: { itemId: FC.Item["id"]; text: string }) => ({
        method: "POST",
        url: `api/v1/statuses`,
        body: {
          status: text,
          in_reply_to_id: itemId,
          media_ids: [],
          sensitive: false,
          spoiler_text: "",
          visibility: "public",
          poll: null,
          language: "en",
          application: {
            name: "tdm",
          },
        },
      }),
    }),
    getCustomFeeds: builder.query<Array<FC.CustomFeed>, void>({
      query: () => "flipboard/v1/builder",
    }),
    getCustomFeed: builder.query<FC.CustomFeed, FC.CustomFeed["id"]>({
      query: (id: FC.Group["id"]) => `flipboard/v1/builder/${id}`,
    }),
    getPublicCustomFeed: builder.query<FC.CustomFeed, FC.CustomFeed["id"]>({
      query: (id: FC.Group["id"]) => `flipboard/v1/builder/public/${id}`,
    }),
    createCustomFeed: builder.mutation<
      FC.CustomFeed,
      { id?: unknown; body: FC.CustomFeedConfiguration }
    >({
      query: ({ body }) => ({
        method: "POST",
        url: "flipboard/v1/builder",
        body,
      }),
    }),
    updateCustomFeed: builder.mutation<
      FC.CustomFeed,
      {
        id?: FC.CustomFeed["id"];
        body: Pick<
          FC.CustomFeedConfiguration,
          "title" | "description" | "image"
        >;
      }
    >({
      query: ({ id, body }) => ({
        method: "PUT",
        url: `flipboard/v1/builder/${id}`,
        body,
      }),
      invalidatesTags: (_, __, arg) => [
        { type: "GetGroupItems", groupRequestKey: arg.id },
      ],
    }),
    removeCustomFeedItemExclude: builder.mutation<
      void,
      { id: FC.CustomFeed["id"]; item: string }
    >({
      query: ({ id, item }) => ({
        method: "PUT",
        url: `flipboard/v1/builder/${id}/removeTopicExclude`,
        params: {
          id,
          topic: item,
        },
      }),
    }),
    addCustomFeedItemExclude: builder.mutation<
      void,
      { id: FC.CustomFeed["id"]; item: string }
    >({
      query: ({ id, item }) => ({
        method: "PUT",
        url: `flipboard/v1/builder/${id}/addTopicExclude`,
        params: {
          id,
          topic: item,
        },
      }),
      invalidatesTags: (_, __, arg) => [
        { type: "GetGroupItems", groupRequestKey: arg.id },
      ],
    }),
    addCustomFeedItem: builder.mutation<
      void,
      { id: FC.CustomFeed["id"]; item: string }
    >({
      query: ({ id, item }) => ({
        method: "PUT",
        url: `flipboard/v1/builder/${id}/addTopicInclude`,
        params: {
          id,
          topic: item,
        },
      }),
    }),
    removeCustomFeedItem: builder.mutation<
      void,
      { id: FC.CustomFeed["id"]; item: string }
    >({
      query: ({ id, item }) => ({
        method: "PUT",
        url: `flipboard/v1/builder/${id}/removeTopicInclude`,
        params: {
          id,
          topic: item,
        },
      }),
      invalidatesTags: (_, __, arg) => [
        { type: "GetGroupItems", groupRequestKey: arg.id },
      ],
    }),
    deleteCustomFeed: builder.mutation<
      FC.CustomFeedConfiguration,
      FC.CustomFeed["id"]
    >({
      query: (id) => ({
        method: "DELETE",
        url: `flipboard/v1/builder/${id}`,
      }),
    }),
    searchTopic: builder.query<Array<FC.Group>, string>({
      query: (q) => ({
        url: `flipboard/v1/search/topic`,
        params: { q },
      }),
      transformResponse: (
        data: Array<{ topic: string; displayName: string }>
      ) =>
        data.map((d) => ({
          id: idFromTopicName(d.topic),
          title: d.displayName,
          requestType: GroupRequestType.TOPIC,
          requestKey: d.topic,
          customFeedValue: d.topic,
          icon: "asterisk",
        })),
    }),
    searchMastodon: builder.query<
      Array<FC.Group>,
      {
        q: string;
        type?: "accounts" | "hashtags";
        host: string;
        isAuthenticated: boolean | null;
        applicationMode: ApplicationMode;
      }
    >({
      query: ({ q, isAuthenticated, type }) => ({
        url: "api/v2/search",
        params: {
          q,
          ...(isAuthenticated ? { resolve: true } : undefined),
          ...(type ? { type } : undefined),
        },
      }),
      transformResponse: mastodonSearchResultsToSearchResults,
    }),
    searchRss: builder.query<Array<FC.Group>, string>({
      query: (q) => {
        const isUrl = q.match(/^https?:\/\//);
        return {
          method: isUrl ? "POST" : "GET",
          url: isUrl
            ? `flipboard/v1/search/rss/find`
            : `flipboard/v1/search/rss/search`,
          params: isUrl ? { url: q, add: "true" } : { q },
        };
      },
      transformResponse: rssSearchToFeeds(RSSFeedType.RSS),
    }),
    searchYoutube: builder.query<Array<FC.Group>, string>({
      query: (q) => {
        return {
          method: "GET",
          url: `flipboard/v1/search/youtube/search`,
          params: { q },
        };
      },
      transformResponse: rssSearchToFeeds(RSSFeedType.YOUTUBE),
    }),
    getRSSFeed: builder.query<FC.Group, string>({
      query: (id: string) => `flipboard/v1/search/rss/${id}`,
      transformResponse: (response: RSSFeed) => {
        const type =
          response?.domain.indexOf("youtube") === 0
            ? RSSFeedType.YOUTUBE
            : RSSFeedType.RSS;
        return rssFeedfromRSSFeed(type)(response);
      },
    }),
    feedBotPrompt: builder.mutation<
      FC.FeedBotOutput,
      {
        prompt: string;
        input?: FC.FeedBotInput;
      }
    >({
      query: ({ prompt, input }) => ({
        url: "flipboard/v1/search/feedbot",
        method: "POST",
        body: {
          prompt,
          input,
        },
      }),
    }),
    uploadMedia: builder.mutation<{ url: string }, FormData>({
      query(body) {
        return {
          url: `flipboard/v1/media/upload`,
          method: "POST",
          body,
          formData: true,
        };
      },
    }),
    getLists: builder.query<Array<{ id: string; title: string }>, void>({
      query: () => "api/v1/lists",
    }),
    getListAccounts: builder.query<Array<FC.Author>, string>({
      query: (id) => `api/v1/lists/${id}/accounts`,
    }),
    getInBeta: builder.query<boolean, void>({
      query: () => "flipboard/v1/beta/authorized",
    }),
    getLiveImage: builder.query<string | null, FC.Group>({
      query: (group) => {
        if (group.requestType === GroupRequestType.CUSTOM_FEED) {
          return `flipboard/v1/feed/custom/liveImage/${group.requestKey}`;
        }
        return `flipboard/v1/feed/custom/liveImage?topics=${encodeURIComponent(
          group?.customFeedValue || ""
        )}`;
      },
      transformResponse: (response: { live_image: { url: string | null } }) =>
        response.live_image?.url || null,
    }),
  }),
});

export const {
  useGetPreferencesQuery,
  useLazyGetPreferencesQuery,
  useSetPreferencesMutation,
  useGetAuthorizeRedirectUrlQuery,
  useLazyRevokeTokenQuery,
  useVerifyCredentialsQuery,
  useLazyGetAuthorizeRedirectUrlQuery,
  useLazyVerifyCredentialsQuery,
  useLazyGetGroupItemsQuery,
  useGetInstanceItemQuery,
  useLazyGetInstanceItemQuery,
  useFavoriteStatusMutation,
  useUnfavoriteStatusMutation,
  useBookmarkStatusMutation,
  useUnbookmarkStatusMutation,
  useReblogStatusMutation,
  useUnreblogStatusMutation,
  useDeleteStatusMutation,
  useLazyReplyQuery,
  useStatusContextQuery,
  useLazyStatusContextQuery,
  useLazyGetInstanceAuthorQuery,
  useLazyGetCustomFeedAuthorByUrlQuery,
  useGetInstanceAuthorQuery,
  useLazyGetFollowingQuery,
  useGetPollQuery,
  useLazyGetPollQuery,
  useFollowMutation,
  useUnfollowMutation,
  useNotifyMutation,
  useUnnotifyMutation,
  useSearchInstanceQuery,
  useVoteOnPollMutation,
  useCreateCustomFeedMutation,
  useUpdateCustomFeedMutation,
  useGetCustomFeedsQuery,
  useLazyGetCustomFeedsQuery,
  useGetCustomFeedQuery,
  useLazyGetCustomFeedQuery,
  useDeleteCustomFeedMutation,
  useLazyGetPublicCustomFeedQuery,
  useLazySearchMastodonQuery,
  useLazySearchTopicQuery,
  useLazySearchRssQuery,
  useLazySearchYoutubeQuery,
  useLazySearchInstanceQuery,
  useFeedBotPromptMutation,
  useUploadMediaMutation,
  useGetListsQuery,
  useLazyGetListAccountsQuery,
  useGetInBetaQuery,
  useLazyGetInBetaQuery,
  useLazyGetRSSFeedQuery,
  useGetLiveImageQuery,
} = api;
export default api;
