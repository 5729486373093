// Taken from:
// https://gist.github.com/nandorojo/066ff2f40419b7e06054cc7282e24f8d
// TODO: We can probably simplify much of this for our sake.

// credit to https://gist.github.com/ianmartorell/32bb7df95e5eff0a5ee2b2f55095e6a6
// this file was repurosed from there
// via this issue https://gist.github.com/necolas/1c494e44e23eb7f8c5864a2fac66299a
// because RNW's pressable doesn't bubble events to parent pressables: https://github.com/necolas/react-native-web/issues/1875

import { canUseDOM } from "fbjs/lib/ExecutionEnvironment";

let isEnabled = false;

if (canUseDOM) {
  /**
   * Web browsers emulate mouse events (and hover states) after touch events.
   * This code infers when the currently-in-use modality supports hover
   * (including for multi-modality devices) and considers "hover" to be enabled
   * if a mouse movement occurs more than 1 second after the last touch event.
   * This threshold is long enough to account for longer delays between the
   * browser firing touch and mouse events on low-powered devices.
   */
  const HOVER_THRESHOLD_MS = 500;
  const SCROLL_DELAY_THRESHOLD_MS = 250;
  let lastTouchTimestamp = 0;
  let lastScrollEvent = 0;

  function enableHover() {
    const now = Date.now();
    if (
      isEnabled ||
      now - lastTouchTimestamp < HOVER_THRESHOLD_MS ||
      // safari fires a mousemove after a scroll event even if you
      // don't move your mouse, so we ignore them for a short period
      // after a scroll
      now - lastScrollEvent < SCROLL_DELAY_THRESHOLD_MS
    ) {
      return;
    }
    lastTouchTimestamp = 0;
    lastScrollEvent = 0;
    isEnabled = true;
  }

  function disableHover() {
    lastTouchTimestamp = Date.now();
    if (isEnabled) {
      isEnabled = false;
    }
  }

  function onScroll() {
    lastScrollEvent = Date.now();
    disableHover();
  }

  document.addEventListener("touchstart", disableHover, true);
  document.addEventListener("touchmove", disableHover, true);
  document.addEventListener("mousemove", enableHover, true);
  document.addEventListener("scroll", onScroll, true);
}

function isHoverEnabled() {
  return isEnabled;
}

import React, { useCallback, useRef, ReactNode } from "react";
import { useSharedValue, useAnimatedReaction } from "react-native-reanimated";
import { Platform, Pressable, StyleProp, ViewStyle } from "react-native";

export interface HoverHandlerProps {
  onHoverIn?: () => void;
  onHoverOut?: () => void;
  onPressIn?: () => void;
  onPressOut?: () => void;
  children: ReactNode;
}

export function HoverHandler({
  onHoverIn,
  onHoverOut,
  children,
  onPressIn,
  onPressOut,
}: HoverHandlerProps) {
  const showHover = useSharedValue(true);
  const hasEntered = useSharedValue(false);
  const hasMoved = useSharedValue(false);
  const isHovered = useSharedValue(false);

  const hoverIn = useRef<undefined | (() => void)>(() => onHoverIn?.());
  const hoverOut = useRef<undefined | (() => void)>(() => onHoverOut?.());
  const pressIn = useRef<undefined | (() => void)>(() => onPressIn?.());
  const pressOut = useRef<undefined | (() => void)>(() => onPressOut?.());

  hoverIn.current = onHoverIn;
  hoverOut.current = onHoverOut;
  pressIn.current = onPressIn;
  pressOut.current = onPressOut;

  useAnimatedReaction(
    () => {
      return Platform.OS === "web" && showHover.value && isHovered.value;
    },
    (hovered, previouslyHovered) => {
      if (hovered !== previouslyHovered) {
        if (hovered && hoverIn.current) {
          // no need for runOnJS, it's always web
          hoverIn.current();
        } else if (hoverOut.current) {
          hoverOut.current();
        }
      }
    },
    []
  );

  const handleMouseEnter = useCallback(() => {
    hasEntered.value = true;
    if (hasMoved.value && isHoverEnabled() && !isHovered.value) {
      isHovered.value = true;
      hoverIn.current?.();
    }
  }, [isHovered, hasEntered, hasMoved]);

  const handleMouseMove = useCallback(() => {
    hasMoved.value = true;
    if (hasEntered.value) {
      handleMouseEnter();
    }
  }, [hasEntered, hasMoved, handleMouseEnter]);

  const handleMouseLeave = useCallback(() => {
    if (isHovered.value) {
      isHovered.value = false;
      hoverOut.current?.();
    }
  }, [isHovered]);

  const handleGrant = useCallback(() => {
    showHover.value = false;
    pressIn.current?.();
  }, [showHover]);

  const handleRelease = useCallback(() => {
    showHover.value = true;
    pressOut.current?.();
  }, [showHover]);

  let webProps = {};
  if (Platform.OS === "web") {
    webProps = {
      onMouseEnter: handleMouseEnter,
      onMouseLeave: handleMouseLeave,
      onMouseMove: handleMouseMove,
      // prevent hover showing while responder
      onResponderGrant: handleGrant,
      onResponderRelease: handleRelease,
    };
  }

  return React.cloneElement(React.Children.only(children) as any, {
    ...webProps,
    // if child is Touchable
    onPressIn: handleGrant,
    onPressOut: handleRelease,
  });
}

type HoverableProps = {
  children: (isHovered: boolean) => ReactNode;
  style?: StyleProp<ViewStyle>;
};

const Hoverable: React.FC<HoverableProps> = ({ children, style }) => {
  const [isHovered, setIsHovered] = React.useState(false);

  if (Platform.OS !== "web") {
    return <>{children(false)}</>;
  }

  return (
    <HoverHandler
      onHoverIn={() => {
        setIsHovered(true);
      }}
      onHoverOut={() => {
        setIsHovered(false);
      }}
    >
      <Pressable style={style}>{children(isHovered)}</Pressable>
    </HoverHandler>
  );
};
export default Hoverable;
