import { memo, useEffect } from "react";
import {
  clearPaginationImpulse,
  selectPaginationImpulse,
} from "~/concepts/pagination";
import { PaginationImpulse } from "~/enums";
import { useAppDispatch, useAppSelector } from "~/hooks";

const CarouselKeyboardNavListener: React.FC<{
  handlePaginationImpulse: (paginationImpulse: PaginationImpulse) => void;
}> = ({ handlePaginationImpulse }) => {
  const paginationImpulse = useAppSelector(selectPaginationImpulse);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (paginationImpulse) {
      dispatch(clearPaginationImpulse());
      handlePaginationImpulse(paginationImpulse);
    }
  }, [dispatch, paginationImpulse, handlePaginationImpulse]);
  return null;
};

export default memo(CarouselKeyboardNavListener);
