import React, { memo } from "react";
import { createStyleSheet, SPACING } from "~/styles";
import { Button, makeStyles, useTheme } from "@rneui/themed";
import {
  Video,
  AVPlaybackStatus,
  AVPlaybackStatusSuccess,
  ResizeMode,
} from "expo-av";
import { isAudioAttachment } from "~/predicates";
import { Row, Image } from "~/components/elements";
import { Pressable, StyleProp } from "react-native";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";

const AVPlayer: React.FC<{
  item: FC.Item;
  mediaContent?: FC.VideoAttachment | FC.AudioAttachment;
  autoPlay?: boolean;
  style?: StyleProp<ViewStyle>;
}> = ({ item, mediaContent, autoPlay, style }) => {
  const styles = useStyles({});
  const { theme } = useTheme();
  const avPlayerRef = React.useRef<Video>(null);
  const [status, setStatus] = React.useState<AVPlaybackStatus | null>(null);

  if (!mediaContent?.url) return null;

  const { card, focused } = item;

  const isAudio = isAudioAttachment(mediaContent);
  const previewURL = mediaContent.preview_url || card?.image || null;
  let mediaSourceURL = mediaContent?.url;
  return (
    <Pressable
      style={[styles.videoWrapper, style]}
      onPress={(event) => {
        event.stopPropagation();
      }}
    >
      {isAudio && (
        <Row flex style={[styles.alignCenter, styles.justifyCenter]}>
          {previewURL && (
            <Image height={"100%"} url={previewURL} contentFit={"contain"} />
          )}
          <Button
            onPress={() => {
              (status as AVPlaybackStatusSuccess)?.isPlaying
                ? avPlayerRef.current?.pauseAsync()
                : avPlayerRef.current?.playAsync();
            }}
            icon={
              <Icon
                name={
                  (status as AVPlaybackStatusSuccess)?.isPlaying
                    ? "pause-circle-outline"
                    : "play-circle-outline"
                }
                size={SPACING.BASE4X}
                color={theme.colors.primary}
              />
            }
            type="clear"
            containerStyle={styles.audioPlayPauseButton}
          />
        </Row>
      )}
      <Video
        ref={avPlayerRef}
        videoStyle={[styles.avPlayer]}
        style={isAudio ? styles.audioPlayerStyle : styles.videoPlayerStyle}
        source={{
          uri: mediaSourceURL,
        }}
        useNativeControls
        shouldPlay={autoPlay && focused}
        resizeMode={ResizeMode.CONTAIN}
        onPlaybackStatusUpdate={setStatus}
      />
    </Pressable>
  );
};

const useStyles = makeStyles((theme) => {
  return createStyleSheet({
    videoWrapper: {
      justifyContent: "flex-end",
    },
    avPlayer: {
      height: "100%",
      width: "100%",
    },
    audioPlayerStyle: {
      minHeight: 50,
    },
    videoPlayerStyle: {
      height: "100%",
    },
    audioPlayPauseButton: {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
      backgroundColor: theme.colors.backgroundOverlay,
      justifyContent: "center",
      alignItems: "center",
      height: SPACING.BASE10X,
      width: SPACING.BASE10X,
      borderRadius: SPACING.BASE5X,
    },
  });
});

export default memo(AVPlayer);
