import React, { memo } from "react";
import { makeStyles } from "@rneui/themed";
import BasicColumn from "~/components/pages/sublayouts/BasicColumn";
import { CardSize } from "~/enums";
import conversationRollup from "~/groupPagePresentationStrategies/conversationRollup";
import socialArticleRollup from "~/groupPagePresentationStrategies/socialArticleRollup";
import topicArticleGrouping from "~/groupPagePresentationStrategies/topicArticleGrouping";
import { createStyleSheet } from "~/styles";

const FeedDebug: FC.PageStrategyResult["Component"] = (props) => {
  const styles = useStyles();
  const groupingStrategies = [
    conversationRollup,
    socialArticleRollup,
    topicArticleGrouping,
  ];
  for (const strategy of groupingStrategies) {
    const result = strategy(props);
    if (result) {
      return <result.Component {...props} />;
    }
  }
  return (
    <BasicColumn
      style={styles.debugStyle}
      items={props.items}
      width={640}
      padding={0}
      cardSize={CardSize.LARGE}
    />
  );
};

const useStyles = makeStyles((theme) =>
  createStyleSheet({
    debugStyle: {
      borderBottomWidth: 1,
      borderBottomColor: theme.colors.dividerPrimary,
    },
  })
);

export default memo(FeedDebug);
