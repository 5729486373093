import { makeStyles, useTheme } from "@rneui/themed";
import React, { memo, useEffect, useState } from "react";
import { ScrollView } from "react-native";
import api, { useGetCustomFeedsQuery } from "~/api";
import { Row, Button, Text, Column, Dropdown } from "~/components/elements";
import { ApplicationMode } from "~/enums";
import { createStyleSheet, LAYOUT, SPACING } from "~/styles";
import { selectApplicationMode } from "~/concepts/application";
import { useAppDispatch, useAppSelector } from "~/hooks";
import { useToast } from "react-native-toast-notifications";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import CustomFeedAddToNewFeed from "~/components/CustomFeedAddToNewFeed";
import FeedDisplayName from "~/components/elements/FeedDisplayName";

const DROPDOWN_WIDTH = 350;
const DROPDOWN_HEIGHT = 325;

const AddToFeedDropdown: React.FC<{
  feed: FC.Group;
}> = ({ feed }) => {
  const styles = useStyles();
  const { theme } = useTheme();
  const dispatch = useAppDispatch();

  const toast = useToast();
  const applicationMode = useAppSelector(selectApplicationMode);
  const [customFeeds, setCustomFeeds] = useState<FC.CustomFeed[]>([]);

  const customFeedsResponse = useGetCustomFeedsQuery();

  useEffect(() => {
    if (customFeedsResponse.isSuccess && customFeedsResponse.data) {
      setCustomFeeds(customFeedsResponse.data);
    }
  }, [customFeedsResponse, dispatch]);

  if (applicationMode !== ApplicationMode.BASE) return null;

  const onAddToFeed = async (item: FC.CustomFeed) => {
    if (!feed.customFeedValue) return;

    const { id, title } = item;
    try {
      const response = await dispatch(
        api.endpoints.addCustomFeedItem.initiate({
          id,
          item: feed.customFeedValue,
        })
      );
      if ("error" in response) {
        throw new Error();
      } else {
        toast.show(`Added ${feed.title} to ${title}`, {
          type: "success",
        });
        close();
      }
    } catch (e) {
      toast.show(`Error adding ${feed.title} to ${title}`, {
        type: "error",
      });
    }
  };

  const renderFeedRow = (item: FC.CustomFeed, onPress: () => void) => {
    const { id, title } = item;
    return (
      <Button
        key={`${id}_${title}`}
        icon={
          <Icon name={"tray-arrow-down"} size={SPACING.BASE3X} solid={true} />
        }
        title={title}
        type={"clear"}
        onPressIn={() => {
          onAddToFeed(item);
          onPress();
        }}
        buttonStyle={[
          {
            gap: SPACING.BASE,
            borderRadius: SPACING.BASE,
          },
          styles.justifyLeft,
        ]}
        containerStyle={styles.addToFeedButton}
      />
    );
  };

  return (
    <Row noGap style={styles.dropdownWrapper}>
      <Dropdown
        clickComponent={
          <Row
            gap={SPACING.SMALL}
            style={[
              styles.alignCenter,
              styles.justifyCenter,
              styles.dropdownButton,
            ]}
          >
            <Icon
              name={"chevron-down"}
              size={24}
              solid={true}
              color={theme.colors.controlsBlackWhite}
            />
            <Text>Add to Feed</Text>
          </Row>
        }
      >
        {(onPress) => (
          <Column flex style={styles.dropdownContents}>
            <Text style={styles.dialogTitle}>
              {"Add "}
              <FeedDisplayName
                feed={feed}
                style={styles.dialogTitle}
                emojiSize={SPACING.LARGE}
              />
              {" to..."}
            </Text>
            <Column flex style={styles.feedWrapper}>
              <ScrollView>
                <Column gap={1} style={styles.listScrollContents}>
                  {customFeeds.map((customFeed) => {
                    return renderFeedRow(customFeed, onPress);
                  })}
                </Column>
              </ScrollView>
            </Column>
            <Row style={styles.justifyRight}>
              <CustomFeedAddToNewFeed feed={feed} onSubmit={close} />
            </Row>
          </Column>
        )}
      </Dropdown>
    </Row>
  );
};

const useStyles = makeStyles((theme) =>
  createStyleSheet({
    dropdownWrapper: {
      height: LAYOUT.HEADER_COMPONENT_HEIGHT,
      alignItems: "center",
      justifyContent: "center",
      borderRadius: SPACING.BASE,
      borderColor: theme.colors.dividerPrimary,
      backgroundColor: theme.colors.backgroundTertiary,
      borderWidth: 1,
    },
    closeButton: {
      position: "absolute",
      top: SPACING.BASE,
      right: SPACING.BASE,
      zIndex: 1,
    },
    dropdownButton: {
      paddingHorizontal: SPACING.BASE,
      flexDirection: "row-reverse",
    },
    dropdownContents: {
      padding: SPACING.LARGE,
      width: DROPDOWN_WIDTH,
      maxHeight: DROPDOWN_HEIGHT,
    },
    feedWrapper: {
      borderRadius: SPACING.BASE,
      borderWidth: 1,
      borderColor: theme.colors.dividerPrimary,
      overflow: "hidden",
      height: "100%",
    },
    listScrollContents: {
      flexShrink: 1,
      backgroundColor: theme.colors.dividerPrimary,
    },
    addToFeedButton: {
      backgroundColor: theme.colors.background,
      justifyContent: "flex-start",
    },
  })
);

export default memo(AddToFeedDropdown);
