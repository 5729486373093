const codingURIComponent =
  (fn: (arg: string) => string) => (string: string) => {
    try {
      return fn(string);
    } catch (e) {
      throw new Error(`Could not ${fn.name}`);
    }
  };

export const euc = codingURIComponent(encodeURIComponent);

export const duc = codingURIComponent(decodeURIComponent);
