import React, { memo } from "react";
import { ApplicationMode } from "~/enums";
import {
  isPhoneSelector,
  selectApplicationMode,
  selectScreenDimensions,
} from "~/concepts/application";
import { useAppSelector } from "~/hooks";
import { Column } from "~/components/elements";
import { LAYOUT, SPACING, createStyleSheet } from "~/styles";
import { makeStyles } from "@rneui/themed";

import { DimensionValue } from "react-native";

const GroupHeader: React.FC<{
  HeaderComponent: React.JSXElementConstructor<{ feed: FC.Group }> | null;
  group: FC.Group;
}> = ({ group, HeaderComponent }) => {
  const applicationMode = useAppSelector(selectApplicationMode);
  const isPhone = useAppSelector(isPhoneSelector);
  const styles = useGroupHeaderStyles({ isPhone });

  const dimensions = useAppSelector(selectScreenDimensions);
  let headerHeight: DimensionValue = LAYOUT.DEFAULT_HEADER_HEIGHT;
  if (dimensions) {
    headerHeight = isPhone
      ? dimensions.height * 0.75
      : dimensions.height * 0.25;
  }

  if (applicationMode !== ApplicationMode.BASE) {
    return null;
  }

  if (!HeaderComponent) return null;
  return (
    <Column
      style={[
        styles.header,
        {
          minHeight: headerHeight,
        },
      ]}
    >
      <HeaderComponent feed={group} />
    </Column>
  );
};

export const useGroupHeaderStyles = makeStyles(
  (theme, { isPhone }: { isPhone?: boolean }) => {
    return createStyleSheet({
      header: {
        width: "100%",
        minHeight: LAYOUT.DEFAULT_HEADER_HEIGHT,
        paddingBottom: SPACING.BASE6X,
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.dividerPrimary,
      },
      headerContainer: {
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      },
      headerContents: {
        alignItems: "center",
        alignSelf: "center",
        backgroundColor: "transparent",
        width: "80%",
      },
      headerControls: {
        backgroundColor: "transparent",
        width: "100%",
        justifyContent: "flex-end",
        padding: SPACING.BASE,
      },
      title: {
        fontFamily: "RobotoCondensed_700Bold",
        fontWeight: "bold",
        fontSize: SPACING.BASE8X,
      },
      headerText: {
        textAlign: isPhone ? "left" : "center",
        lineHeight: "115%",
      },
    });
  }
);
export default memo(GroupHeader);
