interface DarkModePreference {
  $type: "daily#darkModePref";
  enabled: boolean;
}

interface ShowContentWarningPreference {
  $type: "daily#showContentWarningsPref";
  enabled: boolean;
}

interface FeedPreference {
  $type: "daily#savedFeedsPref";
  saved: Array<string>;
  excluded: Array<string>;
  order: Array<string>;
}

export type APIPreferences = Array<
  DarkModePreference | ShowContentWarningPreference | FeedPreference
>;

export const defaultPreferences: FC.Preferences = {
  darkMode: false,
  showContentWarnings: true,
  feeds: {
    saved: [],
    excluded: [],
    order: [],
  },
};

export const transformPreferencesResponse = (
  data: APIPreferences
): FC.Preferences => {
  const preferences: FC.Preferences = { ...defaultPreferences };
  const darkModePreference = data.find(
    (d): d is DarkModePreference => d.$type === "daily#darkModePref"
  );
  if (darkModePreference) {
    preferences.darkMode = darkModePreference.enabled;
  }
  const showContentWarningsPreference = data.find(
    (d): d is ShowContentWarningPreference =>
      d.$type === "daily#showContentWarningsPref"
  );
  if (showContentWarningsPreference) {
    preferences.showContentWarnings = showContentWarningsPreference.enabled;
  }
  const feedPreferences = data.find(
    (d): d is FeedPreference => d.$type === "daily#savedFeedsPref"
  );
  if (feedPreferences) {
    preferences.feeds = { saved: [], excluded: [], order: [] };
    if (feedPreferences.saved) {
      preferences.feeds.saved = feedPreferences.saved;
    }
    if (feedPreferences.excluded) {
      preferences.feeds.excluded = feedPreferences.excluded;
    }
    if (feedPreferences.order) {
      preferences.feeds.order = feedPreferences.order;
    }
  }

  return preferences;
};

export const transformPreferencesRequest = (
  preferences: FC.Preferences
): APIPreferences => [
  { $type: "daily#darkModePref", enabled: preferences.darkMode || false },
  {
    $type: "daily#showContentWarningsPref",
    enabled: preferences.showContentWarnings || false,
  },
  {
    $type: "daily#savedFeedsPref",
    ...preferences.feeds,
  },
];
