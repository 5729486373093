import React, { memo } from "react";
import { useTheme } from "@rneui/themed";
import {
  StyleProp,
  View as RNView,
  ViewProps as RNViewProps,
} from "react-native";

export type ViewProps = {
  children?: React.ReactNode;
  style?: StyleProp<ViewStyle>;
  inverse?: boolean | false;
  defaultBackgroundColor?: boolean | false;
} & RNViewProps;

const View = React.forwardRef<RNView, ViewProps>(
  ({ children, style, inverse, defaultBackgroundColor, ...restProps }, ref) => {
    const { theme } = useTheme();
    const backgroundColorStyle = {
      backgroundColor: inverse
        ? theme.colors.backgroundReverse
        : theme.colors.background,
    };
    const defaultStyle = {
      backgroundColor: "inherit",
    };
    return (
      <RNView
        ref={ref}
        style={[
          defaultBackgroundColor ? backgroundColorStyle : defaultStyle,
          style,
        ]}
        // pointerEvents="box-none" TODO: figure out better solution for this.
        {...restProps}
      >
        {children}
      </RNView>
    );
  }
);

export default memo(View);
