import React, { memo } from "react";
import shuffleSeed from "shuffle-seed";
import { makeStyles } from "@rneui/themed";
import { View } from "react-native";

import { Column, Row } from "~/components/elements";
import { createStyleSheet } from "~/styles";
import { CardSize, ItemType, ScreenSizeType } from "~/enums";
import BaseCard from "~/components/cards/BaseCard";
import ArticlePreviewBasicLink from "~/components/cards/ArticlePreviewBasicLink";

const VigetTwoUp: FC.PageStrategyResult["Component"] = ({
  items,
  contentWidth: pageWidth,
  contentHeight: pageHeight,
  seed,
  viewableContentSize,
}) => {
  const styles = useStyles();

  const baseColumnWidth = pageWidth / 5;

  const featureColumnWidth = baseColumnWidth * 3;
  const secondColumnWidth = baseColumnWidth * 2;

  const randomizedWidths = shuffleSeed.shuffle(
    [featureColumnWidth, secondColumnWidth],
    seed
  );

  const getCardSize = (width: number, otherWidth: number) => {
    if (width > otherWidth) {
      return viewableContentSize === ScreenSizeType.NARROW
        ? CardSize.LARGE
        : CardSize.XLARGE;
    } else {
      return viewableContentSize === ScreenSizeType.NARROW
        ? CardSize.MEDIUM
        : CardSize.LARGE;
    }
  };

  const layout1CardSize = getCardSize(randomizedWidths[0], randomizedWidths[1]);
  const layout2CardSize = getCardSize(randomizedWidths[1], randomizedWidths[0]);

  const layout1 = (
    <Column noGap flex>
      <BaseCard
        key={items[0].id}
        item={items[0]}
        width={randomizedWidths[0]}
        cardSize={layout1CardSize}
        ComponentMap={
          layout1CardSize === CardSize.LARGE
            ? {
                [ItemType.ARTICLE]: ArticlePreviewBasicLink,
              }
            : {}
        }
      />
    </Column>
  );
  const layout2 = (
    <Column flex>
      <BaseCard
        key={items[1].id}
        item={items[1]}
        width={randomizedWidths[1]}
        cardSize={layout2CardSize}
        ComponentMap={
          layout2CardSize === CardSize.LARGE
            ? {
                [ItemType.ARTICLE]: ArticlePreviewBasicLink,
              }
            : {}
        }
      />
    </Column>
  );

  const randomLayout = shuffleSeed.shuffle([true, false], seed)[0]
    ? [layout1, layout2]
    : [layout2, layout1];

  return (
    <Row
      flex
      style={[
        styles.page,
        styles.noGap,
        {
          width: pageWidth,
          height: pageHeight,
        },
      ]}
    >
      {randomLayout.map((component, i) => (
        <View key={`twoUp_${i}`}>{component}</View>
      ))}
    </Row>
  );
};

const useStyles = makeStyles(() =>
  createStyleSheet({
    page: {
      height: "100%",
    },
  })
);

export default memo(VigetTwoUp);
