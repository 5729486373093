import React, { memo, useEffect, useState } from "react";
import {
  setCurrentThreadItem,
  setRequestReplyItemChange,
} from "~/concepts/thread";
import { useAppDispatch } from "~/hooks";
import { setOpenLoginModal } from "~/concepts/auth";
import { useToast } from "react-native-toast-notifications";
import OverflowMenuButton from "~/components/elements/OverflowMenuButton";
import { SPACING, createStyleSheet } from "~/styles";
import { useThreadItemNavigation } from "~/hooks/useThreadItemNavigation";
import { makeStyles } from "@rneui/themed";

const Reply: React.FC<{
  item?: FC.Item;
  disabled?: boolean;
  overlay?: boolean;
  fromThread?: boolean;
  showButtonLabels?: boolean;
  showCount?: boolean;
  error?: boolean;
}> = ({
  item,
  disabled,
  fromThread = false,
  showButtonLabels = false,
  error = false,
  overlay,
  showCount = false,
}) => {
  const dispatch = useAppDispatch();
  const { navigateToThreadItem } = useThreadItemNavigation();
  const [pendingAction, setPendingAction] = useState(false);
  const [triggerDismiss, setTriggerDismiss] = useState(false);
  const toast = useToast();
  const styles = useStyles();

  useEffect(() => {
    if (pendingAction && item) {
      setTriggerDismiss(true);
      dispatch(setCurrentThreadItem(item));
      dispatch(setRequestReplyItemChange(item.id));
    }
  }, [dispatch, item, pendingAction]);

  const replyAction = () => {
    if (error) {
      toast.show("Sorry, something went wrong", {
        type: "fail",
      });
      return;
    }
    if (!item) {
      setPendingAction(true);
      return;
    }
    if (!fromThread) {
      navigateToThreadItem(item);
    } else {
      if (disabled)
        return dispatch(
          setOpenLoginModal({ open: true, unauthenticatedAction: true })
        );
    }
    if (!disabled) {
      dispatch(setRequestReplyItemChange(item.id));
    }
  };

  const numReplies = item?.counts.replies;
  let buttonTitle = showCount && numReplies ? `${numReplies}` : "";
  if (showButtonLabels) buttonTitle = "Reply";
  return (
    <OverflowMenuButton
      icon={"message-reply-outline"}
      title={buttonTitle}
      onPress={replyAction}
      iconProps={{
        size: fromThread ? 18 : 24,
      }}
      style={fromThread && { paddingVertical: SPACING.BASE }}
      pending={pendingAction}
      dismissOnClick={!!(item && !pendingAction)}
      triggerDismiss={triggerDismiss}
      overlay={overlay}
      titleStyle={!showButtonLabels && showCount && styles.replyButtonTitle}
    />
  );
};

const useStyles = makeStyles(() => {
  return createStyleSheet({
    replyButtonTitle: { width: "unset" },
  });
});
export default memo(Reply);
