import React, { memo } from "react";
import { makeStyles, useTheme } from "@rneui/themed";
import Button from "~/components/elements/Button";
import { SPACING } from "~/styles";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { useNavigate } from "~/routing";
import { setSelectedFeed } from "~/concepts/groups";
import { useAppDispatch } from "~/hooks";
import { PaginationImpulse } from "~/enums";
import { setPaginationImpulse } from "~/concepts/pagination";

const HomeButton = () => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const styles = useStyles();

  return (
    <Button
      type="clear"
      buttonStyle={styles.homeButton}
      onPress={() => {
        dispatch(setPaginationImpulse(PaginationImpulse.TOP));
        dispatch(setSelectedFeed(null));
        navigate("/");
      }}
      icon={
        <Icon
          name={"home"}
          size={SPACING.BASE3X}
          color={theme.colors.primary}
        />
      }
    />
  );
};

const useStyles = makeStyles({
  homeButton: {
    borderRadius: 0,
  },
});

export default memo(HomeButton);
