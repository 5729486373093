import React, { useState, useEffect, memo, useCallback } from "react";
import {
  ActivityIndicator,
  NativeSyntheticEvent,
  Pressable,
} from "react-native";

import { TextInputChangeEventData } from "react-native";
import { getHost, setHost } from "~/lib/persistentStore";

import { makeStyles, ListItem } from "@rneui/themed";
import { selectCredentials } from "~/concepts/auth";

import {
  View,
  Text,
  Button,
  Column,
  Image,
  Divider,
  Input,
} from "~/components/elements";

import {
  useLazyGetAuthorizeRedirectUrlQuery,
  useSearchInstanceQuery,
} from "~/api";
import { createStyleSheet, SPACING } from "~/styles";

import { isPhoneSelector } from "~/concepts/application";
import { useAppSelector } from "~/hooks";
import { A } from "@expo/html-elements";
import Author from "~/components/elements/Author";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { selectShowUnauthenticatedActionMsg } from "~/concepts/auth";
import { FlatList } from "react-native";
import mastodonInstanceToServerInstance from "~/apiTransforms/mastodonInstanceToServerInstance";
import { TextSize } from "~/enums";
import { useLocation } from "~/routing";
import { useSetAfterLoginDestination } from "~/hooks/useCookies";

const LOADING_ICON_SIZE = 60;

const UNKNOWN_HOST_MSG = "Unknown Instance";
const ERROR_MSG =
  'Oops something went wrong. Double check that your instance hostname is correct, for example: "mastodon.social';

const getCleanHost = (string: string) => {
  const removeUserPrefixSplit = string.split("@");
  const host = removeUserPrefixSplit[removeUserPrefixSplit.length - 1];
  return host
    .trim()
    .replace(/^https?:\/\//, "")
    .replace(/\/$/, "");
};

const SearchResults: React.FC<{
  search: string;
  onSelect: (s: string) => void;
}> = ({ search, onSelect }) => {
  const { data, isLoading, error } = useSearchInstanceQuery(search);

  if (error || data?.length === 0) {
    return <Text>No matches found</Text>;
  }
  if (isLoading || !data) {
    return <ActivityIndicator />;
  }
  if (data.length === 1 && search === data[0].host) {
    return null;
  }

  const renderItem = ({ item }: { item: string }) => (
    <ListItem
      onPress={() => onSelect(item)}
      containerStyle={{ padding: SPACING.SMALL }}
    >
      <ListItem.Content>
        <ListItem.Title>{item}</ListItem.Title>
      </ListItem.Content>
    </ListItem>
  );
  return (
    <FlatList
      data={data.map((d) => d.host)}
      renderItem={renderItem}
      style={searchListStyles.searchResults}
    />
  );
};
const searchListStyles = createStyleSheet({
  searchResults: { flexGrow: 0, maxHeight: "50%", width: "100%" },
});

const Login: React.FC = () => {
  const [inputHost, setInputHost] = useState("");
  const [redirecting, setRedirecting] = useState(false);
  const authenticated = useAppSelector(selectCredentials);
  const [showSearchResults, setShowSearchResults] = useState(!!inputHost);
  const location = useLocation();
  const selectInputHost = (updatedHost: string) => {
    setInputHost(updatedHost);
    setShowSearchResults(false);
  };

  const isPhone = useAppSelector(isPhoneSelector);
  const [previewLoading, setPreviewLoading] = useState(false);

  const [getAuthorizedRedirectTrigger, getAuthorizedRedirectResponse] =
    useLazyGetAuthorizeRedirectUrlQuery();

  const [instanceData, setInstanceData] = useState<FC.ServerInstance | null>(
    null
  );
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const setAfterLoginDestination = useSetAfterLoginDestination();
  const showUnauthenticatedActionMsg = useAppSelector(
    selectShowUnauthenticatedActionMsg
  );

  useEffect(() => {
    setAfterLoginDestination(location.pathname);
  }, [setAfterLoginDestination, location]);

  useEffect(() => {
    getHost().then((value) => {
      if (value) {
        setInputHost(value);
      }
    });
  }, []);

  const fetchInstancePreview = useCallback(async (instanceToFetch: string) => {
    setErrorMsg(null);
    if (instanceToFetch === "") {
      setPreviewLoading(false);
      setInstanceData(null);
      return null;
    }
    try {
      setPreviewLoading(true);
      const instanceEndPoint = `https://${getCleanHost(
        instanceToFetch
      )}/api/v2/instance`;
      const response = await fetch(instanceEndPoint);
      if (response) {
        const jsonResponse = await response.json();
        setInstanceData(mastodonInstanceToServerInstance(jsonResponse));
        setErrorMsg(null);
      }
    } catch (error) {
      setErrorMsg(UNKNOWN_HOST_MSG);
    } finally {
      setPreviewLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!authenticated) {
      setPreviewLoading(true);

      const triggerCall = setTimeout(() => {
        fetchInstancePreview(inputHost);
      }, 1000);
      return () => clearTimeout(triggerCall);
    }
    return () => null;
  }, [authenticated, inputHost, setPreviewLoading, fetchInstancePreview]);

  const handleSubmit = async () => {
    try {
      setErrorMsg(null);
      const cleanHost = getCleanHost(inputHost);
      await setHost(cleanHost);
      const response = await getAuthorizedRedirectTrigger(cleanHost);
      if (response.isError) {
        throw new Error();
      }
      // TODO onDismiss();
    } catch (_) {
      setErrorMsg(ERROR_MSG);
    }
  };

  const styles = useStyles();

  if (getAuthorizedRedirectResponse.data?.login && !redirecting) {
    setRedirecting(true);
    window.location.href = getAuthorizedRedirectResponse.data.login;
    return null;
  }
  if (redirecting || authenticated) {
    return null;
  }

  const instancePreview = () => {
    if (!instanceData) return;
    return (
      <>
        <View style={styles.instanceImageWrapper}>
          <Image
            url={instanceData.thumbnail?.url}
            blurhash={instanceData.thumbnail?.blurhash}
            blurhashBackground
            contentFit={"contain"}
            height={200}
            width={"100%"}
          />
        </View>
        <Text header size={TextSize.L}>
          {instanceData.title}
        </Text>
        <A href={instanceData.url} target={"_blank"}>
          {instanceData.url}
        </A>
        <Divider />
        <Text header size={TextSize.M}>
          Description:
        </Text>
        <Text>{instanceData.description}</Text>
        <Divider />
        <Text header size={TextSize.M}>
          Contact Account:
        </Text>
        <Author author={instanceData.contactAccount} />
      </>
    );
  };

  const renderInstancePreview = () => {
    if (instanceData?.url) {
      if (previewLoading) setPreviewLoading(false);
      return instancePreview();
    } else if (previewLoading) {
      return <ActivityIndicator size={LOADING_ICON_SIZE} />;
    }
    return null;
  };

  return (
    <>
      <Text header size={TextSize.L}>
        Log In
      </Text>
      <Divider />
      {showUnauthenticatedActionMsg && (
        <Text error> You need to login to perform the requested option.</Text>
      )}
      <View
        style={[
          styles.flex1,
          isPhone ? styles.verticalFlex : styles.horizontalFlex,
          !isPhone ? styles.justifyCenter : {},
          styles.alignCenter,
          styles.loginBody,
        ]}
      >
        <View
          style={[
            styles.loginForm,
            isPhone ? styles.loginFormPhone : styles.loginFormDesktop,
          ]}
        >
          <Input
            label="Mastodon Instance Host"
            placeholder="eg: mastodon.social"
            onChange={(e: NativeSyntheticEvent<TextInputChangeEventData>) => {
              setInstanceData(null);
              setInputHost(e.nativeEvent.text);
              setShowSearchResults(true);
            }}
            value={inputHost}
            containerStyle={styles.formInputContainer}
            labelStyle={styles.formLabel}
            inputStyle={styles.formInput}
            onSubmitEditing={handleSubmit}
            rightIcon={
              <Pressable
                onPress={() => {
                  setInputHost("");
                  setShowSearchResults(true);
                }}
              >
                <Icon name="window-close" size={16} />
              </Pressable>
            }
            errorMessage={errorMsg || undefined}
          />
          {showSearchResults && (
            <SearchResults
              onSelect={selectInputHost}
              search={getCleanHost(inputHost)}
            />
          )}
          <Button
            title={"Connect"}
            onPress={handleSubmit}
            size="sm"
            titleStyle={styles.authButtonTitleText}
            type={"solid"}
          />
        </View>
        {!isPhone && <Divider vertical />}
        <Column
          style={[
            styles.instancePreview,
            isPhone
              ? styles.instancePreviewPhone
              : styles.instancePreviewDesktop,
          ]}
        >
          {renderInstancePreview()}
        </Column>
      </View>
    </>
  );
};

const useStyles = makeStyles((theme) =>
  createStyleSheet({
    loginBody: { width: "100%", overflowY: "scroll" },
    loginForm: {
      justifyContent: "flex-start",
      alignItems: "center",
      padding: SPACING.LARGE,
      minHeight: 250,
      gap: SPACING.BASE,
    },
    loginFormPhone: { width: "100%", marginTop: 20, minHeight: 0 },
    loginFormDesktop: {
      height: "100%",
      width: 300,
    },

    instanceImageWrapper: { width: "100%", height: 200 },
    instancePreview: {
      padding: SPACING.LARGE,
    },
    instancePreviewDesktop: {
      flex: 1,
      height: "100%",
    },
    instancePreviewPhone: { width: "100%", overflowY: "scroll" },
    text: {
      color: "white",
      fontWeight: "bold",
      textTransform: "uppercase",
    },
    formInputContainer: { paddingHorizontal: 0 },
    loadingContainer: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    loadingText: {
      fontSize: 24,
      marginTop: SPACING.BASE3X,
    },
    formLabel: {
      fontSize: 16,
      color: theme.colors.primary,
    },
    formInput: {
      flex: 1,
      color: theme.colors.primary,
      backgroundColor: theme.colors.primaryReverse50,
      padding: SPACING.SMALL,
      fontSize: 16,
      minHeight: "unset",
      width: "100%",
    },
    authButtonTitleText: {
      color: "white",
      fontWeight: "bold",
      textTransform: "uppercase",
    },
  })
);

export default memo(Login);
