import React, { memo } from "react";
import { CardSize, ItemType } from "~/enums";
import FlexibleImageCard from "~/components/cards/FlexibleImageCard";
import WidePhotoCard from "~/components/cards/WidePhotoCard";
import BaseCard from "~/components/cards/BaseCard";
import { FixDimensionType } from "~/components/ItemImage";
import { LAYOUT } from "~/styles";

const CARD_CONTENT_WIDTH = 500;

const GalleryOneUpWide: FC.PageStrategyResult["Component"] = ({
  items,
  contentHeight,
  contentWidth,
}) => {
  const item = items[0];
  const { image } = item;
  if (!image?.width || !image?.height) return null;

  const aspectRatio =
    image?.aspectRatio ||
    (image?.height && image?.width && image?.width / image?.height) ||
    1;

  let ComponentPhoto;
  if (LAYOUT.MAX_FEED_LAYOUT_WIDTH < contentWidth) {
    ComponentPhoto = FlexibleImageCard;
  } else {
    if (contentWidth < image.width && contentHeight < image.height) {
      ComponentPhoto = WidePhotoCard;
    } else {
      ComponentPhoto = FlexibleImageCard;
    }
  }

  return (
    <BaseCard
      item={item}
      height={contentHeight}
      width={contentWidth}
      cardSize={CardSize.LARGE}
      ComponentMap={{
        [ItemType.PHOTO]: ComponentPhoto,
      }}
      componentProps={{
        maxNumberTruncate: 1,
        fixDimension: FixDimensionType.HEIGHT,
        height: contentHeight,
        imageAspectRatio: aspectRatio,
        cardContentStyle: {
          maxWidth: CARD_CONTENT_WIDTH,
        },
      }}
    />
  );
};

export default memo(GalleryOneUpWide);
