import React, { memo } from "react";
import { createStyleSheet, SPACING } from "~/styles";
import { makeStyles, useTheme } from "@rneui/themed";
import { Button, HoverOverlay } from "~/components/elements";
import { ButtonProps } from "~/components/elements/Button";

import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import Hoverable from "~/components/elements/Hoverable";

const ICON_SIZE = SPACING.BASE3X;

type CloseButtonProps = {
  showBackground?: boolean;
} & ButtonProps;

const CloseButton: React.FC<CloseButtonProps> = ({
  overlay = false,
  showBackground = false,
  style,
  buttonStyle,
  ...restProps
}) => {
  const styles = useStyles();
  const { theme } = useTheme();
  return (
    <Hoverable style={[style, styles.wrapper]}>
      {(isHovered) => (
        <>
          <Button
            {...restProps}
            icon={
              <Icon
                name="window-close"
                color={
                  overlay ? theme.colors.primaryReverse : theme.colors.primary
                }
                size={ICON_SIZE}
              />
            }
            type="clear"
            buttonStyle={[
              showBackground && styles.overlayIconContainer,
              buttonStyle,
            ]}
          />

          <HoverOverlay isHovered={isHovered} overlay={overlay} />
        </>
      )}
    </Hoverable>
  );
};

const useStyles = makeStyles((theme) => {
  return createStyleSheet({
    wrapper: { padding: SPACING.SMALL },
    overlayIconContainer: {
      padding: 0,
      paddingHorizontal: 0,
      borderRadius: "50%",
      backgroundColor: theme.colors.backgroundReverse50,
    },
  });
});

export default memo(CloseButton);
