import React, { useEffect } from "react";
import { createStyleSheet, SPACING } from "~/styles";
import { Row, Text } from "~/components/elements";
import { GroupRequestType } from "~/enums";
import { ActivityIndicator, StyleProp, TextStyle } from "react-native";
import { Avatar, useTheme, makeStyles } from "@rneui/themed";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { useFeedFromCustomFeedValue } from "~/hooks/useFeedFromCustomFeedValue";

const ENTRY_SYMBOL_SIZE = 12;

const CustomFeedFormOption: React.FC<{
  arg: string;
  textOnly?: boolean;
  textStyle?: StyleProp<TextStyle>;
}> = ({ arg, textOnly = false, textStyle }) => {
  const { theme } = useTheme();
  const styles = useStyles();

  const { feedFromCustomFeedValue, feed, isError, isLoading } =
    useFeedFromCustomFeedValue();

  useEffect(() => {
    if (!feed && !isLoading) {
      feedFromCustomFeedValue(arg);
    }
  }, [feedFromCustomFeedValue, feed, isLoading, arg]);

  if (isLoading) {
    return <ActivityIndicator />;
  }
  if (!feed || isError) {
    return null;
  }
  if (textOnly) {
    return <Text>{feed.title}</Text>;
  }
  return (
    <Row flex key={feed.customFeedValue} style={[styles.alignCenter]}>
      {feed.icon && (
        <Icon
          name={feed.icon}
          size={ENTRY_SYMBOL_SIZE}
          solid={true}
          color={theme.colors.primary}
          style={styles.entrySymbolContainer}
        />
      )}
      {feed.image && <Avatar rounded size={24} source={{ uri: feed.image }} />}
      <Text style={[styles.flex1, textStyle]}>
        {feed.title}
        {feed.requestType === GroupRequestType.ACCOUNT &&
          feed.description &&
          ` (${feed.description})`}
      </Text>
    </Row>
  );
};

const useStyles = makeStyles((theme) =>
  createStyleSheet({
    entrySymbolContainer: {
      backgroundColor: theme.colors.grey5,
      borderRadius: "50%",
      padding: SPACING.BASE,
    },
  })
);

export default CustomFeedFormOption;
