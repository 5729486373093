import { FetchBaseQueryMeta } from "@reduxjs/toolkit/dist/query";
import flDailyItemToItem, {
  FLDailyItem,
} from "~/apiTransforms/flDailyItemToItem";

const acceptedLinkHeaderMatches = [
  /(\/flipboard\/v1\/feed[^>]+)>; rel="next"/,
  /(\/api\/v1\/timelines\/home[^>]+)>; rel="next"/,
  /(\/api\/v1\/timelines\/tag[^>]+)>; rel="next"/,
  /(\/api\/v1\/accounts\/[0-9]+\/statuses[^>]+)>; rel="next"/,
  /(\/flipboard\/v1\/search\/posts[^>]+)>; rel="next"/,
];

const getGroupItems = (
  items: Array<FLDailyItem>,
  meta: FetchBaseQueryMeta,
  arg: { group: FC.Group }
): FC.GroupPage => {
  const linkHeader = meta.response?.headers.get("link");
  const cursorLink = acceptedLinkHeaderMatches.reduce((acc, regexp) => {
    const match = linkHeader?.match(regexp);
    if (match) {
      acc = match[1];
    }
    return acc;
  }, "");
  return {
    items: items
      .filter((item) => !item.filtered || item.filtered.length === 0)
      .map((s) => flDailyItemToItem(s, arg.group)),
    cursorLink,
  };
};

export default getGroupItems;
