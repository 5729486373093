import { Platform } from "react-native";
import React, { memo, useEffect } from "react";
import { selectModalOpen } from "~/concepts/application";
import { setPaginationImpulse } from "~/concepts/pagination";
import { PaginationImpulse } from "~/enums";
import { useAppDispatch, useAppSelector } from "~/hooks";

const keydownHandler =
  (dispatch: ReturnType<typeof useAppDispatch>) => (e: KeyboardEvent) => {
    const handle = (impulse: PaginationImpulse) => {
      e.preventDefault();
      e.stopPropagation();
      dispatch(setPaginationImpulse(impulse));
    };
    if (e.key === "ArrowUp") {
      return handle(PaginationImpulse.UP);
    }
    if (e.key === "ArrowDown") {
      return handle(PaginationImpulse.DOWN);
    }
    if (e.key === "ArrowRight") {
      return handle(PaginationImpulse.RIGHT);
    }
    if (e.key === "ArrowLeft") {
      return handle(PaginationImpulse.LEFT);
    }
    if (e.key === "PageDown") {
      return handle(PaginationImpulse.DOWN);
    }
    if (e.key === "PageUp") {
      return handle(PaginationImpulse.UP);
    }
    if (e.shiftKey && e.key === " ") {
      return handle(PaginationImpulse.UP);
    }
    if (e.key === " ") {
      return handle(PaginationImpulse.DOWN);
    }
    if (e.key === "Home") {
      return handle(PaginationImpulse.TOP);
    }
    if (e.key === "End") {
      return handle(PaginationImpulse.BOTTOM);
    }
  };

let addedListener = false;

const KeyboardNav: React.FC = () => {
  const dispatch = useAppDispatch();
  const handler = keydownHandler(dispatch);
  const modalOpen = useAppSelector(selectModalOpen);

  useEffect(() => {
    if (Platform.OS !== "web") {
      return;
    }
  }, [dispatch, modalOpen]);

  if (!addedListener && Platform.OS === "web") {
    document?.body?.addEventListener("keydown", handler);
    addedListener = true;
  }
  return null;
};

export default memo(KeyboardNav);
