import React, { memo, useState } from "react";
import { LayoutChangeEvent, StyleProp } from "react-native";
import { createStyleSheet, SPACING, LAYOUT } from "~/styles";
import { ImageContentFit } from "expo-image";

import { makeStyles, useTheme } from "@rneui/themed";
import { Row, View, Column } from "~/components/elements";

import { isPhoneSelector } from "~/concepts/application";
import { useAppSelector } from "~/hooks";
import { LinearGradient } from "expo-linear-gradient";
import { isImageAttachment } from "~/predicates";
import ItemImage from "~/components/ItemImage";
import CardAuthor from "~/components/CardAuthor";
import Content from "~/components/Content";
import { BaseCardVariantType } from "~/enums";
import { GALLERY_FONT_SIZE } from "~/components/elements/Text";
import { BackgroundColorContext } from "~/contexts/BackgroundColorContext";

const MOBILE_FOOTER_HEIGHT = SPACING.BASE10X;
const MAX_CONTENT_WIDTH = 500;

export type PhotoCardProps = {
  item: FC.Item;
  baseCardVariantType?: BaseCardVariantType;
  contentFit?: ImageContentFit;
  isCardAuthorTop?: boolean;
  maxNumberTruncate?: number;
  cardContentStyle?: StyleProp<ViewStyle>;
};

const PhotoCard: React.FC<PhotoCardProps> = ({
  item,
  baseCardVariantType,
  isCardAuthorTop,
  contentFit,
  maxNumberTruncate = 3,
  cardContentStyle,
}) => {
  const styles = useStyles({ baseCardVariantType });
  const { theme } = useTheme();
  const isPhone = useAppSelector(isPhoneSelector);

  const [cardContentWidth, setCardContentWidth] = useState(0);
  const [cardContentHeight, setCardContentHeight] = useState(0);

  const imageAttachments = item.attachments?.filter(isImageAttachment) || [];

  return (
    <BackgroundColorContext.Provider value={"transparent"}>
      <View
        style={[styles.noGap, styles.cardContentWrapper, styles.verticalFlex]}
        onLayout={(event: LayoutChangeEvent) => {
          const { width, height } = event.nativeEvent.layout;
          setCardContentWidth(width);
          setCardContentHeight(height);
        }}
      >
        {imageAttachments.length > 1 ? (
          <Row
            flex
            style={{
              width: cardContentWidth,
              backgroundColor: theme.colors.grey3,
            }}
          >
            {imageAttachments.map((_, index) => {
              const numItems = imageAttachments.length;
              const imageLayerOffset = SPACING.BASE3X;
              const imageLayerShiftIndex = imageAttachments.length - index - 1;

              const photoStackStyle: StyleProp<ViewStyle> = {
                position: "absolute",
                width: cardContentWidth - imageLayerOffset * (numItems - 1),
                height: cardContentHeight - imageLayerOffset * (numItems - 1),
                top: imageLayerOffset * imageLayerShiftIndex,
                left: imageLayerOffset * imageLayerShiftIndex,
                zIndex: numItems - index,
                shadowOffset: { width: -4, height: -4 },
                shadowColor: "#CCCCCC",
                shadowOpacity: 1,
                shadowRadius: 16,
              };
              return (
                <Column flex noGap style={photoStackStyle}>
                  <ItemImage item={item} attachmentNumber={index} />
                </Column>
              );
            })}
          </Row>
        ) : (
          <ItemImage item={item} attachmentNumber={0} contentFit={contentFit} />
        )}

        {!isPhone && (
          <LinearGradient
            // Background Gradient Overlay
            start={{ x: 0.4, y: 1 }}
            end={{ x: 0.6, y: 0 }}
            colors={["#00000080", "#88888800"]}
            locations={[0.2, 0.4]}
            style={styles.overlayBackground}
          />
        )}
        <Column
          style={[
            styles.overlayContents,
            cardContentStyle,
            isPhone
              ? styles.overlayContentsPhone
              : styles.overlayContentsDesktop,
          ]}
        >
          {isCardAuthorTop && <CardAuthor item={item} overlay={!isPhone} />}
          <Content
            item={item}
            style={{
              maxWidth: MAX_CONTENT_WIDTH,
            }}
            numberOfLines={maxNumberTruncate}
            overlay
            textSize={GALLERY_FONT_SIZE}
          />
          {!isCardAuthorTop && <CardAuthor item={item} overlay={!isPhone} />}
        </Column>
      </View>
    </BackgroundColorContext.Provider>
  );
};

const useStyles = makeStyles(
  (
    theme,
    { baseCardVariantType }: { baseCardVariantType?: BaseCardVariantType }
  ) =>
    createStyleSheet({
      cardContentWrapper: {
        flex:
          baseCardVariantType ===
            BaseCardVariantType.FEATURE_GENERIC_GREY_LAYOUT ||
          baseCardVariantType ===
            BaseCardVariantType.FEATURE_GENERIC_WHITE_LAYOUT
            ? LAYOUT.FEATURE_GENERIC_IMAGE_HEIGHT_PERCENTAGE
            : 1,
        ...(baseCardVariantType ===
          BaseCardVariantType.FEATURE_GENERIC_WHITE_LAYOUT && {
          paddingHorizontal: SPACING.BASE5X,
          paddingTop: SPACING.BASE5X,
        }),
      },
      overlayBackground: {
        position: "absolute",
        bottom: 0,
        left: 0,
        height: "100%",
        width: "100%",
        padding: SPACING.SMALL,
        paddingTop: SPACING.MEDIUM,
        zIndex: 1,
      },
      overlayContents: {
        backgroundColor: "transparent",
        padding: SPACING.LARGE,
        paddingTop: SPACING.BASE6X,
        width: "100%",
        justifyContent: "flex-end",
      },
      overlayContentsDesktop: {
        position: "absolute",
        bottom: 0,
        left: 0,
        zIndex: 10,
        height: "100%",
      },
      overlayContentsPhone: {
        height: MOBILE_FOOTER_HEIGHT,
        backgroundColor: theme.colors.background,
      },
      blurhashBackground: {
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
      },

      caption: { alignSelf: "center", fontStyle: "italic" },
      author: { alignSelf: "flex-start", fontWeight: "bold" },
    })
);
export default memo(PhotoCard);
