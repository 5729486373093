import {
  isGalleryItem,
  isGallerySupportedType,
  isPortraitImageItem,
} from "~/predicates";
import GalleryOneUp from "~/components/pages/GalleryOneUp";
const vigetGalleryOneUp: FC.PagePresentationStrategy = ({ items }) => {
  const portraitItems = items
    .filter(isGallerySupportedType)
    .filter(isGalleryItem)
    .filter(isPortraitImageItem);

  if (portraitItems.length >= 1) {
    return {
      usedItems: portraitItems.slice(0, 1),
      Component: GalleryOneUp,
      repeat: true,
    };
  }
  return null;
};

export default vigetGalleryOneUp;
