import SocialArticleRollupMaestra from "~/components/pages/SocialArticleRollupMaestra";
import { GroupingType } from "~/enums";

const socialArticleRollupMaestra: FC.PagePresentationStrategy = ({ items }) => {
  const groupable = items.find((i) => {
    const allReblogs = i.children?.every((c) => c.reblog?.source === i.source);
    return (
      i.groupingType === GroupingType.POST &&
      i.children.length > 1 &&
      !allReblogs
    );
  });
  if (groupable) {
    return {
      usedItems: [groupable],
      Component: SocialArticleRollupMaestra,
      repeat: true,
    };
  }
  return null;
};

export default socialArticleRollupMaestra;
