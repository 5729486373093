import React, { memo, useEffect, useState } from "react";

import { makeStyles } from "@rneui/themed";
import { LAYOUT, createStyleSheet } from "~/styles";
import { Column } from "~/components/elements";
import MainHeader from "~/components/MainHeader";
import { LayoutChangeEvent } from "react-native";
import { Route, Routes, useNavigate } from "~/routing";
import Feed from "~/components/Feed";
import SurfHome from "~/layouts/SurfHome";
import { CustomFeedBuilderMode } from "~/enums";
import CustomFeedBuilder from "~/components/CustomFeedBuilder";
import EditFeeds from "~/components/EditFeeds";
import BetaLoginGate from "~/components/BetaLoginGate";
import { useAppSelector } from "~/hooks";
import { selectInBeta } from "~/concepts/application";
import {
  useClearAfterLoginDestination,
  useGetAfterLoginDestination,
} from "~/hooks/useCookies";
import { selectIsAuthenticated } from "~/concepts/auth";

const Application: React.FC = () => {
  const styles = useStyles();
  const [contentHeight, setContentHeight] = useState(0);
  const [contentWidth, setContentWidth] = useState(0);
  const inBeta = useAppSelector(selectInBeta);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const getAfterLoginDestination = useGetAfterLoginDestination();
  const clearAfterLoginDestination = useClearAfterLoginDestination();
  const navigate = useNavigate();

  useEffect(() => {
    const afterLoginDestination = getAfterLoginDestination();
    if (isAuthenticated && afterLoginDestination) {
      clearAfterLoginDestination();
      navigate(afterLoginDestination);
    }
  }, [
    isAuthenticated,
    navigate,
    getAfterLoginDestination,
    clearAfterLoginDestination,
  ]);

  return (
    <Column
      flex
      noGap
      style={styles.application}
      onLayout={(event: LayoutChangeEvent) => {
        const { height, width } = event.nativeEvent.layout;
        setContentHeight(height - LAYOUT.HEADER_HEIGHT);
        setContentWidth(width);
      }}
    >
      {inBeta && <MainHeader />}
      {/* // TODO: SafeAreaView causing issues in combination with the onLayout on iPadOS.
         // I "think" we don't need it, but we should keep an eye on it if/when we
         // do more mobile work.
         
      <SafeAreaView
        style={[styles.flex1]}
      >

      */}
      <Column
        style={[
          styles.alignLeft,
          {
            height: contentHeight,
            width: contentWidth,
          },
        ]}
      >
        {!inBeta ? (
          <BetaLoginGate />
        ) : (
          <Routes>
            <Route
              path="/feed/:feedId/status/:statusId"
              element={
                <Feed
                  contentHeight={contentHeight}
                  contentWidth={contentWidth}
                />
              }
            />
            <Route
              path="/feed/:feedId"
              element={
                <Feed
                  contentHeight={contentHeight}
                  contentWidth={contentWidth}
                />
              }
            />
            <Route
              path="/custom-feed/:feedId/edit"
              element={
                <CustomFeedBuilder
                  contentHeight={contentHeight}
                  contentWidth={contentWidth}
                  mode={CustomFeedBuilderMode.EDIT}
                />
              }
            />
            <Route
              path="/custom-feed/new"
              element={
                <CustomFeedBuilder
                  mode={CustomFeedBuilderMode.CREATE}
                  contentHeight={contentHeight}
                  contentWidth={contentWidth}
                />
              }
            />
            <Route path="/edit-feeds" element={<EditFeeds />} />
            <Route
              path="*"
              element={
                <SurfHome
                  contentHeight={contentHeight}
                  contentWidth={contentWidth}
                />
              }
            />
          </Routes>
        )}
      </Column>
      {/* </SafeAreaView> */}
    </Column>
  );
};

const useStyles = makeStyles((theme) =>
  createStyleSheet({
    application: {
      backgroundColor: theme.colors.background,
    },
  })
);

export default memo(Application);
