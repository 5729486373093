import React, { memo, useEffect, useState } from "react";
import { createStyleSheet, SPACING } from "~/styles";
import { CheckBox, makeStyles, useTheme } from "@rneui/themed";
import { Column, Dialog, Text } from "~/components/elements";
import OverflowMenuButton from "~/components/elements/OverflowMenuButton";
import { isPhoneSelector, selectApplicationMode } from "~/concepts/application";
import CloseButton from "~/buttons/CloseButton";
import { useAppSelector } from "~/hooks";
import { ScrollView } from "react-native-gesture-handler";
import DisplayName from "~/components/elements/DisplayName";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { selectSelectedFeed } from "~/concepts/groups";
import { useIsGroupOwner } from "~/hooks/useIsGroupOwner";
import { ApplicationMode, GroupRequestType } from "~/enums";
import { useUpdateCustomFeed } from "~/hooks/useUpdateCustomFeed";
import { safeAcct } from "~/lib/safeAcct";
import { useGetHostCookie } from "~/hooks/useCookies";
import { useToast } from "react-native-toast-notifications";
import Loading from "~/components/Loading";

const FeedFilterCheckbox: React.FC<{
  titlePrefix?: string;
  feedFilterItem: string | React.ReactElement<{}>;
  checked: boolean;
  onPress: () => void;
}> = ({ titlePrefix, feedFilterItem, checked, onPress, ...restProps }) => {
  const styles = useStyles();
  const { theme } = useTheme();

  const TitleElement = (
    <Text style={styles.base}>
      {titlePrefix} <Text style={styles.bold}>{feedFilterItem}</Text>
    </Text>
  );

  return (
    <CheckBox
      title={TitleElement}
      containerStyle={styles.feedFilterCheckboxContainer}
      wrapperStyle={styles.feedFilterCheckboxWrapper}
      checked={checked}
      onPress={onPress}
      checkedIcon={
        <Icon
          name="checkbox-marked"
          solid
          size={16}
          color={theme.colors.primary}
        />
      }
      uncheckedIcon={
        <Icon
          name="checkbox-blank-outline"
          size={16}
          color={theme.colors.primary}
        />
      }
      {...restProps}
    />
  );
};

const FeedFilterDialog: React.FC<{
  item: FC.Item;
}> = ({ item }) => {
  const isPhone = useAppSelector(isPhoneSelector);
  const styles = useStyles();

  const toast = useToast();
  const [visible, setVisible] = useState(false);
  const [feedFilterItems, setOmitItems] = useState<Set<string>>(new Set());
  const [triggerDismiss, setTriggerDismiss] = useState(false);

  const getHostCookie = useGetHostCookie();
  const selectedFeed = useAppSelector(selectSelectedFeed);
  const isGroupOwner = useIsGroupOwner();
  const applicationMode = useAppSelector(selectApplicationMode);
  const {
    updateItemsLoading,
    removeFromCustomFeed,
    isUpdateSuccess,
    isUpdateError,
  } = useUpdateCustomFeed();

  useEffect(() => {
    if (isUpdateSuccess) {
      toast.show(`Custom Feed Updated`, {
        type: "success",
      });
      setTriggerDismiss(true);
      setVisible(false);
    }
  }, [isUpdateSuccess, toast]);

  useEffect(() => {
    if (isUpdateError) {
      toast.show(`Sorry, there was a problem updating your feed.`, {
        type: "error",
      });
    }
  }, [isUpdateError, toast]);

  if (
    (selectedFeed && !isGroupOwner(selectedFeed)) ||
    (applicationMode === ApplicationMode.BASE &&
      selectedFeed?.requestType !== GroupRequestType.CUSTOM_FEED)
  )
    return null;

  const open = () => {
    setVisible(true);
    setOmitItems(new Set());
  };

  const close = () => {
    setVisible(false);
  };

  const onSave = () => {
    const removals = Array.from(feedFilterItems);
    removeFromCustomFeed(removals);
  };

  const toggleCheckbox = (key: string) => {
    const newOmitItems = new Set(feedFilterItems);
    if (newOmitItems.has(key)) {
      newOmitItems.delete(key);
    } else {
      newOmitItems.add(key);
    }
    setOmitItems(newOmitItems);
  };
  return (
    <>
      <OverflowMenuButton
        title={"Filter Things Out"}
        icon={"cancel"}
        onPress={open}
        dismissOnClick={false}
        triggerDismiss={triggerDismiss}
      />
      <Dialog isVisible={visible} onBackdropPress={close} fullScreen={isPhone}>
        <CloseButton onPress={close} style={styles.closeButton} />
        <Column flex style={{ paddingVertical: SPACING.BASE3X }}>
          <Dialog.Title>
            Select from the following, how we can improve your feed:
          </Dialog.Title>
          <Column flex style={styles.feedWrapper}>
            {updateItemsLoading ? (
              <Loading />
            ) : (
              <ScrollView>
                <Column gap={SPACING.BASE} style={styles.flexShrink1}>
                  <FeedFilterCheckbox
                    checked={feedFilterItems.has(
                      safeAcct(item.author.acct, getHostCookie())
                    )}
                    onPress={() =>
                      toggleCheckbox(
                        safeAcct(item.author.acct, getHostCookie())
                      )
                    }
                    titlePrefix={"Exclude posts by"}
                    feedFilterItem={<DisplayName author={item.author} />}
                  />
                  {item.tags.map((tag) => {
                    return (
                      <FeedFilterCheckbox
                        checked={feedFilterItems.has(`#${tag.name}`)}
                        onPress={() => toggleCheckbox(`#${tag.name}`)}
                        titlePrefix={"Filter out posts with"}
                        feedFilterItem={`#${tag.name}`}
                      />
                    );
                  })}
                </Column>
              </ScrollView>
            )}
          </Column>
          <Dialog.Actions>
            <Dialog.Button type="outline" title={"Cancel"} onPress={close} />
            <Dialog.Button type="solid" title={"Save"} onPress={onSave} />
          </Dialog.Actions>
        </Column>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles((theme) => {
  return createStyleSheet({
    closeButton: {
      position: "absolute",
      top: SPACING.BASE,
      right: SPACING.BASE,
      zIndex: 1,
    },
    feedWrapper: {
      overflow: "hidden",
      height: "100%",
    },
    feedFilterCheckboxWrapper: {
      gap: SPACING.BASE,
    },
    feedFilterCheckboxContainer: {
      borderWidth: 1,
      borderColor: theme.colors.dividerPrimary,
      borderRadius: SPACING.BASE,
    },
  });
});

export default memo(FeedFilterDialog);
