import React, { memo } from "react";
import { View, Platform, TouchableHighlight } from "react-native";
import * as Tooltip from "universal-tooltip";

const TriggerView = Platform.OS === "web" ? View : (TouchableHighlight as any);

const Popover: React.FC<{
  PopoverComponent: React.ReactNode;
  children: React.ReactNode;
  open: boolean;
  setOpen: (arg: boolean) => void;
}> = ({ PopoverComponent, children, open, setOpen }) => {
  return (
    <Tooltip.Root
      open={open}
      onOpenChange={() => setOpen(!open)}
      usePopover={Platform.OS === "web"}
    >
      <Tooltip.Trigger>
        <TriggerView>{children}</TriggerView>
      </Tooltip.Trigger>
      <Tooltip.Portal>
        <Tooltip.Content
          sideOffset={3}
          onTap={() => {}}
          dismissDuration={500}
          presetAnimation="fadeIn"
        >
          {PopoverComponent}
        </Tooltip.Content>
      </Tooltip.Portal>
    </Tooltip.Root>
  );
};

export default memo(Popover);
