import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultPreferences } from "~/apiTransforms/preferences";
import { ApplicationMode, ScreenSizeType } from "~/enums";
import type { RootState } from "~/store";
import { ScaledSize } from "react-native";

interface ApplicationState {
  mode: ApplicationMode;
  inBeta: boolean | null;
  modalOpen: boolean;
  screenSize: ScreenSizeType;
  preferences: FC.Preferences;
  initialRoutingCompleted: boolean;
  historyPopped: boolean;
  remount: boolean;
  triggerSearch: boolean;
  screenDimensions: ScaledSize;
  hideSearch: boolean;
  searchFocused: boolean;
}

const initialState: ApplicationState = {
  mode: ApplicationMode.BASE,
  inBeta: null,
  modalOpen: false,
  screenSize: ScreenSizeType.DESKTOP,
  preferences: defaultPreferences,
  initialRoutingCompleted: false,
  historyPopped: false,
  remount: false,
  triggerSearch: false,
  screenDimensions: { width: 0, height: 0, scale: 1, fontScale: 1 },
  hideSearch: false,
  searchFocused: false,
};

export const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    setInBeta: (state, { payload }: PayloadAction<boolean>) => {
      state.inBeta = payload;
    },
    setTriggerSearch: (state, { payload }: PayloadAction<boolean>) => {
      state.triggerSearch = payload;
    },
    setHideSearch: (state, { payload }: PayloadAction<boolean>) => {
      state.hideSearch = payload;
    },
    setSearchFocused: (state, { payload }: PayloadAction<boolean>) => {
      state.searchFocused = payload;
    },
    setModalOpen: (state, { payload: modalOpen }: { payload: boolean }) => {
      state.modalOpen = modalOpen;
    },
    setScreenSize: (
      state,
      { payload: screenSize }: { payload: ScreenSizeType }
    ) => {
      state.screenSize = screenSize;
    },
    setScreenDimensions: (
      state,
      { payload: screenDimensions }: { payload: ScaledSize }
    ) => {
      state.screenDimensions = screenDimensions;
    },
    setPreferences: (
      state,
      { payload: preferences }: { payload: FC.Preferences }
    ) => {
      state.preferences = preferences;
    },
    setInitialRoutingCompleted: (state) => {
      state.initialRoutingCompleted = true;
    },
    setHistoryPopped: (state, { payload: value }: { payload: boolean }) => {
      state.historyPopped = value;
    },
    setRemount: (state, { payload: remount }: { payload: boolean }) => {
      state.remount = remount;
    },
    setApplicationMode: (
      state,
      { payload }: PayloadAction<ApplicationMode>
    ) => {
      state.mode = payload;
    },
  },
});

export const {
  setInBeta,
  setTriggerSearch,
  setHideSearch,
  setSearchFocused,
  setModalOpen,
  setScreenSize,
  setScreenDimensions,
  setPreferences,
  setRemount,
  setInitialRoutingCompleted,
  setHistoryPopped,
  setApplicationMode,
} = applicationSlice.actions;

export const selectRemount = (state: RootState) => state.application.remount;

export const selectInitialRoutingCompleted = (state: RootState) =>
  state.application.initialRoutingCompleted;

export const selectHistoryPopped = (state: RootState) =>
  state.application.historyPopped;

export const selectModalOpen = (state: RootState) =>
  state.application.modalOpen;

export const selectScreenSize = (state: RootState) =>
  state.application.screenSize;

export const selectScreenDimensions = (state: RootState) =>
  state.application.screenDimensions;

export const isPhoneSelector = (state: RootState) =>
  state.application.screenSize === ScreenSizeType.PHONE;

export const isNarrowSelector = (state: RootState) =>
  state.application.screenSize === ScreenSizeType.NARROW;

export const selectPreferences = (state: RootState) =>
  state.application.preferences;

export const selectSelectedFeedIsFavorited = (state: RootState) =>
  state.groups.selectedFeedId &&
  state.application.preferences.feeds.saved.includes(
    state.groups.selectedFeedId
  );

export const selectFeedIsFavorited = (feed?: FC.Group) => (state: RootState) =>
  feed && state.application.preferences.feeds.saved.includes(feed.id);

export const selectApplicationMode = (state: RootState) =>
  state.application.mode;

export const selectTriggerSearch = (state: RootState) =>
  state.application.triggerSearch;

export const selectInBeta = (state: RootState) => state.application.inBeta;

export const selectSearchFocused = (state: RootState) =>
  state.application.searchFocused;

export const selectHideSearch = (state: RootState) =>
  state.application.hideSearch;

export default applicationSlice.reducer;
