import React, { memo, useEffect } from "react";
import { CheckBox, makeStyles } from "@rneui/themed";
import { SPACING, createStyleSheet } from "~/styles";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { selectIsAuthenticated } from "~/concepts/auth";
import { useAppSelector } from "~/hooks";
import { useSetFeatureFlagsCookie } from "~/hooks/useCookies";
import { FeatureFlags } from "~/enums";
import { useGetFeatureFlagsCookie } from "~/hooks/useCookies";
import { Column, Divider, Text } from "~/components/elements";
import { isEqual } from "lodash-es";

const featureFlagTitles: Record<string, string> = {
  [FeatureFlags.HOME_FILTER]: "Home Page Filter Tabs",
  [FeatureFlags.MAX_PAGE_WIDTH]: "Restrict Maximum Layout Width",
  [FeatureFlags.DEBUG_DEV_PLAYGROUND]: "Dev Playground Layout only",
  [FeatureFlags.DEBUG_FILTER_LAYOUTS]: "Enable Layout Filtering",
  [FeatureFlags.DEBUG_LAYERS]: "Show Debug Layers",
  [FeatureFlags.DEBUG_FEED]: "Debug Feed",
};
const FeatureFlagControls: React.FC<{}> = ({}) => {
  const styles = useStyles();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  const setFeatureFlagsCookie = useSetFeatureFlagsCookie();
  const getFeatureFlagsCookie = useGetFeatureFlagsCookie();

  const [featureFlags, setFeatureFlags] = React.useState<
    Record<string, boolean>
  >(getFeatureFlagsCookie());

  useEffect(() => {
    const newFlags: Record<string, boolean> = { ...featureFlags } || {};
    // Initialize any flags missing from the cookie to false
    Object.values(FeatureFlags).map((flag) => {
      if (newFlags[flag] === undefined) {
        newFlags[flag] = false;
      }
    });

    if (!isEqual(newFlags, featureFlags)) {
      setFeatureFlagsCookie(newFlags);
      setFeatureFlags(newFlags);
    }
  }, [featureFlags, setFeatureFlagsCookie]);

  const toggleFlag = (flag: FeatureFlags) => {
    const newFlags: Record<string, boolean> = { ...featureFlags };
    newFlags[flag] = !newFlags[flag];
    setFeatureFlags(newFlags);
    setFeatureFlagsCookie(newFlags);
  };

  return isAuthenticated ? (
    <Column noGap style={styles.listWrapper}>
      <Text
        style={[{ padding: SPACING.BASE }, styles.formLabel, styles.devText]}
      >
        Feature Flags
      </Text>
      <Divider />
      <Column flexShrink noGap style={styles.featureFlagList}>
        {Object.entries(featureFlags).map(([key, value]) => (
          <CheckBox
            key={key}
            title={featureFlagTitles[key]}
            containerStyle={{
              padding: SPACING.NONE,
              marginVertical: SPACING.SMALL,
            }}
            checked={value as boolean}
            onPress={() => toggleFlag(key as FeatureFlags)}
            checkedIcon={<Icon name="checkbox-marked" solid size={16} />}
            uncheckedIcon={<Icon name="checkbox-blank-outline" size={16} />}
          />
        ))}
      </Column>
    </Column>
  ) : null;
};

const useStyles = makeStyles((theme) =>
  createStyleSheet({
    listWrapper: {
      backgroundColor: theme.colors.background,
      borderColor: theme.colors.debugPrimary,
      borderWidth: 1,
      borderRadius: SPACING.BASE,
      overflow: "hidden",
    },
    featureFlagList: {
      width: 400,
      maxHeight: 150,
    },
    devText: { color: theme.colors.debugPrimary },
  })
);

export default memo(FeatureFlagControls);
