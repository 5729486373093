import React, { memo, useCallback, useEffect, useState } from "react";
import { ActivityIndicator } from "react-native";
import { ApplicationMode, CustomFeedBuilderMode } from "~/enums";
import CreateCustomFeed from "~/components/CreateCustomFeed";
import EditCustomFeed from "~/components/EditCustomFeed";
import Group from "~/components/Group";
import { useParams } from "~/routing";
import {
  useLazyGetCustomFeedQuery,
  useLazyGetPublicCustomFeedQuery,
} from "~/api";
import { setApplicationMode, setTriggerSearch } from "~/concepts/application";
import {
  selectCustomFeedBuilderFormValue,
  selectCustomFeedBuilderCustomFeedPreviewFeed,
  selectCustomFeedBuilderPreviewFeed,
  setCustomFeedBuilderFormValue,
  setCustomFeedBuilderCustomFeedPreviewFeed,
  setCustomFeedBuilderPreviewFeed,
} from "~/concepts/customFeedBuilder";
import { useAppDispatch, useAppSelector } from "~/hooks";
import { addGroups } from "~/concepts/groups";
import {
  customFeedGroupFromCustomFeed,
  customFeedPreview,
} from "~/utils/groups";
import isEqual from "lodash-es/isEqual";
import omit from "lodash-es/omit";
import { Column, Row, Pressable } from "~/components/elements";
import { Skeleton, makeStyles } from "@rneui/themed";
import { SPACING, createStyleSheet } from "~/styles";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { useFeedNavigation } from "~/hooks/useFeedNavigation";
import { isPhoneSelector } from "~/concepts/application";

const CUSTOM_FEED_BUILDER_WIDTH = 440;
const CUSTOM_FEED_BUILDER_MOBILE_WIDTH = "100%";

const PlaceholderBlock = () => {
  const styles = useStyles();
  return (
    <Skeleton
      style={styles.placeholderBlock}
      animation="none"
      height={"100%"}
    />
  );
};

const CustomFeedBuilder: React.FC<{
  mode: CustomFeedBuilderMode;
  contentWidth: number;
  contentHeight: number;
}> = ({ mode, contentWidth, contentHeight }) => {
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const params = useParams();
  const previewFeed = useAppSelector(selectCustomFeedBuilderPreviewFeed);
  const customFeedPreviewFeed = useAppSelector(
    selectCustomFeedBuilderCustomFeedPreviewFeed
  );
  const customFeedBuilderFormValue = useAppSelector(
    selectCustomFeedBuilderFormValue
  );
  const [customFeed, setCustomFeed] = useState<FC.CustomFeed | null>(null);
  const [getCustomFeedTrigger, getCustomFeedResponse] =
    useLazyGetCustomFeedQuery();
  const [getPublicCustomFeedTrigger, getPublicCustomFeedResponse] =
    useLazyGetPublicCustomFeedQuery();
  const { navigateToGroup } = useFeedNavigation();
  const isPhone = useAppSelector(isPhoneSelector);

  useEffect(() => {
    if (
      getPublicCustomFeedResponse.isSuccess &&
      getPublicCustomFeedResponse.data
    ) {
      const group = customFeedGroupFromCustomFeed(
        getPublicCustomFeedResponse.data
      );
      dispatch(addGroups([group]));
      navigateToGroup(group);
    }
  }, [
    dispatch,
    navigateToGroup,
    getPublicCustomFeedResponse.isSuccess,
    getPublicCustomFeedResponse.data,
  ]);

  useEffect(() => {
    // followed a custom feed edit link for non-owned custom feed?
    if (
      getCustomFeedResponse.isError &&
      (getCustomFeedResponse.error as FetchBaseQueryError)?.status === 404 &&
      !getPublicCustomFeedResponse.isLoading
    ) {
      getPublicCustomFeedTrigger(getCustomFeedResponse.originalArgs || "");
    }
  }, [
    getCustomFeedResponse,
    getPublicCustomFeedTrigger,
    getPublicCustomFeedResponse.isLoading,
  ]);

  const setFormValue = useCallback(
    (formValue: FC.CustomFeedConfiguration) => {
      dispatch(setCustomFeedBuilderFormValue(formValue));
    },
    [dispatch]
  );

  useEffect(() => {
    if (mode === CustomFeedBuilderMode.EDIT && params.feedId) {
      getCustomFeedTrigger(params.feedId);
    }
  }, [mode, getCustomFeedTrigger, params.feedId]);

  useEffect(() => {
    if (customFeedBuilderFormValue.includes.length > 0) {
      const newCustomFeedPreviewFeed = customFeedPreview(
        `${customFeedBuilderFormValue.title || "New Custom Feed"}`,
        customFeedBuilderFormValue.includes,
        customFeedBuilderFormValue.excludes
      );
      if (!isEqual(customFeedPreviewFeed, newCustomFeedPreviewFeed)) {
        dispatch(
          setCustomFeedBuilderCustomFeedPreviewFeed(newCustomFeedPreviewFeed)
        );
      }
    }
  }, [dispatch, customFeedBuilderFormValue, customFeedPreviewFeed]);

  useEffect(() => {
    dispatch(setApplicationMode(ApplicationMode.CUSTOM_FEED_BUILDER));
    dispatch(
      setCustomFeedBuilderFormValue({
        title: "",
        description: "",
        includes: [],
        excludes: [],
        image: null,
      })
    );
    dispatch(setCustomFeedBuilderPreviewFeed(null));
    dispatch(setCustomFeedBuilderCustomFeedPreviewFeed(null));

    return () => {
      dispatch(setApplicationMode(ApplicationMode.BASE));
      dispatch(setCustomFeedBuilderPreviewFeed(null));
      dispatch(setCustomFeedBuilderCustomFeedPreviewFeed(null));
    };
  }, [dispatch]);

  useEffect(() => {
    if (getCustomFeedResponse.data && getCustomFeedResponse.isSuccess) {
      const { data } = getCustomFeedResponse;
      setCustomFeed(data);
      setFormValue({
        title: data.title,
        description: data.description,
        includes: data.includes || [],
        excludes: data.excludes || [],
        image: data.image || null,
      });
    }
  }, [setFormValue, getCustomFeedResponse]);

  return (
    <Row flex noGap style={styles.feedBuilder}>
      <Row
        style={{
          width: isPhone
            ? CUSTOM_FEED_BUILDER_MOBILE_WIDTH
            : CUSTOM_FEED_BUILDER_WIDTH,
        }}
      >
        {mode === CustomFeedBuilderMode.CREATE && (
          <CreateCustomFeed
            formValue={customFeedBuilderFormValue}
            setFormValue={setFormValue}
          />
        )}
        {mode === CustomFeedBuilderMode.EDIT && customFeed && (
          <EditCustomFeed
            formValue={customFeedBuilderFormValue}
            setFormValue={setFormValue}
            customFeed={customFeed}
          />
        )}
        {mode === CustomFeedBuilderMode.EDIT && !customFeed && (
          <ActivityIndicator />
        )}
      </Row>
      {!isPhone && (
        <Column flex style={styles.previewWrapper}>
          {previewFeed && (
            <Group
              group={previewFeed}
              contentHeight={contentHeight}
              contentWidth={
                contentWidth - CUSTOM_FEED_BUILDER_WIDTH - SPACING.BASE6X * 2
              }
            />
          )}
          {!previewFeed && customFeedPreviewFeed && (
            <Group
              // omit title so that we don't re-request every time title is
              // updated, title is not needed by group
              group={omit(customFeedPreviewFeed, "title") as FC.Group}
              contentHeight={contentHeight}
              contentWidth={
                contentWidth - CUSTOM_FEED_BUILDER_WIDTH - SPACING.BASE6X * 2
              }
            />
          )}
          {!previewFeed &&
            !customFeedPreviewFeed &&
            !(customFeedBuilderFormValue.includes.length > 0) && (
              <Pressable
                flex
                onPress={() => dispatch(setTriggerSearch(true))}
                style={{ borderRadius: SPACING.BASE, padding: SPACING.LARGE }}
              >
                <Column flex>
                  <Row flex>
                    <Column flex={3}>
                      <Row flex={3}>
                        <PlaceholderBlock />
                      </Row>
                      <Row flex={2}>
                        <Column flex>
                          <PlaceholderBlock />
                        </Column>
                        <Column flex>
                          <PlaceholderBlock />
                        </Column>
                      </Row>
                    </Column>
                    <Column flex={2}>
                      <PlaceholderBlock />
                    </Column>
                  </Row>
                </Column>
              </Pressable>
            )}
        </Column>
      )}
    </Row>
  );
};

const useStyles = makeStyles((theme) =>
  createStyleSheet({
    feedBuilder: {
      backgroundColor: theme.colors.backgroundSecondary,
    },
    previewWrapper: {
      borderLeftWidth: 1,
      borderLeftColor: theme.colors.dividerPrimary,
      paddingLeft: SPACING.LARGE,
    },
    placeholderBlock: {
      backgroundColor: theme.colors.backgroundTertiary,
    },
  })
);

export default memo(CustomFeedBuilder);
