import React, { memo, useEffect, useState } from "react";
import { View as ReactNativeView } from "react-native";
import { Avatar, AvatarProps, makeStyles } from "@rneui/themed";
import { createStyleSheet, LAYOUT, SPACING } from "~/styles";

import { Row, Column, Button, Text, Divider } from "~/components/elements";
import DisplayName from "~/components/elements/DisplayName";
import { A } from "@expo/html-elements";
import CustomFeedAddToForm from "~/components/CustomFeedAddToForm";
import { useLazyGetRSSFeedQuery } from "~/api";
import Loading from "~/components/Loading";
import { useNavigate } from "~/routing";
import CustomFeedExcludeFeedButton from "~/components/CustomFeedExcludeFeedButton";
import { GroupRequestType } from "~/enums";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { useAppSelector } from "~/hooks";
import { selectSelectedFeed } from "~/concepts/groups";

type RSSCardProps = {
  item: FC.Item;
  avatarSize?: AvatarProps["size"];
  close?: () => void;
};

const RSSCard = React.forwardRef<ReactNativeView, RSSCardProps>(
  ({ item, avatarSize = SPACING.BASE8X, close }, ref) => {
    const [getRSSFeedTrigger, getRSSFeedResponse] = useLazyGetRSSFeedQuery();
    const [feed, setFeed] = useState<FC.Group | null>(null);
    const selectedFeed = useAppSelector(selectSelectedFeed);
    const navigate = useNavigate();
    const styles = useStyles();
    useEffect(() => {
      if (!feed && item.rssFeedId && getRSSFeedResponse.isUninitialized) {
        getRSSFeedTrigger(item.rssFeedId);
      }
    }, [feed, item, getRSSFeedResponse, getRSSFeedTrigger]);

    useEffect(() => {
      if (getRSSFeedResponse.data && getRSSFeedResponse.isSuccess && !feed) {
        setFeed(getRSSFeedResponse.data);
      }
    }, [feed, setFeed, getRSSFeedResponse.data, getRSSFeedResponse.isSuccess]);

    if (!feed) {
      return <Loading />;
    }

    const { avatar, url } = item.author;

    const Description = (
      <A href={url} target="_blank">
        <Text numberOfLines={3} style={styles.description}>
          {feed.description}
        </Text>
      </A>
    );

    const AuthorDisplayNameAndAvatar = (
      <Column noGap>
        <Row noGap flex style={styles.authorDisplayNameContainer}>
          <A href={url} target="_blank">
            {avatar && (
              <Avatar rounded size={avatarSize} source={{ uri: avatar }} />
            )}
          </A>
        </Row>
        <A href={url} target="_blank">
          <Column noGap>
            <Text style={styles.authorDisplayNameTitle}>
              <DisplayName author={item.author} />
            </Text>
          </Column>
        </A>
      </Column>
    );

    const ViewButtons = (
      <Row style={[styles.justifyCenter, styles.viewButtons]}>
        <Button
          icon={<Icon name={feed.icon} size={SPACING.BASE3X} solid={true} />}
          buttonStyle={[{ gap: SPACING.BASE }, styles.justifyLeft]}
          type="outline"
          title={
            feed.requestType === GroupRequestType.YOUTUBE
              ? "View Youtube Channel"
              : "View RSS Feed"
          }
          onPress={() => {
            navigate(`/feed/${feed.id}`);
          }}
        />
      </Row>
    );

    const CustomFeedButtons = (
      <Row style={[styles.alignCenter]}>
        <CustomFeedAddToForm feed={feed} onAdd={close} showTitle type="clear" />
        <CustomFeedExcludeFeedButton
          feed={feed}
          onExclude={close}
          type="clear"
        />
      </Row>
    );

    return (
      <Column ref={ref} style={styles.authorCardContainer}>
        {AuthorDisplayNameAndAvatar}
        {Description}
        {selectedFeed?.id !== feed.id && ViewButtons}
        <Divider />
        {CustomFeedButtons}
      </Column>
    );
  }
);
const useStyles = makeStyles((theme) => {
  return createStyleSheet({
    authorCardContainer: {
      maxHeight: LAYOUT.AUTHOR_BUSINESS_CARD_HEIGHT,
      width: LAYOUT.AUTHOR_BUSINESS_CARD_WIDTH,
      paddingHorizontal: SPACING.LARGE,
      paddingTop: SPACING.LARGE,
      paddingBottom: SPACING.BASE3X,
      display: "flex",
      justifyContent: "space-between",
    },
    authorDisplayNameContainer: {
      justifyContent: "space-between",
      alignItems: "flex-start",
      paddingBottom: SPACING.MEDIUM,
    },
    authorDisplayNameTitle: {
      fontSize: 16,
    },
    description: {
      color: theme.colors.primary70,
      marginTop: SPACING.LARGE,
      marginBottom: SPACING.LARGE,
      width: LAYOUT.AUTHOR_BUSINESS_CARD_WIDTH - SPACING.LARGE * 2,
    },
  });
});
export default memo(RSSCard);
