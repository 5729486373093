import { memo, useEffect, useState } from "react";
import { Dimensions } from "react-native";
import { useAppDispatch } from "~/hooks";

import { setScreenSize, setScreenDimensions } from "~/concepts/application";
import { ScreenSizeType } from "~/enums";

const PHONE_MAX_WIDTH = 600;
const NARROW_MAX_WIDTH = 1000;
const DESKTOP_MAX_WIDTH = 1500;
const Responsive = () => {
  const [dimensions, setDimensions] = useState(Dimensions.get("window"));

  useEffect(() => {
    const subscription = Dimensions.addEventListener("change", ({ window }) => {
      setDimensions(window);
    });
    return () => subscription?.remove();
  });

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (dimensions.width < PHONE_MAX_WIDTH) {
      dispatch(setScreenSize(ScreenSizeType.PHONE));
    } else if (dimensions.width < NARROW_MAX_WIDTH) {
      dispatch(setScreenSize(ScreenSizeType.NARROW));
    } else if (dimensions.width < DESKTOP_MAX_WIDTH) {
      dispatch(setScreenSize(ScreenSizeType.DESKTOP));
    } else {
      dispatch(setScreenSize(ScreenSizeType.DESKTOP_WIDE));
    }

    dispatch(setScreenDimensions(dimensions));
  }, [dispatch, dimensions]);

  return null;
};

export default memo(Responsive);
