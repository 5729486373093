import React, { memo } from "react";
import { View as RNView } from "react-native";
import { createStyleSheet, LAYOUT } from "~/styles";

import View, { ViewProps } from "~/components/elements/View";

interface ColumnProps extends ViewProps {
  flex?: boolean | number;
  noGap?: boolean;
  overlay?: boolean;
  gap?: number;
  flexShrink?: number | boolean;
}

const Column = React.forwardRef<RNView, ColumnProps>(
  (
    {
      flex,
      flexShrink,
      style,
      noGap,
      gap = LAYOUT.DEFAULT_GAP_SIZE,
      overlay,
      ...rest
    },
    ref
  ) => {
    const flexStyle =
      typeof flex === "number" ? { flex } : flex && styles.flex1;

    const flexShrinkStyle =
      typeof flexShrink === "number"
        ? { flexShrink }
        : flexShrink && styles.flexShrink1;

    // TODO: can eliminiate the noGap param, but will follow-on in a separate PR
    // to not clutter this one.
    const gapStyle = !noGap ? { gap } : undefined;
    return (
      <View
        ref={ref}
        style={[
          flexStyle,
          flexShrinkStyle,
          styles.columnView,
          gapStyle,
          overlay && styles.noBackground,
          style,
        ]}
        {...rest}
      />
    );
  }
);

const styles = createStyleSheet({
  columnView: {
    flexDirection: "column",
  },
});

export default memo(Column);
