import React from "react";
import { createStyleSheet } from "~/styles";
import { CardSize } from "~/enums";
import { makeStyles } from "@rneui/themed";
import { Column, Row } from "~/components/elements";
import BasicMediaCard from "~/components/cards/BasicMediaCard";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { ImageBackground, LayoutChangeEvent } from "react-native";
import View from "~/components/elements/View";

export type BasicYoutubeCardProps = {
  item: FC.Item;
  overlay?: boolean;
  cardSize?: CardSize;
  isHorizontal?: boolean;
  maxNumberTruncate?: number;
};
const ALT_TEXT = "YouTube Preview image";
const BasicYoutubeCard: React.FC<BasicYoutubeCardProps> = (props) => {
  const { item, isHorizontal } = props;
  const image = item?.image;
  const { description } = (item as FC.CardItem).card;
  const [imageDimensions, setImageDimensions] = React.useState({
    height: 0,
    width: 0,
  });
  const aspectRatio =
    image?.aspectRatio ||
    (image?.width && image?.height && image?.width / image?.height) ||
    1;

  const styles = useStyles({
    isHorizontal,
    aspectRatio,
  });
  if (!image) return null;
  const PLAY_ICON_SIZE = 42;

  return (
    <Row flex>
      <BasicMediaCard
        {...props}
        MediaComponent={
          <Row
            flex
            style={[styles.imageContainer]}
            onLayout={(event: LayoutChangeEvent) => {
              const { width, height } = event.nativeEvent.layout;
              if (height * aspectRatio > width) {
                setImageDimensions({ width, height: width / aspectRatio });
              } else {
                setImageDimensions({ width: height * aspectRatio, height });
              }
            }}
          >
            <Column
              flexShrink
              style={[
                isHorizontal && styles.justifyCenter,
                { ...imageDimensions },
              ]}
            >
              <ImageBackground
                source={{
                  uri:
                    item.image?.url ||
                    "https://s3.amazonaws.com/s.flipboard.com/assets/webu/images/meta/flipboard-512.png",
                }}
                style={styles.previewImage}
                accessibilityLabel={description || ALT_TEXT}
              >
                <View style={styles.playIconBackground} />
                <Icon
                  style={styles.playIcon}
                  name="youtube"
                  size={PLAY_ICON_SIZE}
                  color="#ffffff"
                />
              </ImageBackground>
            </Column>
          </Row>
        }
      />
    </Row>
  );
};

const useStyles = makeStyles(
  (
    theme,
    {
      isHorizontal,
      aspectRatio,
    }: { isHorizontal?: boolean; aspectRatio?: number }
  ) =>
    createStyleSheet({
      imageContainer: isHorizontal
        ? {
            height: "100%",
            aspectRatio: aspectRatio,
          }
        : {
            width: "100%",
            minHeight: "30%",
          },

      previewImage: {
        flexBasis: "100%",
        aspectRatio,
      },
      playIcon: {
        display: "flex",
        lineHeight: 84,
        justifyContent: "center",
        alignItems: "center",
        opacity: 0.75,
        height: "100%",
        width: "100%",
      },
      playIconBackground: {
        backgroundColor: theme.colors.primary,
        height: 50,
        width: 50,
        position: "absolute",
        top: "50%",
        left: "50%",
        borderRadius: "50%",
        marginTop: -25,
        marginLeft: -25,
        opacity: 0.4,
      },
    })
);
export default BasicYoutubeCard;
