import React, { memo, useEffect, useRef, useState } from "react";
import { StyleProp, View } from "react-native";
import { SPACING, createStyleSheet } from "~/styles";
import { CollapsibleAnimatedView, Text } from "~/components/elements";
import { useGetFeatureFlagsCookie } from "~/hooks/useCookies";
import { FeatureFlags } from "~/enums";

// Something large enough to not be noticed, small enough to guard against infinite loops.
const DEFAULT_MAX_NUMBER_OF_LINES = 100;

type AutoNumberOfLinesWrapperProps = {
  children: React.ReactNode;
  maxNumberOfLines?: number;
  style?: StyleProp<ViewStyle>;
  flex?: number | boolean;
  flexShrink?: boolean;
};

const AutoNumberOfLinesWrapper: React.FC<AutoNumberOfLinesWrapperProps> = ({
  children = "",
  maxNumberOfLines = DEFAULT_MAX_NUMBER_OF_LINES,
  style,
  flex = false,
  flexShrink = false,
}) => {
  const viewRef = useRef<View>(null);

  const [numberOfLines, setNumberOfLines] = useState<number>(1);
  const [complete, setComplete] = useState(false);
  const [delayedCalculationStart, setDelayedCalculationStart] = useState(false);
  const [estimatedSingleLineHeight, setEstimatedSingleLineHeight] = useState<
    number | null
  >(null);
  const [debugCurrentScrollHeight, setDebugCurrentScrollHeight] = useState(0);
  const featureFlags = useGetFeatureFlagsCookie()();

  /**
   * This is a hack to get around the fact that the calculation is being bypassed in firefox.
   * The delay seems to be enough to get around the issue.
   */
  useEffect(() => {
    setTimeout(() => {
      setDelayedCalculationStart(true);
    }, 20);
  }, []);

  const flexStyle = typeof flex === "number" ? { flex } : flex && styles.flex1;
  const flexShrinkStyle =
    typeof flexShrink === "number"
      ? { flexShrink }
      : flexShrink && styles.flexShrink1;

  useEffect(() => {
    if (delayedCalculationStart) {
      setTimeout(() => {
        if (!complete && viewRef.current) {
          const castRef = viewRef.current as unknown as HTMLElement;
          const currentScrollHeight = castRef.scrollHeight;
          setDebugCurrentScrollHeight(currentScrollHeight);
          let childMargin = 0;
          let childPadding = 0;

          if (estimatedSingleLineHeight === null && currentScrollHeight > 0) {
            childMargin = Number(
              (
                castRef.firstChild as unknown as HTMLElement
              )?.style.margin.replace("px", "")
            );
            childPadding = Number(
              (
                castRef.firstChild as unknown as HTMLElement
              )?.style.padding.replace("px", "")
            );

            // The first pass, calculate the height occupied by a single line of text
            // (height of the element less any padding/margins of the child)
            setEstimatedSingleLineHeight(
              currentScrollHeight - childMargin * 2 - childPadding * 2
            );
            setNumberOfLines(100);
          } else if (estimatedSingleLineHeight) {
            const estimatedNumberOfLines = Math.floor(
              currentScrollHeight / estimatedSingleLineHeight
            );
            const newNum = Math.min(estimatedNumberOfLines, maxNumberOfLines);

            setNumberOfLines(newNum || 1);
            setComplete(true);
          }
        }
      }, 100);
    }
  }, [
    complete,
    estimatedSingleLineHeight,
    numberOfLines,
    maxNumberOfLines,
    delayedCalculationStart,
  ]);

  /**
   * If we've calcualated the height of a single line of text, grow the wrapper
   * to the max allowed height ie: flex=1.
   * Using this should give us a reasonable max number of lines. Revert to the caller's
   * flex setting once we're complete.
   */
  const findingMax = !complete && !!estimatedSingleLineHeight;
  return (
    <View
      style={[
        styles.wrapper,
        findingMax ? styles.flex1 : flexStyle,
        !findingMax && flexShrinkStyle,
        style,
      ]}
      ref={viewRef}
    >
      <CollapsibleAnimatedView
        show={complete}
        nullOnHidden={false}
        fade
        style={[
          styles.flex1,
          // Since this is an estimate only, adding a small padding when the content is at "max" height
          // accounts for some off by 1 rounding errors, as well as content that makes some lines slightly taller
          // ie: ensure the container is big enough for n lines
          // + 1/2 a line. This prevents the text from being truncated too aggressively (ie: 1 line too short).
          findingMax && { padding: estimatedSingleLineHeight / 2 },
        ]}
      >
        {React.cloneElement(React.Children.only(children) as any, {
          numberOfLines,
        })}
      </CollapsibleAnimatedView>
      {featureFlags[FeatureFlags.DEBUG_LAYERS] && (
        <View style={[styles.debugInfo, styles.debugLayer]}>
          <Text style={styles.debugText}>
            Ln: {estimatedSingleLineHeight}px | Total:{" "}
            {debugCurrentScrollHeight}px | Num Lines: {numberOfLines}
          </Text>
        </View>
      )}
    </View>
  );
};

const styles = createStyleSheet({
  wrapper: {
    overflow: "hidden",
    width: "100%",
  },
  debugLayer: { top: SPACING.BASE, right: SPACING.BASE },
});

export default memo(AutoNumberOfLinesWrapper);
