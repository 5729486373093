import { authorFeedFromAuthor, hashtagGroupFromHashtag } from "~/utils/groups";
import { ApplicationMode } from "~/enums";

export const mastodonSearchResultsToSearchResults = (
  data: {
    accounts: Array<FC.Author>;
    hashtags: Array<{ name: string }>;
  },
  _queryMeta: unknown,
  { host, applicationMode }: { host: string; applicationMode: ApplicationMode }
) => {
  const results: Array<FC.Group> = [];
  data.accounts.forEach((author) => {
    results.push(authorFeedFromAuthor(author, host, applicationMode));
  });
  data.hashtags.forEach(({ name }) => {
    results.push(hashtagGroupFromHashtag(name, applicationMode));
  });
  return results;
};
