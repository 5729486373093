import React, { memo, useCallback, useState } from "react";
import { createStyleSheet, SPACING } from "~/styles";
import { makeStyles } from "@rneui/themed";
import Popover, { PopoverPlacement } from "react-native-popover-view";
import { Pressable } from "react-native";

type DropdownProps = {
  clickComponent: React.ReactNode;
  children: (onPress: () => void) => React.ReactNode;
};
const Dropdown: React.FC<DropdownProps> = ({ clickComponent, children }) => {
  const styles = useStyles();

  const [visible, setVisible] = useState(false);
  const close = useCallback(() => setVisible(false), [setVisible]);
  const open = useCallback(() => setVisible(true), [setVisible]);

  return (
    <Popover
      isVisible={visible}
      onRequestClose={close}
      from={<Pressable onPress={open}>{clickComponent}</Pressable>}
      popoverStyle={styles.dropdown}
      arrowSize={{ width: 0, height: 0 }}
      backgroundStyle={styles.noBackground}
      placement={[PopoverPlacement.BOTTOM, PopoverPlacement.TOP]}
      verticalOffset={SPACING.BASE}
    >
      {children(close)}
    </Popover>
  );
};

const useStyles = makeStyles((theme) => {
  return createStyleSheet({
    dropdown: {
      justifyContent: "flex-start",
      alignItems: "baseline",
      padding: 0,
      overflow: "hidden",
      backgroundColor: theme.colors.background,
      borderWidth: 1,
      borderColor: theme.colors.dividerTertiary,
      shadowOffset: { width: 10, height: 10 },
      shadowColor: "#000000",
      shadowOpacity: 0.188,
      shadowRadius: 16,
      borderBottomLeftRadius: SPACING.BASE,
      borderBottomRightRadius: SPACING.BASE,
    },
  });
});

export default memo(Dropdown);
