import React, { memo, useEffect, useRef } from "react";
import { View as ReactNativeView } from "react-native";
import { createStyleSheet, SPACING } from "~/styles";
import { makeStyles } from "@rneui/themed";

import { Row, View, Column } from "~/components/elements";

import { isImageAttachment } from "~/predicates";
import ItemImage from "~/components/ItemImage";
import CardAuthor from "~/components/CardAuthor";
import Content from "~/components/Content";
import AutoNumberOfLinesWrapper from "~/components/elements/AutoNumberOfLinesWrapper";
import { LayoutChangeEvent, Pressable } from "react-native";
import {
  imageAttachmentIsLandscape,
  imageAttachmentIsPortrait,
  imageAttachmentIsSquare,
} from "~/utils/image";
import { useThreadItemNavigation } from "~/hooks/useThreadItemNavigation";
import { safariRepaintHack } from "~/utils/browser";
import { GALLERY_FONT_SIZE } from "~/components/elements/Text";
import Reboosts from "~/components/cardComponents/Reboosts";

const CARD_BODY_WIDTH_BOTTOM = 500;
const CARD_BODY_WIDTH_SIDE = 350;
type VigetMultiImagePhotoCardProps = {
  item: FC.Item;
};

const VigetMultiImagePhotoCard: React.FC<VigetMultiImagePhotoCardProps> = ({
  item,
}) => {
  const ref = useRef<ReactNativeView>(null);
  const styles = useStyles();
  const { navigateToThreadItem } = useThreadItemNavigation();

  const imageAttachments = item.attachments?.filter(isImageAttachment) || [];
  const numImages = imageAttachments.length;

  const [imageContainerHeights, setImageContainerHeights] = React.useState<
    Record<string, number>
  >({});

  useEffect(() => {
    const interval = setInterval(() => {
      if (ref.current) {
        safariRepaintHack(ref.current as unknown as HTMLElement);
      }
    }, 250);
    return () => clearInterval(interval);
  }, [ref]);

  if (numImages <= 0) return null;

  const squareImages = imageAttachments.filter(imageAttachmentIsSquare);
  const portraitImages = imageAttachments.filter(imageAttachmentIsPortrait);
  const landscapeImages = imageAttachments.filter(imageAttachmentIsLandscape);

  const renderCardBody = (contentIsHorizontal: boolean) => (
    <Column
      style={[
        styles.bodyContents,
        contentIsHorizontal && styles.bodyContentsSide,
      ]}
    >
      <CardAuthor item={item} />
      <AutoNumberOfLinesWrapper>
        <Content item={item} textSize={GALLERY_FONT_SIZE} />
      </AutoNumberOfLinesWrapper>

      <Reboosts item={item} />
    </Column>
  );

  const renderImage = (attachment: FC.SupportedMediaAttachment) => {
    const calculatedAspectRatio =
      attachment?.meta?.original?.aspect ||
      (attachment?.meta?.original?.width &&
        attachment?.meta?.original?.height &&
        attachment?.meta?.original?.width / attachment?.meta?.original?.height);

    const wrapperStyle = styles.fullHeight;

    return (
      <View
        key={attachment.id}
        style={[styles.flexShrink1, wrapperStyle]}
        onLayout={(e: LayoutChangeEvent) => {
          const { height } = e.nativeEvent.layout;
          setImageContainerHeights((prev) => ({
            ...prev,
            [attachment.id]: height,
          }));
        }}
      >
        <Pressable
          onPress={() => {
            navigateToThreadItem(item, imageAttachments.indexOf(attachment));
          }}
        >
          <ItemImage
            item={item}
            attachment={attachment}
            disableBlurBackground
            contentFit={"cover"}
            flex={false}
            containerStyle={{
              height: imageContainerHeights[attachment.id],
              aspectRatio: calculatedAspectRatio,
            }}
          />
        </Pressable>
      </View>
    );
  };

  const renderImages = () => {
    let contentIsHorizontal = false;
    let ImagesComponent = null;
    if (portraitImages.length === 2 && numImages === 2) {
      contentIsHorizontal = true;
      /** Two portrait images, side-by-side */
      ImagesComponent = (
        <Row flexShrink style={styles.fullHeight}>
          {imageAttachments.map((a) => renderImage(a))}
        </Row>
      );
    } else if (
      portraitImages.length === 0 &&
      (squareImages.length === 4 || landscapeImages.length === 4)
    ) {
      contentIsHorizontal = true;
      /** 2x2 grid of four images (no portrait images) */
      ImagesComponent = (
        <Row flexShrink style={[styles.maxFullWidth, styles.fullHeight]}>
          <Column flex>
            {renderImage(imageAttachments[0])}
            {renderImage(imageAttachments[1])}
          </Column>
          <Column flex>
            {renderImage(imageAttachments[2])}
            {renderImage(imageAttachments[3])}
          </Column>
        </Row>
      );
    } else if (
      (landscapeImages.length <= 1 &&
        portraitImages.length + squareImages.length >= 1) ||
      numImages === 2
    ) {
      /** Simple row of Images */
      ImagesComponent = (
        <Row
          flexShrink
          style={[
            styles.fullWidth,
            styles.maxFullHeight,
            styles.reducedMinHeight,
          ]}
        >
          {imageAttachments.map((a) => renderImage(a))}
        </Row>
      );
    } else if (
      landscapeImages.length === 2 &&
      portraitImages.length === 1 &&
      numImages >= 3
    ) {
      contentIsHorizontal = true;
      /**
       *  2 landscape images vertical,
       * portrait image on right
       **/
      ImagesComponent = (
        <Row flexShrink style={[styles.maxFullWidth, styles.fullHeight]}>
          <Column flexShrink style={[styles.fullHeight]}>
            {renderImage(landscapeImages[0])}
            {renderImage(landscapeImages[1])}
          </Column>
          <Row flexShrink style={[styles.fullHeight]}>
            {renderImage(portraitImages[0])}
          </Row>
        </Row>
      );
    } else if (
      landscapeImages.length <= 3 &&
      landscapeImages.length >= 2 &&
      numImages >= 3
    ) {
      /** landscape image on left (if 3 landscape images),
       *  2 landscape images vertical in the centre,
       *  non landscape image on right
       **/
      const otherImages = [...squareImages, ...portraitImages];
      ImagesComponent = (
        <Row flexShrink style={[styles.maxFullWidth, styles.fullHeight]}>
          {landscapeImages.length === 3 && renderImage(landscapeImages[0])}
          <Column flexShrink style={[styles.fullHeight]}>
            {renderImage(landscapeImages[landscapeImages.length === 3 ? 1 : 0])}
            {renderImage(landscapeImages[landscapeImages.length === 3 ? 2 : 1])}
          </Column>
          {otherImages.length > 0 && (
            <Row flexShrink style={[styles.fullHeight]}>
              {otherImages.map((ia) => renderImage(ia))}
            </Row>
          )}
        </Row>
      );
    } else if (
      landscapeImages.length <= 1 &&
      squareImages.length === 2 &&
      numImages >= 3
    ) {
      /**
       * landscape image on left,
       * 2 square images stack in center,
       * portrait images on right
       */
      return (
        <Row flexShrink style={[styles.maxFullWidth, styles.fullHeight]}>
          {landscapeImages.length === 1 && renderImage(landscapeImages[0])}
          <Column flexShrink style={[styles.fullHeight]}>
            {renderImage(squareImages[0])}
            {renderImage(squareImages[1])}
          </Column>
          {portraitImages.length > 0 && (
            <Row flexShrink style={[styles.fullHeight]}>
              {portraitImages.map((ia) => renderImage(ia))}
            </Row>
          )}
        </Row>
      );
    }

    if (ImagesComponent) {
      const ContentWrapper = contentIsHorizontal ? Row : Column;
      return (
        <ContentWrapper flexShrink style={styles.contentWrapper}>
          {ImagesComponent}
          {renderCardBody(contentIsHorizontal)}
        </ContentWrapper>
      );
    }

    /**
     * TODO: Remove.
     * Leaving this in for now to help with debugging any cases that were missed.
     * This ideally should never happen.
     */
    console.log(
      "%c ~LOG~ Multi Image Album Card: no layout found.",
      "color: white; background: #984448; font-size: 12px;",
      { squareImages, portraitImages, landscapeImages, numImages }
    );
  };

  return (
    <Column ref={ref} flex style={styles.multiImageCard}>
      <Column flexShrink gap={SPACING.BASE5X} style={[styles.cardInnerWrapper]}>
        {renderImages()}
      </Column>
    </Column>
  );
};

const useStyles = makeStyles(() =>
  createStyleSheet({
    multiImageCard: {
      padding: SPACING.BASE8X,
    },
    cardInnerWrapper: {
      flex: 1,
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      maxWidth: "100%",
    },
    contentWrapper: {
      maxWidth: "95%",
      height: "100%",
      justifyContent: "center",
    },
    imageStrip: {},
    bodyContents: {
      flexShrink: 1,
      maxWidth: CARD_BODY_WIDTH_BOTTOM,
      height: 100,
    },
    bodyContentsSide: {
      width: CARD_BODY_WIDTH_SIDE,
      height: "100%",
      justifyContent: "flex-end",
    },

    fullWidth: { width: "100%" },
    maxFullWidth: { maxWidth: "100%" },
    fullHeight: { height: "100%" },
    reducedHeight: { height: "70%" },
    reducedMinHeight: { minHeight: "70%" },
    maxFullHeight: { maxHeight: "100%" },
  })
);
export default memo(VigetMultiImagePhotoCard);
