import React, { useRef } from "react";
import { makeStyles } from "@rneui/themed";
import { Video, ResizeMode } from "expo-av";
import { LinearGradient } from "expo-linear-gradient";
import { Column, View } from "~/components/elements";

import { createStyleSheet, SPACING } from "~/styles";
import { BaseCardVariantType, TextSize } from "~/enums";
import { isPhoneSelector } from "~/concepts/application";
import { useAppSelector } from "~/hooks";
import { isGifvAttachment } from "~/predicates";
import CardAuthor from "~/components/CardAuthor";

import Hoverable from "~/components/elements/Hoverable";
import Content from "~/components/Content";
import ItemFocusDrawerButton from "~/components/cardComponents/ItemFocusDrawerButton";

type GifvCardProps = {
  item: FC.Item;
  isCondensed?: boolean;
  baseCardVariantType?: BaseCardVariantType;
};
const GifvCard: React.FC<GifvCardProps> = ({
  item,
  baseCardVariantType,
  isCondensed,
}) => {
  const styles = useStyles({ baseCardVariantType });
  const isPhone = useAppSelector(isPhoneSelector);
  const { attachments } = item;
  const gifvContent = attachments?.find(isGifvAttachment);
  const videoRef = useRef<Video>(null);
  if (!gifvContent) {
    return null;
  }

  const { url } = gifvContent;

  const gifvPlayer = (
    <Video
      ref={videoRef}
      style={styles.flex1}
      videoStyle={styles.gifvVideo}
      source={{
        uri: url,
      }}
      isLooping
      resizeMode={ResizeMode.COVER}
    />
  );

  return (
    <Column flex noGap>
      <Hoverable style={styles.flex1}>
        {(isHovered) => {
          if (isHovered) {
            videoRef.current?.playAsync();
          } else {
            videoRef.current
              ?.replayAsync()
              .then(() => {
                return videoRef.current?.pauseAsync();
              })
              .catch((error) => {
                console.error(
                  "Error while trying to replay/pause the video:",
                  error
                );
              });
          }
          return (
            <View style={styles.gifvContainer}>
              {gifvPlayer}
              <Column
                style={[
                  styles.cardBodyWrapper,
                  isPhone
                    ? styles.cardBodyWrapperPhone
                    : styles.cardBodyWrapperDesktop,
                ]}
              >
                {/* Duplicating the ItemFocusDrawerButton from BaseCard as it appears
                that the expo-av player absorbs clicks. */}
                <ItemFocusDrawerButton flex item={item}>
                  {!isPhone && (
                    <LinearGradient
                      // Background Gradient Overlay
                      colors={["#88888800", "#00000060"]}
                      locations={[0.6, 0.9]}
                      style={styles.overlayBackground}
                    />
                  )}

                  <Column style={styles.contentWrapper}>
                    {!isCondensed && (
                      <Content
                        item={item}
                        overlay
                        textSize={TextSize.M}
                        numberOfLines={3}
                      />
                    )}
                    <CardAuthor item={item} overlay={!isPhone} />
                  </Column>
                </ItemFocusDrawerButton>
              </Column>
            </View>
          );
        }}
      </Hoverable>
    </Column>
  );
};

const useStyles = makeStyles((theme) =>
  createStyleSheet({
    gifvContainer: {
      flex: 1,
    },
    gifvVideo: {
      height: "100%",
      width: "100%",
    },
    cardBodyWrapper: {
      bottom: 0,
      zIndex: 2,
      width: "100%",
      flexDirection: "column",
      justifyContent: "flex-end",
      gap: SPACING.BASE,
    },
    cardBodyWrapperDesktop: {
      position: "absolute",
      height: "100%",
    },
    cardBodyWrapperPhone: {
      height: SPACING.BASE12X,
      backgroundColor: theme.colors.background,
    },
    cardBodyDesktop: {
      position: "absolute",
      height: "100%",
    },
    cardBodyPhone: {
      backgroundColor: theme.colors.background,
    },
    contentWrapper: {
      height: "100%",
      padding: SPACING.BASE3X,
      justifyContent: "flex-end",
    },
    contentStyle: {
      justifyContent: "flex-end",
    },
    overlayBackground: {
      position: "absolute",
      bottom: 0,
      left: 0,
      height: "100%",
      width: "100%",
      padding: SPACING.SMALL,
      paddingTop: SPACING.MEDIUM,
    },
  })
);

export default GifvCard;
