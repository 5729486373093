import React, { memo } from "react";
import { LAYOUT, SPACING, createStyleSheet } from "~/styles";
import { Row } from "~/components/elements";
import BaseCard from "~/components/cards/BaseCard";
import BasicImageCard from "~/components/cards/BasicImageCard";
import PhotoCard from "~/components/cards/PhotoCard";
import { isPhotoItem, isGraphicItem } from "~/predicates";
import { StyleProp } from "react-native";
import { ItemType, BaseCardVariantType, CardSize } from "~/enums";
import { Divider, useTheme } from "@rneui/themed";

type BasicRowProps = {
  flex?: boolean | number;
  items: Array<FC.Item>;
  width: number;
  padding?: number;
  ComponentMap?: FC.CardComponentMap;
  style?: StyleProp<ViewStyle>;
  baseCardVariantType?: BaseCardVariantType;
  cardSize?: CardSize;
  overlay?: boolean;
  noGap?: boolean;
  cellDivider?: boolean;
  componentProps?: Record<string, any>;
};
const BasicRow: React.FC<BasicRowProps> = ({
  flex,
  items,
  width,
  padding = SPACING.LARGE,
  ComponentMap,
  style,
  baseCardVariantType,
  overlay,
  cardSize = CardSize.MEDIUM,
  cellDivider = false,
  noGap,
  componentProps,
}) => {
  const numberOfItems = items.length;
  const baseCardWidth =
    (width - padding * 2 - LAYOUT.DEFAULT_GAP_SIZE * (items.length - 1)) /
    numberOfItems;
  const flexStyle = typeof flex === "number" ? { flex } : flex && styles.flex1;

  const { theme } = useTheme();

  return (
    <Row
      noGap={noGap}
      style={[flexStyle, { ...(!cellDivider && { padding: padding }) }, style]}
    >
      {items.map((item, i) => {
        const defaultComponentMap: FC.CardComponentMap = {
          [ItemType.PHOTO]: PhotoCard,
        };
        if (isPhotoItem(item)) {
          defaultComponentMap[ItemType.PHOTO] = PhotoCard;
        }
        if (isGraphicItem(item)) {
          defaultComponentMap[ItemType.PHOTO] = BasicImageCard;
        }

        return (
          <React.Fragment key={i}>
            <Row
              flex
              style={[styles.cardWrapper, cellDivider && { padding: padding }]}
            >
              <BaseCard
                item={item}
                width={baseCardWidth}
                baseCardVariantType={baseCardVariantType}
                ComponentMap={ComponentMap || defaultComponentMap}
                cardSize={cardSize}
                overlay={overlay}
                componentProps={componentProps}
              />
            </Row>
            {cellDivider && i < items.length - 1 && (
              <Divider
                color={theme.colors.dividerOverlay}
                orientation={"vertical"}
              />
            )}
          </React.Fragment>
        );
      })}
    </Row>
  );
};
const styles = createStyleSheet({ cardWrapper: { height: "100%" } });

export default memo(BasicRow);
