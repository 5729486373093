import { memo, useEffect } from "react";
import { v5 as uuidv5 } from "uuid";
import { UUID_V5_NAMESPACE_URL } from "~/config";
import { useSetCUIDCookie } from "~/hooks/useCookies";
import { getCUID, setCUID } from "~/lib/persistentStore";

// We store this in local storage as well as in the cookie so it
// survives cookie clears.  This helps us id a single client.

const ClientUniqueId = () => {
  const setCUIDCookie = useSetCUIDCookie();
  useEffect(() => {
    getCUID().then((value) => {
      let cuid: string;
      if (value) {
        cuid = value;
      } else {
        const namespaceUUID = uuidv5(UUID_V5_NAMESPACE_URL, uuidv5.URL);
        cuid = uuidv5(
          // lazy but should be unique
          `${Math.random()}${new Date().getTime()}`,
          namespaceUUID
        );
      }
      setCUID(cuid);
      setCUIDCookie(cuid);
    });
  });

  return null;
};

export default memo(ClientUniqueId);
