import React, { memo } from "react";
import { makeStyles } from "@rneui/themed";
import PhotoCard, { PhotoCardProps } from "~/components/cards/PhotoCard";
import { createStyleSheet, SPACING } from "~/styles";

const CARD_CONTENT_WIDTH = 500;

const WidePhotoCard: React.FC<PhotoCardProps> = (props) => {
  const styles = useStyles();

  return (
    <PhotoCard
      {...props}
      contentFit="cover"
      isCardAuthorTop
      maxNumberTruncate={1}
      cardContentStyle={styles.cardContent}
    />
  );
};

const useStyles = makeStyles((_) =>
  createStyleSheet({
    cardContent: {
      maxWidth: CARD_CONTENT_WIDTH,
      padding: SPACING.BASE5X,
    },
  })
);

export default memo(WidePhotoCard);
