import React, { memo } from "react";
import BasicRow from "~/components/pages/sublayouts/BasicRow";
import { ItemType, CardSize, TextSize } from "~/enums";
import { makeStyles } from "@rneui/themed";
import { SPACING, createStyleSheet } from "~/styles";
import ArticlePreviewBasicLink from "~/components/cards/ArticlePreviewBasicLink";
import { Column, Text } from "~/components/elements";
import { useNavigate } from "~/routing";
import { A } from "@expo/html-elements/build/elements/Anchor";

const TopicArticleGrouping: FC.PageStrategyResult["Component"] = ({
  items,
  contentWidth,
}) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const itemGrouping = items[0];
  if (!itemGrouping) {
    return null;
  }

  return (
    <Column flex noGap>
      {itemGrouping.content && (
        <A
          style={styles.title}
          onPress={() => navigate(itemGrouping.flusUrl || "")}
        >
          <Text header size={TextSize.XXL}>
            {itemGrouping.content}
          </Text>
        </A>
      )}
      <BasicRow
        flex
        items={itemGrouping.children.slice(0, 3)}
        width={contentWidth}
        cardSize={CardSize.LARGE}
        ComponentMap={{
          [ItemType.ARTICLE]: ArticlePreviewBasicLink,
        }}
        style={[styles.justifyLeft]}
        noGap
      />
    </Column>
  );
};

const useStyles = makeStyles((_) =>
  createStyleSheet({
    title: {
      textAlign: "center",
      margin: SPACING.BASE3X,
    },
  })
);

export default memo(TopicArticleGrouping);
