import React, { useEffect, memo } from "react";

import { makeStyles } from "@rneui/themed";

import { Row } from "~/components/elements";
import Modal from "react-native-modal";

import { createStyleSheet, SPACING, LAYOUT } from "~/styles";

import { isPhoneSelector, setModalOpen } from "~/concepts/application";
import { useAppDispatch, useAppSelector } from "~/hooks";
import Login from "~/components/Login";
import CloseButton from "~/buttons/CloseButton";
import useEscClose from "~/hooks/useEscClose";

interface LoginDrawerProps {
  visible: boolean;
  onDismiss(): void;
}

const LoginDrawer: React.FC<LoginDrawerProps> = ({ visible, onDismiss }) => {
  const dispatch = useAppDispatch();
  useEscClose(onDismiss);

  useEffect(() => {
    dispatch(setModalOpen(visible));
  }, [visible, dispatch]);

  const isPhone = useAppSelector(isPhoneSelector);

  const styles = useStyles();

  return (
    <Modal
      isVisible={visible}
      onBackdropPress={onDismiss}
      style={[styles.modalWrapper, isPhone && styles.modalWrapperPhone]}
      accessible
      animationIn="slideInLeft"
      animationOut="slideOutLeft"
    >
      <Row style={styles.closeButtonWrapper}>
        <CloseButton onPress={onDismiss} />
      </Row>
      <Login />
    </Modal>
  );
};

const useStyles = makeStyles((theme) =>
  createStyleSheet({
    modalWrapper: {
      backgroundColor: theme.colors.background,
      width: "60%",
      height: "80%",
      alignSelf: "flex-start",
      margin: 0,
      padding: SPACING.LARGE,
      paddingTop: SPACING.BASE6X,
      maxWidth: LAYOUT.LOGIN_WIDTH,
      maxHeight: "80%",
      justifyContent: "center",
      justifySelf: "center",
      alignItems: "center",
      gap: SPACING.LARGE,
    },
    modalWrapperPhone: {
      width: "100%",
      height: "100%",
      maxWidth: "100%",
      maxHeight: "100%",
    },
    closeButtonWrapper: {
      position: "absolute",
      top: SPACING.SMALL,
      right: SPACING.SMALL,
      zIndex: 1,
    },
  })
);

export default memo(LoginDrawer);
