import React, { memo } from "react";

import { Image, Row, View } from "~/components/elements";
import { ImageContentFit, ImageContentPosition, ImageStyle } from "expo-image";
import { LayoutChangeEvent, StyleProp } from "react-native";
import { makeStyles } from "@rneui/base";
import { createStyleSheet } from "~/styles";
import { getClosestImageAspectRatio } from "~/utils/image";

const ALT_TEXT = "Preview Image";
const TINY_IMAGE_THRESHOLD = 400;

export const enum FixDimensionType {
  HEIGHT = "height",
  WIDTH = "width",
  NONE = "none",
}
type ItemImageProps = {
  item: FC.Item;
  attachment?: FC.SupportedMediaAttachment;
  attachmentNumber?: number;
  containerStyle?: StyleProp<ViewStyle>;
  style?: ImageStyle;
  disableBlurBackground?: boolean;
  contentFit?: ImageContentFit;
  contentPosition?: ImageContentPosition;
  lighten?: boolean;
  darken?: boolean;
  flex?: boolean | number;
  fixDimension?: FixDimensionType;
};

const ItemImage: React.FC<ItemImageProps> = ({
  item,
  attachment,
  attachmentNumber = 0,
  disableBlurBackground = false,
  contentFit,
  contentPosition = "center",
  style = {},
  containerStyle,
  lighten,
  darken,
  flex = false,
  fixDimension,
}) => {
  const styles = useStyles();
  const [originalContainerDimensions, setOriginalContainerDimensions] =
    React.useState<{
      width?: number;
      height?: number;
    } | null>(null);

  const attachmentImage = attachment || item?.attachments?.[attachmentNumber];
  const {
    hints,
    width: originalWidth,
    height: originalHeight,
  } = item.image || {};
  const blurhash = attachmentImage?.blurhash || item.image?.blurhash;
  const aspectRatio =
    attachmentImage?.meta?.original?.aspect ||
    (originalWidth && originalHeight && originalWidth / originalHeight);

  const url = attachmentImage?.url || item?.image?.url;
  if (!url) return null;

  const isPhoto = hints?.photo;
  let calculatedContentFit = contentFit || (isPhoto ? "cover" : "contain");
  if (
    originalHeight &&
    originalWidth &&
    originalHeight <= TINY_IMAGE_THRESHOLD &&
    originalWidth <= TINY_IMAGE_THRESHOLD &&
    originalContainerDimensions?.width &&
    originalContainerDimensions?.width > originalWidth &&
    originalContainerDimensions?.height &&
    originalContainerDimensions?.height > originalHeight
  ) {
    calculatedContentFit = "none";
  }
  let focus;
  if (attachmentImage) {
    const { x: focusX, y: focusY } = attachmentImage?.meta?.focus || {
      x: null,
      y: null,
    };

    /**
     * Mastodon API returns focusX and focusY as a number between -1 and 1
     * Convert to a percentage between 0 and 100, measured from the bottom left.
     */
    if (focusX && focusY) {
      const x = Math.round(((focusX + 1) / 2) * 100);
      const y = Math.round(((focusY + 1) / 2) * 100);
      focus = { x, y };
    }
  }

  const containerDimensionStyle: StyleProp<ViewStyle> = {
    width: "100%",
    height: "100%",
  };
  const imageDimensionStyle: StyleProp<ViewStyle> = {
    width: "100%",
    height: "100%",
  };

  if (aspectRatio) {
    if (contentFit === "cover") {
      /** If our contentFit is 'cover', and our fixDimension is not 'none',
       * we can expand the container to the closest supported aspect ratio.
       */
      const closetSupportedAspectRatio =
        getClosestImageAspectRatio(aspectRatio);
      if (
        fixDimension === FixDimensionType.WIDTH &&
        originalContainerDimensions?.width
      ) {
        containerDimensionStyle.height =
          originalContainerDimensions?.width / closetSupportedAspectRatio;
      } else if (
        fixDimension === FixDimensionType.HEIGHT &&
        originalContainerDimensions?.height
      ) {
        containerDimensionStyle.width =
          originalContainerDimensions?.height * closetSupportedAspectRatio;
      }
      imageDimensionStyle.flex = 1;
    } else if (
      contentFit === "contain" &&
      disableBlurBackground &&
      originalContainerDimensions?.width &&
      originalContainerDimensions?.height
    ) {
      /** If our contentFit is 'contain' and there is no blurHashBackground,
       * we can shrink the wrapping container to the aspect ratio of the image.
       */
      const containerAspectRatio =
        originalContainerDimensions?.width /
        originalContainerDimensions?.height;
      if (containerAspectRatio > aspectRatio) {
        /** Bind height to container, shrink image wrapper to fit. */
        imageDimensionStyle.height = originalContainerDimensions?.height;
        imageDimensionStyle.width =
          (originalContainerDimensions?.width * aspectRatio) /
          containerAspectRatio;
      } else {
        /** Bind width to container, shrink image wrapper  to fit. */
        imageDimensionStyle.width = originalContainerDimensions?.width;
        imageDimensionStyle.height =
          (originalContainerDimensions?.height * containerAspectRatio) /
          aspectRatio;
      }
    }
  }

  return (
    <Row
      flex={flex}
      flexShrink={!flex}
      style={[styles.itemImageWrapper, containerDimensionStyle, containerStyle]}
      onLayout={(event: LayoutChangeEvent) => {
        const { width, height } = event.nativeEvent.layout;
        if (
          originalContainerDimensions &&
          originalContainerDimensions.width !== width &&
          originalContainerDimensions.height !== height
        )
          setOriginalContainerDimensions({
            height,
            width,
          });
      }}
      pointerEvents={disableBlurBackground ? "none" : "auto"} // We want to override the pointerEvents if there is no blurHash background.
    >
      <View style={[styles.flexShrink1, imageDimensionStyle]}>
        <Image
          flex
          height={"100%"}
          width={"100%"}
          url={url}
          blurhash={disableBlurBackground ? undefined : blurhash}
          blurhashBackground={!disableBlurBackground}
          contentFit={calculatedContentFit}
          accessibilityLabel={attachmentImage?.description || ALT_TEXT}
          focusPercent={focus}
          contentPosition={contentPosition}
          style={[style]}
          lighten={lighten}
          darken={darken}
        />
      </View>
    </Row>
  );
};

const useStyles = makeStyles(() => {
  return createStyleSheet({
    itemImageWrapper: {
      justifyContent: "center",
      alignItems: "center",
    },
  });
});

export default memo(ItemImage);
