import React, { memo } from "react";
import { Avatar, AvatarProps } from "@rneui/themed";
import { createStyleSheet, SPACING } from "~/styles";

import {
  Row,
  Text,
  Column,
  CollapsibleAnimatedView,
  Pressable,
} from "~/components/elements";
import AuthorTooltip from "~/components/elements/AuthorTooltip";
import DisplayName from "~/components/elements/DisplayName";
import { Colors } from "@rneui/themed/dist/config/colors";
import { Linking, StyleProp } from "react-native";
import { ItemService, TextAttributionStyle } from "~/enums";
import RSSTooltip from "~/components/elements/RSSTooltip";
import { SLIDE_IN_DIRECTION } from "~/components/elements/CollapsibleAnimatedView";

type AuthorProps = {
  item?: FC.Item;
  author?: FC.Author;
  style?: StyleProp<ViewStyle>;
  avatarSize?: AvatarProps["size"];
  overlay?: boolean;
  color?: keyof Colors;
  showUsername?: boolean;
  SubText?: React.ReactNode;
  hideTooltip?: boolean;
  avatarOnly?: boolean;
  link?: boolean;
};
const Author: React.FC<AuthorProps> = ({
  item,
  author,
  style,
  avatarSize = SPACING.BASE4X,
  overlay,
  color,
  showUsername = false,
  SubText = null,
  hideTooltip = false,
  avatarOnly = false,
  link = true,
}) => {
  if (!author?.username) return null;

  const username = author.username?.startsWith("@")
    ? author.username
    : `@${author.username}`;
  const AuthorContents = (
    <Row style={[styles.authorWrapper, style]}>
      {author.avatar && (
        <Avatar rounded size={avatarSize} source={{ uri: author.avatar }} />
      )}

      <CollapsibleAnimatedView
        show={!avatarOnly}
        nullOnHidden={false}
        fade
        slideInDirection={SLIDE_IN_DIRECTION.LEFT}
        style={styles.noZIndex}
      >
        <Column flex noGap>
          <DisplayName flex overlay={overlay} color={color} author={author} />
          {showUsername && (
            <Text
              style={styles.authorUsername}
              overlay={overlay}
              color={color}
              numberOfLines={1}
              attribution={TextAttributionStyle.STANDARD_SUBTEXT}
            >
              {username}
            </Text>
          )}
          {SubText}
        </Column>
      </CollapsibleAnimatedView>
    </Row>
  );
  if (hideTooltip || !item) {
    if (!link) {
      return AuthorContents;
    }
    return (
      <Pressable
        onPress={() => {
          if (author.url) {
            Linking.openURL(author.url);
          }
        }}
      >
        {AuthorContents}
      </Pressable>
    );
  }
  if (item.service === ItemService.MASTODON) {
    return (
      <AuthorTooltip author={author as FC.Author}>
        {AuthorContents}
      </AuthorTooltip>
    );
  }
  if (item.service === ItemService.RSS) {
    return <RSSTooltip item={item}>{AuthorContents}</RSSTooltip>;
  }
  return null;
};

const styles = createStyleSheet({
  authorWrapper: {
    backgroundColor: "transparent",
    alignItems: "center",
    paddingVertical: SPACING.SMALL,
    paddingRight: SPACING.LARGE,
    gap: SPACING.BASE,
  },
});
export default memo(Author);
