import React from "react";
import { createStyleSheet, SPACING } from "~/styles";
import { makeStyles } from "@rneui/themed";

import { Anchor, Column, Row, Text, View } from "~/components/elements";
import { isPhoneSelector } from "~/concepts/application";
import { useAppSelector } from "~/hooks";
import { CardSize, TextAttributionStyle } from "~/enums";
import ItemImage from "~/components/ItemImage";
import AutoNumberOfLinesWrapper from "~/components/elements/AutoNumberOfLinesWrapper";
import CardAuthor from "~/components/CardAuthor";
import { LinearGradient } from "expo-linear-gradient";
import CardText from "~/components/cardComponents/CardText";
import Reboosts from "~/components/cardComponents/Reboosts";

export type BasicLinkCardProps = {
  item: FC.Item;
  overlay?: boolean;
  cardSize?: CardSize;
  isHorizontal?: boolean;
};

const BasicLinkCard: React.FC<BasicLinkCardProps> = ({
  item,
  overlay,
  cardSize,
  isHorizontal = false,
}) => {
  const { image, card } = item;
  const imageAspectRatio =
    image?.width && image?.height && image?.width / image?.height;
  const styles = useStyles({ cardSize, imageAspectRatio, isHorizontal });
  const isPhone = useAppSelector(isPhoneSelector);

  if (!card) return null;

  const WrapperCompponent = isHorizontal ? Row : Column;
  return (
    <WrapperCompponent flex style={styles.newsItemCard}>
      <Column
        style={
          isHorizontal
            ? styles.imageContainerHorizontal
            : styles.imageContainerVertical
        }
      >
        <ItemImage item={item} disableBlurBackground contentFit={"cover"} />
        <LinearGradient
          // Background Gradient Overlay
          colors={["#00000000", "#00000080"]}
          locations={[0.9, 0.95]}
          style={styles.gradientOverlay}
        />
        <Anchor flex href={card.url} style={styles.overlayContents}>
          <View style={[styles.imageOverlay]}>
            <Text overlay attribution={TextAttributionStyle.SPECIAL}>
              {item?.card?.flip?.publisherName || item?.card?.provider_name}
            </Text>
          </View>
        </Anchor>
      </Column>
      <Column
        flex
        style={[
          styles.cardAuthorAndContentContainer,
          isPhone && styles.articleContentPhone,
        ]}
      >
        <CardAuthor item={item} overlay={overlay} />
        <AutoNumberOfLinesWrapper flex>
          <CardText header cardSize={cardSize} overlay={overlay}>
            {card.title}
          </CardText>
        </AutoNumberOfLinesWrapper>
        <Reboosts item={item} />
      </Column>
    </WrapperCompponent>
  );
};
const cardMargin = (cardSize?: CardSize) => {
  switch (cardSize) {
    case CardSize.SMALL:
      return { margin: SPACING.BASE3X };
    case CardSize.MEDIUM:
      return { margin: SPACING.BASE4X };
    case CardSize.LARGE:
    default:
      return { margin: SPACING.BASE5X };
  }
};

const getHorizontalPadding = (cardSize?: CardSize) => {
  switch (cardSize) {
    case CardSize.SMALL:
      return SPACING.BASE3X;
    case CardSize.MEDIUM:
      return SPACING.BASE4X;
    case CardSize.LARGE:
      return SPACING.BASE5X;
    case CardSize.XLARGE:
      return SPACING.BASE7X;
  }
};

const useStyles = makeStyles(
  (
    theme,
    {
      cardSize,
      imageAspectRatio,
      isHorizontal,
    }: {
      cardSize?: CardSize;
      imageAspectRatio?: number;
      isHorizontal?: boolean;
    }
  ) =>
    createStyleSheet({
      newsItemCard: {
        padding: isHorizontal ? getHorizontalPadding(cardSize) : 0,
        overflow: "hidden",
        ...cardMargin(cardSize),
      },
      imageContainerVertical: {
        width: "100%",
        aspectRatio: imageAspectRatio,
        maxHeight: "55%",
      },
      imageContainerHorizontal: {
        height: "100%",
        aspectRatio: imageAspectRatio,
        maxWidth: "50%",
      },
      imageOverlay: {
        width: "100%",
        height: "100%",
        backgroundColor: "transparent",
        alignItems: "flex-start",
        justifyContent: "flex-end",
        padding: SPACING.BASE,
      },
      overlayContents: {
        backgroundColor: "transparent",
        position: "absolute",
        bottom: 0,
        left: 0,
        height: "100%",
        width: "100%",
        zIndex: 2,
        justifyContent: "flex-end",
      },
      gradientOverlay: {
        position: "absolute",
        height: "100%",
        width: "100%",
        zIndex: 1,
      },
      articleContentPhone: {
        padding: SPACING.LARGE,
      },
      title: {},
      cardAuthorAndContentContainer: {
        backgroundColor: "transparent",
        height: "100%",
      },
    })
);
export default BasicLinkCard;
