import React from "react";
import { createStyleSheet, SPACING } from "~/styles";
import { makeStyles } from "@rneui/themed";
import { Column } from "~/components/elements";
import Content from "~/components/Content";
import { isPhoneSelector } from "~/concepts/application";
import { useAppSelector } from "~/hooks";
import { CardSize } from "~/enums";
import AutoNumberOfLinesWrapper from "~/components/elements/AutoNumberOfLinesWrapper";
import CardAuthor from "~/components/CardAuthor";
import { isFeatureableWide } from "~/utils/image";
import { cardSizeToTextSize } from "~/utils/fonts";
import ArticlePreview from "~/components/cards/ArticlePreview";
import Reboosts from "~/components/cardComponents/Reboosts";

type BasicLinkCardProps = {
  item: FC.Item;
  cardSize?: CardSize;
  overlay?: boolean;
  hidePreview?: boolean;
};

const SocialArticleCard: React.FC<BasicLinkCardProps> = ({
  item,
  cardSize,
  overlay,
  hidePreview,
}) => {
  const isPhone = useAppSelector(isPhoneSelector);
  const styles = useStyles({});

  if (!item.card) return null;

  const showLargeVariant =
    isFeatureableWide(item.image) &&
    cardSize &&
    [CardSize.LARGE, CardSize.XLARGE].includes(cardSize);

  return (
    <Column
      flex
      style={[styles.newsItemCard, isPhone && styles.newsItemCardPhone]}
    >
      <CardAuthor item={item} overlay={overlay} />
      <AutoNumberOfLinesWrapper flexShrink style={styles.contentWrapper}>
        <Content textSize={fontSize(cardSize)} item={item} overlay={overlay} />
      </AutoNumberOfLinesWrapper>
      {!hidePreview && (
        <ArticlePreview
          flexShrink
          item={item}
          cardSize={cardSize}
          isCompact={!showLargeVariant}
        />
      )}
      <Reboosts item={item} />
    </Column>
  );
};

const fontSize = (cardSize?: CardSize) => {
  let fontCardSize = cardSize;
  if (cardSize === CardSize.XLARGE) fontCardSize = CardSize.LARGE;
  else if (cardSize === CardSize.LARGE) fontCardSize = CardSize.MEDIUM;
  else if (cardSize === CardSize.MEDIUM) fontCardSize = CardSize.SMALL;
  return cardSizeToTextSize(fontCardSize);
};

const useStyles = makeStyles((_theme) =>
  createStyleSheet({
    newsItemCard: { margin: SPACING.BASE3X },
    newsItemCardPhone: {
      padding: SPACING.LARGE,
    },
    contentWrapper: { minHeight: "1em" },
  })
);
export default SocialArticleCard;
