import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "~/store";
import { PaginationImpulse } from "~/enums";

interface PaginationState {
  impulse?: PaginationImpulse;
  rootPageSize: number;
  rootPageIndex: number;
  navToRootPageIndex?: number;
  secondaryPageIndex: number;
  scrolling: boolean;
}

const initialState: PaginationState = {
  rootPageSize: 0,
  rootPageIndex: 0,
  secondaryPageIndex: 0,
  scrolling: false,
};

export const paginationSlice = createSlice({
  name: "pagination",
  initialState,
  reducers: {
    setPaginationImpulse: (
      state,
      { payload }: PayloadAction<PaginationImpulse>
    ) => {
      state.impulse = payload;
    },
    clearPaginationImpulse: (state) => {
      state.impulse = undefined;
    },
    setRootPageSize: (
      state,
      { payload: rootPageSize }: PayloadAction<number>
    ) => {
      return { ...state, rootPageSize };
    },
    setRootPageIndex: (
      state,
      { payload: rootPageIndex }: PayloadAction<number>
    ) => {
      return {
        ...state,
        secondaryPageIndex: 0,
        rootPageIndex,
      };
    },
    setJumpToRootPageIndex: (
      state,
      { payload: navToRootPageIndex }: PayloadAction<number>
    ) => {
      return { ...state, navToRootPageIndex };
    },
    clearJumpToRootPageIndex: (state) => {
      state.navToRootPageIndex = undefined;
    },
    setSecondaryPageIndex: (
      state,
      { payload: secondaryPageIndex }: PayloadAction<number>
    ) => {
      return { ...state, secondaryPageIndex };
    },
    setGlobalScrolling: (state, { payload }: PayloadAction<boolean>) => {
      state.scrolling = payload;
    },
  },
});

export const {
  setPaginationImpulse,
  clearPaginationImpulse,
  setRootPageSize,
  setRootPageIndex,
  setJumpToRootPageIndex,
  clearJumpToRootPageIndex,
  setSecondaryPageIndex,
  setGlobalScrolling,
} = paginationSlice.actions;

export const selectGlobalScrolling = (state: RootState) =>
  state.pagination.scrolling;

export const selectPaginationImpulse = (state: RootState) =>
  state.pagination.impulse;

export const selectRootPageSize = (state: RootState) =>
  state.pagination.rootPageSize;

export const selectRootPageIndex = (state: RootState) =>
  state.pagination.rootPageIndex;

export const selectSecondaryPageIndex = (state: RootState) =>
  state.pagination.secondaryPageIndex;

export const selectNavToRootPageIndex = (state: RootState) =>
  state.pagination.navToRootPageIndex;

export default paginationSlice.reducer;
