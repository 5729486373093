import React, { memo } from "react";
import { A } from "@expo/html-elements";
import { Text, Row } from "~/components/elements";
import DisplayName from "~/components/elements/DisplayName";
import { SPACING, createStyleSheet } from "~/styles";
import { makeStyles, useTheme } from "@rneui/themed";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { TextAttributionStyle } from "~/enums";
import { useFeedNavigation } from "~/hooks/useFeedNavigation";

const REBLOG_ICON_SIZE = 14;

const ReblogAttribution: React.FC<{
  reblogger: FC.Item["reblogger"];
  overlay?: boolean;
}> = ({ reblogger, overlay = false }) => {
  const { theme } = useTheme();
  const styles = useStyles({ overlay });
  const { navigateToAccountFeed } = useFeedNavigation();

  return reblogger ? (
    <Row style={styles.container}>
      <Icon
        name="repeat"
        size={REBLOG_ICON_SIZE}
        color={overlay ? theme.colors.overlay : undefined}
      />
      <Text
        attribution={TextAttributionStyle.STANDARD_SUBTEXT}
        overlay={overlay}
      >
        Boosted by
      </Text>
      <A
        onPress={() => {
          navigateToAccountFeed(reblogger);
        }}
      >
        <DisplayName flex overlay={overlay} author={reblogger} subText />
      </A>
    </Row>
  ) : null;
};

const useStyles = makeStyles(() =>
  createStyleSheet({
    container: {
      gap: SPACING.SMALL,
      alignItems: "baseline",
    },
  })
);
export default memo(ReblogAttribution);
