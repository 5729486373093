import ConversationRollup from "~/components/pages/ConversationRollup";
import { isStatusItem } from "~/predicates";

const MINIMUM_REPLIES = 2;

const conversationRollup: FC.PagePresentationStrategy = ({ items }) => {
  const statusItemsWithReplies = items.filter(isStatusItem).filter((i) => {
    const item = i.reblog || i;
    return (
      item?.counts?.replies &&
      item?.counts?.replies >= MINIMUM_REPLIES &&
      !item.isReply
    );
  });
  if (statusItemsWithReplies?.length > 0) {
    return {
      usedItems: [statusItemsWithReplies[0]],
      Component: ConversationRollup,
      repeat: true,
    };
  }
  return null;
};

export default conversationRollup;
