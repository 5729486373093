import React from "react";
import { createStyleSheet } from "~/styles";
import { CardSize } from "~/enums";
import { makeStyles } from "@rneui/themed";
import { Row } from "~/components/elements";
import BasicMediaCard from "~/components/cards/BasicMediaCard";
import AVPlayerPreview from "~/components/cardComponents/AVPlayerPreview";
import { LayoutChangeEvent } from "react-native";
import { isVideoAttachment } from "~/predicates";

export type BasicAVCardProps = {
  item: FC.Item;
  overlay?: boolean;
  cardSize?: CardSize;
  isHorizontal?: boolean;
  maxNumberTruncate?: number;
};

const BasicAVCard: React.FC<BasicAVCardProps> = (props) => {
  const { item, isHorizontal } = props;

  const [imageDimensions, setImageDimensions] = React.useState({
    height: 0,
    width: 0,
  });

  const originalAttachment = item.attachments?.[0].meta.original;
  let aspectRatio =
    item?.image?.aspectRatio ||
    originalAttachment?.aspect ||
    (originalAttachment?.width &&
      originalAttachment?.height &&
      originalAttachment?.width / originalAttachment?.height) ||
    16 / 9;
  if (item.service === "rss") {
    const videoContent = item.attachments?.find(isVideoAttachment);
    aspectRatio =
      (videoContent?.meta?.width &&
        videoContent?.meta?.height &&
        videoContent?.meta?.width / videoContent?.meta?.height) ||
      16 / 9;
  }

  const styles = useStyles({
    isHorizontal,
  });

  return (
    <Row flex>
      <BasicMediaCard
        {...props}
        MediaComponent={
          <Row
            flexShrink
            style={[styles.imageContainer]}
            onLayout={(event: LayoutChangeEvent) => {
              const { width, height } = event.nativeEvent.layout;

              if (height === 0 || height * aspectRatio > width) {
                setImageDimensions({ width, height: width / aspectRatio });
              } else {
                setImageDimensions({ width: height * aspectRatio, height });
              }
            }}
          >
            <AVPlayerPreview
              item={item}
              style={[styles.justifyCenter, { ...imageDimensions }]}
            />
          </Row>
        }
      />
    </Row>
  );
};

const useStyles = makeStyles(
  (_, { isHorizontal }: { isHorizontal?: boolean }) =>
    createStyleSheet({
      imageContainer: isHorizontal
        ? {
            height: "100%",
          }
        : {
            width: "100%",
          },
    })
);
export default BasicAVCard;
