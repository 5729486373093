import React, { memo, ReactNode } from "react";
import { SPACING } from "~/styles";
import { ToastProvider } from "react-native-toast-notifications";

import ToastMessage from "~/components/toasts/ToastMessage";

interface ToastContainerProps {
  children?: ReactNode;
}

const ToastContainer: React.FC<ToastContainerProps> = ({ children }) => {
  return (
    <ToastProvider
      placement="bottom"
      offsetBottom={SPACING.BASE}
      swipeEnabled={false}
      renderType={{
        success: (toast) => <ToastMessage success onPressHide toast={toast} />,
        info: (toast) => <ToastMessage info onPressHide toast={toast} />,
        fail: (toast) => <ToastMessage fail onPressHide toast={toast} />,
      }}
    >
      {children}
    </ToastProvider>
  );
};

export default memo(ToastContainer);
