import FeedDebug from "~/components/pages/FeedDebug";

const feedDebugStrategy: FC.PagePresentationStrategy = ({ items }) => {
  const firstItem = items[0];
  if (firstItem) {
    return {
      usedItems: [firstItem],
      Component: FeedDebug,
    };
  }
  return null;
};

export default feedDebugStrategy;
