import { useFeedNavigation } from "~/hooks/useFeedNavigation";
import { useAppDispatch, useAppSelector } from "~/hooks";
import { selectHistoryPopped, setHistoryPopped } from "~/concepts/application";
import { useEffect } from "react";
import { useLocation } from "~/routing";

export const usePopstateMonitor = () => {
  const { navigateToGroupById } = useFeedNavigation();
  const dispatch = useAppDispatch();
  const historyPopped = useAppSelector(selectHistoryPopped);
  const location = useLocation();

  const handlePopState = () => {
    dispatch(setHistoryPopped(true));
  };

  useEffect(() => {
    if (historyPopped) {
      const { pathname } = location;
      const [_match, groupId] = pathname.match(/^\/feed\/([^/]+)/) || [];
      if (groupId) {
        navigateToGroupById(groupId);
      }
      setTimeout(() => {
        dispatch(setHistoryPopped(false));
      }, 100);
    }
  }, [navigateToGroupById, historyPopped, dispatch, location]);

  useEffect(() => {
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
    // we want mount and unmount only, so `[]`
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
