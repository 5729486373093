import React, { memo } from "react";
import { createStyleSheet, SPACING } from "~/styles";
import { Avatar, makeStyles } from "@rneui/themed";
import { Row, Text } from "~/components/elements";
import DisplayName from "~/components/elements/DisplayName";
import { TextAttributionStyle } from "~/enums";
import { isReboostOf } from "~/predicates";

const Reboosts: React.FC<{ item: FC.Item }> = ({ item }) => {
  const styles = useStyles();
  const reboosts = item.children.filter(isReboostOf(item));
  if (reboosts.length === 0) return null;
  const numItems = reboosts.length;
  const first = reboosts[0]?.reblog?.reblogger;
  if (!first) return null;
  return (
    <Row noGap style={[styles.alignCenter]}>
      <Row noGap style={styles.facePile}>
        {reboosts.map((i, index) => (
          <Avatar
            rounded
            size={SPACING.BASE3X}
            source={{ uri: i?.reblog?.reblogger?.avatar || "" }}
            containerStyle={[
              {
                marginLeft: index > 0 ? -SPACING.MEDIUM : SPACING.NONE,
                zIndex: index,
              },
              styles.avatar,
            ]}
          />
        ))}
      </Row>
      <Text attribution={TextAttributionStyle.STANDARD_SUBTEXT}>
        <DisplayName author={first} subText />
        {numItems > 1 ? ` and ${numItems - 1} others` : null} reposted this
      </Text>
    </Row>
  );
};

const useStyles = makeStyles((theme) => {
  return createStyleSheet({
    facePile: { paddingRight: SPACING.BASE },
    avatar: {
      //   position: "absolute",
      borderWidth: 1,
      borderColor: theme.colors.dividerTertiaryReverse,
      shadowColor: theme.colors.dividerTertiaryReverse,
      shadowRadius: SPACING.SMALL,
      shadowOffset: { width: 1, height: 1 },
    },
  });
});

export default memo(Reboosts);
