import { useState, useEffect } from "react";
import { useLazyGetInstanceAuthorQuery } from "~/api";
import { GroupRequestType } from "~/enums";
import { authorRequestKeyFromRouteFeedId } from "~/utils/id";

export const useGetAuthorFromFeed = (feed?: FC.Group) => {
  const [getInstanceAuthorTrigger, getInstanceAuthorResponse] =
    useLazyGetInstanceAuthorQuery();
  const [author, setAuthor] = useState<FC.Author | null>(null);

  useEffect(() => {
    if (getInstanceAuthorResponse.isSuccess && getInstanceAuthorResponse.data) {
      setAuthor(getInstanceAuthorResponse.data);
    }
  }, [getInstanceAuthorResponse]);

  useEffect(() => {
    if (feed?.requestType === GroupRequestType.ACCOUNT && feed?.id) {
      getInstanceAuthorTrigger({
        acct: authorRequestKeyFromRouteFeedId(feed.id),
      });
    }
  }, [feed, getInstanceAuthorTrigger]);

  if (!feed) return null;
  return author;
};
