import React, { memo } from "react";
import { Button } from "~/components/elements";
import {
  addCustomFeedBuilderExclude,
  setCustomFeedBuilderTriggerSave,
} from "~/concepts/customFeedBuilder";
import { useAppDispatch, useAppSelector } from "~/hooks";
import { selectApplicationMode } from "~/concepts/application";
import { ApplicationMode, GroupRequestType } from "~/enums";
import api from "~/api";
import { selectSelectedFeed } from "~/concepts/groups";
import { useToast } from "react-native-toast-notifications";
import { useIsGroupOwner } from "~/hooks/useIsGroupOwner";
import { ButtonProps } from "~/components/elements/Button";
import { useTheme } from "@rneui/themed";

import Icon from "@expo/vector-icons/MaterialCommunityIcons";

const CustomFeedExcludeFeedButton: React.FC<
  {
    feed: FC.Group;
    children?: React.ReactNode;
    onExclude?: () => void;
    type?: "outline" | "solid" | "clear";
  } & ButtonProps
> = ({ feed, children, onExclude, type = "outline", ...restProps }) => {
  const { theme } = useTheme();
  const dispatch = useAppDispatch();
  const isGroupOwner = useIsGroupOwner();

  const toast = useToast();
  const selectedFeed = useAppSelector(selectSelectedFeed);

  const applicationMode = useAppSelector(selectApplicationMode);

  if (
    (selectedFeed && !isGroupOwner(selectedFeed)) ||
    (applicationMode === ApplicationMode.BASE &&
      selectedFeed?.requestType !== GroupRequestType.CUSTOM_FEED)
  )
    return null;

  const onPress = async () => {
    if (!feed.customFeedValue) {
      return;
    }
    if (applicationMode === ApplicationMode.CUSTOM_FEED_BUILDER) {
      dispatch(addCustomFeedBuilderExclude(feed.customFeedValue));
      dispatch(setCustomFeedBuilderTriggerSave(true));
    } else if (
      applicationMode === ApplicationMode.BASE &&
      selectedFeed?.requestType === GroupRequestType.CUSTOM_FEED
    ) {
      try {
        const response = await dispatch(
          api.endpoints.addCustomFeedItemExclude.initiate({
            id: selectedFeed.id.replace(/^(.*)~/, ""),
            item: feed.customFeedValue,
          })
        );
        if ("error" in response) {
          throw new Error();
        } else {
          toast.show(`Excluding ${feed.title} from ${selectedFeed.title}`, {
            type: "success",
          });
        }
      } catch (e) {
        toast.show(`Error excluding ${feed.title} from ${selectedFeed.title}`, {
          type: "error",
        });
      }
    }
    onExclude?.();
  };

  return (
    <Button
      {...restProps}
      type={type}
      title={"Exclude From This Feed"}
      onPress={onPress}
      icon={<Icon name="cancel" size={12} color={theme.colors.controlsRed2} />}
      iconRight
      color={theme.colors.controlsRed2}
    >
      {children}
    </Button>
  );
};

export default memo(CustomFeedExcludeFeedButton);
