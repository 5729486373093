import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Carousel, {
  RenderPageItemPropsType,
} from "~/components/elements/Carousel";
import { createStyleSheet, LAYOUT, SPACING } from "~/styles";
import { View, Text, Row, Button } from "~/components/elements";
import api from "~/api";
import { useAppDispatch, useAppSelector } from "~/hooks";
import getGroupPages, { getDevelopmentPages } from "~/utils/getGroupPages";
import flatten from "lodash-es/flatten";
import { selectItemsForGroupPages } from "~/concepts/groups";
import isEqual from "lodash-es/isEqual";
import { QueryActionCreatorResult } from "@reduxjs/toolkit/dist/query/core/buildInitiate";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  QueryDefinition,
} from "@reduxjs/toolkit/dist/query";
import Loading from "~/components/Loading";
import { calcPageSize } from "~/utils/responsive";
import { makeStyles, useTheme } from "@rneui/themed";
import { selectApplicationMode } from "~/concepts/application";
import { ApplicationMode, GroupRequestType, FeatureFlags } from "~/enums";
import {
  useGetDevCardFilterCookie,
  useGetDevLayoutFilterCookie,
  useGetDevelopmentCookie,
  useGetFeatureFlagsCookie,
} from "~/hooks/useCookies";
import { StyleProp } from "react-native";
import GroupHeader from "~/components/GroupHeader";
import { BackgroundColorContext } from "~/contexts/BackgroundColorContext";
import AccountHeader from "~/components/GroupHeaders/AccountHeader";
import CustomFeedHeader from "~/components/GroupHeaders/CustomFeedHeader";
import HashtagHeader from "~/components/GroupHeaders/HashtagHeader";
import TopicHeader from "~/components/GroupHeaders/TopicHeader";
import RSSHeader from "~/components/GroupHeaders/RSSHeader";
import FeedHeader from "~/components/GroupHeaders/FeedHeader";
import SearchHeader from "~/components/GroupHeaders/SearchHeader";

const headerMap: Partial<
  Record<GroupRequestType, React.JSXElementConstructor<{ feed: FC.Group }>>
> = {
  [GroupRequestType.ACCOUNT]: AccountHeader,
  [GroupRequestType.CUSTOM_FEED]: CustomFeedHeader,
  [GroupRequestType.HASHTAG]: HashtagHeader,
  [GroupRequestType.TOPIC]: TopicHeader,
  [GroupRequestType.RSS]: RSSHeader,
  [GroupRequestType.FEED]: FeedHeader,
  [GroupRequestType.SEARCH]: SearchHeader,
};

type GetGroupsRequest = QueryActionCreatorResult<
  QueryDefinition<
    {
      group: FC.Group;
      applicationMode: ApplicationMode;
      bustUpstreamCache?: boolean;
    },
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
    never,
    FC.GroupPage,
    "api"
  >
>;

class RowItem extends React.PureComponent<{
  strategy: any;
  pageSizeStyle: StyleProp<ViewStyle>;
  pageWidth: number;
  contentHeight: number;
  styles: any;
}> {
  render() {
    const { strategy, pageSizeStyle, pageWidth, contentHeight, styles } =
      this.props;

    const pageSize = calcPageSize(pageWidth);
    return (
      <Row
        style={[
          styles.justifyCenter,
          styles.defaultPageSizeStyle,
          pageSizeStyle,
        ]}
      >
        <strategy.Component
          group={strategy.group}
          items={strategy.items}
          seed={strategy.seed}
          contentWidth={pageWidth}
          viewableContentSize={pageSize}
          contentHeight={contentHeight}
          strategyProps={strategy.strategyProps}
        />
      </Row>
    );
  }
}
const MemoizedRowItem = memo(RowItem);
const Group: React.FC<{
  group: FC.Group;
  contentHeight: number;
  contentWidth: number;
}> = ({ group, contentHeight, contentWidth }) => {
  const dispatch = useAppDispatch();
  const styles = useStyles();
  const { theme } = useTheme();
  const [requests, setRequests] = useState<Array<GetGroupsRequest>>([]);
  const [lock, setLock] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [scrolledToIndex, setScrolledToIndex] = useState(0);
  const [populated, setPopulated] = useState(false);
  const [populatingRetries, setPopulatingRetries] = useState(0);
  const [populatingRetriesFailure, setPopulatingRetriesFailure] =
    useState(false);
  const cleanup = useRef<(() => void) | null>(null);
  const applicationMode = useAppSelector(selectApplicationMode);
  const devItemFilter = useGetDevCardFilterCookie()();
  const featureFlags = useGetFeatureFlagsCookie()();
  const debugPresentationStrategies = useGetDevLayoutFilterCookie()();
  const devMode = useGetDevelopmentCookie()();
  const renderPageThreshold = 2;
  // retries for one minute
  const populatingRetryTimeout = 2000;
  const populatingRetryLimit = 30;
  const pageSize = calcPageSize(contentWidth);

  useEffect(() => {
    setRequests([]);
  }, [group]);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (group.populating && !populated && !populatingRetriesFailure) {
      interval = setInterval(() => {
        setRequests([]);
        setPopulatingRetries(populatingRetries + 1);
      }, populatingRetryTimeout);
      if (populatingRetries > populatingRetryLimit) {
        setPopulatingRetriesFailure(true);
        clearInterval(interval);
        return;
      }
    }
    return () => clearInterval(interval);
  }, [
    group,
    populated,
    populatingRetries,
    setPopulatingRetries,
    populatingRetriesFailure,
    setPopulatingRetriesFailure,
  ]);

  const itemsForGroupPages = useAppSelector(
    selectItemsForGroupPages(requests.map((r) => r.arg)),
    isEqual
  );

  const loadGroupItems = useCallback(
    (cursorLink?: string) => {
      const requestGroup = { ...group, cursorLink };
      const alreadyRequested = requests.some((r) =>
        isEqual(r.arg.group, requestGroup)
      );
      if (alreadyRequested) {
        return;
      }

      const request = dispatch(
        api.endpoints.getGroupItems.initiate(
          {
            group: requestGroup,
            applicationMode,
            bustUpstreamCache: requests.length === 0,
          },
          {
            forceRefetch: requests.length === 0,
          }
        )
      );
      setRequests((r) => {
        return [...r, request];
      });
      setLock(true);
      request.then((result) => {
        setLock(false);
        if (result.isError) {
          setRequestError(true);
        }
      });
    },
    [dispatch, applicationMode, requests, group]
  );

  useEffect(() => {
    cleanup.current = () => {
      requests.forEach((request) => {
        request.unsubscribe();
      });
    };
  }, [dispatch, requests]);

  useEffect(() => {
    return () => {
      if (cleanup.current) {
        cleanup.current();
      }
    };
  }, []);

  if (requests.length === 0) {
    loadGroupItems();
  }

  const pages = useMemo(() => {
    let leftovers: Array<FC.Item> = [];
    let filters: Array<(i: FC.Item) => boolean> = [];
    return flatten(
      itemsForGroupPages.map((items) => {
        const groupItems = [...leftovers, ...(items || [])];
        const {
          groupPages,
          leftoverItems,
          filters: resultFilters,
        } = devMode &&
        (featureFlags[FeatureFlags.DEBUG_FILTER_LAYOUTS] ||
          featureFlags[FeatureFlags.DEBUG_DEV_PLAYGROUND])
          ? getDevelopmentPages({
              group,
              items: groupItems || [],
              filters,
              viewableContentSize: pageSize,
              devItemFilter:
                featureFlags[FeatureFlags.DEBUG_DEV_PLAYGROUND] &&
                devItemFilter,
              strategyNames: featureFlags[FeatureFlags.DEBUG_DEV_PLAYGROUND]
                ? ["devPlayground"]
                : debugPresentationStrategies,
            })
          : getGroupPages({
              group,
              items: groupItems || [],
              filters,
              viewableContentSize: pageSize,
              feedDebug: featureFlags[FeatureFlags.DEBUG_FEED],
            });
        filters = resultFilters;
        leftovers = leftoverItems;
        return groupPages;
      })
    );
  }, [
    itemsForGroupPages,
    featureFlags,
    group,
    pageSize,
    devMode,
    devItemFilter,
    debugPresentationStrategies,
  ]);

  useEffect(() => {
    if (pages.length > 0) {
      setPopulated(true);
    }
  }, [pages, setPopulated]);

  const onEndReached = useCallback(() => {
    const lastRequest = requests[requests.length - 1];
    if (!lastRequest || lock) {
      return;
    }
    lastRequest.then((v) => {
      const cursorLink = v?.data?.cursorLink;
      if (cursorLink) {
        loadGroupItems(cursorLink);
      }
    });
  }, [requests, loadGroupItems, lock]);

  if (group.populating && !populated && pages.length === 0) {
    if (!populatingRetriesFailure) {
      return (
        <Loading
          message={`populating feed${Array.from(Array(populatingRetries))
            .fill(".")
            .join("")}`}
        />
      );
    } else {
      return (
        <View style={styles.populatingRetryFailure}>
          <Text
            style={[styles.loadingText, styles.populatingRetryFailureMessage]}
          >
            Unable to find any items at the moment :(
          </Text>
          <Button
            title="Retry"
            onPress={() => {
              setPopulatingRetriesFailure(false);
              setPopulatingRetries(0);
            }}
          />
        </View>
      );
    }
  }

  if (requests.length === 1 && pages.length === 0 && lock === false) {
    // we only made one request, have no pages, and are not locked means
    // we got nothing
    return (
      <View style={[styles.errorContainer]}>
        <Text style={styles.loadingText}>
          {requestError && "Whoops. Something went wrong :("}
          {applicationMode === ApplicationMode.CUSTOM_FEED_BUILDER &&
            !requestError &&
            "We're only able to get recent posts from this profile. You can still add it to your feed, and new posts will be included."}
          {applicationMode === ApplicationMode.BASE &&
            !requestError &&
            "Whoops. Nothing found :("}
        </Text>
      </View>
    );
  }

  if (pages.length === 0) {
    return <Loading />;
  }

  const pageWidth = featureFlags[FeatureFlags.MAX_PAGE_WIDTH]
    ? Math.min(contentWidth, LAYOUT.MAX_FEED_LAYOUT_WIDTH)
    : contentWidth;

  const HeaderComponent = headerMap[group.requestType] || null;

  return (
    <Row flex>
      <BackgroundColorContext.Provider value={theme.colors.background}>
        <Carousel
          headerComponent={
            HeaderComponent ? (
              <GroupHeader HeaderComponent={HeaderComponent} group={group} />
            ) : null
          }
          containerStyle={[styles.flex1]}
          pageItems={pages}
          onEndReachedThreshold={6}
          onEndReached={onEndReached}
          onUpdateCurrentIndex={setScrolledToIndex}
          renderPageItem={({
            item: strategy,
            index: i,
          }: RenderPageItemPropsType) => {
            const pageSizeStyle = {
              height: featureFlags[FeatureFlags.DEBUG_FEED]
                ? undefined
                : contentHeight,
              width: pageWidth,
            };
            const pageFocusDifferential = Math.abs(scrolledToIndex - i);
            const key = `${strategy.seed}_${i}`;
            // if we are a few pages away, don't bother rendering
            return featureFlags[FeatureFlags.DEBUG_FEED] ||
              pageFocusDifferential <= renderPageThreshold ? (
              <MemoizedRowItem
                key={key}
                strategy={strategy}
                pageSizeStyle={pageSizeStyle}
                pageWidth={pageWidth}
                contentHeight={contentHeight}
                styles={styles}
              />
            ) : (
              <View style={pageSizeStyle} key={key}>
                <Loading />
              </View>
            );
          }}
          vertical={true}
          showControls={false}
        />
      </BackgroundColorContext.Provider>
    </Row>
  );
};

const useStyles = makeStyles(() => {
  return createStyleSheet({
    loadingText: {
      fontSize: 24,
      marginTop: SPACING.BASE3X,
    },
    defaultPageSizeStyle: {
      left: "50%",
      transform: "translateX(-50%)",
    },
    errorContainer: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    populatingRetryFailure: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    populatingRetryFailureMessage: {
      margin: SPACING.LARGE,
    },
  });
});

export default memo(Group);
