import React, { memo } from "react";
import { Column, Row, Text } from "~/components/elements";
import { useGroupHeaderStyles } from "~/components/GroupHeader";
import { SPACING } from "~/styles";
import { selectIsAuthenticated } from "~/concepts/auth";
import { useAppSelector } from "~/hooks";
import FeedFavoriteButton from "~/buttons/FeedFavoriteButton";
import { A } from "@expo/html-elements";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";

const RSSHeader: React.FC<{ feed: FC.Group }> = ({ feed: rssFeed }) => {
  const styles = useGroupHeaderStyles();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  return (
    <Column flex style={[styles.headerContainer]}>
      <Row style={[styles.headerControls]}>
        {isAuthenticated && (
          <FeedFavoriteButton feed={rssFeed} showTitle type={"outline"} />
        )}
      </Row>
      <Column flex gap={SPACING.BASE3X} style={styles.headerContents}>
        <Row style={[styles.alignCenter, styles.justifyCenter]}>
          <Icon name="rss" size={64} solid={true} color="black" />
          <Text style={[styles.title, styles.headerText]}>
            {rssFeed?.title}
          </Text>
        </Row>
        <A href={rssFeed.url}>{rssFeed.url}</A>
      </Column>
    </Column>
  );
};
export default memo(RSSHeader);
