import {
  supportedMediaTypes,
  isYouTube,
  isFirstPartyVideo,
  isGifv,
  isAudio,
  isImageAttachment,
  isPoll,
  isSocialArticle,
  isYoutubeShort,
} from "~/predicates";
import {
  CardType,
  GroupPresentationType,
  GroupingType,
  ItemService,
  ItemType,
  Visibility,
} from "~/enums";
import pick from "lodash-es/pick";

interface FLDailyMediaAttachment extends FC.MediaAttachment {
  flipboard?: {
    flcp?: FC.FlipboardImage;
  };
}

export interface FLDailyItem {
  id: string;
  content: string;
  card?: FC.Card;
  account: FC.Author;
  uri: string;
  url: string;
  reblog?: FLDailyItem;
  favourited: boolean;
  bookmarked: boolean;
  reblogged: boolean;
  media_attachments?: Array<FLDailyMediaAttachment>;
  in_reply_to_id?: string;
  created_at: string;
  sensitive: boolean;
  spoiler_text: string;
  flipboard?: {
    feed_id?: string;
    flcp: FC.Flip;
    service: ItemService;
    topics?: Array<{ topic: string; displayName: string; score: number }>;
    children: Array<FLDailyItem>;
    flus_url?: string;
    type?: GroupingType;
  };
  mentions: Array<FC.AuthorMention>;
  poll?: FC.Poll;
  visibility: Visibility;
  filtered: Array<unknown>;
  replies_count: number;
  reblogs_count: number;
  favourites_count: number;
  tags: Array<{ name: string; url: string }>;
}

const getIsReadOnly = (item: Omit<FC.Item, "capabilities">) =>
  item.service === ItemService.BLUESKY || item.service === ItemService.RSS;

const getIsRSS = (item: Omit<FC.Item, "capabilities">) =>
  item.service === ItemService.RSS;

const getCapabilities = (item: Omit<FC.Item, "capabilities">) => {
  const readOnly = getIsReadOnly(item);
  const isRSS = getIsRSS(item);
  return {
    canFilter: readOnly ? false : true,
    canReply: readOnly ? false : true,
    canFavorite: readOnly ? false : true,
    canBoost: readOnly ? false : true,
    canBookmark: readOnly ? false : true,
    canViewOnMastodon: readOnly ? false : true,
    canViewThread: readOnly ? false : true,
    canShowAuthor: readOnly && !isRSS ? false : true,
  };
};

const parseHints = (flipImage: FC.FlipboardImage): FC.ImageHints => {
  const { width, height, hints } = flipImage?.original || {};
  if (!hints) {
    return {};
  }
  const split = hints.split(",");
  const calcFocus = (hintKey: string) => {
    const focus = split.find((x) => x.startsWith(hintKey))?.split("-");
    if (!width || !height || !focus) {
      return;
    }
    /**
     * Focus Hint is coming down as a coordinate with 0,0 being the top left.
     * Convert to a percentage with 0,0 being the bottom left.
     */

    return {
      x: Math.round((Number(focus[1]) / width) * 100),
      y: Math.round(100 - (Number(focus[2]) / height) * 100),
    };
  };

  return {
    graphic: split.includes("graphic"),
    photo: split.includes("photo"),
    focus: calcFocus("focus"),
    face: calcFocus("face"),
  };
};

export const itemImageFromFlipboardImage = (
  flipboardImage: FC.FlipboardImage
) => {
  if (!flipboardImage) {
    return;
  }
  const largest =
    flipboardImage.xlarge ||
    flipboardImage.large ||
    // original doesn't have alternative so we don't prefer it // TODO: ????
    flipboardImage.original ||
    flipboardImage.medium ||
    flipboardImage.small;
  if (!largest) {
    return;
  }

  return {
    height: largest.height,
    width: largest.width,
    aspectRatio:
      largest.width && largest.height && largest.width / largest.height,
    url: largest.alternative?.url || largest.url,
    hints: parseHints(flipboardImage),
  };
};

const combineFlipWithItemImage = (
  flipboardImage?: FC.FlipboardImage,
  thingWithBlurhash?: { blurhash?: string }
) => {
  if (!flipboardImage) {
    return;
  }
  const flipImage = itemImageFromFlipboardImage(flipboardImage);
  if (flipImage) {
    return {
      ...flipImage,
      ...(thingWithBlurhash?.blurhash
        ? { blurhash: thingWithBlurhash.blurhash }
        : undefined),
    };
  }
};

const getImage = (item: FC.Item): FC.ItemImage | undefined => {
  const toRender = item.reblog || item;
  const isShort = isYoutubeShort(toRender);

  // try to get the first attachment
  const supportedImageAttachments =
    toRender.attachments?.filter(isImageAttachment);

  if (supportedImageAttachments?.[0]) {
    if (supportedImageAttachments[0].flipboardImage) {
      const comboImageAttachment = combineFlipWithItemImage(
        supportedImageAttachments[0].flipboardImage,
        supportedImageAttachments[0]
      );
      if (comboImageAttachment) {
        if (isShort) comboImageAttachment.aspectRatio = 9 / 16;
        return comboImageAttachment;
      }
    }
    if (supportedImageAttachments?.[0].meta?.original) {
      const {
        meta: {
          original: { height, width },
        },
        blurhash,
        url,
      } = supportedImageAttachments[0];
      return {
        height,
        width,
        aspectRatio: isShort ? 9 / 16 : width && height && width / height,
        url,
        blurhash,
        hints: {},
      };
    }
  }
  // fall back to card image
  const comboImage = combineFlipWithItemImage(
    toRender.card?.flip?.images?.[0],
    toRender?.card
  );
  if (comboImage) {
    if (isShort) comboImage.aspectRatio = 9 / 16;
    return comboImage;
  }
  if (toRender.card?.image) {
    const { height, width, image, blurhash } = toRender.card;
    return {
      height,
      width,
      aspectRatio: isShort ? 9 / 16 : width && height && width / height,
      url: image,
      blurhash,
      hints: {},
    };
  }
};

const getItemType = (item: FC.Item, group?: FC.Group): ItemType => {
  // TODO restore at some point when we have better special magazine
  // treatments
  // if (isFlipboardMagazine(item)) {
  //   return ItemType.FLIPBOARD_MAGAZINE;
  // }
  if (item.image) {
    if (
      !item.card ||
      group?.presentationType === GroupPresentationType.GALLERY
    ) {
      return ItemType.PHOTO;
    }
  }
  // prefer youtube over attachments for rss items
  if (item.service === ItemService.RSS && isYouTube(item)) {
    return ItemType.YOUTUBE;
  }
  if (item.attachments) {
    if (isFirstPartyVideo(item) || isAudio(item)) {
      return ItemType.AV;
    }
    if (isGifv(item)) {
      return ItemType.GIFV;
    }
  }
  if (isYouTube(item) || isYoutubeShort(item)) {
    return ItemType.YOUTUBE;
  }
  if (isPoll(item)) {
    return ItemType.POLL;
  }
  if (
    item.card &&
    item.image &&
    group?.presentationType !== GroupPresentationType.GALLERY
  ) {
    if (isSocialArticle(item)) {
      return ItemType.SOCIAL_ARTICLE;
    }
    return ItemType.ARTICLE;
  }
  return ItemType.STATUS;
};

const getFlip = (flipboardFlcp?: FC.Flip) =>
  flipboardFlcp
    ? pick(
        flipboardFlcp,
        "title",
        "images",
        "publisherName",
        "shortExcerpt",
        "domain",
        "author",
        "url",
        "description",
        "type",
        "height",
        "width",
        "videoDuration"
      )
    : undefined;

const flDailyItemToItem = (
  flDailyItem: FLDailyItem,
  group?: FC.Group
): FC.Item => {
  const {
    id,
    content,
    card,
    account,
    uri,
    url,
    reblog,
    favourited,
    bookmarked,
    reblogged,
    media_attachments,
    in_reply_to_id,
    created_at,
    sensitive,
    spoiler_text,
    flipboard,
    mentions,
    visibility,
    replies_count,
    reblogs_count,
    favourites_count,
    tags,
  } = flDailyItem;
  const projectedReblog =
    reblog && flDailyItemToItem({ ...reblog, flipboard }, group);
  if (projectedReblog) {
    projectedReblog.reblogger = account;
  }

  const validAttachments = (media_attachments || [])
    .map((attachment) => {
      if (attachment.flipboard?.flcp) {
        return { ...attachment, flipboardImage: attachment.flipboard.flcp };
      }
      return attachment;
    })
    .filter(supportedMediaTypes);

  const attachments =
    validAttachments.length > 0 ? validAttachments : undefined;

  let itemCard: FC.Card | undefined = card;

  if (
    flipboard?.flcp &&
    !reblog &&
    !itemCard &&
    flipboard.flcp.images?.[0]?.original
  ) {
    // we have no card but have a flip, we can derive itemCard from flip
    itemCard = {
      image: flipboard.flcp.images[0].original.url,
      width: flipboard.flcp.width || flipboard.flcp.images[0].original.width,
      height: flipboard.flcp.height || flipboard.flcp.images[0].original.height,
      url: flipboard.flcp.url,
      description: flipboard.flcp.description,
      provider_name: flipboard.flcp.domain,
      author_name: flipboard.flcp.author,
      title: flipboard.flcp.title,
      html: flipboard.flcp.description,
      type:
        flipboard.flcp.type === CardType.VIDEO ? CardType.VIDEO : CardType.LINK,
      duration: flipboard.flcp.videoDuration,
    };
  }

  if (flipboard && itemCard) {
    itemCard.flip = getFlip(flipboard.flcp);
  }

  let author = account;
  if (
    flipboard?.service === ItemService.RSS &&
    !author?.acct &&
    itemCard?.author_name
  ) {
    author = {
      ...author,
      acct: itemCard?.author_name,
      username: itemCard?.author_name,
    };
  }
  const item: FC.Item = {
    id,
    createdAt: created_at,
    content:
      (flipboard?.service === ItemService.RSS && flipboard?.flcp?.title) ||
      content,
    author,
    reblog: projectedReblog,
    source: uri,
    targetUrl: reblog ? reblog.url : url,
    isFavorited: favourited,
    isBookmarked: bookmarked,
    isReblogged: reblogged,
    isReply: !!in_reply_to_id,
    card: itemCard,
    attachments,
    sensitive,
    spoilerText: spoiler_text,
    mentions,
    poll: flDailyItem.poll,
    type: ItemType.STATUS,
    visibility,
    counts: {
      replies: replies_count,
      reblogs: reblogs_count,
      favorites: favourites_count,
    },
    needsInstanceContext: false,
    capabilities: {
      canFilter: false,
      canReply: false,
      canFavorite: false,
      canBoost: false,
      canBookmark: false,
      canViewOnMastodon: false,
      canViewThread: false,
      canShowAuthor: false,
    },
    tags,
    // TODO: Currently on the `Following` group, no service comes back.
    //   remove the default to mastodon once the API is consistent?
    service: flipboard?.service || ItemService.MASTODON,
    rssFeedId: flipboard?.feed_id,
    topics: flipboard?.topics,
    groupingType: flipboard?.type,
    flusUrl: flipboard?.flus_url,
    children: (flipboard?.children || []).map((c) =>
      flDailyItemToItem(c, group)
    ),
  };
  const image = projectedReblog?.image || getImage(item);

  if (!item.id && item.groupingType) {
    item.id = `${item.groupingType}-${item.children
      .map((i) => i.id)
      .join("_")}`;
  }

  return {
    ...item,
    type: getItemType({ ...item, image }, group),
    image,
    needsInstanceContext: !getIsReadOnly(item),
    capabilities: getCapabilities(item),
  };
};

export default flDailyItemToItem;
