import { ApplicationMode, GroupRequestType, RSSFeedType } from "~/enums";
import {
  CUSTOM_FEED_ID_SEPARATOR,
  idFromAuthorUrl,
  idFromHashtagName,
  idFromRSSUID,
  idFromSearchTerm,
} from "~/utils/id";
import { RSSFeed } from "~/apiTransforms/rss";
import { safeAcct } from "~/lib/safeAcct";
import { getSlugTitle } from "~/lib/getSlugTitle";

export const hashtagGroupFromHashtag = (
  hashtag: string,
  applicationMode: ApplicationMode
): FC.Group => ({
  id: idFromHashtagName(hashtag),
  requestKey:
    applicationMode === ApplicationMode.CUSTOM_FEED_BUILDER
      ? `${encodeURIComponent("#")}${hashtag.toLowerCase()}`
      : hashtag,
  requestType: GroupRequestType.HASHTAG,
  title: `#${hashtag}`,
  customFeedValue: `#${hashtag}`,
  icon: "pound",
});

export const authorFeedFromAuthor = (
  author: FC.Author,
  host?: string,
  applicationMode?: ApplicationMode
): FC.Group => {
  const { display_name, avatar, uri } = author;
  const fullAcct = safeAcct(author.acct, host || "");
  return {
    id: idFromAuthorUrl(uri),
    requestKey:
      applicationMode === ApplicationMode.CUSTOM_FEED_BUILDER
        ? fullAcct
        : author.id,
    requestType: GroupRequestType.ACCOUNT,
    title: display_name,
    customFeedValue: fullAcct,
    description: fullAcct,
    image: avatar,
  };
};

export const rssFeedfromRSSFeed =
  (rssType: RSSFeedType) =>
  (data: RSSFeed): FC.Group => ({
    id: data.uid && idFromRSSUID(data.uid),
    title: data.title,
    description: data.description,
    requestType:
      rssType === RSSFeedType.YOUTUBE
        ? GroupRequestType.YOUTUBE
        : GroupRequestType.RSS,
    requestKey: data.uid,
    customFeedValue: data.uid,
    url: data.url,
    icon:
      rssType === RSSFeedType.YOUTUBE ? ("youtube" as const) : ("rss" as const),
    populating: true,
  });

export const searchFeedFromTerm = (term: string) => ({
  id: idFromSearchTerm(term),
  title: `Search: "${term}"`,
  description: `Search results for ${term}`,
  requestType: GroupRequestType.SEARCH,
  requestKey: term,
  icon: "magnify" as const,
});

export const customFeedGroupFromCustomFeed = (
  customFeed: FC.CustomFeed
): FC.Group => ({
  id: `${getSlugTitle(customFeed.title)}${CUSTOM_FEED_ID_SEPARATOR}${
    customFeed.id
  }`,
  title: customFeed.title,
  requestType: GroupRequestType.CUSTOM_FEED,
  requestKey: customFeed.id.toString(),
  image: customFeed.image,
  description: customFeed.description,
  ownerURL: customFeed.account_uri,
});

export const customFeedPreview = (
  title: string,
  includes: Array<string>,
  excludes: Array<string>
) => {
  return {
    id: "custom-feed-preview",
    title,
    requestType: GroupRequestType.CUSTOM_FEED_PREVIEW,
    requestKey: `${includes
      .map((s) => `topics=${encodeURIComponent(s)}`)
      .join("&")}${excludes.length > 0 ? "&" : ""}${excludes
      .map((s) => `notInTopics=${encodeURIComponent(s)}`)
      .join("&")}`,
  };
};
