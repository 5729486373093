import { REGEX_YOUTUBE_ID, REGEX_YOUTUBE_SHORTS_ID } from "~/utils/reg-exp";

export const getYouTubeID = (url: string) => {
  let match;
  if (url.includes("shorts")) {
    match = url.match(REGEX_YOUTUBE_SHORTS_ID);
    return match && match[1];
  }
  match = url.match(REGEX_YOUTUBE_ID);
  return match && match[1];
};
