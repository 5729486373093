import React, { memo } from "react";
import BasicRow from "~/components/pages/sublayouts/BasicRow";
import { ItemType, CardSize } from "~/enums";
import { makeStyles } from "@rneui/themed";
import { SPACING, createStyleSheet } from "~/styles";
import StatusCardJustifyTop from "~/components/cards/StatusCardJustifyTop";
import ArticlePreviewBasicLink from "~/components/cards/ArticlePreviewBasicLink";
import { Row } from "~/components/elements";

const VigetThreeUpColumn: FC.PageStrategyResult["Component"] = ({
  items,
  contentWidth,
}) => {
  const styles = useStyles();

  return (
    <Row flex style={{ paddingHorizontal: SPACING.BASE3X }}>
      <BasicRow
        flex
        items={items}
        width={contentWidth}
        cardSize={CardSize.LARGE}
        ComponentMap={{
          [ItemType.STATUS]: StatusCardJustifyTop,
          [ItemType.ARTICLE]: ArticlePreviewBasicLink,
        }}
        style={[styles.justifyLeft]}
        noGap
      />
    </Row>
  );
};

const useStyles = makeStyles((_) => createStyleSheet({}));

export default memo(VigetThreeUpColumn);
