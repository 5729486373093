import { memo, useEffect } from "react";
// import { Appearance } from "react-native";
import { useThemeMode } from "@rneui/themed";
// import { usePreferences } from "~/hooks/usePreferences";

//TODO: disabling theme for now.
const ApplicationTheme = () => {
  // const colorScheme = Appearance.getColorScheme();
  const { mode, setMode } = useThemeMode();
  // const [preferences] = usePreferences();

  useEffect(() => {
    if (!mode) setMode("light");
  }, [mode, setMode]);

  // useEffect(() => {
  //   const newMode = preferences.darkMode ? "dark" : "light";
  //   if (newMode !== mode) {
  //     setMode(newMode);
  //   }
  // }, [mode, setMode, colorScheme, preferences]);

  return null;
};

export default memo(ApplicationTheme);
