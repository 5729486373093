import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "~/store";

interface Card {
  id: FC.Item["id"];
  hovered: boolean;
}
type CardHoverState = {
  hovered?: FC.Item["id"];
};

const initialState: CardHoverState = {};

export const cardHoverSlice = createSlice({
  name: "cardHover",
  initialState,
  reducers: {
    setCardHovered: (state, { payload: card }: PayloadAction<Card>) => {
      if (card.hovered) {
        return { ...state, hovered: card.id };
      }
      return { ...state, hovered: undefined };
    },
  },
});

export const { setCardHovered } = cardHoverSlice.actions;

export const getCardHoverState = (state: RootState) => state.cardHover;
export const getCardHovered = (item: FC.Item) => (state: RootState) =>
  state.cardHover.hovered === item.id;

export default cardHoverSlice.reducer;
