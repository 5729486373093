import React, { memo } from "react";
import { ApplicationMode } from "~/enums";
import { SPACING } from "~/styles";

import { useTheme } from "@rneui/themed";
import { useAppSelector, useAppDispatch } from "~/hooks";
import { homeTimelineGroup, selectSelectedFeed } from "~/concepts/groups";
import { useIsGroupOwner } from "~/hooks/useIsGroupOwner";
import { selectApplicationMode } from "~/concepts/application";
import {
  addCustomFeedBuilderInclude,
  selectCustomFeedBuilderPreviewFeed,
  setRequestCustomFeedEditSwitchToView,
  setCustomFeedBuilderPreviewFeed,
  setCustomFeedBuilderTriggerSave,
} from "~/concepts/customFeedBuilder";

import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { Button } from "~/components/elements";
import EditCustomFeedButton from "~/components/EditCustomFeedButton";
import { isPhoneSelector } from "~/concepts/application";
import AddToFeedDropdown from "~/components/AddToFeedDropdown";

const HeaderFeedControls: React.FC = () => {
  const dispatch = useAppDispatch();
  const { theme } = useTheme();
  const customFeedBuilderPreviewFeed = useAppSelector(
    selectCustomFeedBuilderPreviewFeed
  );
  const selectedFeed = useAppSelector(selectSelectedFeed);
  const applicationMode = useAppSelector(selectApplicationMode);
  const isGroupOwner = useIsGroupOwner();
  const isPhone = useAppSelector(isPhoneSelector);

  if (!selectedFeed && !customFeedBuilderPreviewFeed) {
    return null;
  }
  return (
    <>
      {applicationMode === ApplicationMode.CUSTOM_FEED_BUILDER &&
        customFeedBuilderPreviewFeed && (
          <>
            <Button
              title="Add To Feed"
              onPress={() => {
                dispatch(addCustomFeedBuilderInclude());
                dispatch(setCustomFeedBuilderTriggerSave(true));
                dispatch(setCustomFeedBuilderPreviewFeed(null));
              }}
              type={"clear"}
              buttonStyle={{
                gap: SPACING.SMALL,
              }}
              icon={
                <Icon
                  name="timeline-plus"
                  color={theme.colors.controlsGreen}
                  size={SPACING.BASE3X}
                />
              }
            />
            <Button
              title="Cancel"
              onPress={() => {
                dispatch(setCustomFeedBuilderPreviewFeed(null));
              }}
              type={"clear"}
              buttonStyle={{
                gap: SPACING.SMALL,
              }}
              icon={
                <Icon
                  name="cancel"
                  color={theme.colors.controlsRed}
                  size={SPACING.BASE3X}
                />
              }
            />
          </>
        )}

      {applicationMode === ApplicationMode.CUSTOM_FEED_BUILDER &&
        !customFeedBuilderPreviewFeed && (
          <Button
            title={isPhone ? undefined : "View Feed"}
            onPress={() => {
              dispatch(setRequestCustomFeedEditSwitchToView(true));
            }}
            type={"clear"}
            buttonStyle={{
              gap: SPACING.SMALL,
            }}
            icon={
              <Icon
                name="eye"
                color={theme.colors.primary}
                size={SPACING.BASE3X}
              />
            }
          />
        )}
      {selectedFeed &&
        isGroupOwner(selectedFeed) &&
        applicationMode === ApplicationMode.BASE && (
          <EditCustomFeedButton
            group={selectedFeed}
            showIcon
            showLabel={!isPhone}
          />
        )}
      {selectedFeed &&
        !isPhone &&
        !isGroupOwner(selectedFeed) &&
        applicationMode === ApplicationMode.BASE &&
        selectedFeed !== homeTimelineGroup && (
          <>
            {!!selectedFeed.customFeedValue && (
              <AddToFeedDropdown feed={selectedFeed} />
            )}
          </>
        )}
    </>
  );
};

export default memo(HeaderFeedControls);
