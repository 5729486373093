import { useState, useEffect, useCallback } from "react";
import groupBy from "lodash-es/groupBy";
import {
  useLazySearchMastodonQuery,
  useLazySearchRssQuery,
  useLazySearchTopicQuery,
  useLazySearchYoutubeQuery,
} from "~/api";
import { GroupRequestType, SearchResultType } from "~/enums";
import {
  useSelectFavoriteFeeds,
  useSelectFeatureFeeds,
} from "~/hooks/useSelectGroupsWithPreferences";
import isEqual from "lodash-es/isEqual";
import { useAppSelector } from "~/hooks";
import { selectIsAuthenticated } from "~/concepts/auth";
import {
  useGetHostCookie,
  useGetRecentSearchResults,
} from "~/hooks/useCookies";
import { selectApplicationMode } from "~/concepts/application";
import { selectMyCustomFeeds, selectSelectedFeed } from "~/concepts/groups";
import { searchFeedFromTerm } from "~/utils/groups";
import uniq from "lodash-es/uniq";
import reduce from "lodash-es/reduce";
import { uniqFeeds } from "~/utils/feeds";

type SearchResult = {
  [K in SearchResultType]?: Array<FC.Group>;
};

type SearchOptions = {
  limit?: number;
  exactMatch?: boolean;
};

export const useSearch = (
  searchTypes: Array<SearchResultType>,
  options?: SearchOptions
) => {
  const applicationMode = useAppSelector(selectApplicationMode);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const getHostCookie = useGetHostCookie();
  const [searchTopicTrigger, searchTopicResponse] = useLazySearchTopicQuery();
  const [searchMastodonTrigger, searchMastodonResponse] =
    useLazySearchMastodonQuery();
  const [searchRssTrigger, searchRssResponse] = useLazySearchRssQuery();
  const [searchYoutubeTrigger, searchYoutubeResponse] =
    useLazySearchYoutubeQuery();
  const [searchResults, setSearchResults] = useState<SearchResult>({});
  const [favoritesSearchResults, setFavoritesSearchResults] = useState<
    Array<FC.Group>
  >([]);
  const [featuredResults, setFeaturedResults] = useState<Array<FC.Group>>([]);
  const [recentResults, setRecentResults] = useState<Array<FC.Group>>([]);
  const [directResults, setDirectResults] = useState<Array<FC.Group>>([]);
  const myCustomFeeds = useAppSelector(selectMyCustomFeeds, isEqual);
  const favorites = useSelectFavoriteFeeds();
  const featureFeeds = useSelectFeatureFeeds();
  const getRecentSearchResults = useGetRecentSearchResults();
  const selectedFeed = useAppSelector(selectSelectedFeed);
  const [query, setQuery] = useState("");

  const search = useCallback(
    (q: string) => {
      if (!q) {
        setSearchResults({});
        return;
      }
      const host = getHostCookie();
      if (
        q.indexOf("#") === 0 &&
        searchTypes.includes(SearchResultType.HASHTAG)
      ) {
        if (q.length === 1) {
          return;
        }
        if (searchTypes.includes(SearchResultType.HASHTAG)) {
          searchMastodonTrigger(
            {
              q: q.replace(/^#/, ""),
              type: "hashtags",
              host,
              isAuthenticated,
              applicationMode,
            },
            true
          );
        }
      } else if (
        q.indexOf("@") === 0 &&
        searchTypes.includes(SearchResultType.ACCOUNT) &&
        isAuthenticated
      ) {
        if (q.length === 1) {
          return;
        }
        searchMastodonTrigger(
          {
            q: q.replace(/^@/, ""),
            type: "accounts",
            host,
            isAuthenticated,
            applicationMode,
          },
          true
        );
      } else {
        if (searchTypes.includes(SearchResultType.YOUTUBE)) {
          searchYoutubeTrigger(q, true);
        }
        if (searchTypes.includes(SearchResultType.RSS)) {
          searchRssTrigger(q, true);
        }
        if (searchTypes.includes(SearchResultType.TOPIC)) {
          searchTopicTrigger(q, true);
        }
        if (isAuthenticated) {
          if (searchTypes.includes(SearchResultType.ACCOUNT)) {
            searchMastodonTrigger(
              { q, type: "accounts", host, isAuthenticated, applicationMode },
              true
            );
          }
        }
      }
      setQuery(q);
    },
    [
      getHostCookie,
      searchTypes,
      isAuthenticated,
      searchMastodonTrigger,
      applicationMode,
      searchTopicTrigger,
      searchRssTrigger,
      searchYoutubeTrigger,
    ]
  );

  useEffect(() => {
    const newRecentResults = getRecentSearchResults().filter(
      (f) => selectedFeed?.id !== f.id
    );
    if (!isEqual(recentResults, newRecentResults))
      setRecentResults(newRecentResults);
  }, [selectedFeed, recentResults, setRecentResults, getRecentSearchResults]);

  useEffect(() => {
    const favoritesUpdate = uniq([...favorites, ...myCustomFeeds]);
    if (!isEqual(favoritesSearchResults, favoritesUpdate)) {
      setFavoritesSearchResults(favoritesUpdate);
    }
  }, [
    query,
    favorites,
    myCustomFeeds,
    favoritesSearchResults,
    setFavoritesSearchResults,
  ]);

  useEffect(() => {
    if (!isEqual(featuredResults, featureFeeds)) {
      setFeaturedResults(featureFeeds);
    }
  }, [query, featureFeeds, featuredResults, setFeaturedResults]);

  useEffect(() => {
    if (searchTypes.includes(SearchResultType.DIRECT)) {
      const setValue = query.length > 0 ? [searchFeedFromTerm(query)] : [];
      if (!isEqual(setValue, directResults)) {
        setDirectResults(setValue);
      }
    }
  }, [searchTypes, query, directResults, setDirectResults]);

  useEffect(() => {
    let results: Array<FC.Group> = [];
    const append = (
      feeds: Array<FC.Group>,
      searchResultType?: SearchResultType
    ) => {
      const cleanedQueryChunks = query
        // want to search for @jeremy cheng and have it work (username
        // is actually @jcheng) and a "@jeremy" chunk won't match that
        .replace(/^@/g, "")
        .toLowerCase()
        .split(" ");
      results = uniqFeeds(
        results.concat(
          options?.exactMatch
            ? feeds.filter((feed) => {
                return (
                  // hacky way to do URL search matches for RSS
                  (feed.url && searchResultType === SearchResultType.RSS) ||
                  // match each word to either title or description
                  // a query of "blue red" should match "Red & Blue"
                  cleanedQueryChunks.every(
                    (chunk) =>
                      (feed.title || "").toLowerCase().indexOf(chunk) > -1 ||
                      // also consider description, only for users
                      // right now (description containrs their
                      // mastodon username, which may not match their
                      // display name / title)
                      (feed.requestType === GroupRequestType.ACCOUNT &&
                        (feed.description || "").toLowerCase().indexOf(chunk) >
                          -1)
                  )
                );
              })
            : feeds
        )
      );
    };
    if (
      searchTypes.includes(SearchResultType.DIRECT) &&
      directResults.length > 0
    ) {
      append(directResults);
    }
    if (
      searchTypes.includes(SearchResultType.RECENT) &&
      recentResults.length > 0
    ) {
      append(recentResults);
    }
    if (
      searchTypes.includes(SearchResultType.FAVORITE) &&
      favoritesSearchResults.length > 0
    ) {
      append(favoritesSearchResults);
    }
    if (
      searchTypes.includes(SearchResultType.FEATURED) &&
      featuredResults.length > 0
    ) {
      append(featuredResults);
    }
    if (
      searchTopicResponse.data ||
      searchMastodonResponse.data ||
      searchRssResponse.data ||
      searchYoutubeResponse.data
    ) {
      if (searchTopicResponse.data) {
        append(searchTopicResponse.data);
      }
      if (searchMastodonResponse.data) {
        append(searchMastodonResponse.data);
      }
      if (searchRssResponse.data) {
        append(searchRssResponse.data, SearchResultType.RSS);
      }
      if (searchYoutubeResponse.data) {
        append(searchYoutubeResponse.data);
      }
    }

    const newResults = groupBy(results, (feed) => {
      if (directResults.includes(feed)) {
        return SearchResultType.DIRECT;
      }
      if (recentResults.includes(feed)) {
        return SearchResultType.RECENT;
      }
      if (favoritesSearchResults.includes(feed)) {
        return SearchResultType.FAVORITE;
      }
      if (featuredResults.includes(feed)) {
        return SearchResultType.FEATURED;
      }
      if (feed.requestType === GroupRequestType.ACCOUNT) {
        return SearchResultType.ACCOUNT;
      }
      if (feed.requestType === GroupRequestType.HASHTAG) {
        return SearchResultType.HASHTAG;
      }
      if (feed.requestType === GroupRequestType.TOPIC) {
        return SearchResultType.TOPIC;
      }
      if (feed.requestType === GroupRequestType.RSS) {
        return SearchResultType.RSS;
      }
      if (feed.requestType === GroupRequestType.YOUTUBE) {
        return SearchResultType.YOUTUBE;
      }
    });

    const limitedResults = reduce(
      newResults,
      (acc, v, k) => {
        const limit = options?.limit || 0;
        const value = limit > 0 ? v.slice(0, limit) : v;
        acc[k as SearchResultType] = value;
        return acc;
      },
      {} as SearchResult
    );
    if (!isEqual(limitedResults, searchResults)) {
      setSearchResults(limitedResults);
    }
  }, [
    query,
    searchTypes,
    searchResults,
    searchTopicResponse,
    searchMastodonResponse,
    favoritesSearchResults,
    featureFeeds,
    featuredResults,
    recentResults,
    directResults,
    options,
    searchRssResponse,
    searchYoutubeResponse,
  ]);

  const searchReset = () => {
    setQuery("");
    setSearchResults({});
  };

  return { searchResults, searchTrigger: search, searchReset };
};
