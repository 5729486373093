import React from "react";
import BasicYoutubeCard, {
  BasicYoutubeCardProps,
} from "~/components/cards/BasicYoutubeCard";

const BasicYoutubeCardHorizontal: React.FC<BasicYoutubeCardProps> = (props) => {
  return <BasicYoutubeCard {...props} isHorizontal />;
};

export default BasicYoutubeCardHorizontal;
