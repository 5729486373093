import { useEffect, useState } from "react";
const useDebounce = <T>(initialValue: T, delay: number) => {
  const [actualValue, setActualValue] = useState<T>(initialValue);
  const [debouncedValue, setDebouncedValue] = useState<T>(initialValue);
  useEffect(() => {
    const debounceId = setTimeout(() => setDebouncedValue(actualValue), delay);
    return () => clearTimeout(debounceId);
  }, [actualValue, delay]);
  return {
    value: actualValue,
    debouncedValue,
    setValue: setActualValue,
  };
};

export default useDebounce;
