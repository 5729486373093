import DevPlayground from "~/components/pages/DevPlayground";

const devPlayground: FC.PagePresentationStrategy = ({ items }) => {
  if (items.length > 0)
    return {
      usedItems: items.slice(0, 1),
      Component: DevPlayground,
    };
  return null;
};

export default devPlayground;
