import React from "react";

import Clipboard from "@react-native-clipboard/clipboard";

import OverflowMenuButton from "~/components/elements/OverflowMenuButton";

type CopyLinkProps = {
  item: FC.Item;
};

const CopyLink: React.FC<CopyLinkProps> = ({ item }) => {
  const copyToClipboard = () => {
    Clipboard.setString(item.targetUrl);
  };

  return (
    <OverflowMenuButton
      title={"Copy link to post"}
      icon={"content-copy"}
      onPress={() => copyToClipboard()}
    />
  );
};

export default CopyLink;
