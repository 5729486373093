import React, { memo, useEffect } from "react";
import { useVerifyCredentialsQuery } from "~/api";
import { setCredentials, setIsAuthenticated } from "~/concepts/auth";
import { useAppDispatch } from "~/hooks";

const VerifyCredentials: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const dispatch = useAppDispatch();
  const { data, isError, isSuccess } = useVerifyCredentialsQuery();
  useEffect(() => {
    if (isError) {
      dispatch(setIsAuthenticated(false));
      return;
    }
    if (isSuccess) {
      dispatch(setIsAuthenticated(true));
      dispatch(setCredentials(data));
    }
  }, [dispatch, data, isError, isSuccess]);
  return children;
};

export default memo(VerifyCredentials);
