import React, { useEffect, useState, useRef } from "react";
import {
  FlatList,
  NativeScrollEvent,
  NativeSyntheticEvent,
  Pressable,
} from "react-native";
import { Button, Column, Row, Text, SearchBar } from "~/components/elements/";
import { addGroups } from "~/concepts/groups";
import { hashtagGroupFromHashtag } from "~/utils/groups";
import { useAppDispatch, useAppSelector } from "~/hooks";
import { useTheme, makeStyles } from "@rneui/themed";

import { createStyleSheet, SPACING } from "~/styles";
import { usePreferences } from "~/hooks/usePreferences";
import { useToast } from "react-native-toast-notifications";
import { selectIsAuthenticated } from "~/concepts/auth";
import isDevelopment from "~/lib/isDevelopment";
import { useNavigate } from "~/routing";
import { GroupRequestType } from "~/enums";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import DragList, { DragListRenderItemInfo } from "react-native-draglist";
import { useSelectAllGroupsWithPreferences } from "~/hooks/useSelectGroupsWithPreferences";
import difference from "lodash-es/difference";
import isEqual from "lodash-es/isEqual";
import EditFeedCustomGroupControls from "~/components/EditFeedCustomGroupControls";
import { useFeedNavigation } from "~/hooks/useFeedNavigation";
import FeedFavoriteButton from "~/buttons/FeedFavoriteButton";
import { selectApplicationMode } from "~/concepts/application";

const EditFeeds: React.FC<{ onSave?: () => void }> = ({ onSave }) => {
  const [preferencesDraft, setPreferencesDraft] = useState<
    Record<string, boolean>
  >({});
  const [hashtagInputValue, setHashtagInputValue] = useState("");
  const clearInput = () => setHashtagInputValue("");
  const [preferences, setPreferences] = usePreferences();
  const groups = useSelectAllGroupsWithPreferences();
  const [data, setData] = useState<FC.Group[]>(groups);
  const { theme } = useTheme();
  const styles = useStyles();
  const toast = useToast();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const applicationMode = useAppSelector(selectApplicationMode);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [longPressed, setLongPressed] = useState(false);
  const [scrollOffset, setScrollOffset] = useState(0);
  const [remount, setRemount] = useState(false);
  const flatListRef = useRef<FlatList<FC.Group>>(null);
  const { navigateToGroup } = useFeedNavigation();

  useEffect(() => {
    if (remount) {
      setRemount(false);
    }
  }, [remount]);

  // deal with groups added or removed
  useEffect(() => {
    const added = difference(groups, data);
    const removed = difference(data, groups);
    const newData = data.filter((g) => !removed.includes(g)).concat(added);
    if (!isEqual(newData, data)) {
      setData(newData);
    }
  }, [data, groups]);

  useEffect(() => {
    setPreferencesDraft(
      data.reduce<Record<string, boolean>>((acc, group) => {
        if (preferences.feeds.excluded.includes(group.id)) {
          acc[group.id] = false;
        } else {
          acc[group.id] = true;
        }
        return acc;
      }, {})
    );
  }, [data, preferences]);

  if (!isAuthenticated) {
    return null;
  }

  const onChangeAll = (value: boolean) => {
    const newPreferencesDraft = { ...preferencesDraft };
    Object.entries(preferencesDraft).forEach(([key, _]) => {
      newPreferencesDraft[key] = value;
    });
    setPreferencesDraft(newPreferencesDraft);
  };

  const save = () => {
    const feeds = Object.keys(preferencesDraft).reduce<FC.Preferences["feeds"]>(
      (acc, key) => {
        if (preferencesDraft[key]) {
          acc.saved.push(key);
        } else {
          acc.excluded.push(key);
        }
        acc.order.push(key);
        return acc;
      },
      { saved: [], excluded: [], order: [] }
    );
    setPreferences({
      ...preferences,
      feeds,
    });
    onSave?.();
    toast.show("Updated feed preferences", {
      type: "success",
    });
  };

  const addHashtag = () => {
    const hashtag = hashtagInputValue.trim().replace(/^#/, "");
    if (hashtag.length === 0) {
      return;
    }
    dispatch(addGroups([hashtagGroupFromHashtag(hashtag, applicationMode)]));
    setHashtagInputValue("");
  };

  const renderFeedRowItem = (info: DragListRenderItemInfo<FC.Group>) => {
    const { item: group, onDragStart, onDragEnd, isActive } = info;

    return (
      <Row flex noGap style={styles.feedRow} pointerEvents={"auto"}>
        <Pressable
          key={group.id}
          onPressIn={onDragStart}
          onPressOut={() => {
            if (longPressed) {
              setLongPressed(false);
              onDragEnd();
              return;
            }
          }}
          onLongPress={() => {
            setLongPressed(true);
          }}
          disabled={isActive}
        >
          <Icon
            name={"drag"}
            size={24}
            solid={true}
            color={theme.colors.primary}
          />
        </Pressable>
        <Text style={[styles.flex1, styles.noUserSelect]}>
          <Button
            type="clear"
            onPress={() => {
              navigateToGroup(group);
            }}
          >
            {group.title}
          </Button>
        </Text>
        {group.requestType === GroupRequestType.CUSTOM_FEED && (
          <EditFeedCustomGroupControls group={group} />
        )}
        <FeedFavoriteButton feed={group} showTitle />
      </Row>
    );
  };

  const onReordered = async (fromIndex: number, toIndex: number) => {
    const copy = [...data]; // Don't modify react data in-place
    const removed = copy.splice(fromIndex, 1);

    copy.splice(toIndex, 0, removed[0]); // Now insert at the new pos
    setData(copy);
    setRemount(true);
  };

  return (
    <Column flex pointerEvents="box-none" style={styles.editFeedsForm}>
      <Row style={[styles.justifyCenter, styles.alignCenter]}>
        <Button
          title="Create Custom Feed"
          onPress={() => navigate("/custom-feed/new")}
          color={theme.colors.controls}
        />

        <SearchBar
          containerStyle={styles.inputContainer}
          onChangeText={setHashtagInputValue}
          onSubmitEditing={addHashtag}
          onClear={clearInput}
          placeholder="Add Hashtag (eg: frogs)"
          placeholderTextColor={theme.colors.grey3}
          value={hashtagInputValue}
        />
        <Button
          title="Add"
          onPress={addHashtag}
          disabled={hashtagInputValue.length === 0}
        />
      </Row>
      <Row>
        {isDevelopment() && (
          <>
            <Button
              title="Activate All"
              onPress={() => onChangeAll(true)}
              color={theme.colors.controls}
            />
            <Button
              title="Deactivate All"
              onPress={() => onChangeAll(false)}
              type={"outline"}
              color={theme.colors.controls}
            />
          </>
        )}
      </Row>
      <Row flex>
        {!remount && (
          <DragList
            ref={flatListRef}
            data={data}
            keyExtractor={(group: FC.Group) => group.id}
            onReordered={onReordered}
            renderItem={renderFeedRowItem}
            containerStyle={styles.switchList}
            scrollEventThrottle={16}
            onLayout={() => {
              scrollOffset > 0 &&
                setTimeout(() => {
                  flatListRef.current?.scrollToOffset({
                    offset: scrollOffset,
                    animated: false,
                  });
                }, 10);
            }}
            onScroll={(e: NativeSyntheticEvent<NativeScrollEvent>) => {
              setScrollOffset(e.nativeEvent.contentOffset.y);
            }}
          />
        )}
      </Row>
      <Row style={styles.bottomControls}>
        <Button title="Save" onPress={save} />
      </Row>
    </Column>
  );
};

const useStyles = makeStyles((theme) =>
  createStyleSheet({
    editFeedsForm: { padding: SPACING.BASE12X },
    switchList: {
      flex: 1,
      userSelect: "none",
      width: "100%",
    },
    feedRow: {
      width: "100%",
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: theme.colors.grey5,
      borderRadius: SPACING.BASE,
      padding: SPACING.BASE,
      marginBottom: SPACING.SMALL,
      alignItems: "center",
      userSelect: "none",
    },
    input: {
      color: theme.colors.primary,
      backgroundColor: theme.colors.primaryReverse50,
      fontSize: 16,
      borderWidth: 1,
    },
    inputError: {
      margin: 0,
    },
    inputContainer: {
      flex: 1,
      padding: 0,
      width: "auto",
    },
  })
);

export default EditFeeds;
