import VigetThreeUpColumn from "~/components/pages/VigetThreeUpColumn";
import { isArticleItem } from "~/predicates";

const vigetThreeUpArticles: FC.PagePresentationStrategy = ({ items }) => {
  const articleItems = items.filter((i) => isArticleItem(i));

  if (articleItems.length >= 3) {
    return {
      usedItems: articleItems.slice(0, 3),
      Component: VigetThreeUpColumn,
    };
  }
  return null;
};

export default vigetThreeUpArticles;
