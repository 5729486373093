import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import {
  ActivityIndicator,
  FlatList,
  LayoutChangeEvent,
  SafeAreaView,
  ViewabilityConfig,
  ViewabilityConfigCallbackPairs,
  ViewToken,
} from "react-native";
import { Button, Column, Row } from "~/components/elements";
import { createStyleSheet, SPACING } from "~/styles";
import { makeStyles, useTheme } from "@rneui/themed";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { PaginationImpulse } from "~/enums";
import CarouselKeyboardNavListener from "~/components/CarouselKeyboardNavListener";
import { isPhoneSelector } from "~/concepts/application";
import { useAppSelector } from "~/hooks";

const CAROUSEL_NAV_BUTTON_SIZE_PHONE = SPACING.LARGE;
const CAROUSEL_NAV_BUTTON_SIZE = SPACING.BASE4X;

export type RenderPageItemPropsType = {
  item: any;
  index: number;
  extraData?: any;
};
export enum PageControlStyleType {
  THUMBNAIL = "thumbnail",
  NUMBER = "number",
}
type SimpleCarouselProps = {
  vertical: boolean;
  pageItems: Array<any>;
  renderPageItem: (props: RenderPageItemPropsType) => React.ReactElement;
  showControls?: boolean;
  showNextPrev?: boolean;
  hideBackground?: boolean;
  onIndexChange?: (index: number) => void;
  currentIndex: number;
};

const SimpleCarousel: React.FC<SimpleCarouselProps> = ({
  currentIndex = 0,
  pageItems,
  renderPageItem,
  onIndexChange,
  vertical = true,
}) => {
  const { theme } = useTheme();
  const isPhone = useAppSelector(isPhoneSelector);
  const styles = useStyles({ isPhone });

  const [contentHeight, setContentHeight] = useState(0);
  const [contentWidth, setContentWidth] = useState(0);

  const numPages = pageItems.length;

  const ref = React.useRef<FlatList<any>>(null);
  const pageSize = vertical ? contentHeight : contentWidth;

  const overrideIndex = useCallback(
    (i: number) => {
      let targetIndex = i;
      // moving left and right
      if (i < 0) {
        // move to start to end
        targetIndex = numPages - 1;
      } else if (i >= numPages) {
        // move to end to start
        targetIndex = 0;
      }

      // otherwise just go to the requested index
      ref.current?.scrollToIndex({
        index: targetIndex,
        animated: true,
      });
    },
    [numPages]
  );
  useEffect(() => {
    overrideIndex(currentIndex);
  }, [overrideIndex, currentIndex]);

  const onViewableItemsChanged = ({
    viewableItems,
  }: {
    viewableItems: Array<ViewToken>;
  }) => {
    const currentViewedItems = viewableItems.map((v) => v.index);

    if (currentViewedItems && currentViewedItems.length === 1) {
      const scrolledToIndex = currentViewedItems[0];
      if (scrolledToIndex != null) {
        onIndexChange && onIndexChange(scrolledToIndex);
      }
    }
  };

  const viewabilityConfig: ViewabilityConfig = {
    itemVisiblePercentThreshold: 95,
    minimumViewTime: 100,
  };
  const viewabilityConfigCallbackPairs = useRef<ViewabilityConfigCallbackPairs>(
    [{ onViewableItemsChanged, viewabilityConfig }]
  );

  /** For whatever reason scrolling is weird when jumping to new items.
   * Explicitely setting the size of the flatlist itself seems to help
   **/
  const flatListStyle = { width: contentWidth, height: contentHeight };
  return (
    <Row
      flex
      noGap
      style={[styles.alignCenter, styles.justifyCenter]}
      pointerEvents={"box-none"}
    >
      {numPages > 1 && (
        <Button onPress={() => overrideIndex(currentIndex - 1)} type={"clear"}>
          <Icon
            name={"chevron-left"}
            size={CAROUSEL_NAV_BUTTON_SIZE}
            solid={true}
            color={theme.colors.primaryReverse}
            style={styles.controlButton}
          />
        </Button>
      )}
      <Column
        flex
        noGap
        style={styles.carouselWrapper}
        pointerEvents="box-none"
      >
        <SafeAreaView
          style={styles.flex1}
          onLayout={(event: LayoutChangeEvent) => {
            const { height, width } = event.nativeEvent.layout;
            setContentHeight(height);
            setContentWidth(width);
          }}
          pointerEvents="box-none"
        >
          {pageSize === 0 ? (
            <ActivityIndicator />
          ) : (
            <FlatList
              initialScrollIndex={currentIndex}
              ref={ref}
              data={pageItems}
              renderItem={(renderItemProps) =>
                renderPageItem({
                  ...renderItemProps,
                  extraData: { contentHeight, contentWidth },
                })
              }
              horizontal={!vertical}
              style={[styles.galleryCarousel, flatListStyle]}
              showsVerticalScrollIndicator={vertical}
              showsHorizontalScrollIndicator={vertical}
              pagingEnabled={true}
              scrollEventThrottle={16}
              viewabilityConfigCallbackPairs={
                viewabilityConfigCallbackPairs.current
              }
              decelerationRate={"fast"}
              keyExtractor={(item, index) => `${item?.title}_${index}`}
              extraData={{ contentHeight, contentWidth }}
              getItemLayout={(_data, index: number) => ({
                length: pageSize,
                offset: pageSize * index,
                index: index,
              })}
              pointerEvents="none"
            />
          )}
        </SafeAreaView>
      </Column>
      {numPages > 1 && (
        <Button onPress={() => overrideIndex(currentIndex + 1)} type={"clear"}>
          <Icon
            name={"chevron-right"}
            size={CAROUSEL_NAV_BUTTON_SIZE}
            solid={true}
            color={theme.colors.primaryReverse}
            raised
            style={styles.controlButton}
          />
        </Button>
      )}
      {numPages > 1 && (
        <CarouselKeyboardNavListener
          handlePaginationImpulse={handlePaginationImpulse(
            overrideIndex,
            currentIndex
          )}
        />
      )}
    </Row>
  );
};

const handlePaginationImpulse =
  (overrideIndex: (i: number) => void, currentIndex: number) =>
  (paginationImpulse: PaginationImpulse) => {
    if (paginationImpulse === PaginationImpulse.RIGHT) {
      overrideIndex(currentIndex + 1);
    }
    if (paginationImpulse === PaginationImpulse.LEFT) {
      overrideIndex(currentIndex - 1);
    }
  };

const useStyles = makeStyles((_, { isPhone }: { isPhone: boolean }) =>
  createStyleSheet({
    carouselWrapper: {
      justifyContent: "center",
      width: "100%",
      height: "100%",
    },
    galleryCarousel: {
      flex: 1,
      width: "100%",
      height: "100%",
      overflow: "hidden",
    },
    controlButton: {
      margin: SPACING.SMALL,
      minWidth: isPhone
        ? CAROUSEL_NAV_BUTTON_SIZE_PHONE
        : CAROUSEL_NAV_BUTTON_SIZE,
      minHeight: CAROUSEL_NAV_BUTTON_SIZE,
    },
  })
);

export default memo(SimpleCarousel);
