import React, { memo } from "react";
import {
  Text as RNEText,
  TextProps as RNETextProps,
  useTheme,
  Colors,
  makeStyles,
} from "@rneui/themed";
import { ColorValue, StyleProp, TextStyle } from "react-native";
import { createStyleSheet, typographyStyles } from "~/styles";
import { TextAttributionStyle, TextSize } from "~/enums";
import { baseFontSize, headerFontSize } from "~/utils/fonts";

const DEFAULT_FONT_SIZE = TextSize.XS;

export const GALLERY_FONT_SIZE = 16;
export const getFontSize = (size?: TextSize) => {
  return size ? baseFontSize[size] : baseFontSize[DEFAULT_FONT_SIZE];
};

export const getHeaderFontSize = (size?: TextSize) => {
  return size ? headerFontSize[size] : headerFontSize[DEFAULT_FONT_SIZE];
};

export interface TextProps extends RNETextProps {
  children?: React.ReactNode;
  style?: StyleProp<TextStyle>;
  inverse?: boolean;
  overlay?: boolean;
  error?: boolean;
  color?: keyof Colors;
  size?: TextSize | number;
  header?: boolean;
  attribution?: TextAttributionStyle;
}

const Text: React.FC<TextProps> = ({
  children,
  style,
  inverse,
  overlay,
  error,
  color,
  size,
  header,
  attribution,
  ...restProps
}) => {
  const { theme } = useTheme();
  const styles = useStyles({ size, overlay });

  let themeColorKey = color || "primary";
  if (inverse) themeColorKey = "primaryReverse";
  if (overlay) themeColorKey = attribution ? "overlay75" : "overlay";
  if (error) themeColorKey = "error";

  const colorStyle = { color: theme.colors[themeColorKey] as ColorValue };

  const fontSizeFunction = header ? getHeaderFontSize : getFontSize;
  let baseStyle: StyleProp<TextStyle> = header
    ? styles.baseHeader
    : styles.baseText;
  const fontSizeStyle = !!size && {
    fontSize: typeof size === "number" ? size : fontSizeFunction(size),
  };
  switch (attribution) {
    case TextAttributionStyle.SPECIAL:
      baseStyle = styles.attributionSpecial;
      break;
    case TextAttributionStyle.STANDARD_HEADLINE:
      baseStyle = styles.attributionStandardHeadline;
      break;
    case TextAttributionStyle.STANDARD_SUBTEXT:
      baseStyle = styles.attributionStandardSubtext;
      break;
    case TextAttributionStyle.HOME_TILE:
      baseStyle = styles.attributionHomeTile;
      break;
  }
  return (
    <RNEText
      style={[colorStyle, baseStyle, fontSizeStyle, style]}
      {...restProps}
    >
      {children}
    </RNEText>
  );
};

const useStyles = makeStyles(
  (
    theme,
    { size, overlay }: { size?: TextSize | number; overlay?: boolean }
  ) => {
    return createStyleSheet({
      baseText: {
        ...typographyStyles.base,
        lineHeight: "140%",
      },
      baseHeader: {
        ...typographyStyles.header,
      },
      attributionSpecial: {
        fontFamily: "Roboto_500Medium",
        fontWeight: "medium",
        fontSize: size || 14,
        color: overlay ? theme.colors.overlay75 : theme.colors.primary70,
      },
      attributionStandardHeadline: {
        fontFamily: "Roboto_500Medium",
        fontWeight: "semibold",
        fontSize: size || 14,
      },
      attributionStandardSubtext: {
        fontFamily: "Roboto_400Regular",
        fontWeight: "normal",
        fontSize: size || 12,
        color: overlay ? theme.colors.overlay75 : theme.colors.primary70,
      },
      attributionHomeTile: {
        fontFamily: "Roboto_500Medium",
        fontWeight: "semibold",
        fontSize: size || 12,
        color: overlay ? theme.colors.overlay75 : theme.colors.primary70,
      },
    });
  }
);

export default memo(Text);
