import React, { memo, useCallback, useEffect } from "react";
import Group from "~/components/Group";
import { useSelectAllGroupsWithPreferences } from "~/hooks/useSelectGroupsWithPreferences";
import { usePathChangeMonitor } from "~/hooks/usePathChangeMonitor";
import { usePopstateMonitor } from "~/hooks/usePopstateMonitor";
import { useLoadMissingFeed } from "~/hooks/useLoadMissingFeed";
import { useParams } from "~/routing";
import {
  isCustomFeedId,
  selectSelectedFeed,
  setFeedLoading,
  setSelectedFeed,
} from "~/concepts/groups";
import { useAppDispatch, useAppSelector } from "~/hooks";
import { selectIsAuthenticated } from "~/concepts/auth";
import ItemFocusDrawer from "~/components/ItemFocusDrawer";
import Loading from "~/components/Loading";
import { customFeedIdFromRouteFeedId } from "~/utils/id";
import { Row } from "~/components/elements";
import { makeStyles } from "@rneui/themed";
import { createStyleSheet } from "~/styles";

const Feed: React.FC<{ contentWidth: number; contentHeight: number }> = ({
  contentWidth,
  contentHeight,
}) => {
  const styles = useStyles();
  const feeds = useSelectAllGroupsWithPreferences();
  const loadMissingFeed = useLoadMissingFeed();

  usePathChangeMonitor();
  usePopstateMonitor();
  const dispatch = useAppDispatch();
  const params = useParams();
  const selectedFeed = useAppSelector(selectSelectedFeed);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const paramFeedId = encodeURIComponent(params.feedId || "");

  const feedFromRouteFeedId = useCallback(
    (routeFeedId: string) => {
      return isCustomFeedId(routeFeedId)
        ? feeds.find(
            (f) => f.requestKey === customFeedIdFromRouteFeedId(routeFeedId)
          )
        : feeds.find((f) => f.id === routeFeedId);
    },
    [feeds]
  );

  useEffect(() => {
    let selected: FC.Group | undefined;
    selected = feedFromRouteFeedId(paramFeedId);
    if (selected && selected !== selectedFeed) {
      dispatch(setFeedLoading(false));
      dispatch(setSelectedFeed(selected.id));
    }
    if (!selected && paramFeedId) {
      dispatch(setFeedLoading(true));
      loadMissingFeed(paramFeedId);
    }
  }, [
    feedFromRouteFeedId,
    isAuthenticated,
    loadMissingFeed,
    dispatch,
    paramFeedId,
    selectedFeed,
  ]);

  if (!selectedFeed || selectedFeed !== feedFromRouteFeedId(paramFeedId)) {
    return (
      <Row style={[styles.alignCenter]}>
        <Loading />
      </Row>
    );
  }
  return (
    <>
      <Group
        group={selectedFeed}
        contentHeight={contentHeight}
        contentWidth={contentWidth}
      />
      <ItemFocusDrawer />
    </>
  );
};
const useStyles = makeStyles(() => createStyleSheet({}));

export default memo(Feed);
