import TopicArticleGrouping from "~/components/pages/TopicArticleGrouping";
import { GroupingType } from "~/enums";

const topicArticleGrouping: FC.PagePresentationStrategy = ({ items }) => {
  const itemGrouping = items.find(
    (item) =>
      item.groupingType === GroupingType.GROUP && item.children.length > 0
  );
  if (itemGrouping) {
    const result: FC.PageStrategyResult<{ topic: FC.Topic }> = {
      usedItems: [itemGrouping],
      Component: TopicArticleGrouping,
      repeat: true,
    };
    return result;
  }
  return null;
};

export default topicArticleGrouping;
