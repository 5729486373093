import React, { memo } from "react";
import { useGetAuthorFromFeed } from "~/hooks/useGetAuthor";
import { GroupRequestType } from "~/enums";
import DisplayName, {
  DisplayNameProps,
} from "~/components/elements/DisplayName";
import { Text } from "~/components/elements";
import { StyleProp, TextStyle } from "react-native";

type FeedDisplayNameProps = {
  feed: FC.Group;
  style?: StyleProp<TextStyle>;
} & Omit<DisplayNameProps, "author" | "displayNameStyle">;

// TODO: abstract this to something more intelligent.
// Displayname should probably be generic (not depend on Author), and this wrapping component
// should abstract the emojis and then pass it in as needed.
const FeedDisplayName: React.FC<FeedDisplayNameProps> = ({
  feed,
  style,
  ...restProps
}) => {
  const author = useGetAuthorFromFeed(feed);

  if (feed.requestType !== GroupRequestType.ACCOUNT || !author)
    return <Text style={style}>{feed.title}</Text>;
  return (
    <DisplayName {...restProps} displayNameStyle={style} author={author} />
  );
};

export default memo(FeedDisplayName);
