import React from "react";
import { Pressable, StyleProp, View } from "react-native";

import { createStyleSheet, SPACING } from "~/styles";

import { makeStyles } from "@rneui/themed";
import { Column, Row, Text, ViewProps } from "~/components/elements";
import Content from "~/components/Content";
import Author from "~/components/elements/Author";
import { usePreferences } from "~/hooks/usePreferences";
import { TextSize, CardSize } from "~/enums";

type ContentWarningFilterProps = {
  item: FC.Item;
  children: React.ReactElement;
  style?: StyleProp<ViewStyle>;
  cardSize?: CardSize;
} & ViewProps;
const ContentWarningFilter: React.FC<ContentWarningFilterProps> = ({
  children,
  style,
  item,
  cardSize,
  ...restProps
}) => {
  const styles = useStyles({ cardSize });
  const [showSensitive, setShowSensitive] = React.useState(false);
  const [preferences] = usePreferences();

  const isBlurred =
    preferences.showContentWarnings && !showSensitive && item.sensitive;
  const renderItem = item;
  const WrapperComponent = cardSize === CardSize.SMALL ? Row : Column;
  return (
    <View style={[styles.contentWarningWrapper, style]} {...restProps}>
      <View style={[styles.contentWarningLayer, isBlurred && styles.blur]}>
        {children}
      </View>
      {isBlurred && (
        <Pressable
          onPress={() => setShowSensitive(true)}
          style={styles.unBlurButton}
        >
          <WrapperComponent
            gap={cardSize === CardSize.SMALL ? SPACING.SMALL : SPACING.BASE}
            style={styles.spoilerContent}
          >
            <Column
              flex
              gap={cardSize === CardSize.SMALL ? SPACING.SMALL : SPACING.BASE}
              style={styles.alignStart}
            >
              <Author item={renderItem} author={item.author} />
              <Content item={item} numberOfLines={3} />
            </Column>
            <Text style={[styles.spoilerTextPill]}>
              <Text header size={TextSize.M} style={styles.contentWarningLabel}>
                Content Warning
              </Text>
              {item.spoilerText && `:\n ${item.spoilerText}`}
            </Text>
          </WrapperComponent>
        </Pressable>
      )}
    </View>
  );
};

const useStyles = makeStyles((theme, { cardSize }: { cardSize?: CardSize }) =>
  createStyleSheet({
    contentWarningWrapper: { overflow: "hidden" },
    contentWarningLayer: { flex: 1 },
    blurLayer: {
      zIndex: 101,
      position: "absolute",
      width: "100%",
      height: "100%",
    },
    blur: {
      filter: `blur(${SPACING.BASE8X}px)`,
    },
    unBlurButton: {
      flex: 1,
      zIndex: 102,
      position: "absolute",
      backgroundColor: "#00000080",
      width: "100%",
      height: "100%",
      alignContent: "center",
      alignItems: "center",
      justifyContent: "center",
      padding: SPACING.BASE3X,
    },
    spoilerContent: {
      width: "100%",
      textAlign: "center",
      backgroundColor: theme.colors.background,
      padding: SPACING.LARGE,
      borderRadius: SPACING.BASE,
      overflow: "hidden",
    },
    contentPreviewText: { height: "30%", maxHeight: "30%" },

    contentWarningLabel: {
      color: theme.colors.primaryReverse,
    },
    spoilerTextPill: {
      alignSelf: "center",
      ...(cardSize !== CardSize.SMALL && { width: "100%" }),
      textAlign: "center",
      color: theme.colors.primaryReverse,
      backgroundColor: theme.colors.backgroundReverse,
      padding: cardSize === CardSize.SMALL ? SPACING.BASE : SPACING.LARGE,
      margin: cardSize === CardSize.SMALL ? SPACING.BASE : SPACING.LARGE,
      borderRadius: SPACING.BASE,
    },
  })
);

export default ContentWarningFilter;
