import { CardSize, TextSize } from "~/enums";

export const baseFontSize: Record<TextSize, number> = {
  [TextSize.XXS]: 12,
  [TextSize.XS]: 14,
  [TextSize.S]: 18,
  [TextSize.M]: 20,
  [TextSize.L]: 24,
  [TextSize.XL]: 32,
  [TextSize.XXL]: 36,
};

export const headerFontSize: Record<TextSize, number> = {
  [TextSize.XXS]: 14,
  [TextSize.XS]: 16,
  [TextSize.S]: 18,
  [TextSize.M]: 20,
  [TextSize.L]: 24,
  [TextSize.XL]: 32,
  [TextSize.XXL]: 36,
};

export const cardSizeToTextSize = (cardSize?: CardSize) => {
  switch (cardSize) {
    case CardSize.SMALL:
      return TextSize.XS;
    case CardSize.MEDIUM:
      return TextSize.S;
    case CardSize.LARGE:
      return TextSize.M;
    case CardSize.XLARGE:
      return TextSize.L;
    default:
      return TextSize.XS;
  }
};

export const cardSizeToHeaderSize = (cardSize?: CardSize) => {
  switch (cardSize) {
    case CardSize.SMALL:
      return TextSize.XXS;
    case CardSize.MEDIUM:
      return TextSize.XS;
    case CardSize.LARGE:
      return TextSize.S;
    case CardSize.XLARGE:
      return TextSize.M;
    default:
      return TextSize.XS;
  }
};
