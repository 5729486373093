import React, { memo } from "react";
import { isArticleItem, isSocialArticleItem } from "~/predicates";
import SocialArticleRollup from "~/components/pages/SocialArticleRollup";

const SocialArticleRollupNew: FC.PageStrategyResult["Component"] = ({
  items,
  contentWidth,
  contentHeight,
}) => {
  const articlePreviewItem = items[0].reblog || items[0];
  const articleItems = articlePreviewItem.children.filter(isArticleItem);
  const socialArticleItems =
    articlePreviewItem.children.filter(isSocialArticleItem);

  return (
    <SocialArticleRollup
      contentWidth={contentWidth}
      contentHeight={contentHeight}
      previewItem={articlePreviewItem}
      articleItems={articleItems}
      socialArticleItems={socialArticleItems}
    />
  );
};

export default memo(SocialArticleRollupNew);
