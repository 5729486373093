import VigetGalleryRow from "~/components/pages/VigetGalleryRow";
import { isYoutubeShort } from "~/predicates";

const vigetGalleryAVShortsThreeUp: FC.PagePresentationStrategy = ({
  items,
}) => {
  const supportedTypes = items.filter(isYoutubeShort);

  if (supportedTypes.length >= 3) {
    return {
      usedItems: supportedTypes.slice(0, 3),
      Component: VigetGalleryRow,
      // repeat: true,
    };
  }
  return null;
};

export default vigetGalleryAVShortsThreeUp;
