import React, { memo, useContext } from "react";

import { selectCredentials } from "~/concepts/auth";
import { useAppSelector } from "~/hooks";
import { useGetInstanceItemQuery } from "~/api";
import { CollapsibleAnimatedView, Column, Row } from "~/components/elements";

import { createStyleSheet, LAYOUT, SPACING } from "~/styles";
import OverflowMenu from "~/components/elements/OverflowMenu";
import SecondaryActions from "~/components/CardActions/SecondaryActions";
import PrimaryActionButtons from "~/components/CardActions/PrimaryActionButtons";
import Reply from "~/components/CardActions/Reply";
import { CardHoveredContext } from "~/components/cards/BaseCard";
import { isPhoneSelector } from "~/concepts/application";
import { makeStyles } from "@rneui/themed";
import { BackgroundColorContext } from "~/contexts/BackgroundColorContext";

export type CardActionsProps = {
  item: FC.Item;
  overlay?: boolean;
  fromThread?: boolean;
  error?: boolean;
  isHovered?: boolean;
  disabled?: boolean;
};

const ThreadCardActions: React.FC<CardActionsProps> = ({ item, overlay }) => {
  const styles = useStyles();
  return (
    <Row style={[styles.actionButtonsWrapper, styles.threadActions]}>
      <Reply item={item} overlay={overlay} showCount />
      <PrimaryActionButtons
        item={item}
        fromThread={true}
        showButtonLabels
        showCount
      />
      <Row flex style={styles.justifyCenter}>
        <OverflowMenu item={item} overlay={overlay}>
          <SecondaryActions item={item} />
        </OverflowMenu>
      </Row>
    </Row>
  );
};

const OverflowMenuActions: React.FC<CardActionsProps> = ({
  item,
  error,
  overlay,
  disabled,
}) => {
  const styles = useStyles();
  return (
    <Row style={[styles.actionButtonsWrapper]}>
      {item.capabilities.canReply && (
        <Reply
          item={item}
          disabled={disabled}
          overlay={overlay}
          showButtonLabels
          error={error}
        />
      )}
      <PrimaryActionButtons
        item={item}
        showButtonLabels
        error={error}
        overlay={overlay}
      />
      <SecondaryActions item={item} />
    </Row>
  );
};

const FetchStatusContextWrapper: React.FC<{
  children: (props: {
    item: FC.Item;
    instanceItem: FC.Item | undefined;
    error: boolean;
  }) => React.ReactElement;
  item: FC.Item;
}> = ({ children, item }) => {
  const { data, isError } = useGetInstanceItemQuery(item.source);
  return children({ item, instanceItem: data, error: isError });
};

const CardActions: React.FC<CardActionsProps> = (props) => {
  const cardIsHovered = useContext(CardHoveredContext);
  const backgroundColor = useContext(BackgroundColorContext);
  const styles = useStyles({ backgroundColor });
  const authenticated = useAppSelector(selectCredentials);
  const isPhone = useAppSelector(isPhoneSelector);

  const disabled = !authenticated;
  const { overlay, error, item, fromThread } = props;

  if (!item?.capabilities) return null;
  if (fromThread) {
    // Thread does the heavy lifting to figure out correct item
    return <ThreadCardActions {...props} />;
  }

  const ReplyButton = props.item.capabilities.canReply && (
    <Column style={[styles.horizontalFlex, styles.alignCenter]}>
      <Reply
        item={props.item}
        disabled={disabled}
        overlay={overlay}
        error={error}
        showCount
      />
    </Column>
  );

  return (
    <>
      <Row
        style={{
          height: LAYOUT.AUTHOR_ACTION_HEIGHT,
          width: SPACING.XSMALL,
        }}
      >
        <CollapsibleAnimatedView
          style={[
            styles.horizontalFlex,
            styles.alignCenter,
            styles.mainActionsBar,
          ]}
          flexRange={[0, 1]}
          nullOnHidden={true}
          show={cardIsHovered || isPhone}
          fade
        >
          <Row
            flexShrink
            noGap
            style={[styles.shadowWrapper, styles.alignCenter]}
          >
            {!isPhone &&
              (authenticated && item.needsInstanceContext ? (
                <FetchStatusContextWrapper item={item}>
                  {({ instanceItem, error: e }) => (
                    <PrimaryActionButtons
                      item={instanceItem}
                      error={e}
                      overlay={overlay}
                      showCount
                    />
                  )}
                </FetchStatusContextWrapper>
              ) : (
                <PrimaryActionButtons item={props.item} overlay={overlay} />
              ))}
            {!item?.counts.replies && ReplyButton}
          </Row>
        </CollapsibleAnimatedView>
      </Row>
      {!!item?.counts.replies && ReplyButton}
      <OverflowMenu {...props}>
        {authenticated && item.needsInstanceContext ? (
          <FetchStatusContextWrapper item={props.item}>
            {({ instanceItem, error: e }) => (
              <OverflowMenuActions
                item={instanceItem || props.item}
                error={e}
                overlay={false}
                disabled={disabled}
              />
            )}
          </FetchStatusContextWrapper>
        ) : (
          <OverflowMenuActions {...props} item={props.item} overlay={false} />
        )}
      </OverflowMenu>
    </>
  );
};

const useStyles = makeStyles(
  (_theme, { backgroundColor }: { backgroundColor: string }) => {
    return createStyleSheet({
      shadowWrapper: {
        justifySelf: "flex-end",
        height: "100%",
        marginLeft: SPACING.BASE6X,
        shadowOffset: { width: -24, height: 0 },
        shadowRadius: 24,
        shadowColor: backgroundColor,
        backgroundColor,
      },

      actionButtonsWrapper: {
        gap: 0,
        alignItems: "flex-start",
        flexDirection: "column",
      },
      threadActions: {
        gap: SPACING.MEDIUM,
        alignItems: "center",
        flexDirection: "row",
        marginTop: SPACING.LARGE,
      },
      cardActionWrapper: {
        marginLeft: "auto",
        alignItems: "center",
        justifyContent: "flex-end",
        minWidth: SPACING.BASE6X,
        height: "100%",
        zIndex: 1,
        paddingLeft: 50,
      },
      overflowMenuPlaceholder: { width: 36 },
      mainActionsBar: {
        height: "100%",
        position: "absolute",
        right: 0,
        overflow: "hidden",
      },
      replyButtonWrapper: {
        position: "absolute",
        right: 36,
        top: 0,
        zIndex: 100,
      },
    });
  }
);

export default memo(CardActions);
