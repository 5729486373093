import React, { memo } from "react";
import DeleteCustomFeedButton from "~/components/DeleteCustomFeedButton";
import EditCustomFeedButton from "~/components/EditCustomFeedButton";
import ShareDialog from "~/components/dialogs/ShareDialog";
import Button from "~/components/elements/Button";
import { usePreferences } from "~/hooks/usePreferences";
import { removeGroup } from "~/concepts/groups";
import { useAppDispatch } from "~/hooks";
import { useIsGroupOwner } from "~/hooks/useIsGroupOwner";

const EditFeedCustomGroupControls: React.FC<{ group: FC.Group }> = ({
  group,
}) => {
  const dispatch = useAppDispatch();
  const [preferences, setPreferences] = usePreferences();
  const isGroupOwner = useIsGroupOwner();

  const onPressDeleteOtherCustomFeed = () => {
    const preferencesUpdate = { ...preferences };
    preferencesUpdate.feeds = {
      ...preferencesUpdate.feeds,
      saved: preferences.feeds.saved.filter((id) => id !== group.id),
    };
    dispatch(removeGroup(group));
    setPreferences(preferencesUpdate);
  };

  return isGroupOwner(group) ? (
    <>
      <ShareDialog group={group} />
      <EditCustomFeedButton group={group} />
      <DeleteCustomFeedButton group={group} />
    </>
  ) : (
    <Button
      title="Delete"
      type="clear"
      onPress={onPressDeleteOtherCustomFeed}
    />
  );
};

export default memo(EditFeedCustomGroupControls);
