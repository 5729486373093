import React, { memo } from "react";
import { createStyleSheet } from "~/styles";
import { makeStyles } from "@rneui/themed";
import View from "~/components/elements/View";

const HoverOverlay: React.FC<{ isHovered?: boolean; overlay?: boolean }> = ({
  isHovered,
  overlay,
}) => {
  const styles = useStyles({ overlay });
  return isHovered ? <View style={styles.hoverOverlay} /> : null;
};

const useStyles = makeStyles((theme, { overlay }: { overlay?: boolean }) => {
  return createStyleSheet({
    hoverOverlay: {
      backgroundColor: overlay
        ? theme.colors.backgroundHoverOverlay
        : theme.colors.backgroundHover,
      position: "absolute",
      width: "100%",
      height: "100%",
      flex: 1,
      zIndex: 1,
      pointerEvents: "none",
    },
  });
});

export default memo(HoverOverlay);
