import React, { memo } from "react";
import { createStyleSheet } from "~/styles";
import { makeStyles } from "@rneui/themed";
import { Pressable, StyleProp, View } from "react-native";

import Hoverable from "~/components/elements/Hoverable";
import { useThreadItemNavigation } from "~/hooks/useThreadItemNavigation";

const ItemFocusDrawerButton: React.FC<{
  children?: React.ReactNode | ((isHovered: boolean) => React.ReactNode);
  item: FC.Item;
  flex?: number | boolean;
  overlay?: boolean;
  style?: StyleProp<ViewStyle>;
}> = ({ children, item, flex, overlay, style }) => {
  const styles = useStyles({ overlay });
  const { navigateToThreadItem } = useThreadItemNavigation();
  const flexStyle = typeof flex === "number" ? { flex } : flex && styles.flex1;

  const itemToRender = item.reblog || item;

  return (
    <Hoverable style={[flexStyle, style]}>
      {(isHovered) => {
        return (
          <>
            <Pressable
              style={[styles.flex1]}
              onPress={() => {
                navigateToThreadItem(itemToRender);
              }}
            >
              {isHovered && <View style={styles.hoverBackground} />}
              {typeof children === "function" ? children(isHovered) : children}
            </Pressable>
          </>
        );
      }}
    </Hoverable>
  );
};

const useStyles = makeStyles((theme, { overlay }: { overlay?: boolean }) => {
  return createStyleSheet({
    hoverBackground: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: overlay
        ? theme.colors.backgroundHoverOverlay
        : theme.colors.backgroundHover,
    },
  });
});

export default memo(ItemFocusDrawerButton);
