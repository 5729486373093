import React, { memo } from "react";
import { createStyleSheet } from "~/styles";
import { makeStyles } from "@rneui/themed";
import Author from "~/components/elements/Author";
import CardActions from "~/components/CardActions";
import { Row, Column, Text } from "~/components/elements";
import TimeAgo from "~/components/TimeAgo";
import { StyleProp } from "react-native";
import ReblogAttribution from "~/components/ReblogAttribution";
import { TextAttributionStyle } from "~/enums";

const CardAuthor: React.FC<{
  item: FC.Item;
  overlay?: boolean;
  justifyRight?: boolean;
  authorStyle?: StyleProp<ViewStyle>;
  style?: StyleProp<ViewStyle>;
  avatarSize?: number;
}> = ({
  item,
  overlay = false,
  justifyRight = false,
  authorStyle,
  style,
  avatarSize,
}) => {
  const styles = useStyles({ overlay });

  const AuthorSubtext = (
    <Row flex noGap style={[styles.alignBaseline]}>
      <ReblogAttribution reblogger={item.reblogger} overlay={overlay} />
      <Text
        attribution={TextAttributionStyle.STANDARD_SUBTEXT}
        overlay={overlay}
        numberOfLines={1}
      >
        {item.reblogger && item.createdAt && "-  "}
      </Text>
      <TimeAgo style={[styles.timeStamp]} overlay={overlay}>
        {new Date(item.createdAt)}
      </TimeAgo>
    </Row>
  );
  return (
    <Row
      noGap
      evenlySpaced={!justifyRight}
      style={[styles.cardAttributionRow, style]}
    >
      <Column flex style={[styles.alignCenter, styles.authorContainer]}>
        <Author
          hideTooltip={!item.capabilities.canShowAuthor}
          item={item}
          author={item.author}
          color={"primaryDark"}
          style={authorStyle}
          overlay={overlay}
          SubText={AuthorSubtext}
          avatarSize={avatarSize}
        />
      </Column>
      <CardActions item={item} overlay={overlay} />
    </Row>
  );
};

const useStyles = makeStyles(() => {
  return createStyleSheet({
    cardAttributionRow: {
      width: "100%",
      alignItems: "center",
    },
    authorContainer: {
      overflow: "hidden",
      alignItems: "flex-start",
    },
    timeStamp: { fontSize: 12 },
  });
});

export default memo(CardAuthor);
