import React from "react";
import OverflowMenuButton from "~/components/elements/OverflowMenuButton";
import { useAppDispatch } from "~/hooks";
import api, { useLazyRevokeTokenQuery } from "~/api";
import { useToast } from "react-native-toast-notifications";
import { useClearUIDCookie } from "~/hooks/useCookies";
import { setRemount } from "~/concepts/application";
import { RESET_ACTION } from "~/store";
import { removeBearerToken } from "~/lib/persistentStore";

const Signout: React.FC<{ overlay: boolean }> = ({ overlay }) => {
  const [revokeTokenTrigger] = useLazyRevokeTokenQuery();
  const clearUIDCookie = useClearUIDCookie();
  const toast = useToast();
  const dispatch = useAppDispatch();

  const handleSigningOut = async () => {
    try {
      await removeBearerToken();
      await revokeTokenTrigger();
      clearUIDCookie();
      toast.show("Logged out from your account", {
        type: "success",
      });
    } finally {
      dispatch(api.util.resetApiState());
      dispatch(RESET_ACTION);
      dispatch(setRemount(true));
    }
  };
  return (
    <OverflowMenuButton
      title="Log out"
      overlay={overlay}
      onPress={() => {
        handleSigningOut();
      }}
    />
  );
};

export default Signout;
