import React, { memo, useState } from "react";
import { Avatar, useTheme, makeStyles } from "@rneui/themed";
import { Tooltip } from "@rneui/base";

import { selectCredentials } from "~/concepts/auth";
import { useAppSelector } from "~/hooks";
import { createStyleSheet, LAYOUT, SPACING } from "~/styles";
import Column from "~/components/elements/Column";
import Signout from "~/components/ProfileActions/Signout";
import ContentWarningSwitch from "~/components/ContentWarningSwitch";
import { LayoutChangeEvent } from "react-native";
import EditFeedsButton from "~/components/EditFeedsButton";
import isDevelopment from "~/lib/isDevelopment";
import DevModeSwitch from "~/components/DevModeSwitch";
import MyPosts from "~/components/ProfileActions/MyPosts";

const UserAvatar: React.FC<{ size: number }> = ({
  size = LAYOUT.LOGO_SIZE,
}) => {
  const [showProfileContextToolTip, setShowProfileContextToolTip] =
    useState(false);
  const { theme } = useTheme();
  const styles = useStyles();
  const credentials = useAppSelector(selectCredentials);
  const [menuHeight, setMenuHeight] = useState(0);

  const contentMenu = (
    <Column
      gap={SPACING.SMALL}
      style={styles.overflowButtonWrapper}
      onLayout={(event: LayoutChangeEvent) => {
        const { height } = event.nativeEvent.layout;
        setMenuHeight(height);
      }}
    >
      <ContentWarningSwitch />
      {isDevelopment() && <EditFeedsButton />}
      <DevModeSwitch inverse />
      <MyPosts
        overlay
        onPress={() => {
          setShowProfileContextToolTip(false);
        }}
      />
      <Signout overlay />
    </Column>
  );

  if (!credentials?.avatar) return null;
  return (
    <Tooltip
      withOverlay={false}
      withPointer={false}
      containerStyle={styles.container}
      backgroundColor={theme.colors.primary}
      visible={showProfileContextToolTip}
      onOpen={() => {
        setShowProfileContextToolTip(true);
      }}
      onClose={() => {
        setShowProfileContextToolTip(false);
      }}
      popover={contentMenu}
      width={LAYOUT.PROFILE_ACTIONS_OVERFLOW_WIDTH + SPACING.LARGE * 2}
      height={menuHeight + 2 * SPACING.LARGE}
    >
      <Avatar rounded size={size} source={{ uri: credentials.avatar }} />
    </Tooltip>
  );
};

const useStyles = makeStyles((theme) => {
  return createStyleSheet({
    container: {
      justifyContent: "flex-start",
      alignItems: "flex-start",
      marginBottom: "auto",
      borderRadius: 4,
      backgroundColor: theme.colors.profileContextMenuBackground,
      borderColor: theme.colors.profileContextMenuBorderColor,
      borderWidth: 1,
      paddingVertical: SPACING.LARGE,
      paddingHorizontal: SPACING.LARGE,
    },
    overflowButtonWrapper: {
      width: "100%",
    },
  });
});

export default memo(UserAvatar);
