import {
  isGalleryItem,
  isWideFeatureImageItem,
  isImageItem,
  isPhotoItem,
} from "~/predicates";
import GalleryOneUpWide from "~/components/pages/GalleryOneUpWide";

const vigetGalleryOneUpWide: FC.PagePresentationStrategy = ({ items }) => {
  const wideLandscapeItems = items
    .filter(isImageItem)
    .filter(isGalleryItem)
    .filter(isWideFeatureImageItem)
    .filter(isPhotoItem);

  if (wideLandscapeItems.length >= 1) {
    return {
      usedItems: wideLandscapeItems.slice(0, 1),
      Component: GalleryOneUpWide,
      repeat: true,
    };
  }
  return null;
};

export default vigetGalleryOneUpWide;
