import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { api } from "~/api";
import { ApplicationMode, GroupCategory, GroupRequestType } from "~/enums";
import type { RootState } from "~/store";
import sortBy from "lodash-es/sortBy";
import { CUSTOM_FEED_ID_SEPARATOR } from "~/utils/id";

export const homeTimelineGroup: FC.Group = {
  id: "home-timeline",
  title: "Following",
  requestKey: GroupRequestType.HOME_TIMELINE, // not used
  requestType: GroupRequestType.HOME_TIMELINE,
};

export const developmentGroups: Array<FC.Group> = [
  {
    id: GroupCategory.TESTING_STUFF,
    title: "🧪Testing Stuff",
    requestType: GroupRequestType.FEED,
    requestKey: GroupCategory.TESTING_STUFF,
  },
  {
    id: "hashtag-hospitals",
    title: "🧪Content Warning",
    requestType: GroupRequestType.HASHTAG,
    /* Other hashtags: "politics", "death", "crime", "hospitals", "spoiler", "spoilers", also....... "nsfw"
     */
    requestKey: "hospitals",
  },
  // {
  //   title: "🧪Youtubes",
  //   requestType: GroupRequestType.FEED,
  //   requestKey: GroupCategory.YOUTUBES,
  // },
  // {
  //   title: "🧪First Party Videos",
  //   requestType: GroupRequestType.FEED,
  //   requestKey: GroupCategory.FIRSTPARTYVIDEOS,
  // },
  {
    id: GroupCategory.ATOM,
    title: "🧪atom",
    requestType: GroupRequestType.FEED,
    requestKey: GroupCategory.ATOM,
  },
];
const initialGroups: Array<FC.Group> = [];

interface GroupsState {
  selectedFeedId: FC.Group["id"] | null;
  groups: Array<FC.Group>;
  feedLoading: boolean;
}

const initialState: GroupsState = {
  selectedFeedId: null,
  groups: initialGroups,
  feedLoading: false,
};

export const groupsSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    setSelectedFeed: (
      state,
      { payload: feedId }: PayloadAction<FC.Group["id"] | null>
    ) => {
      state.selectedFeedId = feedId;
    },
    setHomeTimeline: (state) => {
      state.groups = [
        homeTimelineGroup,
        ...state.groups.filter((group) => group.id !== homeTimelineGroup.id),
      ];
    },
    addGroups: (state, { payload: groups }: PayloadAction<Array<FC.Group>>) => {
      groups.forEach((group) => {
        const foundGroup = state.groups.find((g) => group.id === g.id);
        if (!foundGroup) {
          state.groups.push(group);
        }
      });
    },
    removeGroup: (state, { payload: group }: PayloadAction<FC.Group>) => {
      state.groups = state.groups.filter((g) => g.id !== group.id);
    },
    replaceGroup: (state, { payload: newGroup }: PayloadAction<FC.Group>) => {
      state.groups = state.groups.reduce<Array<FC.Group>>((acc, g) => {
        if (g.requestKey === newGroup.requestKey) {
          acc.push(newGroup);
        } else {
          acc.push(g);
        }
        return acc;
      }, []);
    },
    setFeedLoading: (
      state,
      { payload: feedLoading }: PayloadAction<boolean>
    ) => {
      state.feedLoading = feedLoading;
    },
  },
});

export const {
  addGroups,
  removeGroup,
  replaceGroup,
  setHomeTimeline,
  setSelectedFeed,
  setFeedLoading,
} = groupsSlice.actions;

export const selectGroups = (state: RootState) => state.groups.groups;

export const selectHomeTimelineGroup = (state: RootState) =>
  state.groups.groups.find((g) => g.id === homeTimelineGroup.id);

export const selectMyCustomFeeds = (state: RootState) =>
  state.groups.groups.filter(
    (g) =>
      g.requestType === GroupRequestType.CUSTOM_FEED &&
      g?.ownerURL === state.auth?.credentials?.url
  );

export const selectFeaturedGroups = (state: RootState) =>
  state.groups.groups.filter((g) => g.featured === true);

export const selectAllGroupsWithPreferences =
  (preferences: FC.Preferences) => (state: RootState) => {
    const retVal = sortBy(state.groups.groups, (g) =>
      preferences.feeds.order.indexOf(g.id)
    );
    return retVal;
  };

export const selectFilteredGroupsWithPreferences =
  (preferences: FC.Preferences) => (state: RootState) => {
    const allWithPreferences =
      selectAllGroupsWithPreferences(preferences)(state);
    return state.auth.isAuthenticated
      ? allWithPreferences.filter(
          (group) => !preferences.feeds.excluded.includes(group.id)
        )
      : allWithPreferences;
  };

export const selectFavoriteFeeds =
  (preferences: FC.Preferences) => (state: RootState) => {
    const allWithPreferences =
      selectAllGroupsWithPreferences(preferences)(state);
    return state.auth.isAuthenticated
      ? allWithPreferences.filter((group) =>
          preferences.feeds.saved.includes(group.id)
        )
      : allWithPreferences;
  };

export const selectRecentFavoriteFeeds =
  (preferences: FC.Preferences) => (state: RootState) => {
    const favoriteFeeds = selectFavoriteFeeds(preferences)(state);
    return sortBy(
      favoriteFeeds,
      (f) => preferences.feeds.saved.indexOf(f.id) * -1
    );
  };

export const selectItemsForGroupPages =
  (
    groupRequestArguments: Array<{
      group: FC.Group;
      applicationMode: ApplicationMode;
      bustUpstreamCache?: boolean;
    }>
  ) =>
  (state: RootState) => {
    return groupRequestArguments
      .map((arg) => {
        const pageResult = api.endpoints.getGroupItems.select(arg)(state);
        return pageResult?.data?.items;
      })
      .filter((x) => x !== undefined);
  };

export const selectSelectedFeed = (state: RootState) =>
  state.groups.groups.find((f) => f.id === state.groups.selectedFeedId);

export const selectFeedGroupFromCustomFeed =
  (customFeed: FC.CustomFeed) => (state: RootState) => {
    return state.groups.groups.find((g) => g.requestKey === customFeed.id);
  };

export const selectFeedLoading = (state: RootState) => state.groups.feedLoading;

const customFeedIdRegExp = new RegExp(`${CUSTOM_FEED_ID_SEPARATOR}\\w{26}$`);
export const isCustomFeedId = (id: string) => id.match(customFeedIdRegExp);

export default groupsSlice.reducer;
