import React from "react";
import { ImageBackground, StyleProp } from "react-native";
import { createStyleSheet } from "~/styles";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { makeStyles } from "@rneui/themed";
import { Column } from "~/components/elements";
import View from "~/components/elements/View";

import {
  isVideoAttachment,
  isAudioAttachment,
  isImageAttachment,
} from "~/predicates";

type AVPlayerPreviewProps = {
  item: FC.Item;
  style?: StyleProp<ViewStyle>;
};

const PLAY_ICON_SIZE = 42;

const AVPlayerPreview: React.FC<AVPlayerPreviewProps> = ({ item, style }) => {
  const originalAttachment = item.attachments?.[0].meta.original;
  const aspectRatio =
    item?.image?.aspectRatio ||
    originalAttachment?.aspect ||
    (originalAttachment?.width &&
      originalAttachment?.height &&
      originalAttachment?.width / originalAttachment?.height) ||
    16 / 9;
  const styles = useStyles({ aspectRatio });
  const { attachments } = item;

  const ALT_TEXT = "Video Preview image";

  const videoContent = attachments?.find(isVideoAttachment);
  const imageContent = attachments?.find(isImageAttachment);
  const audioContent = attachments?.find(isAudioAttachment);
  if (!videoContent && !audioContent) {
    return null;
  }
  const isAudio = audioContent ? true : false;

  let previewURL = isAudio
    ? audioContent?.preview_url
    : imageContent?.preview_url ||
      videoContent?.preview_url ||
      item.card?.image ||
      null;

  return (
    <Column flexShrink style={[styles.playerWrapper, style]}>
      {previewURL ? (
        <ImageBackground
          source={{
            uri: previewURL,
          }}
          style={styles.playerWrapper}
          accessibilityLabel={item.card?.description || ALT_TEXT}
        >
          <View style={styles.playIconBackground} />
          <Icon
            style={styles.playIcon}
            name="play-circle"
            size={PLAY_ICON_SIZE}
            color="#ffffff"
          />
        </ImageBackground>
      ) : (
        <Column style={styles.audioPreviewWrapper}>
          <Icon
            style={styles.playIcon}
            name="play-circle"
            size={PLAY_ICON_SIZE}
            color="#ffffff"
          />
        </Column>
      )}
    </Column>
  );
};

const useStyles = makeStyles(
  (theme, { aspectRatio }: { aspectRatio?: number }) =>
    createStyleSheet({
      playerWrapper: {
        maxWidth: "100%",
        aspectRatio,
      },
      audioPreviewWrapper: {
        width: "100%",
        aspectRatio,
      },
      playIcon: {
        display: "flex",
        lineHeight: 84,
        justifyContent: "center",
        alignItems: "center",
        opacity: 0.75,
        height: "100%",
        width: "100%",
      },
      playIconBackground: {
        backgroundColor: theme.colors.primary,
        height: 50,
        width: 50,
        position: "absolute",
        top: "50%",
        left: "50%",
        borderRadius: "50%",
        marginTop: -25,
        marginLeft: -25,
        opacity: 0.4,
      },
    })
);

export default AVPlayerPreview;
