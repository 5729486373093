import React, { memo, useCallback } from "react";
import AuthorCard from "~/components/elements/AuthorCard";
import { useLazyGetFollowingQuery, useLazyGetInstanceAuthorQuery } from "~/api";
import AttributionTooltip, {
  AttributionTooltipPopoverComponent,
} from "~/components/elements/AttributionTooltip";

type AuthorPopoverComponentProps = {
  author: FC.Author;
};

const AuthorPopoverComponent: AttributionTooltipPopoverComponent<
  AuthorPopoverComponentProps
> = ({ close, popoverComponentProps: { author } }) => (
  <AuthorCard author={author} close={close} />
);

const AuthorTooltip: React.FC<
  React.PropsWithChildren<AuthorPopoverComponentProps>
> = ({ author, children }) => {
  const [getInstanceAuthorTrigger, getInstanceAuthorResponse] =
    useLazyGetInstanceAuthorQuery();

  const [getFollowingTrigger, getFollowingResponse] =
    useLazyGetFollowingQuery();

  const onHover = useCallback(() => {
    if (getInstanceAuthorResponse.isUninitialized) {
      getInstanceAuthorTrigger({ acct: author.acct });
    }
  }, [
    getInstanceAuthorResponse.isUninitialized,
    getInstanceAuthorTrigger,
    author.acct,
  ]);

  if (getInstanceAuthorResponse.data && getFollowingResponse.isUninitialized) {
    getFollowingTrigger(getInstanceAuthorResponse.data, true);
  }

  return (
    <AttributionTooltip<AuthorPopoverComponentProps>
      PopoverComponent={AuthorPopoverComponent}
      popoverComponentProps={{ author }}
      onHover={onHover}
    >
      {children}
    </AttributionTooltip>
  );
};

export default memo(AuthorTooltip);
