import React, { memo } from "react";
import { Linking, View as ReactNativeView } from "react-native";
import { Avatar, AvatarProps, makeStyles } from "@rneui/themed";
import { createStyleSheet, LAYOUT, SPACING } from "~/styles";

import {
  HTMLText,
  Row,
  Text,
  Column,
  Button,
  Divider,
} from "~/components/elements";
import FollowingButton from "~/components/FollowingButton";
import DisplayName from "~/components/elements/DisplayName";
import { A } from "@expo/html-elements";
import { useAppSelector } from "~/hooks";
import { selectIsAuthenticated } from "~/concepts/auth";
import { useGetAuthorUrl } from "~/hooks/useGetAuthorUrl";
import { useFeedNavigation } from "~/hooks/useFeedNavigation";
import CustomFeedAddToForm from "~/components/CustomFeedAddToForm";
import { useGetHostCookie } from "~/hooks/useCookies";
import { safeAcct } from "~/lib/safeAcct";
import { authorFeedFromAuthor } from "~/utils/groups";
import CustomFeedExcludeFeedButton from "~/components/CustomFeedExcludeFeedButton";

type AuthorCardProps = {
  author?: FC.Author;
  style?: ViewStyle;
  avatarSize?: AvatarProps["size"];
  showFollow?: boolean;
  close?: () => void;
};

const AuthorCard = React.forwardRef<ReactNativeView, AuthorCardProps>(
  ({ author, avatarSize = SPACING.BASE8X, close }, ref) => {
    const isAuthenticated = useAppSelector(selectIsAuthenticated);

    const getHostCookie = useGetHostCookie();

    const getAuthorUrl = useGetAuthorUrl();
    const { navigateToAccountFeed } = useFeedNavigation();

    const styles = useStyles();
    if (!author) return null;

    const { followers_count, following_count, statuses_count, note } = author;

    const url = getAuthorUrl(author);

    const AuthorDisplayNameAndAvatar = (
      <Column noGap>
        <Row noGap flex style={styles.authorDisplayNameContainer}>
          <A href={url} target="_blank">
            {author.avatar && (
              <Avatar
                rounded
                size={avatarSize}
                source={{ uri: author.avatar }}
              />
            )}
          </A>
          {isAuthenticated && <FollowingButton author={author} />}
        </Row>
        <A href={url} target="_blank">
          <Column noGap>
            <DisplayName author={author} />
            <Text style={styles.authorAccountNameTitle}>
              {safeAcct(author.acct, getHostCookie())}
            </Text>
          </Column>
        </A>
      </Column>
    );

    const AuthorBioDescription = (
      <A href={url} target="_blank">
        <Row>
          <HTMLText
            html={note}
            style={styles.authorDescription}
            numberOfLines={3}
            shouldDecorateContent={false}
            externalHashtag
          />
        </Row>
      </A>
    );

    const AuthorStats = (
      <Row>
        <Text style={styles.statsText}>
          <Text style={styles.boldText}>{`${statuses_count} `}</Text>
          Posts
        </Text>
        <Text style={styles.statsText}>
          <Text style={styles.boldText}>{`${following_count} `}</Text>
          Following
        </Text>
        <Text style={styles.statsText}>
          <Text style={styles.boldText}>{`${followers_count} `}</Text>
          Followers
        </Text>
      </Row>
    );

    const ViewButtons = (
      <Row style={[styles.justifyLeft, styles.viewButtons]}>
        <Button
          type={"outline"}
          title={"View Posts"}
          onPress={() => navigateToAccountFeed(author)}
        />
        <Button
          type={"outline"}
          title={"View On Mastodon"}
          onPress={() => Linking.openURL(url)}
        />
      </Row>
    );

    const CustomFeedButtons = (
      <Row noGap style={[styles.alignCenter, styles.evenlySpaced]}>
        <CustomFeedAddToForm
          feed={authorFeedFromAuthor(author)}
          onAdd={close}
          showTitle
          type="clear"
        />
        <CustomFeedExcludeFeedButton
          feed={authorFeedFromAuthor(author)}
          onExclude={close}
          type="clear"
        />
      </Row>
    );

    return (
      <Column flexShrink ref={ref} style={styles.authorCardContainer}>
        {AuthorDisplayNameAndAvatar}
        {AuthorBioDescription}
        {AuthorStats}
        {ViewButtons}
        <Divider />
        {CustomFeedButtons}
      </Column>
    );
  }
);
const useStyles = makeStyles((theme) => {
  return createStyleSheet({
    authorCardContainer: {
      // height: LAYOUT.AUTHOR_BUSINESS_CARD_HEIGHT,
      width: LAYOUT.AUTHOR_BUSINESS_CARD_WIDTH,
      paddingHorizontal: SPACING.LARGE,
      paddingTop: SPACING.LARGE,
      paddingBottom: SPACING.BASE3X,
      display: "flex",
      justifyContent: "space-between",
    },
    authorDisplayNameContainer: {
      justifyContent: "space-between",
      alignItems: "flex-start",
      paddingBottom: SPACING.MEDIUM,
    },
    authorDisplayNameTitle: {
      fontSize: 16,
    },
    statsText: {
      fontSize: 12,
    },
    boldText: {
      fontWeight: "700",
    },
    authorAccountNameTitle: {
      color: theme.colors.grey1,
    },
    authorDescription: {
      marginBottom: "auto",
      width: LAYOUT.AUTHOR_BUSINESS_CARD_WIDTH - SPACING.LARGE * 2,
    },
  });
});
export default memo(AuthorCard);
