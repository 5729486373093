import React, { memo } from "react";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { makeStyles } from "@rneui/themed";
import { Row, Text } from "~/components/elements";
import { createStyleSheet, SPACING } from "~/styles";
import { TextSize } from "~/enums";

const CustomFeedBuilderImportEdu = () => {
  const styles = useStyles();

  return (
    <Row style={styles.eduWrapper} gap={SPACING.BASE}>
      <Icon name="information-outline" size={SPACING.BASE3X} />
      <Text size={TextSize.XS}>
        You have some lists in your Mastodon Account. You can import the
        accounts from these lists into your custom feed by clicking "Import"
        below.
      </Text>
    </Row>
  );
};

const useStyles = makeStyles(() =>
  createStyleSheet({
    eduWrapper: {
      backgroundColor: "transparent",
      padding: SPACING.LARGE,
    },
  })
);

export default memo(CustomFeedBuilderImportEdu);
