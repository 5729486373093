import { createTheme } from "@rneui/themed";

/**
 * Custom colour keys:
 * https://reactnativeelements.com/docs/customization/extending
 */
import "@rneui/themed";
import { SPACING } from "~/styles";

declare module "@rneui/themed" {
  export interface Colors {
    primaryReverse: string;
    primaryReverse50: string;
    primary70: string;
    backgroundLight: string;
    backgroundSecondary: string;
    backgroundSecondaryReverse: string;
    backgroundTertiary: string;
    backgroundReverse: string;
    backgroundReverse10: string;
    backgroundReverse20: string;
    backgroundReverse40: string;
    backgroundReverse50: string;
    backgroundReverse90: string;
    backgroundOverlay: string;
    backgroundOverlayReverse: string;
    backgroundHover: string;
    backgroundHoverSecondary: string;
    backgroundHoverOverlay: string;
    primaryDark: string;
    overlay: string;
    overlay75: string;
    dividerPrimary: string;
    dividerHighlight: string;
    dividerOverlay: string;
    dividerTertiary: string;
    dividerPrimaryReverse: string;
    dividerTertiaryReverse: string;
    controls: string;
    controlsBlackWhite: string;
    controlsRed: string;
    controlsRed2: string;
    controlsBlue: string;
    controlsGreen: string;
    controlsYellow: string;
    controlsGrey: string;
    successBackground: string;
    infoBackground: string;
    failBackground: string;
    videoBackground: string;
    anchor: string;
    anchorOverlay: string;
    barColorBackground: string;
    barColorBackgroundOverlay: string;
    profileContextMenuBackground: string;
    profileContextMenuBorderColor: string;
    overlayBorder: string;
    debugPrimary: string;
  }
}

/**
 * Default theme color keys:
 * https://reactnativeelements.com/docs/customization/theme_object
 *
 * TODO: Flesh our default colour set.
 */
const mainTheme = createTheme({
  lightColors: {
    primary: "#000000",
    primary70: "#000000B3",
    primaryReverse: "#ffffff",
    primaryReverse50: "#ffffff80",
    background: "#ffffff",
    backgroundLight: "#ffffff",
    backgroundSecondary: "#f0f0f0",
    backgroundSecondaryReverse: "#111213",
    backgroundTertiary: "#e0e0e0",
    backgroundReverse: "#000000",
    backgroundReverse10: "#0000001A",
    backgroundReverse20: "#00000033",
    backgroundReverse40: "#00000066",
    backgroundReverse50: "#00000080",
    backgroundReverse90: "#000000E0",
    backgroundOverlay: "#CCCCCC66",
    backgroundOverlayReverse: "#80808060",
    backgroundHover: "#EEEEEE22",
    backgroundHoverSecondary: "#E0E0E0",
    backgroundHoverOverlay: "#33333322",
    dividerOverlay: "#404040",
    primaryDark: "#000000",
    overlay: "#ffffff",
    overlay75: "#ffffffC0",
    secondary: "#f52828",
    dividerPrimary: "#DDDDDD",
    dividerHighlight: "#2089dc",
    dividerTertiary: "#CCCCCC",
    dividerPrimaryReverse: "#333333",
    dividerTertiaryReverse: "#555555",
    controls: "#f52828",
    controlsBlackWhite: "#141414",
    controlsRed: "#f52828",
    controlsRed2: "#C14B62",
    controlsBlue: "#3998D3",
    controlsGreen: "#53b913",
    controlsYellow: "#C98D36",
    controlsGrey: "#CECECE",
    successBackground: "#304030",
    infoBackground: "#303030",
    failBackground: "#f52828",
    videoBackground: "#000000c0",
    anchor: "#2779F6",
    anchorOverlay: "#2779F6",
    barColorBackground: "#4682b4",
    barColorBackgroundOverlay: "#A682b4",
    profileContextMenuBackground: "#242424",
    profileContextMenuBorderColor: "#2C2C2C",
    overlayBorder: "#ffffffb3",
    debugPrimary: "firebrick",
  },
  darkColors: {
    primary: "#ffffff",
    primary70: "#ffffffB3",
    primaryReverse: "#000000",
    primaryReverse50: "#00000080",
    background: "#000000",
    backgroundLight: "#ffffff",
    backgroundSecondary: "#111213",
    backgroundSecondaryReverse: "#f0f0f0",
    backgroundTertiary: "#2f2f2f",
    backgroundReverse: "#ffffff",
    backgroundReverse50: "#ffffff80",
    backgroundReverse90: "#ffffffE0",
    backgroundOverlay: "#80808060",
    backgroundOverlayReverse: "#CCCCCC66",
    backgroundHover: "#33333322",
    backgroundHoverSecondary: "#171717",
    primaryDark: "#000000",
    overlay: "#ffffff",
    overlay75: "#ffffffC0",
    secondary: "#f52828",
    dividerPrimary: "#DDDDDD",
    dividerHighlight: "#2089dc",
    dividerTertiary: "#555555",
    dividerPrimaryReverse: "#DDDDDD",
    dividerTertiaryReverse: "#CCCCCC",
    controls: "#f52828",
    controlsBlackWhite: "#ffffff",
    controlsRed: "#f52828",
    successBackground: "#00C851",
    failBackground: "#ff4444",
    videoBackground: "#000000c0",
    anchor: "#809fff",
    anchorOverlay: "#2779F6",
    barColorBackground: "#A682b4",
    profileContextMenuBackground: "#242424",
    profileContextMenuBorderColor: "#2C2C2C",
    overlayBorder: "#ffffffb3",
  },
  components: {
    Button: {
      titleStyle: {
        fontSize: 14,
      },
      raised: true,
    },
    CardTitle: {
      h2Style: {
        fontSize: 16,
        marginBottom: SPACING.SMALL,
      },
      h4Style: {
        fontSize: 12,
        fontWeight: "500",
      },
    },
    CardFeaturedSubtitle: (_props, theme) => ({
      style: { color: theme.colors.primary },
    }),
    CardDivider: {
      style: {
        marginHorizontal: SPACING.LARGE,
      },
    },
    SearchBar: (_props, theme) => ({
      containerStyle: {
        backgroundColor: theme.colors.background,
        borderBottomWidth: 0,
        borderTopWidth: 0,
        padding: 0,
      },
      inputContainerStyle: {
        backgroundColor: theme.colors.background,
        borderWidth: 0,
        borderBottomWidth: 0,
        borderRadius: SPACING.BASE,
        overflow: "visible",
        margin: 1,
        width: "99%",
        height: "100%",
      },
      inputStyle: {
        color: theme.colors.primary,
        borderColor: theme.colors.dividerPrimary,
        borderWidth: 1,
        position: "absolute",
        marginLeft: 0,
        margin: 1,
        height: "100%",
        width: "100%",
        borderRadius: SPACING.BASE3X,
        paddingHorizontal: SPACING.BASE5X,
        zIndex: 1,
      },
      rightIconContainerStyle: { position: "absolute", right: 0, zIndex: 2 },
    }),
    Input: (_props, theme) => ({
      inputContainerStyle: { borderBottomWidth: 0 },
      inputStyle: {
        padding: SPACING.SMALL,
        borderWidth: 1,
        borderColor: theme.colors.dividerPrimary,
        borderStyle: "solid",
        borderRadius: SPACING.BASE,
      },
      errorStyle: {
        margin: 0,
      },
    }),
    Text: {
      h1Style: {
        fontFamily: "Roboto",
        fontSize: 36,
        fontWeight: "700",
      },
      h2Style: {
        fontFamily: "Roboto",
        fontSize: 21,
        fontWeight: "800",
      },
      h3Style: {
        fontSize: 20,
        fontWeight: "700",
      },
      h4Style: {
        fontSize: 14,
        fontWeight: "700",
      },
    },
    CheckBox: () => ({
      containerStyle: { backgroundColor: "transparent" },
    }),
  },
});

export default mainTheme;
