import { makeStyles } from "@rneui/themed";
import React from "react";

import { createStyleSheet, SPACING, LAYOUT } from "~/styles";
import { A } from "@expo/html-elements";
import OverflowMenuButton from "~/components/elements/OverflowMenuButton";

type UrlButtonProps = {
  title?: string;
  icon?: string;
  onPress?: () => void;
  disabled?: boolean;
  url?: string;
};

const UrlButton: React.FC<UrlButtonProps> = ({
  title,
  icon,
  onPress = () => {},
  disabled,
  url,
}) => {
  const styles = useStyles();

  if (!url) return null;
  return (
    <OverflowMenuButton
      title={title}
      onPress={onPress}
      disabled={disabled}
      icon={icon}
    >
      <A href={url} style={[styles.title]} target="_blank">
        {title}
      </A>
    </OverflowMenuButton>
  );
};

const useStyles = makeStyles(() => {
  return createStyleSheet({
    title: {
      fontFamily: "Roboto_400Regular",
      fontSize: 16,
      width: "100%",
      textAlign: "left",
      display: "flex",
      alignItems: "center",
      minHeight: LAYOUT.CARD_ACTIONS_BUTTON_HEIGHT,
      gap: SPACING.BASE,
    },
  });
});

export default UrlButton;
