import VigetTwoUp from "~/components/pages/VigetTwoUp";
import {
  isGenericMediaItem,
  isFeatureableImageItem,
  isSocialArticleItem,
} from "~/predicates";

const vigetTwoUp: FC.PagePresentationStrategy = ({ items }) => {
  const genericItems = items
    .filter(isGenericMediaItem)
    .filter((i) => !isFeatureableImageItem(i));
  const socialArticleItem = genericItems.filter(isSocialArticleItem)[0];
  const nonSocialCardItem = genericItems.filter(
    (x) => !isSocialArticleItem(x)
  )[0];

  if (nonSocialCardItem && socialArticleItem) {
    return {
      usedItems: [nonSocialCardItem, socialArticleItem],
      Component: VigetTwoUp,
    };
  }
  return null;
};

export default vigetTwoUp;
