import React, { useEffect, useState } from "react";
import { ImageBackground, Pressable, StyleProp } from "react-native";
import { ContentPosition } from "~/enums";
import { Column, Row, Text } from "~/components/elements";
import { FixDimensionType } from "~/components/ItemImage";
import {
  BOTTOM_CONTENT_HEIGHT,
  BasicMediaCardProps,
} from "~/components/cards/BasicMediaCard";
import CardAuthor from "~/components/CardAuthor";
import AutoNumberOfLinesWrapper from "~/components/elements/AutoNumberOfLinesWrapper";
import { makeStyles, useTheme } from "@rneui/themed";
import { SPACING, createStyleSheet } from "~/styles";
import Content from "~/components/Content";
import { GALLERY_FONT_SIZE } from "~/components/elements/Text";
import { getYouTubeID } from "~/utils/get-youtube-id";
import { selectModalOpen } from "~/concepts/application";
import { useAppSelector } from "~/hooks";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { secondsToMinutesString } from "~/utils/parse-time";
import isDevelopment from "~/lib/isDevelopment";
import CardText from "~/components/cardComponents/CardText";
import { isEqual } from "lodash-es";
import Reboosts from "~/components/cardComponents/Reboosts";

const ALT_TEXT = "YouTube Preview image";
const PLAYER_HEADER_HEIGHT = 50;
const PLAYER_FOOTER_HEIGHT = 50;

export interface FlexibleYoutubeCardProps
  extends Omit<BasicMediaCardProps, "MediaComponent"> {
  maxNumberTruncate?: number;
  fixDimension?: FixDimensionType;
  height?: number;
  imageAspectRatio?: number;
  contentPosition?: ContentPosition;
  cardContentStyle?: StyleProp<ViewStyle>;
}

const FlexibleYoutubeCard: React.FC<FlexibleYoutubeCardProps> = ({
  item,
  overlay,
  cardSize,
  fixDimension,
  height,
  imageAspectRatio,
  contentPosition = ContentPosition.BOTTOM,
  cardContentStyle,
}) => {
  const styles = useStyles();
  const { theme } = useTheme();

  const { url, title, description } = (item as FC.CardItem).card;
  const uniqueContent = !isEqual(title, item.content);

  const [youTubeEmbeddedPlayer, setYouTubeEmbeddedPlayer] = useState(false);
  const modalOpen = useAppSelector(selectModalOpen);

  const toggleYouTubeEmbeddedPlayer = () => {
    setYouTubeEmbeddedPlayer(!youTubeEmbeddedPlayer);
  };

  const stopYouTubeEmbeddedPlayer = () => {
    setYouTubeEmbeddedPlayer(false);
  };
  useEffect(() => {
    if (modalOpen) {
      stopYouTubeEmbeddedPlayer();
    }
  }, [modalOpen]);

  const heightReduction =
    contentPosition === ContentPosition.BOTTOM ? BOTTOM_CONTENT_HEIGHT : 0;
  const playerHeight =
    fixDimension === FixDimensionType.HEIGHT && height
      ? height - heightReduction
      : undefined;
  const imageContainerStyle = {
    height: playerHeight,
    aspectRatio: imageAspectRatio,
    border: 0,
  };

  const WrapperComponent =
    contentPosition === ContentPosition.BOTTOM ? Column : Row;

  const youTubeID = getYouTubeID(url || "");
  if (!youTubeID) return null;
  const iFrameYoutubePlayer = (
    <iframe
      style={imageContainerStyle}
      height={heightReduction}
      src={`https://www.youtube-nocookie.com/embed/${youTubeID}?autoplay=${
        isDevelopment() ? 0 : 1
      }`}
      allow={"autoplay;fullscreen"}
    />
  );
  const CardContent = (
    <Column
      style={[
        contentPosition === ContentPosition.BOTTOM
          ? styles.bottomContent
          : styles.sideContent,
        cardContentStyle,
      ]}
    >
      <CardAuthor item={item} overlay={overlay} />
      {uniqueContent && (
        <AutoNumberOfLinesWrapper>
          <Content
            cardSize={cardSize}
            item={item}
            textSize={GALLERY_FONT_SIZE}
          />
        </AutoNumberOfLinesWrapper>
      )}
      <Reboosts item={item} />
    </Column>
  );

  const playerContainerStyle: StyleProp<ViewStyle> = {
    justifyContent: "center",
    height: playerHeight
      ? playerHeight + PLAYER_HEADER_HEIGHT + PLAYER_FOOTER_HEIGHT
      : undefined,
    backgroundColor: theme.colors.backgroundReverse,
    borderRadius: SPACING.BASE,
    overflow: "hidden",
  };

  const YoutubePreviewPlayer = youTubeEmbeddedPlayer ? (
    <Column flex noGap style={playerContainerStyle}>
      {iFrameYoutubePlayer}
    </Column>
  ) : (
    <Column flex noGap style={playerContainerStyle}>
      <Row gap={SPACING.SMALL} style={styles.durationOverlay}>
        <Icon name="youtube" size={16} color="#ffffff" />
        {item.card?.duration && (
          <Text overlay>{secondsToMinutesString(item.card?.duration)}</Text>
        )}
      </Row>
      <Row flexShrink style={imageContainerStyle}>
        <ImageBackground
          source={{
            uri:
              item.image?.url ||
              "https://s3.amazonaws.com/s.flipboard.com/assets/webu/images/meta/flipboard-512.png",
          }}
          resizeMode="cover"
          style={styles.flex1}
          accessibilityLabel={description || ALT_TEXT}
        >
          <Pressable
            onPress={toggleYouTubeEmbeddedPlayer}
            style={styles.playClickHandler}
          >
            {/* <Icon
              style={styles.playIcon}
              name="youtube"
              size={PLAY_ICON_SIZE}
              color="#ffffff"
            /> */}
          </Pressable>
        </ImageBackground>
      </Row>
      <Row flexShrink style={styles.textOverlay}>
        <AutoNumberOfLinesWrapper flex maxNumberOfLines={1}>
          <CardText header overlay>
            {title}
          </CardText>
        </AutoNumberOfLinesWrapper>
      </Row>
    </Column>
  );
  return (
    <Column flex style={styles.justifyCenter}>
      <WrapperComponent
        flexShrink
        style={[styles.card, { height, minHeight: height }]}
      >
        {contentPosition === ContentPosition.LEFT ? (
          <>
            {CardContent}
            {YoutubePreviewPlayer}
          </>
        ) : (
          <>
            {YoutubePreviewPlayer}
            {CardContent}
          </>
        )}
      </WrapperComponent>
    </Column>
  );
};

const useStyles = makeStyles((theme) =>
  createStyleSheet({
    card: {
      width: "100%",
      maxWidth: `calc(100% - ${SPACING.BASE3X * 2}px)`,
      overflow: "hidden",
      margin: SPACING.BASE3X,
    },
    contentContainer: {
      width: "100%",
      maxHeight: "100%",
    },
    contentContainerHorizontal: { height: "100%", justifyContent: "center" },
    bottomContent: { height: BOTTOM_CONTENT_HEIGHT - 80 },
    sideContent: { flexShrink: 1, alignSelf: "flex-end", width: 350 },

    playClickHandler: {
      position: "absolute",
      zIndex: 1,
      height: "100%",
      width: "100%",
    },
    iframeContainer: {
      width: "100%",
    },
    durationOverlay: {
      backgroundColor: theme.colors.backgroundReverse,
      padding: SPACING.MEDIUM,
      height: PLAYER_HEADER_HEIGHT,
      alignItems: "center",
    },
    textOverlay: {
      backgroundColor: theme.colors.backgroundReverse,
      padding: SPACING.MEDIUM,
      zIndex: 1,
      width: "100%",
      height: PLAYER_FOOTER_HEIGHT,
      alignItems: "flex-end",
    },
    playIcon: {
      display: "flex",
      lineHeight: 84,
      backgroundColor: theme.colors.videoBackground,
      justifyContent: "center",
      alignItems: "center",
      opacity: 0.75,
      height: "100%",
      width: "100%",
    },
  })
);

export default FlexibleYoutubeCard;
