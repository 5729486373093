import VigetGalleryRow from "~/components/pages/VigetGalleryRow";
import {
  isGalleryItem,
  isGallerySupportedType,
  isGifv,
  isLandscapeImageItem,
  isLandscapeOrTallerImageItem,
} from "~/predicates";

const vigetGalleryTwoUp: FC.PagePresentationStrategy = ({ items }) => {
  const supportedTypes = items.filter(isGallerySupportedType);
  const usableItems = supportedTypes.filter(
    (i) => (isGalleryItem(i) && isLandscapeOrTallerImageItem(i)) || isGifv(i)
  );

  const landscapeItems = usableItems.filter(isLandscapeImageItem);
  const otherItems = usableItems.filter(
    (i) => !isLandscapeImageItem(i) || isGifv(i)
  );
  const filteredItems = [...landscapeItems.slice(0, 1), ...otherItems];

  if (filteredItems.length >= 2) {
    return {
      usedItems: filteredItems.slice(0, 2),
      Component: VigetGalleryRow,
      repeat: true,
    };
  }
  return null;
};

export default vigetGalleryTwoUp;
