import React, { memo, useEffect } from "react";
import { useDeleteCustomFeedMutation } from "~/api";
import Button from "~/components/elements/Button";
import { removeGroup } from "~/concepts/groups";
import { useAppDispatch } from "~/hooks";
import { useToast } from "react-native-toast-notifications";
import { useFavorite } from "~/hooks/useFavorite";

const DeleteCustomFeedButton: React.FC<{ group: FC.Group }> = ({ group }) => {
  const toast = useToast();
  const dispatch = useAppDispatch();
  const { removeFavorite } = useFavorite();
  const [deleteCustomFeedTrigger, { isLoading, isSuccess }] =
    useDeleteCustomFeedMutation();

  useEffect(() => {
    if (isSuccess) {
      dispatch(removeGroup(group));
      removeFavorite(group);
    }
  }, [group, dispatch, removeFavorite, isSuccess]);

  const onPress = () => {
    if (group.requestKey) {
      deleteCustomFeedTrigger(group.requestKey);
      toast.show(`Deleted custom feed ${group.title}`, {
        type: "info",
      });
    }
  };
  return (
    <Button title="Delete" onPress={onPress} loading={isLoading} type="clear" />
  );
};

export default memo(DeleteCustomFeedButton);
