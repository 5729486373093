import { useCookies } from "react-cookie";
import URL from "~/utils/url";
import { uniqFeeds } from "~/utils/feeds";
import { FeatureFlags } from "~/enums";

const COOKIE_PREFIX = "fd_";

const rootDomain = () =>
  window?.location?.host &&
  `.${window.location.host
    .split(".")
    .reverse()
    .splice(0, 2)
    .reverse()
    .join(".")}`;

const cookieOptions = () => ({
  domain: rootDomain(),
  path: "/",
  maxAge: 60 * 60 * 24 * 365,
});

const CUID_COOKIE_NAME = `${COOKIE_PREFIX}c`;
const UID_COOKIE_NAME = `${COOKIE_PREFIX}u`;
const HOST_COOKIE_NAME = `${COOKIE_PREFIX}host`;
const BEARER_TOKEN_COOKIE_NAME = `${COOKIE_PREFIX}bearer`;
const PREFERENCES_COOKIE_NAME = `${COOKIE_PREFIX}p`;
const DEVELOPMENT_COOKIE_NAME = `${COOKIE_PREFIX}d`;
const DEV_CARD_FILTER_COOKIE_NAME = `${COOKIE_PREFIX}df`;
const DEV_LAYOUT_FILTER_COOKIE_NAME = `${COOKIE_PREFIX}dlf`;
const DEBUG_COOKIE_NAME = `${COOKIE_PREFIX}debug`;
const DEBUG_LAYERS_COOKIE_NAME = `${COOKIE_PREFIX}dd`;
const AFTER_LOGIN_DESTINATION_COOKIE_NAME = `${COOKIE_PREFIX}goto`;
const RECENT_SEARCH_RESULTS_COOKIE_NAME = `${COOKIE_PREFIX}rs`;
const FEATURE_FLAG_COOKIE_NAME = `${COOKIE_PREFIX}ff`;

export const useGetRecentSearchResults = () => {
  const [cookies] = useCookies([RECENT_SEARCH_RESULTS_COOKIE_NAME]);
  return (): Array<FC.Group> =>
    cookies[RECENT_SEARCH_RESULTS_COOKIE_NAME] || [];
};

export const useAddRecentSearchResults = () => {
  const [cookies, setCookie] = useCookies([RECENT_SEARCH_RESULTS_COOKIE_NAME]);
  return (searchResult: FC.Group) => {
    const currentValue = cookies[RECENT_SEARCH_RESULTS_COOKIE_NAME] || [];
    const recentSearchResults = uniqFeeds([
      searchResult,
      ...currentValue,
    ]).slice(0, 5);
    setCookie(
      RECENT_SEARCH_RESULTS_COOKIE_NAME,
      recentSearchResults,
      cookieOptions()
    );
  };
};

export const useClearAfterLoginDestination = () => {
  const [_cookies, _setCookie, removeCookie] = useCookies([
    AFTER_LOGIN_DESTINATION_COOKIE_NAME,
  ]);
  return () =>
    removeCookie(AFTER_LOGIN_DESTINATION_COOKIE_NAME, cookieOptions());
};

export const useGetAfterLoginDestination = () => {
  const [cookies] = useCookies([AFTER_LOGIN_DESTINATION_COOKIE_NAME]);
  return () => cookies[AFTER_LOGIN_DESTINATION_COOKIE_NAME];
};

export const useSetAfterLoginDestination = () => {
  const [_cookies, setCookie] = useCookies([
    AFTER_LOGIN_DESTINATION_COOKIE_NAME,
  ]);
  return (value: string) =>
    setCookie(AFTER_LOGIN_DESTINATION_COOKIE_NAME, value, cookieOptions());
};

export const useSetDebugCookie = () => {
  const [_cookies, setCookie] = useCookies([DEBUG_COOKIE_NAME]);
  return (value: string) =>
    setCookie(DEBUG_COOKIE_NAME, value, cookieOptions());
};

export const useGetDebugCookie = () => {
  const [cookies] = useCookies([DEBUG_COOKIE_NAME]);
  return () => cookies[DEBUG_COOKIE_NAME];
};

export const useSetCUIDCookie = () => {
  const [_cookies, setCookie] = useCookies([CUID_COOKIE_NAME]);
  return (value: string) => setCookie(CUID_COOKIE_NAME, value, cookieOptions());
};

export const useGetCUIDCookie = () => {
  const [cookies] = useCookies([CUID_COOKIE_NAME]);
  return () => cookies[CUID_COOKIE_NAME];
};

export const useGetUIDCookie = () => {
  const [cookies] = useCookies([UID_COOKIE_NAME]);
  return () => cookies[UID_COOKIE_NAME];
};

export const useGetHostCookie = () => {
  const [cookies] = useCookies([HOST_COOKIE_NAME]);
  return () => cookies[HOST_COOKIE_NAME];
};

export const useGetBearerTokenCookie = () => {
  const [cookies] = useCookies([BEARER_TOKEN_COOKIE_NAME]);
  return () => cookies[BEARER_TOKEN_COOKIE_NAME];
};

export const useSetUIDCookie = () => {
  const [_cookies, setCookie] = useCookies([UID_COOKIE_NAME]);
  return ({ id, url }: FC.Credentials) => {
    const u = new URL(url);
    setCookie(UID_COOKIE_NAME, btoa(`${id}|${u.host}`), cookieOptions());
  };
};

export const useClearUIDCookie = () => {
  const [_cookies, _setCookie, removeCookie] = useCookies([UID_COOKIE_NAME]);
  return () => removeCookie(UID_COOKIE_NAME, cookieOptions());
};

export const useGetPreferencesCookie = () => {
  const [cookies] = useCookies([PREFERENCES_COOKIE_NAME]);
  return () => {
    const preferencesValue = cookies[PREFERENCES_COOKIE_NAME];
    if (preferencesValue) {
      return JSON.parse(atob(preferencesValue));
    }
  };
};

export const useSetPreferencesCookie = () => {
  const [_cookies, setCookie] = useCookies([PREFERENCES_COOKIE_NAME]);
  return (preferences: FC.Preferences) => {
    setCookie(
      PREFERENCES_COOKIE_NAME,
      btoa(JSON.stringify(preferences)),
      cookieOptions()
    );
  };
};

export const useGetFeatureFlagsCookie = () => {
  const [cookies] = useCookies([FEATURE_FLAG_COOKIE_NAME]);
  return (): Record<FeatureFlags, boolean> => {
    const featureFlagsValue = cookies[FEATURE_FLAG_COOKIE_NAME];
    return featureFlagsValue || {};
  };
};

export const useSetFeatureFlagsCookie = () => {
  const [_cookies, setCookie] = useCookies([FEATURE_FLAG_COOKIE_NAME]);
  return (featureFlags: Record<FeatureFlags, boolean>) =>
    setCookie(FEATURE_FLAG_COOKIE_NAME, featureFlags, cookieOptions());
};

/** development Only cookies */

export const useSetDevelopmentCookie = () => {
  const [_cookies, setCookie] = useCookies([DEVELOPMENT_COOKIE_NAME]);
  return (value: boolean) =>
    setCookie(DEVELOPMENT_COOKIE_NAME, value, cookieOptions());
};

export const useGetDevelopmentCookie = () => {
  const [cookies] = useCookies([DEVELOPMENT_COOKIE_NAME]);
  return () => cookies[DEVELOPMENT_COOKIE_NAME] === "true";
};

export const useGetDevCardFilterCookie = () => {
  const [cookies] = useCookies([DEV_CARD_FILTER_COOKIE_NAME]);
  return () => Number(cookies[DEV_CARD_FILTER_COOKIE_NAME]) || 0;
};

export const useSetDevCardFilterCookie = () => {
  const [_cookies, setCookie] = useCookies([DEV_CARD_FILTER_COOKIE_NAME]);
  return (value: number) =>
    setCookie(DEV_CARD_FILTER_COOKIE_NAME, value, cookieOptions());
};

export const useGetDebugLayersCookie = () => {
  const [cookies] = useCookies([DEBUG_LAYERS_COOKIE_NAME]);
  return () => cookies[DEBUG_LAYERS_COOKIE_NAME] === "true";
};

export const useSetDebugLayersCookie = () => {
  const [_cookies, setCookie] = useCookies([DEBUG_LAYERS_COOKIE_NAME]);
  return (value: boolean) =>
    setCookie(DEBUG_LAYERS_COOKIE_NAME, value, cookieOptions());
};

export const useGetDevLayoutFilterCookie = () => {
  const [cookies] = useCookies([DEV_LAYOUT_FILTER_COOKIE_NAME]);
  return () => {
    return cookies[DEV_LAYOUT_FILTER_COOKIE_NAME] || [];
  };
};

export const useSetDevLayoutFilterCookie = () => {
  const [_cookies, setCookie] = useCookies([DEV_LAYOUT_FILTER_COOKIE_NAME]);
  return (value: Array<string>) =>
    setCookie(DEV_LAYOUT_FILTER_COOKIE_NAME, value, cookieOptions());
};
