import isEqual from "lodash-es/isEqual";
import differenceBy from "lodash-es/differenceBy";
import {
  selectFilteredGroupsWithPreferences,
  selectAllGroupsWithPreferences,
  selectFavoriteFeeds,
  selectRecentFavoriteFeeds,
  selectFeaturedGroups,
} from "~/concepts/groups";
import { useAppSelector } from "~/hooks";
import { usePreferences } from "~/hooks/usePreferences";

export const useSelectFavoriteFeeds = () => {
  const [preferences] = usePreferences();
  return useAppSelector(selectFavoriteFeeds(preferences), isEqual);
};

export const useSelectFeatureFeeds = () => {
  const feeds = useAppSelector(selectFeaturedGroups, isEqual);
  const favorites = useSelectFavoriteFeeds();
  return differenceBy(feeds, favorites, "id");
};

export const useSelectRecentFavoriteFeeds = () => {
  const [preferences] = usePreferences();
  return useAppSelector(selectRecentFavoriteFeeds(preferences), isEqual);
};

export const useSelectAllGroupsWithPreferences = () => {
  const [preferences] = usePreferences();
  return useAppSelector(selectAllGroupsWithPreferences(preferences), isEqual);
};

export const useSelectFilteredGroupsWithPreferences = () => {
  const [preferences] = usePreferences();
  return useAppSelector(
    selectFilteredGroupsWithPreferences(preferences),
    isEqual
  );
};
