import React, { memo, useState } from "react";

import Icon from "@expo/vector-icons/MaterialCommunityIcons";

import { useTheme, makeStyles } from "@rneui/themed";
import { createStyleSheet, SPACING } from "~/styles";

import { Column, Popover } from "~/components/elements";
import Hoverable from "~/components/elements/Hoverable";

export const DismissTooltipContext = React.createContext<() => void>(() => {});

interface OverflowMenuProps {
  item?: FC.Item;
  overlay?: boolean;
  children: React.ReactNode;
}

const OverflowMenu: React.FC<OverflowMenuProps> = ({
  item,
  overlay,
  children,
}) => {
  const [open, setOpen] = useState(false);

  const { theme } = useTheme();
  const styles = useStyles();
  if (!item) return null;

  let buttonColor = overlay ? theme.colors.overlay : theme.colors.primary;

  let iconBackgroundColor = overlay
    ? theme.colors.backgroundHoverOverlay
    : theme.colors.backgroundHover;
  return (
    <Hoverable>
      {(isHovered) => (
        <Popover
          PopoverComponent={
            <DismissTooltipContext.Provider value={() => setOpen(false)}>
              <Column style={styles.overflowTooltip}>{children}</Column>
            </DismissTooltipContext.Provider>
          }
          open={open}
          setOpen={setOpen}
        >
          <Icon
            name="dots-vertical"
            size={20}
            color={buttonColor}
            style={{
              paddingHorizontal: SPACING.BASE,
              paddingVertical: SPACING.MEDIUM,
              backgroundColor: isHovered ? iconBackgroundColor : undefined,
            }}
          />
        </Popover>
      )}
    </Hoverable>
  );
};

const useStyles = makeStyles((theme) => {
  return createStyleSheet({
    container: {
      flex: 1,
      borderWidth: 1,
      borderColor: theme.colors.dividerPrimary,
      zIndex: 1,
    },
    overflowTooltip: {
      justifyContent: "flex-start",
      width: "100%",
      gap: 0,
      backgroundColor: theme.colors.background,
      borderWidth: 1,
      borderColor: theme.colors.dividerTertiary,
      shadowOffset: { width: 10, height: 10 },
      shadowColor: "#000000",
      shadowOpacity: 0.188,
      shadowRadius: 16,
    },
    overflowButton: {
      flexDirection: "row",
      gap: SPACING.BASE,
      padding: SPACING.SMALL,
      width: "100%",
    },
    hoverIn: {
      backgroundColor: theme.colors.primaryReverse,
    },
    hoverOut: {
      backgroundColor: theme.colors.primary,
    },
    text: {
      gap: SPACING.SMALL,
      fontWeight: "bold",
      color: theme.colors.primaryReverse,
    },
    textHoverIn: {
      color: theme.colors.primary,
    },
  });
});

export default memo(OverflowMenu);
