import VigetTwoUp from "~/components/pages/VigetTwoUp";
import { isFeatureItem } from "~/predicates";

const vigetTwoUpRestrictive: FC.PagePresentationStrategy = ({ items }) => {
  const featureItems = items.filter(isFeatureItem);
  const one = featureItems[0];
  const two = featureItems.find((i) => i.type !== one.type);
  if (one && two) {
    return {
      usedItems: [one, two],
      Component: VigetTwoUp,
    };
  }
  return null;
};

export default vigetTwoUpRestrictive;
