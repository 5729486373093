import React, { memo } from "react";
import BaseCard from "~/components/cards/BaseCard";
import { Row } from "~/components/elements";

const Single: React.FC<
  FC.PageStrategyComponentProps & {
    CardComponent?: React.JSXElementConstructor<FC.CardComponentProps>;
  }
> = ({ items, contentWidth, contentHeight, CardComponent }) => (
  <Row
    flex
    style={{
      width: contentWidth || "100%",
      height: contentHeight || "100%",
    }}
  >
    <BaseCard
      item={items[0]}
      width={contentWidth}
      CardComponent={CardComponent}
    />
  </Row>
);
export default memo(Single);
