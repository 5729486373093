import { makeStyles } from "@rneui/themed";
import uniq from "lodash-es/uniq";
import React, { memo, useState } from "react";
import { FlatList } from "react-native";
import { useGetListsQuery, useLazyGetListAccountsQuery } from "~/api";
import { Row, Button, Text, Divider, Dialog } from "~/components/elements";
import { TextSize } from "~/enums";
import { safeAcct } from "~/lib/safeAcct";
import { createStyleSheet, SPACING } from "~/styles";
import CustomFeedBuilderImportEdu from "~/components/edu/CustomFeedBuilderImportEdu";
import { useGetHostCookie } from "~/hooks/useCookies";
import CloseButton from "~/buttons/CloseButton";
import { useAppSelector } from "~/hooks";
import { isPhoneSelector } from "~/concepts/application";

const CustomFeedFormImportFromLists: React.FC<{
  formValue: FC.CustomFeedConfiguration;
  setFormValue: (formValue: FC.CustomFeedConfiguration) => void;
}> = ({ formValue, setFormValue }) => {
  const isPhone = useAppSelector(isPhoneSelector);
  const styles = useStyles();
  const getHostCookie = useGetHostCookie();
  const [visible, setVisible] = useState(false);
  const { data } = useGetListsQuery();
  const [getListAccountsTrigger, getListAccountsResponse] =
    useLazyGetListAccountsQuery();

  if (!data || data.length === 0) {
    return null;
  }

  const importList = (id: string) => () => {
    getListAccountsTrigger(id).then(({ data: accounts }) => {
      setVisible(false);
      if (accounts && getHostCookie()) {
        setFormValue({
          ...formValue,
          includes: uniq([
            ...accounts.map((a) => safeAcct(a.acct, getHostCookie())),
            ...formValue.includes,
          ]),
        });
      }
    });
  };

  return (
    <>
      <Button
        title={"Import List"}
        onPress={() => setVisible(true)}
        containerStyle={{ borderRadius: SPACING.BASE }}
        buttonStyle={{
          gap: SPACING.BASE,
          borderRadius: SPACING.BASE,
        }}
        type={"outline"}
      />
      <Dialog
        fullScreen={isPhone}
        isVisible={visible}
        onBackdropPress={() => setVisible(false)}
      >
        <CloseButton
          onPress={() => setVisible(false)}
          style={styles.closeButton}
        />
        <Dialog.Title>Import users from your mastodon lists</Dialog.Title>
        <CustomFeedBuilderImportEdu />
        <Divider style={styles.divider} />
        <FlatList
          data={data}
          renderItem={({ item: { id, title } }) => (
            <Row style={styles.listItem} evenlySpaced flex>
              <Text size={TextSize.XS}>{title}</Text>
              <Button
                title={"Import"}
                onPress={importList(id)}
                disabled={getListAccountsResponse.isLoading}
                loading={getListAccountsResponse.isLoading}
                buttonStyle={{
                  gap: SPACING.BASE,
                  borderRadius: SPACING.BASE,
                }}
                containerStyle={styles.importListButtonContainer}
              />
            </Row>
          )}
        />
      </Dialog>
    </>
  );
};

const useStyles = makeStyles((theme) =>
  createStyleSheet({
    importForm: {
      borderWidth: 1,
      borderColor: theme.colors.dividerPrimary,
      borderRadius: SPACING.SMALL,
      margin: -`${SPACING.SMALL}`,
      padding: SPACING.SMALL,
      shadowColor: theme.colors.dividerPrimary,
      shadowRadius: SPACING.SMALL,
      shadowOffset: { width: 0, height: 0 },
      maxHeight: 160,
    },
    listItem: {
      marginBottom: SPACING.BASE,
      paddingHorizontal: SPACING.LARGE,
    },
    importListButtonContainer: {
      borderRadius: SPACING.BASE,
      borderWidth: 1,
      borderColor: theme.colors.dividerPrimary,
    },
    closeButton: {
      position: "absolute",
      top: SPACING.BASE,
      right: SPACING.BASE,
      zIndex: 1,
    },
    divider: {
      marginTop: SPACING.LARGE,
      marginBottom: SPACING.LARGE,
    },
  })
);

export default memo(CustomFeedFormImportFromLists);
