import React, { memo, useEffect, useState } from "react";
import { Button, Column, Divider, Row } from "~/components/elements";
import { CardSize } from "~/enums";
import { SPACING, createStyleSheet } from "~/styles";
import { makeStyles, useTheme } from "@rneui/themed";
import BaseCard from "~/components/cards/BaseCard";
import { ScrollView } from "react-native-gesture-handler";
import { isPhoneSelector } from "~/concepts/application";
import { useAppSelector } from "~/hooks";
import { isEqual } from "lodash-es";
import { BackgroundColorContext } from "~/contexts/BackgroundColorContext";
import AutoNumberOfLinesWrapper from "~/components/elements/AutoNumberOfLinesWrapper";
import Content from "~/components/Content";
import StatusCard from "~/components/cards/StatusCard";
import CardAuthor from "~/components/CardAuthor";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import ItemFocusDrawerButton from "~/components/cardComponents/ItemFocusDrawerButton";
import { useGetInstanceItemQuery, useLazyStatusContextQuery } from "~/api";

const INITIAL_DISPLAY_COUNT = 3;
const INITIAL_DISPLAY_COUNT_MOBILE = 2;
const BUBBLE_ARROW_HEIGHT = 80;

const COMMENTARY_CARD_HEIGHT = 200;

const ConversationRollup: FC.PageStrategyResult["Component"] = ({
  items,
  contentWidth,
}) => {
  const isPhone = useAppSelector(isPhoneSelector);
  const styles = useStyles({ isPhone, contentWidth });
  const { theme } = useTheme();

  const previewItem = items[0].reblog || items[0];
  const [replyItems, setReplyItems] = useState([] as Array<FC.Item>);

  const [showAll, setShowAll] = useState(false);
  const [displayItems, setDisplayItems] = useState([] as Array<FC.Item>);
  const { data: instanceItemData } = useGetInstanceItemQuery(
    previewItem.source
  );
  const [getStatusContextTrigger, getStatusContextResponse] =
    useLazyStatusContextQuery();

  useEffect(() => {
    if (instanceItemData && getStatusContextResponse.isUninitialized) {
      getStatusContextTrigger(instanceItemData.id);
    }
  }, [instanceItemData, getStatusContextTrigger, getStatusContextResponse]);

  useEffect(() => {
    setReplyItems(getStatusContextResponse.data?.descendants || []);
  }, [getStatusContextResponse.data]);

  useEffect(() => {
    let newDisplayItems = replyItems;
    if (!showAll)
      newDisplayItems = newDisplayItems.slice(
        0,
        isPhone ? INITIAL_DISPLAY_COUNT_MOBILE : INITIAL_DISPLAY_COUNT
      );

    if (!isEqual(displayItems, newDisplayItems))
      setDisplayItems(newDisplayItems);
  }, [displayItems, isPhone, showAll, replyItems]);

  return (
    <Row flex gap={SPACING.BASE8X} style={styles.conversationRollupCard}>
      <ItemFocusDrawerButton
        flex={2}
        item={previewItem}
        style={styles.mainStatusItemWrapper}
      >
        <Column flex noGap style={[styles.alignCenter, styles.justifyCenter]}>
          <Row flexShrink style={styles.mainStatusItemBubble}>
            <AutoNumberOfLinesWrapper>
              <Content
                item={previewItem}
                textSize={22}
                style={styles.fullHeight}
              />
            </AutoNumberOfLinesWrapper>
          </Row>

          <Row style={styles.bubbleAttribution}>
            <Row style={styles.bubbleArrow} />
            <CardAuthor
              item={previewItem}
              style={styles.flex1}
              avatarSize={SPACING.BASE5X}
            />
          </Row>
        </Column>
      </ItemFocusDrawerButton>
      <Column flexShrink style={[styles.alignCenter, styles.justifyCenter]}>
        <ScrollView
          horizontal={isPhone}
          pagingEnabled
          snapToInterval={
            isPhone ? undefined : COMMENTARY_CARD_HEIGHT + SPACING.BASE
          }
          style={[{ flexGrow: SPACING.NONE }, isPhone && styles.fullWidth]}
          contentContainerStyle={[{ gap: SPACING.BASE }]}
        >
          <Column>
            {displayItems.map((item, index) => (
              <>
                <BackgroundColorContext.Provider
                  value={"transparent"}
                  key={`${item.id}_${index}`}
                >
                  <BaseCard
                    item={item}
                    cardSize={CardSize.SMALL}
                    CardComponent={StatusCard}
                    componentProps={{
                      margin: SPACING.NONE,
                      padding: SPACING.NONE,
                    }}
                    style={[
                      styles.converstionReplyCard,
                      isPhone && {
                        marginLeft: index === 0 ? SPACING.BASE3X : SPACING.BASE,
                        marginRight:
                          index === displayItems.length - 1
                            ? SPACING.BASE3X
                            : SPACING.NONE,
                      },
                    ]}
                  />
                </BackgroundColorContext.Provider>
                <Divider />
              </>
            ))}
          </Column>
        </ScrollView>
        {!isPhone && replyItems.length > INITIAL_DISPLAY_COUNT && (
          <BackgroundColorContext.Provider value={theme.colors.background}>
            <Button
              style={[styles.fullWidth]}
              buttonStyle={[styles.moreButton]}
              title={
                showAll
                  ? "Show less"
                  : `Show ${replyItems.length - INITIAL_DISPLAY_COUNT} more`
              }
              icon={
                <Icon
                  name={showAll ? "chevron-down" : "chevron-up"}
                  color={theme.colors.primary}
                  size={24}
                />
              }
              iconRight
              color={theme.colors.primaryReverse}
              type="solid"
              onPress={() => {
                setShowAll(!showAll);
              }}
            />
          </BackgroundColorContext.Provider>
        )}
      </Column>
    </Row>
  );
};
const useStyles = makeStyles(
  (
    theme,
    { isPhone, contentWidth }: { isPhone?: boolean; contentWidth?: number }
  ) =>
    createStyleSheet({
      conversationRollupCard: {
        padding: SPACING.BASE10X,
        backgroundColor: theme.colors.backgroundSecondary,
      },
      mainStatusItemWrapper: {
        paddingVertical: SPACING.LARGE,
      },
      mainStatusItemBubble: {
        width: "100%",
        backgroundColor: theme.colors.background,
        padding: SPACING.BASE5X,
        borderRadius: SPACING.LARGE,
      },
      bubbleAttribution: {
        backgroundColor: "transparent",
        width: "100%",
      },

      bubbleArrow: {
        marginLeft: SPACING.BASE7X,
        top: -(BUBBLE_ARROW_HEIGHT / 2),
        width: 0,
        height: 0,
        borderRightWidth: (BUBBLE_ARROW_HEIGHT / 2) * 1.5,
        borderBottomWidth: BUBBLE_ARROW_HEIGHT / 2,
        borderTopWidth: BUBBLE_ARROW_HEIGHT / 2,
        borderRightColor: theme.colors.background,
        borderBottomColor: "transparent",
        borderTopColor: "transparent",
      },

      converstionReplyCard: {
        height: "unset",
        width: isPhone && contentWidth && contentWidth - SPACING.BASE6X,
        minWidth:
          !isPhone &&
          (contentWidth
            ? Math.min(400, contentWidth && contentWidth / 3 - SPACING.BASE3X)
            : 400),
        maxWidth:
          !isPhone &&
          (contentWidth
            ? Math.min(500, contentWidth && contentWidth / 3 - SPACING.BASE3X)
            : 500),
      },
      moreButton: {
        borderColor: "transparent",
        justifyContent: "space-between",
      },
    })
);

export default memo(ConversationRollup);
