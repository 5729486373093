import {
  AnyAction,
  combineReducers,
  configureStore,
  Reducer,
} from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import applicationReducer from "~/concepts/application";
import authReducer from "~/concepts/auth";
import cardHoverReducer from "~/concepts/cardHover";
import paginationReducer from "~/concepts/pagination";
import groupsReducer from "~/concepts/groups";
import threadReducer from "~/concepts/thread";
import navigationReducer from "~/concepts/navigation";
import customFeedBuilderReducer from "~/concepts/customFeedBuilder";
import api from "~/api";
import dynconfig from "~/dynconfig";

const reducerMap = {
  api: api.reducer,
  dynconfig: dynconfig.reducer,
  application: applicationReducer,
  auth: authReducer,
  cardHover: cardHoverReducer,
  groups: groupsReducer,
  pagination: paginationReducer,
  thread: threadReducer,
  navigation: navigationReducer,
  customFeedBuilder: customFeedBuilderReducer,
};
const combinedReducer = combineReducers(reducerMap);
type CombinedReducerState = ReturnType<typeof combinedReducer>;

export const RESET_ACTION = { type: "RESET_EVERYTHING" };
const rootReducer: Reducer = (state: CombinedReducerState, action: AnyAction) =>
  combinedReducer(
    action.type === RESET_ACTION.type ? undefined : state,
    action
  );

const store = configureStore({
  devTools: true,
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: { warnAfter: 128 },
      serializableCheck: { warnAfter: 128 },
    }).concat(api.middleware, dynconfig.middleware),
});

setupListeners(store.dispatch);

export type RootState = CombinedReducerState;
export type AppDispatch = typeof store.dispatch;

if (window) {
  window.$store = store;
}

export default store;
