import VigetGalleryRow from "~/components/pages/VigetGalleryRow";
import { isVideo, isYoutubeShort } from "~/predicates";

const vigetGalleryAVOneUp: FC.PagePresentationStrategy = ({ items }) => {
  const supportedTypes = items
    .filter(isVideo)
    .filter((i) => !isYoutubeShort(i));

  if (supportedTypes.length >= 1) {
    return {
      usedItems: supportedTypes.slice(0, 1),
      Component: VigetGalleryRow,
      // repeat: true,
    };
  }
  return null;
};

export default vigetGalleryAVOneUp;
