import { useCallback } from "react";
import { useLocation, useNavigate } from "~/routing";
import { useSelectAllGroupsWithPreferences } from "~/hooks/useSelectGroupsWithPreferences";
import { authorFeedFromAuthor, hashtagGroupFromHashtag } from "~/utils/groups";
import { useAppDispatch, useAppSelector } from "~/hooks";
import { selectApplicationMode } from "~/concepts/application";
import { ApplicationMode } from "~/enums";
import { setCustomFeedBuilderPreviewFeed } from "~/concepts/customFeedBuilder";
import { useGetHostCookie } from "~/hooks/useCookies";
import { setFollowingThreadLink } from "~/concepts/thread";
import { NavigateOptions } from "react-router-native";
import { idFromAuthorUrl, idFromHashtagName } from "~/utils/id";

export const useFeedNavigation = () => {
  const groups = useSelectAllGroupsWithPreferences();
  const navigate = useNavigate();
  const location = useLocation();
  const applicationMode = useAppSelector(selectApplicationMode);
  const getHostCookie = useGetHostCookie();
  const dispatch = useAppDispatch();

  const navigateToGroup = useCallback(
    (group: FC.Group, navigateOptions: NavigateOptions = {}) => {
      const groupIndex = groups.findIndex((g) => g.id === group.id);
      if (groupIndex > -1) {
        if (location.pathname !== `/feed/${encodeURIComponent(group.id)}`) {
          navigate(`/feed/${encodeURIComponent(group.id)}`, navigateOptions);
        }
      }
    },
    [navigate, groups, location.pathname]
  );

  const navigateToGroupById = useCallback(
    (id: FC.Group["id"], navigateOptions?: NavigateOptions) => {
      const foundGroup = groups.find((g) => g.id === id);

      if (foundGroup) {
        navigateToGroup(foundGroup, navigateOptions);
      }
    },
    [groups, navigateToGroup]
  );
  const navigateToCustomFeed = useCallback(
    (customFeed: FC.CustomFeed, navigateOptions?: NavigateOptions) => {
      const foundGroup = groups.find((g) => g.requestKey === customFeed.id);

      if (foundGroup) {
        navigateToGroup(foundGroup, navigateOptions);
      }
    },
    [groups, navigateToGroup]
  );

  const navigateToAccountFeed = (author: FC.Author | string) => {
    if (
      applicationMode === ApplicationMode.CUSTOM_FEED_BUILDER &&
      typeof author !== "string"
    ) {
      dispatch(
        setCustomFeedBuilderPreviewFeed(
          authorFeedFromAuthor(author, getHostCookie(), applicationMode)
        )
      );
    } else if (
      applicationMode === ApplicationMode.BASE &&
      typeof author !== "string"
    ) {
      navigate(`/feed/${idFromAuthorUrl(author.uri)}`);
    } else if (
      typeof author === "string" &&
      applicationMode === ApplicationMode.BASE
    ) {
      navigate(`/feed/${idFromAuthorUrl(author)}`);
    }
  };

  const navigateToHashtag = (hashtag: string) => {
    if (hashtag) {
      if (applicationMode === ApplicationMode.CUSTOM_FEED_BUILDER) {
        dispatch(
          setCustomFeedBuilderPreviewFeed(
            hashtagGroupFromHashtag(hashtag, applicationMode)
          )
        );
      } else {
        dispatch(setFollowingThreadLink(true));
        navigate(`/feed/${idFromHashtagName(hashtag)}`);
      }
    }
  };

  return {
    navigateToGroup,
    navigateToGroupById,
    navigateToCustomFeed,
    navigateToAccountFeed,
    navigateToHashtag,
  };
};
