import React, { memo } from "react";

import CopyLink from "~/components/CardActions/CopyLink";
import DeleteStatus from "~/components/CardActions/DeleteStatus";
import OwnInstanceUrl from "~/components/CardActions/OwnInstanceUrl";
import UrlButton from "~/components/elements/URLButton";
import FeedFilterDialog from "~/components/FeedFilterDialog";

const SecondaryActions: React.FC<{
  item: FC.Item;
}> = ({ item }) => {
  return (
    <>
      {item.capabilities.canFilter && <FeedFilterDialog item={item} />}
      <CopyLink item={item} />
      <UrlButton
        title={"View Original"}
        icon={"open-in-new"}
        url={item.targetUrl}
      />
      {item.capabilities.canViewOnMastodon && <OwnInstanceUrl item={item} />}
      <DeleteStatus item={item} />
    </>
  );
};

export default memo(SecondaryActions);
