import React, { memo } from "react";
import { CardSize, ContentPosition, ItemType } from "~/enums";
import shuffleSeed from "shuffle-seed";
import FlexibleImageCard from "~/components/cards/FlexibleImageCard";
import BaseCard from "~/components/cards/BaseCard";

import { FixDimensionType } from "~/components/ItemImage";

const GalleryOneUp: FC.PageStrategyResult["Component"] = ({
  items,
  contentHeight,
  seed,
}) => {
  const isImageCoverRight = shuffleSeed.shuffle([true, false], seed);
  const item = items[0];
  const { image } = item;
  if (!image?.width) return null;

  const aspectRatio =
    image?.aspectRatio ||
    (image?.height && image?.width && image?.width / image?.height) ||
    1;

  const contentPosition = isImageCoverRight[0]
    ? ContentPosition.RIGHT
    : ContentPosition.LEFT;

  return (
    <BaseCard
      item={item}
      height={contentHeight}
      cardSize={CardSize.XLARGE}
      ComponentMap={{
        [ItemType.SOCIAL_ARTICLE]: FlexibleImageCard,
        [ItemType.ARTICLE]: FlexibleImageCard,
        [ItemType.PHOTO]: FlexibleImageCard,
      }}
      componentProps={{
        maxNumberTruncate: 1,
        fixDimension: FixDimensionType.HEIGHT,
        height: contentHeight,
        contentPosition: contentPosition,
        imageAspectRatio: aspectRatio,
      }}
    />
  );
};

export default memo(GalleryOneUp);
