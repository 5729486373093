import React from "react";
import { selectCredentials } from "~/concepts/auth";
import { useAppSelector } from "~/hooks";
import URL from "~/utils/url";
import UrlButton from "~/components/elements/URLButton";

type OwnInstanceUrlProps = {
  item: FC.Item;
};

const OwnInstanceUrl: React.FC<OwnInstanceUrlProps> = ({ item }) => {
  const authenticated = useAppSelector(selectCredentials);
  if (!authenticated) {
    return null;
  }
  const myHost = authenticated?.url?.split(/@\w+/)[0];
  const {
    author: { acct },
    id,
  } = item;
  if (!myHost || !acct || !id) return null;

  const myHostServer = new URL(myHost).host;
  const instanceUrl = `${authenticated?.url.split("@")[0]}@${
    item?.author.acct
  }/${item?.id}`;

  return (
    <UrlButton
      title={`View on @${myHostServer}`}
      icon={"link-variant"}
      url={instanceUrl}
    />
  );
};

export default OwnInstanceUrl;
