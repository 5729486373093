import VigetGalleryRow from "~/components/pages/VigetGalleryRow";
import {
  isGalleryItem,
  isGallerySupportedType,
  isGifv,
  isLandscapeOrTallerImageItem,
} from "~/predicates";

const vigetGalleryTwoUpLandscape: FC.PagePresentationStrategy = ({ items }) => {
  const supportedTypes = items.filter(isGallerySupportedType);
  const usableItems = supportedTypes.filter(
    (i) => (isGalleryItem(i) && isLandscapeOrTallerImageItem(i)) || isGifv(i)
  );

  if (usableItems.length >= 2) {
    return {
      usedItems: usableItems.slice(0, 2),
      Component: VigetGalleryRow,
      repeat: true,
    };
  }
  return null;
};

export default vigetGalleryTwoUpLandscape;
