import SocialArticleRollupClassic from "~/components/pages/SocialArticleRollupClassic";
import groupBy from "lodash-es/groupBy";
import { isArticleItem, isSocialArticleItem } from "~/predicates";

// leaving in for now to deal with non-maestra feeds
const socialArticleRollupClassic: FC.PagePresentationStrategy = ({ items }) => {
  const articles = items
    .filter((i) => isArticleItem(i) || isSocialArticleItem(i))
    .filter((i) => {
      const renderItem = i.reblog || i;
      return !!renderItem?.card?.url;
    });
  const grouped = groupBy(articles, (i) => {
    const renderItem = i.reblog || i;
    return renderItem.card?.url;
  });

  const rollup = Object.values(grouped).find((group) => group.length > 1);
  if (rollup) {
    const sampleItem = rollup[0].reblog || rollup[0];
    return {
      usedItems: rollup,
      Component: SocialArticleRollupClassic,
      repeat: true,
      filters: [
        (i) => {
          if (!sampleItem.card?.url) {
            return true;
          }
          const renderItem = i.reblog || i;
          return renderItem.card?.url !== sampleItem.card.url;
        },
      ],
    };
  }
  return null;
};

export default socialArticleRollupClassic;
