import OneUp from "~/components/pages/OneUp";
import {
  isArticleItem,
  isImageAttachmentItem,
  isFeatureableImageItem,
} from "~/predicates";

const vigetOneUpBigImage: FC.PagePresentationStrategy = ({ items }) => {
  const articleItems = items
    .filter(isArticleItem)
    .filter(isImageAttachmentItem)
    .filter(isFeatureableImageItem);
  if (articleItems.length > 0) {
    return {
      usedItems: articleItems.slice(0, 1),
      Component: OneUp,
      repeat: true,
    };
  }
  return null;
};

export default vigetOneUpBigImage;
