import { useState } from "react";
import { authorFeedFromAuthor, hashtagGroupFromHashtag } from "~/utils/groups";
import { ApplicationMode } from "~/enums";
import {
  isHashtagCustomFeedValue,
  isRSSFeedCustomFeedValue,
  isUserCustomFeedValue,
} from "~/utils/customFeedValue";
import { api } from "~/api";
import { useAppDispatch } from "~/hooks";
import { useGetHostCookie } from "~/hooks/useCookies";

export const useFeedFromCustomFeedValue = () => {
  const [feed, setFeed] = useState<FC.Group | null>(null);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const getHostCookie = useGetHostCookie();

  const rssResolver = async (id: string) => {
    const response = await dispatch(api.endpoints.getRSSFeed.initiate(id));
    return response.data;
  };

  const hashtagResolver = async (id: string) =>
    hashtagGroupFromHashtag(id.replace(/^#/, ""), ApplicationMode.BASE);

  const userResolver = async (id: string) => {
    const response = await dispatch(
      api.endpoints.getInstanceAuthor.initiate({
        acct: id,
      })
    );
    return response.data
      ? authorFeedFromAuthor(response.data, getHostCookie())
      : null;
  };

  const topicResolver = async (id: string) => {
    const response = await dispatch(api.endpoints.searchTopic.initiate(id));
    return response.data ? response.data[0] : null;
  };

  const getResolver = (id: string) => {
    if (isRSSFeedCustomFeedValue(id)) {
      return rssResolver;
    } else if (isHashtagCustomFeedValue(id)) {
      return hashtagResolver;
    } else if (isUserCustomFeedValue(id)) {
      return userResolver;
    }
    return topicResolver;
  };

  const feedFromCustomFeedValue = async (id: string) => {
    setIsLoading(true);
    const resolver = getResolver(id);
    try {
      if (!resolver) {
        throw new Error();
      } else {
        const resolvedFeed = await resolver(id);
        if (!resolvedFeed) {
          throw new Error();
        }
        setFeed(resolvedFeed);
        setIsError(false);
        setIsLoading(false);
      }
    } catch {
      setFeed(null);
      setIsError(true);
      setIsLoading(false);
    }
  };

  return {
    feedFromCustomFeedValue,
    feed,
    isError,
    isLoading,
  };
};
