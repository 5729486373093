import React from "react";
import { CardSize } from "~/enums";
import Poll from "~/components/cardComponents/Poll";

type ThreadPollCardProps = {
  item: FC.Item;
  cardSize?: CardSize;
};

const ThreadPollCard: React.FC<ThreadPollCardProps> = (props) => {
  return <Poll {...props} />;
};

export default ThreadPollCard;
