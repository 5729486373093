import React, { memo, useState } from "react";
import { createStyleSheet, SPACING, LAYOUT } from "~/styles";
import { makeStyles } from "@rneui/themed";

import Clipboard from "@react-native-clipboard/clipboard";

import { Button, Column, Text, Dialog } from "~/components/elements/";
import CloseButton from "~/buttons/CloseButton";
import { SERVER_HOST } from "~/config";
import { isPhoneSelector } from "~/concepts/application";
import { useAppSelector } from "~/hooks";

const ShareDialog: React.FC<{ group: FC.Group }> = ({ group }) => {
  const isPhone = useAppSelector(isPhoneSelector);
  const styles = useStyles({ isPhone });
  const [isCopied, setIsCopied] = useState(false);

  const [dialogVisible, setDialogVisible] = useState(false);

  const shareURL = `${SERVER_HOST}/feed/${group.id}`;
  return (
    <>
      <Button
        title={"Copy Link"}
        onPress={() => setDialogVisible(true)}
        type={"outline"}
        buttonStyle={{
          gap: SPACING.BASE,
          borderRadius: SPACING.BASE,
        }}
        containerStyle={{ borderRadius: SPACING.BASE }}
      />
      <Dialog
        isVisible={dialogVisible}
        onBackdropPress={() => setDialogVisible(!dialogVisible)}
        overlayStyle={styles.shareDialog}
      >
        <CloseButton
          onPress={() => setDialogVisible(false)}
          style={styles.closeButton}
        />
        <Column>
          <Dialog.Title>Share this Feed</Dialog.Title>
          <Text>Click to copy the URL:</Text>
          <Button
            title={shareURL}
            onPress={() => {
              Clipboard.setString(shareURL);
              setIsCopied(true);
            }}
            type={"outline"}
            buttonStyle={styles.copyButton}
            titleStyle={styles.url}
          />
          {isCopied && <Text style={styles.copiedNote}>Copied!</Text>}
        </Column>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles((theme, { isPhone }: { isPhone: boolean }) => {
  return createStyleSheet({
    shareDialog: isPhone
      ? {
          width: "100%",
          height: "100%",
        }
      : {
          width: LAYOUT.SHARE_DIALOG_WIDTH,
          height: LAYOUT.SHARE_DIALOG_HEIGHT,
        },
    closeButton: {
      position: "absolute",
      top: SPACING.BASE,
      right: SPACING.BASE,
      zIndex: 1,
    },
    url: { overflowWrap: "anywhere" },
    copyButton: {
      backgroundColor: theme.colors.backgroundSecondary,
      borderRadius: SPACING.SMALL,
    },
    copiedNote: { color: theme.colors.controlsRed, alignSelf: "center" },
  });
});

export default memo(ShareDialog);
