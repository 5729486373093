import { A, P } from "@expo/html-elements";
import { Platform, StyleProp, TextStyle } from "react-native";
import HtmlToJsonParser, { JSONType } from "html-to-json-parser";
import React, { memo, useEffect, useState } from "react";
import Text from "~/components/elements/Text";
import { Colors, makeStyles, useTheme } from "@rneui/themed";
import { createStyleSheet } from "~/styles";
import { useAppDispatch } from "~/hooks";
import { JSONContent } from "html-to-json-parser/dist/types";
import URL from "~/utils/url";
import { setFollowingThreadLink } from "~/concepts/thread";
import reverse from "lodash-es/reverse";
import { useFeedNavigation } from "~/hooks/useFeedNavigation";
import { CardSize, TextSize } from "~/enums";
import CardText from "~/components/cardComponents/CardText";

const TextStyleContext = React.createContext("");
const TextColorContext = React.createContext<string | undefined>("");
const TextHeaderContext = React.createContext<boolean | undefined>(false);

interface JSONAttributes {
  class?: string;
  href?: string;
}

interface JSONComponentProps {
  children: React.ReactNode;
  attributes?: JSONAttributes;
  overlay?: boolean;
  externalHashtag?: boolean;
}

const Mention: React.FC<JSONComponentProps> = ({ children, attributes }) => {
  const { navigateToAccountFeed } = useFeedNavigation();
  const dispatch = useAppDispatch();
  if (!attributes?.href) {
    return (
      <TextStyleContext.Provider value="mentionLinkText">
        {children}
      </TextStyleContext.Provider>
    );
  }

  return (
    <TextStyleContext.Provider value="mentionLinkText">
      <A
        onPress={() => {
          dispatch(setFollowingThreadLink(true));
          navigateToAccountFeed(attributes.href || "");
        }}
      >
        {children}
      </A>
    </TextStyleContext.Provider>
  );
};

const getHashtag = (href?: string) => {
  if (!href) {
    return "";
  }
  const u = new URL(href);
  const pathSplit = u.pathname.split("/");
  const hashtag = pathSplit[pathSplit.length - 1];
  return hashtag;
};

const Hashtag: React.FC<JSONComponentProps> = ({ children, attributes }) => {
  const hashtag = getHashtag(attributes?.href);
  const { navigateToHashtag } = useFeedNavigation();

  return (
    <TextStyleContext.Provider value="hashtagLinkText">
      <A
        href={Platform.OS === "web" ? attributes?.href : "#"}
        onPress={(e) => {
          e.preventDefault();
          navigateToHashtag(hashtag);
        }}
      >
        {children}
      </A>
    </TextStyleContext.Provider>
  );
};

const Link: React.FC<JSONComponentProps> = ({
  children,
  attributes,
  overlay,
}) => {
  const removedInvisibleChildren =
    Array.isArray(children) &&
    children.filter(
      (child) => child.props.htmlAsJson?.attributes?.class !== "invisible"
    );
  const shortSpan: React.ReactElement<{ htmlAsJson: JSONType }> =
    removedInvisibleChildren && removedInvisibleChildren?.[0];
  let linkText = shortSpan && shortSpan?.props?.htmlAsJson?.content?.[0];
  if (linkText) {
    linkText = `${linkText}…`;
  }
  return (
    <TextStyleContext.Provider value={overlay ? "overlayLinkText" : "linkText"}>
      <A href={attributes?.href} target="_blank">
        {linkText ? <Span>{linkText}</Span> : children}
      </A>
    </TextStyleContext.Provider>
  );
};
/** For whatever reason, the BR tag in html-elements isn't working? */
const LineBreak: React.FC = () => {
  return <Text>{"\n"}</Text>;
};

const getMainSection =
  (): React.FC<JSONComponentProps> =>
  ({ overlay, children }) => {
    const { theme } = useTheme();
    const defaultTextColor = overlay
      ? theme.colors.overlay
      : theme.colors.primary;
    return (
      <TextHeaderContext.Consumer>
        {(header) => (
          <Text header={header} style={[{ color: defaultTextColor }]}>
            {children}
          </Text>
        )}
      </TextHeaderContext.Consumer>
    );
  };

// Duplicate line break to simulate a paragraph break...
const Paragraph: React.FC<JSONComponentProps> = ({ children }) => {
  return <P>{children}</P>;
};

const Span: React.FC<JSONComponentProps> = ({ children }) => {
  const styles = useStyles();
  return (
    <TextHeaderContext.Consumer>
      {(header) => (
        <TextColorContext.Consumer>
          {(color) => {
            return (
              <TextStyleContext.Consumer>
                {(styleKey) => {
                  const textStyle = [{ color }, styles[styleKey]];
                  return (
                    <Text header={header} style={textStyle}>
                      {children}
                    </Text>
                  );
                }}
              </TextStyleContext.Consumer>
            );
          }}
        </TextColorContext.Consumer>
      )}
    </TextHeaderContext.Consumer>
  );
};

const getAnchor = (
  attributes: JSONAttributes,
  externalHashtag?: boolean
): React.FunctionComponent<JSONComponentProps> => {
  if (
    !externalHashtag &&
    attributes.class?.includes("hashtag") &&
    attributes.href?.match(/\/tags\/[^/]+$/)
  ) {
    return Hashtag;
  }
  if (
    attributes.class?.includes("mention") &&
    attributes.class?.includes("u-url")
  ) {
    return Mention;
  }
  return Link;
};

const getComponent = (
  json: JSONType | string,
  externalHashtag?: boolean
): React.FunctionComponent<JSONComponentProps> | null => {
  if (typeof json === "string") {
    return Span;
  }
  if (json.type === "main") return getMainSection();

  if (json.type === "a" && json.attributes) {
    return getAnchor(json.attributes as JSONAttributes, externalHashtag);
  }

  return (
    {
      p: Paragraph,
      span: Span,
      br: LineBreak,
    }[json.type] || null
  );
};
type RenderContentProps = {
  overlay?: boolean;
  htmlAsJson: JSONType | string;
  color?: keyof Colors;
  externalHashtag?: boolean;
  prevContentItem?: JSONType | string | JSONContent;
};

const RenderContent: React.FC<RenderContentProps> = ({
  overlay = false,
  htmlAsJson,
  color,
  externalHashtag = false,
  prevContentItem,
}) => {
  if (typeof htmlAsJson === "string") {
    return <Span>{htmlAsJson}</Span>;
  }
  /** Since our paragraphs are simply rendered as text, paragraph spacing doesn't work very well.
   * Simulate line breaks when we have two consecutive paragraphs */
  let insertParagraphBreak = false;
  if (
    typeof prevContentItem !== "string" &&
    prevContentItem?.type === "p" &&
    htmlAsJson.type === "p"
  ) {
    insertParagraphBreak = true;
  }

  const Component = getComponent(htmlAsJson, externalHashtag);
  if (!Component) {
    return null;
  }
  return (
    <TextColorContext.Provider value={color}>
      {insertParagraphBreak && (
        <>
          <LineBreak />
          <LineBreak />
        </>
      )}
      <Component attributes={htmlAsJson.attributes} overlay={overlay}>
        {htmlAsJson.content?.map((contentItem, i) => {
          return (
            <RenderContent
              key={`content${i}`}
              htmlAsJson={contentItem}
              overlay={overlay}
              externalHashtag={externalHashtag}
              prevContentItem={i > 0 ? htmlAsJson.content[i - 1] : undefined}
            />
          );
        })}
      </Component>
    </TextColorContext.Provider>
  );
};

const deHashtagBombAllowed = 3;
const deHashtagBombContent = (parsed: JSONType): JSONType => {
  if (!parsed || !parsed.content) {
    return parsed;
  }
  if (
    typeof parsed.content !== "string" &&
    parsed.content.every((c) => typeof c !== "string" && c.type === "p")
  ) {
    return {
      ...parsed,
      content: [
        {
          type: "p",
          content: parsed.content.map((i) => {
            if (typeof i === "string") {
              return i;
            }
            return deHashtagBombContent(i);
          }),
        },
      ],
    };
  }
  const backwards = reverse([...parsed.content]);

  const isSpace = (item: string | JSONContent) =>
    typeof item === "string" && item.match(/^\s+$/);

  const isHashtag = (item: string | JSONContent) =>
    typeof item !== "string" &&
    item.attributes &&
    getAnchor(item.attributes) === Hashtag;

  let itemsToRemove = 0;
  for (const [i, item] of backwards.entries()) {
    const previousItem = backwards[i - 1];
    if (
      (isHashtag(item) && (!previousItem || isSpace(previousItem))) ||
      (isSpace(item) && (!previousItem || isHashtag(previousItem)))
    ) {
      itemsToRemove++;
      continue;
    }
    break;
  }
  // adds (deHashtagBombAllowed - 1) to account for spaces
  const buffer = deHashtagBombAllowed + (deHashtagBombAllowed - 1);
  if (itemsToRemove > buffer) {
    return {
      ...parsed,
      content: [...reverse(backwards.slice(itemsToRemove - buffer)), " #.."],
    };
  }
  return parsed;
};

const decorateContent = (parsed: JSONType) => {
  const decoratedContent = deHashtagBombContent(parsed);
  return decoratedContent;
};

type HTMLTextProps = {
  html?: string;
  shouldDecorateContent?: boolean;
} & Omit<RenderContentProps, "htmlAsJson">;

const HTMLText: React.FC<HTMLTextProps> = ({
  html,
  shouldDecorateContent = true,
  ...restProps
}) => {
  const [parsed, setParsed] = useState<JSONType | null>(null);

  const cleanRawHtml = (rawHtml?: string) => {
    return rawHtml
      ? `<main>${rawHtml
          .replace(/<br>/g, "<br/>")
          .replace(/&nbsp;/g, " ")}</main>`
      : "";
  };
  useEffect(() => {
    // Quick hack to ensure this is valid xml
    const unformattedContent = cleanRawHtml(html);

    // For whatever reason the default is required on Android.
    const htmlParser =
      // @ts-ignore ts can't find default
      Platform.OS === "web" ? HtmlToJsonParser : HtmlToJsonParser.default;

    if (unformattedContent && unformattedContent.length > 0)
      htmlParser(unformattedContent, true)
        .then((result: JSONContent | string) => {
          if (typeof result === "string") {
            setParsed(JSON.parse(result));
          } else {
            setParsed(result);
          }
        })
        .catch(console.error);
  }, [html]);

  return parsed ? (
    <RenderContent
      htmlAsJson={shouldDecorateContent ? decorateContent(parsed) : parsed}
      {...restProps}
    />
  ) : null;
};

const useStyles = makeStyles((theme) => {
  return createStyleSheet({
    link: {
      display: "inline",
    },
    linkText: {
      color: theme.colors.anchor,
      textDecorationLine: "underline",
    },
    overlayLinkText: {
      color: theme.colors.overlay,
      textDecorationLine: "underline",
    },
    mentionLinkText: {
      fontWeight: "bold",
    },
    hashtagLinkText: {
      fontWeight: "bold",
    },
    fakeSpace: { lineHeight: 8 },
  });
});

const HTMLTextMemo = memo(HTMLText);

export type HTMLTextWrapperProps = {
  numberOfLines?: number;
  style?: StyleProp<TextStyle>;
  overlay?: boolean;
  cardSize?: CardSize;
  textSize?: TextSize | number;
  header?: boolean;
} & HTMLTextProps;

export const HTMLTextWrapper: React.FC<HTMLTextWrapperProps> = ({
  numberOfLines,
  style,
  overlay,
  cardSize,
  textSize,
  header,
  ...rest
}) => {
  const { theme } = useTheme();

  const textStyles = overlay ? [style, { color: theme.colors.overlay }] : style;

  return (
    <CardText
      cardSize={cardSize}
      textSize={textSize}
      numberOfLines={numberOfLines}
      style={textStyles}
      header={header}
    >
      <TextHeaderContext.Provider value={header}>
        <HTMLTextMemo {...rest} overlay={overlay} />
      </TextHeaderContext.Provider>
    </CardText>
  );
};

export default memo(HTMLTextWrapper);
