import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { DEPLOY_ENV, API_ROOT } from "~/config";
import { FeedConfigType } from "~/enums";

enum DOW {
  Sunday = 0,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

type Config = Array<{
  title: string;
  id: string;
  active?: Array<keyof typeof DOW>;
  image?: string;
  description?: string;
  byline: string;
  feedType?: FeedConfigType;
}>;

const dynconfig = createApi({
  reducerPath: "dynconfig",
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_ROOT}/v1/static/`,
  }),
  endpoints: (builder) => ({
    config: builder.query<Config, void>({
      query: () => {
        const now = new Date().getTime();
        return `${
          DEPLOY_ENV === "surf" || DEPLOY_ENV === "development"
            ? "daily.json"
            : "daily_beta.json"
        }?time=${now}`;
      },
      transformResponse: (data: Config) =>
        data.filter((c) => {
          if (!c.active) {
            return true;
          }
          const currentDayIndex = new Date().getDay();
          return c.active.some((dayName) => DOW[dayName] === currentDayIndex);
        }),
    }),
  }),
});

export const { useConfigQuery } = dynconfig;

export default dynconfig;
