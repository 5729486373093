import React, { memo, useEffect, useState } from "react";
import {
  useLazyGetCustomFeedAuthorByUrlQuery,
  useLazyGetPublicCustomFeedQuery,
} from "~/api";
import { GroupRequestType, TextSize } from "~/enums";
import { Column, HTMLText, Row, Text } from "~/components/elements";
import { useGroupHeaderStyles } from "~/components/GroupHeader";
import Author from "~/components/elements/Author";
import { SPACING } from "~/styles";
import { selectIsAuthenticated } from "~/concepts/auth";
import { useAppSelector } from "~/hooks";
import FeedFavoriteButton from "~/buttons/FeedFavoriteButton";
import { useIsGroupOwner } from "~/hooks/useIsGroupOwner";

const CustomFeedHeader: React.FC<{ feed: FC.Group }> = ({
  feed: customFeedGroup,
}) => {
  const styles = useGroupHeaderStyles();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  const [customFeedData, setCustomFeedData] = useState<FC.CustomFeed | null>(
    null
  );
  const [author, setAuthor] = useState<FC.Author | null>(null);
  const isGroupOwner = useIsGroupOwner();
  const [getPublicCustomFeedTrigger, getPublicCustomFeedResponse] =
    useLazyGetPublicCustomFeedQuery();
  const [getCustomFeedAuthorByUrlTrigger, getCustomFeedAuthorByUrlResponse] =
    useLazyGetCustomFeedAuthorByUrlQuery();

  useEffect(() => {
    if (
      getPublicCustomFeedResponse.isSuccess &&
      getPublicCustomFeedResponse.data
    ) {
      setCustomFeedData(getPublicCustomFeedResponse.data);
      getCustomFeedAuthorByUrlTrigger(
        getPublicCustomFeedResponse.data.account_uri
      );
    }
  }, [getPublicCustomFeedResponse, getCustomFeedAuthorByUrlTrigger]);

  useEffect(() => {
    if (
      getCustomFeedAuthorByUrlResponse.isSuccess &&
      getCustomFeedAuthorByUrlResponse.data
    ) {
      setAuthor(getCustomFeedAuthorByUrlResponse.data);
    }
  }, [getCustomFeedAuthorByUrlResponse]);

  useEffect(() => {
    if (
      customFeedGroup.requestType === GroupRequestType.CUSTOM_FEED &&
      customFeedGroup.id
    ) {
      getPublicCustomFeedTrigger(customFeedGroup.requestKey);
    }
  }, [customFeedGroup, getPublicCustomFeedTrigger]);

  return (
    <Column flex style={[styles.headerContainer]}>
      <Row style={[styles.headerControls]}>
        {isAuthenticated && author && !isGroupOwner(customFeedGroup) && (
          <FeedFavoriteButton
            feed={customFeedGroup}
            showTitle
            type={"outline"}
          />
        )}
      </Row>
      <Column flex gap={SPACING.BASE3X} style={styles.headerContents}>
        <Text style={[styles.title, styles.headerText]}>
          {customFeedData?.title}
        </Text>
        {author && <Author author={author} />}
        <HTMLText
          textSize={TextSize.S}
          html={customFeedData?.description}
          style={[styles.headerText]}
          numberOfLines={3}
        />
      </Column>
    </Column>
  );
};
export default memo(CustomFeedHeader);
