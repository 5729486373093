import React, { memo, useCallback, useEffect, useState } from "react";
import { createStyleSheet, SPACING } from "~/styles";
import { Input, makeStyles, useTheme } from "@rneui/themed";
import CloseButton from "~/buttons/CloseButton";
import { Row, Column, Dialog, Divider, Text } from "~/components/elements";
import { Button } from "~/components/elements";
import { useCreateCustomFeedMutation } from "~/api";
import { useToast } from "react-native-toast-notifications";
import FeedDisplayName from "~/components/elements/FeedDisplayName";
import { useAppSelector } from "~/hooks";
import { isPhoneSelector } from "~/concepts/application";
import { TextAttributionStyle } from "~/enums";
import { useSelectFavoriteFeeds } from "~/hooks/useSelectGroupsWithPreferences";

type CustomFeedAddToNewFeedProps = {
  feed: FC.Group;
  onSubmit?: () => void;
};
const CustomFeedAddToNewFeed: React.FC<CustomFeedAddToNewFeedProps> = ({
  feed,
  onSubmit,
}) => {
  const styles = useStyles();
  const { theme } = useTheme();
  const [visible, setVisible] = useState(false);
  const [newFeedTitle, setNewFeedTitle] = useState("");
  const isPhone = useAppSelector(isPhoneSelector);
  const toast = useToast();
  const [trigger, { isSuccess, isError }] = useCreateCustomFeedMutation();
  const favorites = useSelectFavoriteFeeds();
  const [includes, setIncludes] = useState<string[]>(
    feed?.customFeedValue ? [feed?.customFeedValue] : []
  );

  const includeSelected = (f: FC.Group) => {
    if (!f.customFeedValue) return false;
    return includes.includes(f.customFeedValue);
  };

  const toggleInclude = (f: FC.Group) => {
    if (!f.customFeedValue) return;
    if (includeSelected(f)) {
      setIncludes(includes.filter((i) => i !== f.customFeedValue));
    } else {
      setIncludes([...includes, f.customFeedValue]);
    }
  };

  const onClose = () => {
    setVisible(false);
  };
  const onSave = useCallback(() => {
    if (!feed?.customFeedValue) return;

    const formValue = {
      title: newFeedTitle,
      description: "",
      includes,
      excludes: [],
      image: null,
    };

    trigger({ body: formValue });
  }, [feed?.customFeedValue, newFeedTitle, includes, trigger]);

  useEffect(() => {
    if (isSuccess) {
      toast.show(`Created "${newFeedTitle}"`, {
        type: "success",
      });
      onClose();
      onSubmit?.();
    }
  }, [isSuccess, newFeedTitle, onSubmit, toast]);

  useEffect(() => {
    if (isError) {
      toast.show(`Error creating "${newFeedTitle}"`, {
        type: "error",
      });
    }
  }, [isError, toast, newFeedTitle]);

  return (
    <>
      <Row>
        <Button
          type={"outline"}
          title={"Create New Feed"}
          onPress={() => {
            setVisible(true);
          }}
        />
      </Row>
      <Dialog
        isVisible={visible}
        onBackdropPress={onClose}
        width={isPhone ? "100%" : undefined}
      >
        <CloseButton onPress={onClose} style={styles.closeButton} />
        <Column flex>
          <Dialog.Title>{"Create a New Feed"}</Dialog.Title>
          <Divider />
          <Dialog.Title>
            {"Include "}
            <FeedDisplayName
              feed={feed}
              style={styles.dialogTitle}
              emojiSize={SPACING.LARGE}
            />
            {" in..."}
          </Dialog.Title>
          <Input
            placeholder="eg: My Custom Feed Title"
            value={newFeedTitle}
            onChangeText={setNewFeedTitle}
          />
          <Text attribution={TextAttributionStyle.SPECIAL}>
            Want to add any of your favorites to this feed?
          </Text>
          <Row style={styles.flexWrap}>
            {favorites
              .filter(
                (f) =>
                  f.customFeedValue &&
                  f.customFeedValue !== feed?.customFeedValue
              )
              .slice(0, 8)
              .map((f) => (
                <Button
                  title={f.title}
                  type="solid"
                  color={theme.colors.backgroundTertiary}
                  buttonStyle={
                    includeSelected(f)
                      ? styles.selectedFavorite
                      : styles.unselectedFavorite
                  }
                  onPress={() => toggleInclude(f)}
                />
              ))}
          </Row>
          <Dialog.Actions>
            <Dialog.Button type="outline" title={"Cancel"} onPress={onClose} />
            <Dialog.Button
              type="solid"
              title={"Create"}
              disabled={!newFeedTitle || newFeedTitle?.length <= 0}
              onPress={onSave}
            />
          </Dialog.Actions>
        </Column>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles((theme) => {
  return createStyleSheet({
    closeButton: {
      position: "absolute",
      top: SPACING.BASE,
      right: SPACING.BASE,
      zIndex: 1,
    },
    selectedFavorite: {
      borderColor: theme.colors.primary,
      borderWidth: SPACING.XSMALL,
    },
    unselectedFavorite: {
      borderWidth: SPACING.XSMALL,
      borderColor: "transparent",
      shadowColor: "transparent",
      shadowRadius: 0,
    },
  });
});

export default memo(CustomFeedAddToNewFeed);
