import React, { memo, useEffect, useState } from "react";
import { Button, makeStyles } from "@rneui/themed";
import {
  selectCredentials,
  setOpenLoginModal,
  selectIsOpenLoginModal,
  selectIsAuthenticated,
} from "~/concepts/auth";
import { useAppSelector, useAppDispatch } from "~/hooks";
import { View } from "~/components/elements";
import { useLazyGetAuthorizeRedirectUrlQuery } from "~/api";
import { createStyleSheet, SPACING, LAYOUT } from "~/styles";

import LoginDrawer from "~/components/dialogs/LoginDrawer";
import { useSetUIDCookie } from "~/hooks/useCookies";
import { useGetCUIDCookie } from "~/hooks/useCookies";
import { Platform } from "react-native";

const Connect: React.FC = () => {
  const dispatch = useAppDispatch();
  const styles = useStyles();
  const isOpenLoginModal = useAppSelector(selectIsOpenLoginModal);

  return (
    <View style={styles.authWrapper}>
      <Button
        title={"Log in"}
        onPress={() => {
          dispatch(
            setOpenLoginModal({ open: true, unauthenticatedAction: false })
          );
        }}
        size="sm"
        titleStyle={styles.authButtonTitleText}
        buttonStyle={styles.authButton}
        type="clear"
      />
      {isOpenLoginModal && (
        <LoginDrawer
          visible={isOpenLoginModal}
          onDismiss={() => {
            dispatch(setOpenLoginModal({ open: false }));
          }}
        />
      )}
    </View>
  );
};

const Auth = () => {
  const credentials = useAppSelector(selectCredentials);
  const setUIDCookie = useSetUIDCookie();
  const getCUIDCookie = useGetCUIDCookie();
  const [_, getAuthorizedRedirectResponse] =
    useLazyGetAuthorizeRedirectUrlQuery();
  const [showConnect, setShowConnect] = useState(false);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const CUIDCookie = getCUIDCookie();

  useEffect(() => {
    if (!CUIDCookie) {
      return;
    }
    if (!isAuthenticated) {
      setShowConnect(true);
    }
  }, [CUIDCookie, isAuthenticated]);

  useEffect(() => {
    if (credentials) {
      setUIDCookie(credentials);
    }
  }, [setUIDCookie, credentials]);

  useEffect(() => {
    if (!credentials) {
      setShowConnect(true);
    }
  }, [credentials]);

  if (Platform.OS !== "web") {
    return null;
  }

  if (getAuthorizedRedirectResponse.isLoading) {
    return null;
  }

  if (credentials) {
    return null;
  }
  return <View>{showConnect && <Connect />}</View>;
};

const useStyles = makeStyles((theme) =>
  createStyleSheet({
    authWrapper: {},
    dialogOverlay: {
      width: "30%",
      maxWidth: LAYOUT.DEFAULT_MODAL_WIDTH,
    },
    dialogTitleText: {
      paddingHorizontal: SPACING.BASE,
      color: theme.colors.primary,
      fontSize: 18,
      textTransform: "uppercase",
    },
    authButton: {},
    authButtonTitleText: {
      justifyContent: "flex-start",
      color: theme.colors.primaryReverse,
      fontWeight: "bold",
      textTransform: "uppercase",
    },
    authButtonSignoutText: {
      color: "white",
      fontWeight: "bold",
      textTransform: "uppercase",
    },
  })
);

export default memo(Auth);
