import React, { memo } from "react";
import { Column, Row, Text } from "~/components/elements";
import { useGroupHeaderStyles } from "~/components/GroupHeader";
import { SPACING } from "~/styles";
import { selectIsAuthenticated } from "~/concepts/auth";
import { useAppSelector } from "~/hooks";
import FeedFavoriteButton from "~/buttons/FeedFavoriteButton";

const HashtagHeader: React.FC<{ feed: FC.Group }> = ({ feed: hashtag }) => {
  const styles = useGroupHeaderStyles();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  return (
    <Column flex style={[styles.headerContainer]}>
      <Row style={[styles.headerControls]}>
        {isAuthenticated && (
          <FeedFavoriteButton feed={hashtag} showTitle type={"outline"} />
        )}
      </Row>
      <Column flex gap={SPACING.BASE3X} style={styles.headerContents}>
        <Text style={[styles.title, styles.headerText]}>{hashtag?.title}</Text>
      </Column>
    </Column>
  );
};
export default memo(HashtagHeader);
