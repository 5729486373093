import React, { memo } from "react";
import { ImageBackground, Linking, StyleSheet } from "react-native";

import { SPACING, createStyleSheet } from "~/styles";
import { Avatar, makeStyles, useTheme } from "@rneui/themed";
import { GroupRequestType, TextSize } from "~/enums";
import {
  Button,
  Divider,
  Column,
  Row,
  HTMLText,
  Text,
} from "~/components/elements";
import { useGroupHeaderStyles } from "~/components/GroupHeader";
import { useGetAuthorUrl } from "~/hooks/useGetAuthorUrl";
import { selectIsAuthenticated } from "~/concepts/auth";
import { useAppSelector } from "~/hooks";
import FollowingButton from "~/components/FollowingButton";
import DisplayName from "~/components/elements/DisplayName";
import { useGetAuthorFromFeed } from "~/hooks/useGetAuthor";
import { isPhoneSelector } from "~/concepts/application";

const SHOW_BACKGROUND_IMAGE = false;

const AccountHeader: React.FC<{ feed: FC.Group }> = ({ feed: account }) => {
  const isPhone = useAppSelector(isPhoneSelector);
  const styles = StyleSheet.flatten([
    useGroupHeaderStyles({ isPhone }),
    useStyles(),
  ]);
  const { theme } = useTheme();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  const getAuthorUrl = useGetAuthorUrl();

  const author = useGetAuthorFromFeed(account);

  const showBackgroundImage =
    SHOW_BACKGROUND_IMAGE &&
    !!author?.header &&
    !author?.header.includes("missing.png");

  if (account.requestType !== GroupRequestType.ACCOUNT) return null;

  const renderMetric = (label: string, metric?: number) => {
    if (!metric) return null;
    return (
      <Row>
        <Text
          overlay={showBackgroundImage}
          size={TextSize.S}
          style={styles.bold}
        >
          {metric}
        </Text>
        <Text overlay={showBackgroundImage} size={TextSize.S}>
          {label}
        </Text>
      </Row>
    );
  };

  const MetricContainer = isPhone ? Column : Row;
  const HeaderContents = (
    <Column
      flex
      style={[
        showBackgroundImage && {
          backgroundColor: theme.colors.backgroundReverse50,
        },
        styles.headerContainer,
      ]}
    >
      <Row style={[styles.headerControls]}>
        <Button
          type={"outline"}
          title={"View On Mastodon"}
          onPress={() => author && Linking.openURL(getAuthorUrl(author))}
        />
        {isAuthenticated && author && <FollowingButton author={author} />}
      </Row>
      <Column flex gap={SPACING.BASE3X} style={styles.headerContents}>
        <Avatar rounded size={120} source={{ uri: author?.avatar }} />
        {author && (
          <DisplayName
            overlay={showBackgroundImage}
            author={author}
            displayNameStyle={[styles.title, styles.headerText]}
            emojiSize={SPACING.BASE6X}
            numberOfLines={isPhone ? 3 : 1}
          />
        )}
        <MetricContainer style={styles.metricContainer}>
          <Text overlay={showBackgroundImage} size={TextSize.S}>
            {account.description}
          </Text>
          <Row>
            {!isPhone && <Divider vertical />}
            {renderMetric("Posts", author?.statuses_count)}
            <Divider vertical />
            {renderMetric("Following", author?.following_count)}
            <Divider vertical />
          </Row>
          <Row>{renderMetric("Followers", author?.followers_count)}</Row>
        </MetricContainer>
        <HTMLText
          overlay={showBackgroundImage}
          textSize={TextSize.M}
          html={author?.note}
          style={[styles.headerText]}
          numberOfLines={2}
        />
      </Column>
    </Column>
  );
  if (showBackgroundImage)
    return (
      <ImageBackground source={{ uri: author?.header }} style={styles.flex1}>
        {HeaderContents}
      </ImageBackground>
    );
  return HeaderContents;
};

export const useStyles = makeStyles((_) => {
  return createStyleSheet({
    metricContainer: {
      maxWidth: "100%",
      alignItems: "center",
      alignSelf: "center",
    },
  });
});

export default memo(AccountHeader);
