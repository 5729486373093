import React from "react";
import { ContentPosition } from "~/enums";
import { Column, Row } from "~/components/elements";
import ItemImage, { FixDimensionType } from "~/components/ItemImage";
import { StyleProp } from "react-native";
import {
  BOTTOM_CONTENT_HEIGHT,
  BasicMediaCardProps,
} from "~/components/cards/BasicMediaCard";
import CardAuthor from "~/components/CardAuthor";
import AutoNumberOfLinesWrapper from "~/components/elements/AutoNumberOfLinesWrapper";
import { makeStyles } from "@rneui/themed";
import { SPACING, createStyleSheet } from "~/styles";
import Content from "~/components/Content";
import { GALLERY_FONT_SIZE } from "~/components/elements/Text";
import Reboosts from "~/components/cardComponents/Reboosts";

const MARGIN = SPACING.BASE3X;

export interface FlexibleImageCardProps
  extends Omit<BasicMediaCardProps, "MediaComponent"> {
  maxNumberTruncate?: number;
  fixDimension?: FixDimensionType;
  height?: number;
  imageAspectRatio?: number;
  contentPosition?: ContentPosition;
  cardContentStyle?: StyleProp<ViewStyle>;
}

const FlexibleImageCard: React.FC<FlexibleImageCardProps> = ({
  item,
  overlay,
  cardSize,
  fixDimension,
  maxNumberTruncate,
  height,
  imageAspectRatio,
  contentPosition = ContentPosition.BOTTOM,
  cardContentStyle,
}) => {
  const styles = useStyles();
  const image = item?.image;

  if (!image) return null;

  const heightReduction =
    contentPosition === ContentPosition.BOTTOM ? BOTTOM_CONTENT_HEIGHT : 0;
  const imageContainerStyle = {
    height:
      fixDimension === FixDimensionType.HEIGHT && height
        ? height - heightReduction - MARGIN * 2
        : undefined,
    aspectRatio: imageAspectRatio,
  };

  const WrapperComponent =
    contentPosition === ContentPosition.BOTTOM ? Column : Row;

  const Image = (
    <Row flex style={[imageContainerStyle]}>
      <ItemImage
        item={item}
        disableBlurBackground
        contentFit={"cover"}
        fixDimension={fixDimension}
      />
    </Row>
  );

  const CardContent = (
    <Column
      style={[
        contentPosition === ContentPosition.BOTTOM
          ? styles.bottomContent
          : styles.sideContent,
        cardContentStyle,
      ]}
    >
      <CardAuthor item={item} overlay={overlay} />
      <AutoNumberOfLinesWrapper flexShrink maxNumberOfLines={maxNumberTruncate}>
        <Content
          cardSize={cardSize}
          item={item}
          overlay={overlay}
          textSize={GALLERY_FONT_SIZE}
        />
      </AutoNumberOfLinesWrapper>
      <Reboosts item={item} />
    </Column>
  );
  return (
    <Column flex style={styles.justifyCenter}>
      <WrapperComponent flexShrink style={[styles.card, { height }]}>
        {contentPosition === ContentPosition.LEFT ? (
          <>
            {CardContent}
            {Image}
          </>
        ) : (
          <>
            {Image}
            {CardContent}
          </>
        )}
      </WrapperComponent>
    </Column>
  );
};

const useStyles = makeStyles(() =>
  createStyleSheet({
    card: {
      width: "100%",
      maxWidth: `calc(100% - ${SPACING.BASE3X * 2}px)`,
      overflow: "hidden",
      margin: MARGIN,
    },
    contentContainer: {
      width: "100%",
      maxHeight: "100%",
    },
    contentContainerHorizontal: { height: "100%", justifyContent: "center" },
    bottomContent: { height: BOTTOM_CONTENT_HEIGHT },
    sideContent: { flexShrink: 1, alignSelf: "flex-end", width: 350 },
  })
);

export default FlexibleImageCard;
