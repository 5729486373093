import React, { memo, useEffect, useState } from "react";
import {
  NativeSyntheticEvent,
  Platform,
  TextInputChangeEventData,
} from "react-native";
import {
  selectBearerToken,
  setBearerToken as storeSetBearerToken,
} from "~/concepts/auth";
import { useAppDispatch, useAppSelector } from "~/hooks";
import {
  getBearerToken,
  setBearerToken as persistBearerToken,
} from "~/lib/persistentStore";
import { Button, Column, Input } from "~/components/elements";
import { SPACING, createStyleSheet } from "~/styles";

const TemporaryPhoneAuth: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const dispatch = useAppDispatch();
  const bearerToken = useAppSelector(selectBearerToken);
  const [loading, setLoading] = useState(true);
  const [input, setInput] = useState("");

  const isWeb = Platform.OS === "web";

  useEffect(() => {
    if (isWeb) {
      return;
    }
    if (bearerToken) {
      if (loading) {
        setLoading(false);
      }
      return;
    }
    setLoading(true);
    getBearerToken()
      .then((v) => {
        if (v) {
          dispatch(storeSetBearerToken(v));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [bearerToken, dispatch, loading, setLoading, isWeb]);

  if (isWeb) {
    return children;
  }

  if (loading) {
    return null;
  }

  if (bearerToken) {
    return children;
  }

  const handleSubmit = () => {
    persistBearerToken(input);
    dispatch(storeSetBearerToken(input));
  };

  return (
    <Column style={styles.wrapper}>
      <Input
        label="Bearer"
        value={input}
        placeholder="__Secure-fd_access_token cookie form web"
        onChange={(e: NativeSyntheticEvent<TextInputChangeEventData>) => {
          setInput(e.nativeEvent.text);
        }}
      />
      <Button
        title={"Set Bearer"}
        onPress={handleSubmit}
        size="sm"
        type={"solid"}
      />
    </Column>
  );
};

const styles = createStyleSheet({
  wrapper: {
    padding: SPACING.BASE6X,
  },
});

export default memo(TemporaryPhoneAuth);
