import React, { memo, useState, useEffect } from "react";
import * as DocumentPicker from "expo-document-picker";
import { SPACING, createStyleSheet } from "~/styles";
import { Skeleton, makeStyles, useTheme } from "@rneui/themed";
import {
  ActivityIndicator,
  FlatList,
  NativeSyntheticEvent,
  TextInputChangeEventData,
} from "react-native";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";

import { Image, Column, Row, Text, Button, Input } from "~/components/elements";
import CustomFeedFormOption from "~/components/CustomFeedFormOption";
import { useUploadMediaMutation } from "~/api";
import { CustomFeedFormType, TextSize } from "~/enums";
import { useAppDispatch, useAppSelector } from "~/hooks";
import { setTriggerSearch } from "~/concepts/application";
import CloseButton from "~/buttons/CloseButton";
import CustomFeedFormImport from "~/components/CustomFeedFormImport";
import ShareDialog from "~/components/dialogs/ShareDialog";
import { customFeedGroupFromCustomFeed } from "~/utils/groups";
import CustomFeedFormDeleteDialog from "~/components/dialogs/CustomFeedFormDeleteDialog";
import isEqual from "lodash-es/isEqual";
import CustomFeedFormExcluded from "~/components/CustomFeedFormExcluded";
import { isPhoneSelector } from "~/concepts/application";

type CustomFeedFormProps = {
  customFeed?: FC.CustomFeed;
  type: CustomFeedFormType;
  formValue: FC.CustomFeedConfiguration;
  setFormValue: (value: FC.CustomFeedConfiguration) => void;
  isLoading: boolean;
  isDisabled: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  isSuccess?: boolean;
  isError?: boolean;
  ToggleExpandFeedsButton?: React.ReactNode;
  dirty?: boolean;
  setDirty?: (arg: boolean) => void;
};

const CustomFeedForm: React.FC<CustomFeedFormProps> = (props) => {
  const {
    type,
    formValue,
    setFormValue,
    isError,
    isLoading,
    isDisabled,
    isSuccess,
    onSubmit,
    onCancel,
    customFeed,
    dirty,
    setDirty,
  } = props;
  const styles = useStyles();
  const [upload, setUpload] =
    useState<DocumentPicker.DocumentPickerAsset | null>(null);
  const [shouldSubmit, setShouldSubmit] = useState(false);
  const [uploadMediaTrigger, uploadMediaResponse] = useUploadMediaMutation();
  const { theme } = useTheme();
  const dispatch = useAppDispatch();
  const formTitleActionText =
    type === CustomFeedFormType.CREATE ? "Create" : "Update";
  const formActionText = type === CustomFeedFormType.CREATE ? "Create" : "Save";
  const [showSubmitError, setSubmitShowError] = useState(false);
  const isPhone = useAppSelector(isPhoneSelector);

  // upload has completed and we need to set the formValue image
  useEffect(() => {
    if (uploadMediaResponse.isSuccess && uploadMediaResponse.data) {
      const update = { ...formValue, image: uploadMediaResponse.data.url };
      if (!isEqual(formValue, update)) {
        setFormValue(update);
      }
    }
  }, [formValue, uploadMediaResponse, setFormValue]);

  useEffect(() => {
    if (!shouldSubmit && uploadMediaResponse.data && formValue.image) {
      uploadMediaResponse.reset();
      setShouldSubmit(true);
    }
  }, [shouldSubmit, uploadMediaResponse, formValue.image]);

  useEffect(() => {
    if (shouldSubmit) {
      setShouldSubmit(false);
      onSubmit();
    }
  }, [shouldSubmit, onSubmit]);

  const disabled = isLoading || uploadMediaResponse.isLoading || isDisabled;

  const formValueChange =
    (key: string) => (e: NativeSyntheticEvent<TextInputChangeEventData>) => {
      setFormValue({ ...formValue, [key]: e.nativeEvent.text });
    };

  const inputDisabled = uploadMediaResponse.isLoading || isLoading || isSuccess;

  const isFormValid = () => !!formValue.title && formValue.includes.length >= 1;

  const submitForm = () => {
    if (!isFormValid()) {
      setSubmitShowError(true);
      return;
    }

    if (upload?.file) {
      const formData = new FormData();
      formData.append("file", upload.file);
      uploadMediaTrigger(formData);
    } else {
      onSubmit();
    }
  };

  const imageUrl = upload?.uri || formValue.image;

  const removeIncludeItem = (include: string) => () => {
    setFormValue({
      ...formValue,
      includes: formValue.includes.filter((x) => x !== include),
    });
  };

  const removeButton = (onPress: () => void) => (
    <Button
      onPress={onPress}
      disabled={isLoading}
      loading={isLoading}
      color={theme.colors.background}
      type={"clear"}
      icon={
        <Icon
          name={"window-close"}
          size={24}
          solid={true}
          color={theme.colors.controlsRed}
        />
      }
    />
  );
  const renderCustomFeedFormOption = ({ item: entry }: { item: string }) => {
    return (
      <Row flex key={entry} style={[styles.alignCenter]}>
        <CustomFeedFormOption arg={entry} />
        {removeButton(removeIncludeItem(entry))}
      </Row>
    );
  };

  const showDone = type === CustomFeedFormType.EDIT && !dirty;

  return (
    <Column flex style={styles.customFeedForm}>
      <Text header size={TextSize.L}>
        {formTitleActionText} Custom Feed
      </Text>
      <Column flex>
        <Row style={[styles.alignCenter]}>
          <Column>
            <Row>
              <Column style={styles.imageWrapper}>
                {imageUrl !== null ? (
                  <Image height={200} width={150} url={imageUrl} />
                ) : (
                  <Skeleton height={200} width={150} style={styles.noZIndex} />
                )}
                {(upload || formValue.image) &&
                  !uploadMediaResponse.isLoading && (
                    <CloseButton
                      onPress={() => {
                        setUpload(null);
                        setFormValue({ ...formValue, image: null });
                      }}
                      loading={uploadMediaResponse.isLoading}
                      style={styles.removeImageButton}
                      overlay
                      showBackground
                    />
                  )}
              </Column>
              {!formValue.image && !upload && (
                <Button
                  title={"Upload Image"}
                  titleStyle={{ fontSize: SPACING.MEDIUM }}
                  style={styles.uploadButton}
                  buttonStyle={{
                    gap: SPACING.BASE,
                    borderRadius: SPACING.BASE,
                  }}
                  containerStyle={{
                    borderRadius: SPACING.BASE,
                  }}
                  icon={
                    <Icon
                      name={"image-plus"}
                      size={24}
                      solid={true}
                      color={theme.colors.controlsBlue}
                    />
                  }
                  onPress={async () => {
                    const result = await DocumentPicker.getDocumentAsync({
                      multiple: false,
                      type: ["image/jpeg", "image/gif", "image/png"],
                    });
                    if (result?.assets?.[0]) {
                      setUpload(result.assets[0]);
                      setDirty?.(true);
                    }
                  }}
                />
              )}
              {uploadMediaResponse.isLoading && <ActivityIndicator />}
            </Row>
          </Column>
          <Column flex style={styles.alignCenter}>
            <Input
              label="Title"
              onChange={formValueChange("title")}
              placeholder="eg: My Custom Feed Title"
              value={formValue.title}
              disabled={inputDisabled}
              inputStyle={styles.input}
              containerStyle={styles.inputContainer}
              errorMessage={
                showSubmitError && !formValue.title
                  ? "Title is required"
                  : undefined
              }
              labelStyle={[{ color: theme.colors.primary }, styles.formLabel]}
            />
            <Row flex>
              <Input
                label="Description"
                onChange={formValueChange("description")}
                placeholder="eg: My Custom Feed Description"
                value={formValue.description}
                multiline={true}
                numberOfLines={3}
                disabled={inputDisabled}
                inputStyle={styles.input}
                containerStyle={styles.inputContainer}
                labelStyle={[{ color: theme.colors.primary }, styles.formLabel]}
              />
            </Row>
            {!isPhone && (
              <Column
                noGap
                style={[
                  styles.justifyLeft,
                  styles.fullWidth,
                  { paddingLeft: SPACING.BASE },
                ]}
              >
                <Text style={styles.formLabel}>Sources</Text>
                <Row>
                  <Button
                    title={"Add"}
                    titleStyle={{ fontSize: SPACING.LARGE }}
                    buttonStyle={{
                      gap: SPACING.BASE,
                      borderRadius: SPACING.BASE,
                    }}
                    containerStyle={{
                      borderRadius: SPACING.BASE,
                    }}
                    onPress={() => dispatch(setTriggerSearch(true))}
                    type="outline"
                  />
                  {formValue.excludes?.length > 0 && (
                    <CustomFeedFormExcluded
                      formValue={formValue}
                      setFormValue={setFormValue}
                    />
                  )}
                </Row>
              </Column>
            )}
          </Column>
        </Row>
        <Column flex>
          {formValue.includes.length > 0 && (
            <>
              <Text header size={TextSize.XS}>
                Included Feeds
              </Text>
              <FlatList
                data={formValue.includes}
                keyExtractor={(arg) => arg}
                renderItem={renderCustomFeedFormOption}
                style={[styles.noZIndex]}
              />
            </>
          )}
        </Column>
        {showSubmitError && formValue.includes.length < 1 && (
          <Text error>You must include at least one source.</Text>
        )}
        {isError && (
          <Text error>
            Something went wrong! Try again or change your inputs?
          </Text>
        )}
        <Row style={styles.noZIndex}>
          <Row flex style={styles.controls}>
            <Row>
              <CustomFeedFormImport
                formValue={formValue}
                setFormValue={setFormValue}
              />
              {type === CustomFeedFormType.EDIT && customFeed && (
                <CustomFeedFormDeleteDialog customFeed={customFeed} />
              )}
              {type === CustomFeedFormType.EDIT && customFeed && (
                <ShareDialog
                  group={customFeedGroupFromCustomFeed(customFeed)}
                />
              )}
            </Row>
            <Row>
              {showDone ? (
                <Button
                  title="Done"
                  onPress={onCancel}
                  loading={isLoading}
                  buttonStyle={{
                    gap: SPACING.BASE,
                    borderRadius: SPACING.BASE,
                  }}
                  containerStyle={{ borderRadius: SPACING.BASE }}
                />
              ) : (
                <Button
                  title={formActionText}
                  onPress={submitForm}
                  loading={isLoading}
                  disabled={disabled}
                  buttonStyle={{
                    gap: SPACING.BASE,
                    borderRadius: SPACING.BASE,
                  }}
                  containerStyle={{ borderRadius: SPACING.BASE }}
                />
              )}
            </Row>
          </Row>
        </Row>
      </Column>
    </Column>
  );
};

const useStyles = makeStyles((theme) =>
  createStyleSheet({
    customFeedForm: {
      padding: SPACING.BASE3X,
    },
    input: { flex: 1, width: "100%" },
    inputContainer: { paddingLeft: SPACING.BASE, paddingRight: 0 },
    imageWrapper: {
      borderWidth: 2,
      borderColor: theme.colors.dividerPrimary,
    },
    removeImageButton: {
      position: "absolute",
      top: 0,
      right: 0,
      zIndex: 1,
    },
    uploadButton: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      zIndex: 1,
    },
    previewWrapper: {
      borderWidth: 1,
      borderColor: theme.colors.dividerPrimary,
      borderRadius: SPACING.SMALL,
      margin: -`${SPACING.SMALL}`,
      padding: SPACING.SMALL,
      shadowColor: theme.colors.dividerPrimary,
      shadowRadius: SPACING.SMALL,
      shadowOffset: { width: 0, height: 0 },
    },
    previewNotice: {
      alignItems: "center",
      justifyContent: "space-between",
    },
    controls: {
      justifyContent: "space-between",
    },
  })
);

export default memo(CustomFeedForm);
