import { useNavigate } from "~/routing";
import { useAppDispatch, useAppSelector } from "~/hooks";
import {
  clearCurrentThreadItem,
  getThreadItem,
  selectFollowingThreadLink,
  setCurrentAttachmentIndex,
  setCurrentThreadItem,
  setFollowingThreadLink,
} from "~/concepts/thread";
import { homeTimelineGroup, selectSelectedFeed } from "~/concepts/groups";
import { useFeedNavigation } from "~/hooks/useFeedNavigation";
import { useCallback } from "react";
import { selectApplicationMode } from "~/concepts/application";
import { ApplicationMode } from "~/enums";
import { Linking } from "react-native";

export const useThreadItemNavigation = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const applicationMode = useAppSelector(selectApplicationMode);
  const currentThreadItem = useAppSelector(getThreadItem);
  const selectedFeed = useAppSelector(selectSelectedFeed);
  const followingThreadLink = useAppSelector(selectFollowingThreadLink);
  const { navigateToGroup } = useFeedNavigation();

  const navigateToThreadItem = (
    item: FC.Item,
    initialFocusedAttachment?: number
  ) => {
    const path = `/feed/${encodeURIComponent(
      (selectedFeed || homeTimelineGroup).id
    )}/status/${encodeURIComponent(item.source)}`;
    if (applicationMode === ApplicationMode.CUSTOM_FEED_BUILDER) {
      return Linking.openURL(path);
    }
    navigate(path, { replace: true });
    dispatch(setCurrentThreadItem(item));

    initialFocusedAttachment &&
      dispatch(setCurrentAttachmentIndex(initialFocusedAttachment));
  };

  const navigateAwayFromThread = useCallback(() => {
    if (!followingThreadLink) {
      navigateToGroup(selectedFeed || homeTimelineGroup, { replace: true });
    }
    dispatch(setFollowingThreadLink(false));
    dispatch(clearCurrentThreadItem());
  }, [dispatch, selectedFeed, navigateToGroup, followingThreadLink]);

  return {
    currentThreadItem,
    navigateToThreadItem,
    navigateAwayFromThread,
  };
};
