import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  View,
  FlatList,
  ViewabilityConfigCallbackPairs,
  ViewabilityConfig,
  ViewToken,
} from "react-native";

import { useAppDispatch, useAppSelector } from "~/hooks";
import { useTheme, makeStyles } from "@rneui/themed";
import {
  Column,
  Row,
  Tile,
  Text,
  Button,
  CollapsibleAnimatedView,
} from "~/components/elements/";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";

import { createStyleSheet, LAYOUT, SPACING } from "~/styles";
import { selectCredentials } from "~/concepts/auth";
import { useNavigate } from "~/routing";
import {
  FeatureFlags,
  GroupRequestType,
  PaginationImpulse,
  TextSize,
} from "~/enums";
import {
  useSelectAllGroupsWithPreferences,
  useSelectRecentFavoriteFeeds,
} from "~/hooks/useSelectGroupsWithPreferences";
import {
  isNarrowSelector,
  isPhoneSelector,
  selectHideSearch,
  selectSearchFocused,
  setHideSearch,
  setTriggerSearch,
} from "~/concepts/application";
import { useFeedNavigation } from "~/hooks/useFeedNavigation";
import { setSelectedFeed } from "~/concepts/groups";
import { usePreferences } from "~/hooks/usePreferences";
import FeedFavoriteButton from "~/buttons/FeedFavoriteButton";
import { useIsGroupOwner } from "~/hooks/useIsGroupOwner";
import isEqual from "lodash-es/isEqual";
import { useConfigQuery } from "~/dynconfig";
import sortBy from "lodash-es/sortBy";
import CarouselKeyboardNavListener from "~/components/CarouselKeyboardNavListener";
import { SLIDE_IN_DIRECTION } from "~/components/elements/CollapsibleAnimatedView";
import { chunk, flatten } from "lodash-es";
import { useGetFeatureFlagsCookie } from "~/hooks/useCookies";

const TILE_GAP = SPACING.LARGE;
const FIXED_TILE_WIDTH = 144;
const FIXED_TILE_HEIGHT = 192;
const FIXED_TILE_WIDTH_LARGE = 216;
const FIXED_TILE_HEIGHT_LARGE = 288;
const LEFT_TITLE_WIDTH = 210;

enum HomeFeedGroupType {
  CREATE = "CREATE",
  HOME = "HOME",
  CUSTOM = "CUSTOM",
  FAVORITE = "FAVORITE",
  FEATURED = "FEATURED",
}

interface HomeFeedGroupSet {
  key: HomeFeedGroupType;
  title: string;
  groups: Array<FC.Group | null>;
}

const SurfHome: React.FC<{
  contentHeight: number;
  contentWidth: number;
}> = ({ contentHeight, contentWidth }) => {
  const dispatch = useAppDispatch();
  const { theme } = useTheme();
  const [preferences] = usePreferences();
  const groups = useSelectAllGroupsWithPreferences();
  const isPhone = useAppSelector(isPhoneSelector);
  const isNarrow = useAppSelector(isNarrowSelector);
  const [numColumns, setNumColumns] = useState<number>(5);
  const hideSearch = useAppSelector(selectHideSearch);

  const navigate = useNavigate();
  const [remount, setRemount] = useState(false);
  const { navigateToGroup } = useFeedNavigation();
  const credentials = useAppSelector(selectCredentials);
  const favoriteFeeds = useSelectRecentFavoriteFeeds();
  const configResult = useConfigQuery();
  const searchFocused = useAppSelector(selectSearchFocused);
  const [currentIndex, setCurrentIndex] = useState(0);
  const homeListRef = useRef<FlatList>(null);
  const [filteredSet, setFilteredSet] = useState<HomeFeedGroupType | null>(
    null
  );
  const featureFlags = useGetFeatureFlagsCookie()();

  useEffect(() => {
    if (!featureFlags[FeatureFlags.HOME_FILTER] && filteredSet) {
      setFilteredSet(null);
    }
  }, [featureFlags, filteredSet, setFilteredSet]);

  const SURF_HOME_PADDING = isPhone ? SPACING.BASE3X : SPACING.BASE5X;

  const styles = useStyles({
    isPhone,
    isNarrow,
    contentWidth,
    surfHomePadding: SURF_HOME_PADDING,
  });

  const calcNumColumns = useCallback(() => {
    const possibleNumColuns = Math.floor(
      (contentWidth - SURF_HOME_PADDING) / (FIXED_TILE_WIDTH + TILE_GAP)
    );
    const calculatedNumColumns = contentWidth
      ? Math.min(8, possibleNumColuns)
      : 1;
    return Math.floor(calculatedNumColumns / 2) * 2;
  }, [SURF_HOME_PADDING, contentWidth]);

  useEffect(() => {
    setNumColumns(calcNumColumns());
  }, [calcNumColumns]);

  const isGroupOwner = useIsGroupOwner();

  const [feedGroups, setFeedGroups] = useState<Array<HomeFeedGroupSet> | null>(
    null
  );

  useEffect(() => {
    dispatch(setHideSearch(!searchFocused && currentIndex === 0));
    return () => {
      dispatch(setHideSearch(false));
    };
  }, [searchFocused, currentIndex, dispatch]);

  useEffect(() => {
    // TODO the below can return if LoadPrerequisites is more strict
    // about groups actually being fully loaded before allowing
    // children to render
    // if (feedGroups) return;

    const newFeedGroups: Array<HomeFeedGroupSet> = [];

    // Insert a dummy null item so that our page "chunks" are calculated properly.
    newFeedGroups.push({
      key: HomeFeedGroupType.CREATE,
      title: "Create Your Own Feed",
      groups: [null],
    });
    const homeFeedGroups = groups.filter(
      (f) => f.requestType === GroupRequestType.HOME_TIMELINE
    );
    if (filteredSet === null || filteredSet === HomeFeedGroupType.HOME)
      newFeedGroups.push({
        key: HomeFeedGroupType.HOME,
        title: "",
        groups: homeFeedGroups,
      });
    const ownCustomFeedGroups = groups
      .filter((f) => f.requestType === GroupRequestType.CUSTOM_FEED)
      .filter((g) => isGroupOwner(g));
    if (filteredSet === null || filteredSet === HomeFeedGroupType.CUSTOM)
      newFeedGroups.push({
        key: HomeFeedGroupType.CUSTOM,
        title: "Custom Feeds",
        groups: ownCustomFeedGroups,
      });
    if (filteredSet === null || filteredSet === HomeFeedGroupType.FAVORITE)
      newFeedGroups.push({
        key: HomeFeedGroupType.FAVORITE,
        title: "Favorites",
        groups: favoriteFeeds.filter((f) => !ownCustomFeedGroups.includes(f)),
      });

    const filteredRemainder = groups.filter(
      (g) =>
        ![...homeFeedGroups, ...ownCustomFeedGroups, ...favoriteFeeds].includes(
          g
        ) && (configResult.data || []).find((c) => c.id === g.id)
    );

    if (filteredSet === null || filteredSet === HomeFeedGroupType.FEATURED)
      newFeedGroups.push({
        key: HomeFeedGroupType.FEATURED,
        title: "Featured",
        groups: sortBy(filteredRemainder, (g) => {
          return (configResult.data || []).findIndex((c) => c.id === g.id);
        }),
      });

    if (!isEqual(newFeedGroups, feedGroups)) {
      setFeedGroups(newFeedGroups);
    }
  }, [
    groups,
    favoriteFeeds,
    preferences,
    feedGroups,
    configResult.data,
    isGroupOwner,
    numColumns,
    filteredSet,
  ]);

  useEffect(() => {
    dispatch(setSelectedFeed(null));
  }, [dispatch]);

  useEffect(() => {
    if (remount) {
      setRemount(false);
    }
  }, [remount]);

  const orderedTiles = flatten((feedGroups || []).map((g) => g.groups));

  // Filter out the null item, so it doesn't get "rendered normally"
  const pages = chunk(orderedTiles, numColumns).map(
    (p) => p.filter((g) => g !== null) as Array<FC.Group>
  );

  const onPageDown = useCallback(() => {
    if (currentIndex < pages.length - 1)
      homeListRef.current?.scrollToIndex({
        index: currentIndex + 1,
        animated: true,
      });
  }, [currentIndex, pages.length]);

  const onPageUp = useCallback(() => {
    if (currentIndex > 0)
      homeListRef.current?.scrollToIndex({
        index: currentIndex - 1,
        animated: true,
      });
  }, [currentIndex]);

  const goToTop = useCallback(() => {
    homeListRef.current?.scrollToIndex({
      index: 0,
      animated: true,
    });
  }, []);

  const goToBottom = useCallback(() => {
    homeListRef.current?.scrollToIndex({
      index: pages.length - 1,
      animated: true,
    });
  }, [pages.length]);

  const SurfHomeHeader = (
    <Column flex style={[styles.header]}>
      <CollapsibleAnimatedView
        show={hideSearch}
        fade
        slideInDirection={SLIDE_IN_DIRECTION.TOP}
        style={[
          styles.alignCenter,
          styles.justifyCenter,
          { gap: isPhone ? SPACING.BASE3X : SPACING.BASE5X },
        ]}
      >
        <Text size={SPACING.BASE6X} style={styles.title}>
          {filteredSet != null
            ? `${filteredSet} Feeds`
            : "Welcome to the Private Beta"}
        </Text>
        <Button
          onPress={() => {
            dispatch(setTriggerSearch(true));
            dispatch(setHideSearch(false));
          }}
          type="clear"
          buttonStyle={styles.surfHomeSearch}
          titleStyle={styles.surfHomeSearchTitle}
        >
          <Row
            flex
            style={[
              styles.alignCenter,
              styles.justifyCenter,
              styles.surfHomeSearchInner,
            ]}
          >
            <Icon
              name="magnify"
              color={theme.colors.primary}
              size={SPACING.BASE3X}
              style={styles.searchIcon}
            />
            <Text size={TextSize.M} style={[styles.flex1, styles.textCenter]}>
              What interests you?
            </Text>
          </Row>
        </Button>
      </CollapsibleAnimatedView>
      {featureFlags && featureFlags[FeatureFlags.HOME_FILTER] && (
        <Row
          style={[
            styles.fullWidth,
            styles.alignCenter,
            styles.justifyCenter,
            styles.filterTabRow,
          ]}
        >
          <Button
            title={"All"}
            type={filteredSet === null ? "solid" : "outline"}
            onPress={() => setFilteredSet(null)}
            titleStyle={styles.formLabel}
            buttonStyle={[
              styles.filterButton,
              filteredSet === null && styles.filterButtonSelected,
            ]}
          />
          {Object.values(HomeFeedGroupType)
            .filter((t) => t !== HomeFeedGroupType.CREATE)
            .map((t) => (
              <Button
                title={t}
                type={filteredSet === t ? "solid" : "outline"}
                // type="clear"
                onPress={() => setFilteredSet(t)}
                titleStyle={styles.formLabel}
                buttonStyle={[
                  styles.filterButton,
                  filteredSet === t && styles.filterButtonSelected,
                ]}
              />
            ))}
        </Row>
      )}
    </Column>
  );

  const renderCreateTile = (tileHeight: number, tileWidth: number) => {
    return (
      <View
        style={[
          styles.alignCenter,
          styles.justifyCenter,
          { height: tileHeight, width: tileWidth },
        ]}
      >
        <Tile
          width={tileWidth}
          height={tileHeight}
          backgroundColor={theme.colors.background}
          style={styles.createButton}
          onPress={() => navigate("/custom-feed/new")}
        >
          <Column flex style={styles.createButtonContents}>
            <Icon
              name={"plus"}
              size={SPACING.BASE3X}
              color={theme.colors.primary}
            />
            <Text style={[styles.textCenter]}>Create Your Own Feed</Text>
          </Column>
        </Tile>
      </View>
    );
  };

  const renderFeedRowItem = (group: FC.Group, large: boolean = false) => {
    const tileWidth = large ? FIXED_TILE_WIDTH_LARGE : FIXED_TILE_WIDTH;
    const tileHeight = large ? FIXED_TILE_HEIGHT_LARGE : FIXED_TILE_HEIGHT;
    let feedImage = group.image;
    if (credentials && group.requestType === GroupRequestType.HOME_TIMELINE) {
      feedImage = credentials.header || credentials.avatar;
    }

    return (
      <View
        key={`group_${group.id}`}
        style={[
          styles.alignCenter,
          styles.justifyCenter,
          { height: tileHeight, width: tileWidth },
        ]}
      >
        <Row>
          <Tile
            group={group}
            title={group.title}
            width={tileWidth}
            height={tileHeight}
            image={feedImage}
            onPress={() => {
              navigateToGroup(group);
            }}
            backgroundColor={theme.colors.grey1}
            showGradient
          />
          {!isGroupOwner(group) &&
            group.requestType !== GroupRequestType.HOME_TIMELINE && (
              <FeedFavoriteButton
                feed={group}
                style={styles.feedFavoriteButton}
                color={theme.colors.controlsYellow}
                overlay
              />
            )}
        </Row>
      </View>
    );
  };
  const renderRow = ({
    item: tiles,
    index,
  }: {
    item: Array<FC.Group>;
    index: number;
  }) => {
    const rowWidth =
      index === 0
        ? FIXED_TILE_WIDTH * numColumns + TILE_GAP * (numColumns - 1)
        : (FIXED_TILE_WIDTH_LARGE * numColumns) / 2 +
          TILE_GAP * (numColumns / 2 - 1);

    return (
      <Column noGap style={{ height: contentHeight }}>
        {index === 0 && SurfHomeHeader}
        <Row flex style={[styles.alignCenter, styles.justifyCenter]}>
          <Row
            flexShrink
            gap={TILE_GAP}
            style={[
              styles.flexWrap,
              {
                width: rowWidth,
              },
            ]}
          >
            {index === 0 &&
              renderCreateTile(FIXED_TILE_HEIGHT, FIXED_TILE_WIDTH)}
            {tiles.map((group) => renderFeedRowItem(group, index !== 0))}
          </Row>
        </Row>
      </Column>
    );
  };

  const onViewableItemsChanged = ({
    viewableItems,
  }: {
    viewableItems: Array<ViewToken>;
  }) => {
    const currentViewedItems = viewableItems.map((v) => v.index);

    if (currentViewedItems && currentViewedItems.length === 1) {
      const scrolledToIndex = currentViewedItems[0];
      if (scrolledToIndex !== null) {
        setCurrentIndex(scrolledToIndex);
      }
    }
  };
  const viewabilityConfig: ViewabilityConfig = {
    itemVisiblePercentThreshold: 95,
    minimumViewTime: 100,
  };
  const viewabilityConfigCallbackPairs = useRef<ViewabilityConfigCallbackPairs>(
    [{ onViewableItemsChanged, viewabilityConfig }]
  );

  return (
    <Row flex>
      <FlatList
        ref={homeListRef}
        data={pages}
        renderItem={renderRow}
        pagingEnabled
        keyExtractor={(_, index) => `homePage_${index}`}
        getItemLayout={(_data, index: number) => ({
          length: contentHeight,
          offset: contentHeight * index,
          index,
        })}
        viewabilityConfigCallbackPairs={viewabilityConfigCallbackPairs.current}
        contentContainerStyle={styles.noGap}
      />
      <Button
        title={"More Feeds"}
        titleStyle={styles.formLabel}
        type="clear"
        onPress={onPageDown}
        disabled={currentIndex === pages.length - 1}
        icon={
          <Icon
            name="arrow-down-circle-outline"
            size={SPACING.BASE3X}
            color={
              currentIndex === pages.length - 1
                ? theme.colors.controlsGrey
                : theme.colors.primary
            }
          />
        }
        buttonStyle={{ gap: SPACING.SMALL }}
        style={styles.moreFeedsButton}
      />
      {pages.length > 1 && (
        <CarouselKeyboardNavListener
          handlePaginationImpulse={handlePaginationImpulse(
            onPageUp,
            onPageDown,
            goToTop,
            goToBottom
          )}
        />
      )}
    </Row>
  );
};

const handlePaginationImpulse =
  (
    onPageUp: () => void,
    onPageDown: () => void,
    goToTop: () => void,
    goToBottom: () => void
  ) =>
  (paginationImpulse: PaginationImpulse) => {
    if (paginationImpulse === PaginationImpulse.DOWN) {
      onPageDown();
    }
    if (paginationImpulse === PaginationImpulse.UP) {
      onPageUp();
    }

    if (paginationImpulse === PaginationImpulse.TOP) {
      goToTop();
    }
    if (paginationImpulse === PaginationImpulse.BOTTOM) {
      goToBottom();
    }
  };

const useStyles = makeStyles(
  (
    theme,
    {
      isPhone,
      isNarrow,
      surfHomePadding,
      contentWidth,
    }: {
      isPhone: boolean;
      isNarrow: boolean;
      surfHomePadding: number;
      contentWidth: number;
    }
  ) =>
    createStyleSheet({
      surfHomeWrapper: {
        justifyContent: "center",
        paddingTop: SPACING.BASE5X,
        paddingHorizontal: surfHomePadding,
        paddingBottom: SPACING.BASE12X,
        ...((isPhone || isNarrow) && {
          alignItems: "center",
        }),
      },
      home: {
        backgroundColor: theme.colors.backgroundReverse50,
        margin: isPhone ? 0 : SPACING.BASE,
        borderRadius: SPACING.BASE,
        overflow: "hidden",
      },
      header: {
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.dividerPrimary,
      },
      searchIcon: {
        position: "absolute",
        left: SPACING.LARGE,
        zIndex: 1,
        height: "100%",
      },
      createCustomButton: {
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 1,
      },
      feedFavoriteButton: {
        position: "absolute",
        top: SPACING.BASE,
        right: SPACING.BASE,
        zIndex: 1,
      },
      listColumnWrapper: {
        flexWrap: "wrap",
        gap: SPACING.LARGE,
        justifyContent: "center",
      },
      gridGap: {
        gap: SPACING.LARGE,
      },
      selectedTile: {
        shadowColor: theme.colors.grey3,
        shadowRadius: 8,
        shadowOffset: { width: 0, height: 0 },
        borderWidth: 1,
        borderColor: theme.colors.grey3,
      },
      input: {
        color: theme.colors.primary,
        backgroundColor: theme.colors.primaryReverse50,
        fontSize: 16,
        borderWidth: 1,
      },
      inputContainer: {
        flex: 1,
        padding: 0,
        width: "auto",
      },
      tileOverlayText: {
        backgroundColor: "transparent",
        overflowWrap: "anywhere",
        textAlign: "center",
        textShadowColor: theme.colors.dividerPrimaryReverse,
        textShadowOffset: { width: 1, height: 1 },
        textShadowRadius: 2,
      },
      title: {
        fontFamily: "RobotoCondensed_700Bold",
        fontWeight: "bold",
        textAlign: "center",
      },
      customIconWrapper: {
        marginTop: "auto",
        marginBottom: "auto",
        width: FIXED_TILE_WIDTH * 0.9,
        height: FIXED_TILE_HEIGHT * 0.9,
        borderWidth: 1,
        borderStyle: "dashed",
        borderColor: theme.colors.dividerPrimary,
        borderRadius: SPACING.BASE,
        alignItems: "center",
        justifyContent: "center",
        alignSelf: "center",
      },
      welcome: {
        textAlign: isPhone || isNarrow ? "center" : "left",
        fontSize: isPhone ? SPACING.BASE3X : SPACING.BASE6X,
        fontFamily: "RobotoSlab_500Medium",
        wordWrap: "initial",
        fontWeight: 600,
        ...(!isPhone &&
          !isNarrow && {
            maxWidth: LEFT_TITLE_WIDTH,
          }),
      },
      moreFeedsButton: {
        position: "absolute",
        zIndex: 1,
        bottom: SPACING.BASE3X,
        right: SPACING.BASE3X,
      },
      surfHomeSearch: {
        backgroundColor: theme.colors.backgroundReverse10,
        borderColor: theme.colors.backgroundReverse50,
        borderRadius: SPACING.BASE4X,
        borderWidth: 1,
        padding: SPACING.LARGE,
        width: isPhone ? "95%" : LAYOUT.SEARCH_WIDTH,
        cursor: "text",
      },
      surfHomeSearchContainer: {
        backgroundColor: "black",
      },
      surfHomeSearchInner: {
        backgroundColor: "none",
        width: Math.min(contentWidth * 0.7, 800),
      },
      surfHomeSearchTitle: {
        backgroundColor: "black",
      },
      createButton: {
        borderWidth: 2,
        borderColor: theme.colors.dividerPrimary,
        borderStyle: "dashed",
      },
      createButtonContents: {
        alignItems: "center",
        justifyContent: "center",
        padding: SPACING.LARGE,
        width: "100%",
      },
      filterTabRow: { padding: SPACING.LARGE },
      filterButton: {},
      filterButtonSelected: {},
    })
);

export default SurfHome;
