import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { FlatList } from "react-native";
import { useAppDispatch, useAppSelector } from "~/hooks";
import { SPACING, createStyleSheet } from "~/styles";
import { selectCredentials } from "~/concepts/auth";
import { makeStyles } from "@rneui/themed";
import { useToast } from "react-native-toast-notifications";

import ThreadItem from "~/components/ThreadItem";
import api, {
  useLazyGetInstanceItemQuery,
  useLazyStatusContextQuery,
} from "~/api";
import {
  getShownReplyItem,
  getRequestedReplyItemChange,
  setShownReplyItem,
  setPreviewItem,
  clearShownReplyItem,
} from "~/concepts/thread";
import { useThreadItemNavigation } from "~/hooks/useThreadItemNavigation";
import isEqual from "lodash-es/isEqual";
import Loading from "~/components/Loading";

// HERE BE DRAGONS!
//
// Very easy to break something here so be careful...
//
// Make sure you can still do the following after changing:
// * Favorite the focused thread item (and see the icon update)
// * Favorite a non-focused thread item (and see the icon update)
// * Switch to a different thread item and then favorite it (and see the icon update)
// * Switching to an item to scroll it into focus if it is out of focus
// * Starting with an image should have media view
// * Switching to an image should have media view
// * Switching from an image to a non-image should have no media view
// * All the reply stuff should work from outside of the thread and in
// * etc...

const Thread: React.FC<{ item: FC.Item; onClose: () => void }> = ({
  item,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const styles = useStyles();
  const flatListRef = useRef<FlatList>(null);
  const authenticated = useAppSelector(selectCredentials);
  const [instanceItem, setInstanceItem] = useState<FC.Item | null>(null);
  const [itemToRender, setItemToRender] = useState<FC.Item | null>(null);
  const [lastScrolledToIndex, setLastScrolledToIndex] = useState<number>(-1);

  const shownReplyItem = useAppSelector(getShownReplyItem);
  const requestReplyItemChange = useAppSelector(getRequestedReplyItemChange);
  // we use select with the argument here because just using the
  // response data isn't necessarily scoped to your argument when you
  // are calling it more than once....
  const { data: statusContext } = useAppSelector((state) => {
    const statusContextId = instanceItem?.id || itemToRender?.id;
    if (statusContextId) {
      return api.endpoints.statusContext.select(statusContextId)(state);
    }
    return { data: { ancestors: [], descendants: [] } };
  });

  // we could probably also refactor to do the above for instanceItem

  const { navigateToThreadItem } = useThreadItemNavigation();
  const toast = useToast();

  const setFocusedItem = useCallback(
    (i: FC.Item) => {
      setItemToRender(i);
      dispatch(setPreviewItem(i));
    },
    [dispatch]
  );

  const threadItems = useCallback(() => {
    return [
      ...(statusContext?.ancestors || []),
      { ...itemToRender, focused: true },
      ...(statusContext?.descendants || []),
    ];
  }, [itemToRender, statusContext]);

  // item changed, start over
  useEffect(() => {
    setInstanceItem(null);
    setItemToRender(null);
  }, [item.source]);

  useEffect(() => {
    if (shownReplyItem === null && requestReplyItemChange !== null) {
      dispatch(setShownReplyItem(requestReplyItemChange));
    }
  }, [requestReplyItemChange, dispatch, shownReplyItem]);

  const [
    statusContextQueryTrigger,
    statusContextQueryResponse,
    lastContextPromise,
  ] = useLazyStatusContextQuery();
  const [getInstanceItemTrigger, getInstanceItemResponse, lastInstancePromise] =
    useLazyGetInstanceItemQuery();

  // not logged in, itemToRender can be item
  useEffect(() => {
    if (!authenticated && !itemToRender) {
      setFocusedItem(item);
    }
  }, [authenticated, item, itemToRender, setFocusedItem]);

  // not logged in and need status context
  useEffect(() => {
    if (
      !authenticated &&
      itemToRender &&
      statusContextQueryResponse.isUninitialized
    ) {
      const fetchContext = async () => {
        const response = await statusContextQueryTrigger(item.id);
        if (response.isError) {
          onClose(); // close thread drawer
          toast.show(
            "Sorry, something went wrong. Maybe someone deleted the status?",
            {
              type: "fail",
            }
          );
        }
      };

      fetchContext();
    }
  }, [
    authenticated,
    item.id,
    itemToRender,
    statusContextQueryResponse.isUninitialized,
    statusContextQueryTrigger,
    onClose,
    toast,
  ]);

  // authenticated, no instance item, instance request not started
  useEffect(() => {
    if (authenticated && !instanceItem) {
      getInstanceItemTrigger(item.source);
    }
  }, [authenticated, getInstanceItemTrigger, item.source, instanceItem]);

  // authenticated, no instance item yet, instance request completed
  useEffect(() => {
    if (authenticated && getInstanceItemResponse.isSuccess) {
      if (
        getInstanceItemResponse.data.source === item.source &&
        !isEqual(instanceItem, getInstanceItemResponse.data)
      ) {
        setInstanceItem(getInstanceItemResponse.data);
      }
    } else if (authenticated && getInstanceItemResponse.isError) {
      dispatch(clearShownReplyItem());
      onClose(); // close thread drawer
      toast.show(
        "Sorry, something went wrong.  Maybe someone deleted the status?",
        {
          type: "fail",
        }
      );
    }
  }, [
    authenticated,
    instanceItem,
    getInstanceItemResponse,
    lastInstancePromise.lastArg,
    item.source,
    onClose,
    toast,
    dispatch,
  ]);

  useEffect(() => {
    if (instanceItem) {
      setFocusedItem(instanceItem);
    }
  }, [instanceItem, setFocusedItem]);

  // authenticated, have instance item from above, no context response
  useEffect(() => {
    if (authenticated && instanceItem) {
      if (
        typeof lastContextPromise.lastArg !== "string" ||
        lastContextPromise.lastArg !== instanceItem.id
      ) {
        statusContextQueryTrigger(instanceItem.id);
      }
    }
  }, [
    authenticated,
    instanceItem,
    statusContextQueryResponse.isUninitialized,
    statusContextQueryTrigger,
    lastContextPromise.lastArg,
  ]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const items = threadItems();
      const shownReplyItemIndex = items.findIndex(
        (i) => i.id === shownReplyItem
      );
      const focusedItemIndex = items.findIndex((i) => i.focused);
      const jumpToItemIndex = shownReplyItem
        ? shownReplyItemIndex
        : focusedItemIndex;

      if (jumpToItemIndex > 0 && jumpToItemIndex !== lastScrolledToIndex) {
        setLastScrolledToIndex(jumpToItemIndex);
        flatListRef.current?.scrollToIndex({
          index: jumpToItemIndex,
          // if we are showing a reply item, we focus on the end of the item (1),
          // else 0 for the top.
          viewPosition: shownReplyItem ? 1 : 0,
        });
      }
    }, 250);
    return () => clearTimeout(timeout);
  }, [
    shownReplyItem,
    threadItems,
    lastScrolledToIndex,
    setLastScrolledToIndex,
  ]);

  // still working on getting ready
  if (!statusContext || !itemToRender) {
    return <Loading />;
  }

  return (
    <FlatList
      style={styles.scrollView}
      data={threadItems()}
      ref={flatListRef}
      onScrollToIndexFailed={console.error}
      renderItem={({ item: i, index }) => (
        <ThreadItem
          item={i}
          reblogger={item.reblogger}
          rootContextItem={instanceItem || itemToRender}
          index={index}
          onPress={() => {
            navigateToThreadItem(i);
          }}
        />
      )}
      initialNumToRender={3}
    />
  );
};

const useStyles = makeStyles(() =>
  createStyleSheet({
    scrollView: {
      flex: 1,
      overflowY: "scroll",
      padding: SPACING.LARGE,
    },
  })
);

export default memo(Thread);
