import React, { memo } from "react";
import { createStyleSheet, LAYOUT, SPACING } from "~/styles";

import { Column, Row } from "~/components/elements";

import { Header as HeaderRNE, makeStyles, useTheme } from "@rneui/themed";
import UserAvatar from "~/components/UserAvatar";
import Auth from "~/components/Auth";
import HomeButton from "~/components/HomeButton";
import HeaderFeedControls from "~/components/HeaderFeedControls";
import Search from "~/components/Search";
import DebugControls from "~/components/DebugControls";

const MainHeader = () => {
  const styles = useStyles();
  const { theme } = useTheme();

  return (
    <Column inverse noGap style={styles.headerWrapper}>
      <HeaderRNE
        backgroundColor={theme.colors.background}
        containerStyle={styles.headerContainer}
        style={styles.header}
        leftComponent={
          <Row style={styles.alignCenter}>
            <HomeButton />
          </Row>
        }
        leftContainerStyle={[
          styles.noFlex,
          styles.alignCenter,
          styles.justifyCenter,
        ]}
        centerComponent={
          <Row style={styles.navBar}>
            <Search />
            <HeaderFeedControls />
          </Row>
        }
        centerContainerStyle={[styles.flex1, styles.centerContainer]}
        rightComponent={
          <Row>
            <Auth />
            <DebugControls />
            <UserAvatar size={SPACING.BASE4X} />
          </Row>
        }
        rightContainerStyle={[styles.noFlex, styles.justifyCenter]}
      />
    </Column>
  );
};

const useStyles = makeStyles((theme) => {
  return createStyleSheet({
    headerWrapper: {
      height: LAYOUT.HEADER_HEIGHT,
      position: "relative",
      zIndex: 1,
    },
    headerContainer: {
      flex: 1,
      alignItems: "center",
      paddingVertical: 0,
      borderBottomWidth: 1,
      borderBottomColor: theme.colors.dividerPrimary,
      height: "100%",
    },
    header: { height: "100%" },
    navBar: {
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      width: "100%",
      position: "relative",
    },
    centerContainer: {
      width: "60%",
      height: LAYOUT.HEADER_HEIGHT,
    },
  });
});

export default memo(MainHeader);
