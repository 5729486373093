import React, { memo, PropsWithChildren } from "react";
import { createStyleSheet, LAYOUT, SPACING } from "~/styles";
import { Input, InputProps, makeStyles, useTheme } from "@rneui/themed";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import Button from "~/components/elements/Button";
import { TextInput } from "react-native";

interface SearchBarProps extends InputProps {
  onClear: () => void;
  dark?: boolean;
}

const SearchBar = React.forwardRef<
  PropsWithChildren<TextInput>,
  SearchBarProps
>(
  (
    { onClear, style, containerStyle, inputStyle, dark = false, ...rest },
    ref
  ) => {
    const styles = useStyles(dark);
    const { theme } = useTheme();

    const color = dark ? theme.colors.primaryReverse : theme.colors.primary;
    return (
      <Input
        ref={ref}
        containerStyle={[styles.searchContainer, containerStyle]}
        style={[styles.search, style]}
        inputStyle={[styles.searchInput, inputStyle]}
        inputContainerStyle={styles.searchInputContainer}
        rightIconContainerStyle={styles.rightIconContainer}
        leftIconContainerStyle={styles.leftIconContainer}
        leftIcon={<Icon name="magnify" color={color} size={SPACING.BASE3X} />}
        rightIcon={
          <Button type={"clear"} onPress={onClear}>
            <Icon name="window-close" color={color} size={SPACING.BASE3X} />
          </Button>
        }
        {...rest}
      />
    );
  }
);

const useStyles = makeStyles((theme, dark) => {
  return createStyleSheet({
    searchContainer: {
      height: LAYOUT.HEADER_HEIGHT - 1,
      backgroundColor: theme.colors.background,
      alignItems: "center",
      justifyContent: "center",
      paddingLeft: 0,
      paddingRight: 0,
      borderRadius: SPACING.BASE,
      zIndex: 2,
    },
    search: {
      backgroundColor: dark
        ? theme.colors.backgroundReverse
        : theme.colors.backgroundTertiary,
      height: LAYOUT.HEADER_COMPONENT_HEIGHT,
      position: "absolute",
      width: "100%",
      borderColor: theme.colors.dividerPrimary,
      borderWidth: 1,
      borderRadius: SPACING.BASE,
      paddingHorizontal: SPACING.BASE5X,
      minHeight: LAYOUT.HEADER_COMPONENT_HEIGHT,
      color: dark ? theme.colors.primaryReverse : theme.colors.primary,
      zIndex: 2,
    },
    searchInputContainer: {
      height: LAYOUT.HEADER_COMPONENT_HEIGHT,
      position: "absolute",
      width: "100%",
    },
    searchInput: {},

    leftIconContainer: {
      position: "absolute",
      left: SPACING.BASE,
      zIndex: 100,
    },
    rightIconContainer: {
      position: "absolute",
      right: SPACING.BASE,
      zIndex: 100,
    },
  });
});

export default memo(SearchBar);
