import React from "react";
import { createStyleSheet, SPACING } from "~/styles";
import { makeStyles } from "@rneui/themed";
import { Anchor, Column, Row, Text } from "~/components/elements";
import Content from "~/components/Content";
import { isPhoneSelector } from "~/concepts/application";
import { useAppSelector } from "~/hooks";
import { CardSize, OverlayTitlePosition, TextAttributionStyle } from "~/enums";
import ItemImage from "~/components/ItemImage";
import AutoNumberOfLinesWrapper from "~/components/elements/AutoNumberOfLinesWrapper";
import CardAuthor from "~/components/CardAuthor";
import BlurredOverlayTitle from "~/components/cardComponents/BlurredOverlayTitle";
import CardText from "~/components/cardComponents/CardText";

type BasicLinkCardProps = {
  item: FC.Item;
  cardSize?: CardSize;
  overlay?: boolean;
};

const SocialArticleCardHorizontal: React.FC<BasicLinkCardProps> = (props) => {
  const { item, cardSize, overlay } = props;
  const isPhone = useAppSelector(isPhoneSelector);
  const styles = useStyles({ overlay });

  if (!item.card) return null;

  const renderLargeImageComponent = () => (
    <>
      <Row flex style={[styles.imageContainer]}>
        <ItemImage item={item} />
        <BlurredOverlayTitle
          item={item}
          position={OverlayTitlePosition.BOTTOM_LEFT}
          distanceFromEdge={SPACING.BASE3X}
          cardSize={cardSize}
        />
      </Row>
    </>
  );

  const renderMediumImageComponent = () => (
    <>
      <Column noGap style={[styles.imageContainer]}>
        <ItemImage item={item} contentFit={"cover"} />
        <Column gap={SPACING.MEDIUM} style={styles.cardTitleContainer}>
          <AutoNumberOfLinesWrapper
            flex
            maxNumberOfLines={cardSize === CardSize.SMALL ? 1 : undefined}
          >
            <CardText header cardSize={cardSize}>
              {item.card?.title}
            </CardText>
          </AutoNumberOfLinesWrapper>

          <AutoNumberOfLinesWrapper
            maxNumberOfLines={cardSize === CardSize.SMALL ? 1 : undefined}
          >
            <Text attribution={TextAttributionStyle.SPECIAL}>
              {item?.card?.flip?.publisherName || item?.card?.provider_name}
            </Text>
          </AutoNumberOfLinesWrapper>
        </Column>
      </Column>
    </>
  );

  return (
    <Row
      flex
      style={[styles.newsItemCard, isPhone && styles.articleContentPhone]}
    >
      <Column flex style={[styles.justifyCenter]}>
        <CardAuthor item={item} />
        <AutoNumberOfLinesWrapper>
          <Content cardSize={cardSize} item={item} />
        </AutoNumberOfLinesWrapper>
      </Column>
      <Anchor flex href={item.card?.url}>
        {cardSize === CardSize.LARGE || cardSize === CardSize.XLARGE
          ? renderLargeImageComponent()
          : renderMediumImageComponent()}
      </Anchor>
    </Row>
  );
};

const useStyles = makeStyles((theme, { overlay }: { overlay?: boolean }) =>
  createStyleSheet({
    newsItemCard: {
      padding: SPACING.BASE4X,
      width: "100%",
    },
    imageContainer: {
      borderRadius: SPACING.BASE,
      height: "100%",
      width: "100%",
      borderWidth: 1,
      borderColor: overlay
        ? theme.colors.dividerOverlay
        : theme.colors.dividerPrimary,
    },
    cardTitleContainer: { padding: SPACING.MEDIUM, maxHeight: "70%" },
    articleContentPhone: {
      padding: SPACING.LARGE,
    },
    articleAnchor: { height: "100%" },
  })
);
export default SocialArticleCardHorizontal;
