import { StyleSheet } from "react-native";

export const createStyleSheet = (
  styles: Record<string, Record<string, any>> = {}
): Record<string, Record<string, any>> => {
  return StyleSheet.flatten([baseStyles, typographyStyles, styles]);
};

export const LAYOUT = {
  MAX_FEED_LAYOUT_WIDTH: 1920,
  DEFAULT_GAP_SIZE: 16,
  NAV_WIDTH: 150,
  LOGO_SIZE: 150,
  FEED_WIDTH: 250,
  DEFAULT_MODAL_WIDTH: 450,
  AUTHOR_BUSINESS_CARD_WIDTH: 320,
  AUTHOR_BUSINESS_CARD_HEIGHT: 500,
  THUMB_WIDTH: 50,
  THUMB_HEIGHT: 40,
  CARD_ACTIONS_OVERFLOW_WIDTH: 200,
  CARD_ACTIONS_BUTTON_HEIGHT: 24,
  CARD_ACTIONS_OVERFLOW_HEIGHT: 300,
  PROFILE_ACTIONS_OVERFLOW_WIDTH: 178,
  LOGIN_WIDTH: 720,
  MOBILE_HEADER_HEIGHT: 72,
  HEADER_HEIGHT: 48,
  FEATURE_GENERIC_IMAGE_HEIGHT_PERCENTAGE: 0.6,
  TOAST_MESSAGE_Z_INDEX: 999999999999,
  SHARE_DIALOG_WIDTH: 500,
  SHARE_DIALOG_HEIGHT: 240,
  CUSTOM_FEED_DIALOG_WIDTH: 500,
  HEADER_COMPONENT_HEIGHT: 36,
  SEARCH_WIDTH: 480,
  SEARCH_HEIGHT: 420,
  DEFAULT_HEADER_HEIGHT: 300,
  AUTHOR_ACTION_HEIGHT: 36,
};

export const SPACING = {
  NONE: 0,
  XSMALL: 2,
  SMALL: 4,
  BASE: 8,
  MEDIUM: 12,
  LARGE: 16,
  BASE3X: 24,
  BASE4X: 32,
  BASE5X: 40,
  BASE6X: 48,
  BASE7X: 56,
  BASE8X: 64,
  BASE10X: 80,
  BASE12X: 96,
};

export const STYLE_VALUES = { UNSET: "unset" };

export const COLORS = {
  RED: "#f52828",
  FAVORITE: "#CA8F03",
  BOOKMARK: "#F52828",
  REBLOG: "#029350",
};

export const typographyStyles = {
  base: {
    fontFamily: "Roboto_400Regular",
    fontWeight: "normal",
  },
  header: {
    fontFamily: "RobotoSlab_600SemiBold",
    fontWeight: "semibold",
  },
  bold: { fontWeight: "bold" },
  italic: {
    fontStyle: "italic",
  },
  formLabel: {
    fontFamily: "Roboto_700Bold",
    fontSize: 16,
  },
  dialogTitle: {
    fontSize: SPACING.LARGE,
    fontFamily: "Roboto_500Medium",
    fontWeight: "medium",
  },
  buttonTitle: {
    fontFamily: "Roboto_500Medium",
    fontWeight: "medium",
    fontSize: 14,
  },
  debugText: {
    color: "firebrick",
    fontSize: 12,
    fontWeight: "bold",
  },
};

const baseStyles = {
  noFlex: { flex: -1 },
  noGap: { gap: 0 },
  noZIndex: { zIndex: -1 },
  noUserSelect: { userSelect: "none" },
  flex1: { flex: 1 },
  flex2: { flex: 2 },
  flexShrink1: { flexShrink: 1 },
  flexGrow1: { flexGrow: 1 },
  flexWrap: { flexWrap: "wrap" },
  fullHeight: { height: "100%" },
  fullWidth: { width: "100%" },
  justifyCenter: {
    justifyContent: "center",
    justifySelf: "center",
  },
  alignStart: { alignItems: "flex-start" },
  alignCenter: {
    alignItems: "center",
  },
  alignEnd: { alignItems: "flex-end" },
  alignBaseline: {
    alignItems: "baseline",
  },
  justifyLeft: { justifyContent: "flex-start" },
  justifyRight: {
    justifyContent: "flex-end",
  },
  textCenter: { textAlign: "center" },

  evenlySpaced: {
    justifyContent: "space-between",
  },
  horizontalFlex: { flexDirection: "row" },
  verticalFlex: { flexDirection: "column" },
  container: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  defaultDivider: {
    width: "80%",
    alignSelf: "center",
  },
  noBackground: {
    backgroundColor: "transparent",
  },
  hidden: {
    display: "none",
  },
  reverseBackground: {
    backgroundColor: "#111213", //theme.colors.backgroundSecondaryReverse,
  },
  noPadding: { padding: 0 },
  textShadow: {
    textShadowColor: "#404040", // theme.colors.dividerOverlay
    textShadowOffset: { width: 1, height: 1 },
    textShadowRadius: 2,
  },
  noPointer: { pointerEvents: "none" },
  debugInfo: {
    position: "absolute",
    paddingHorizontal: SPACING.XSMALL,
    borderRadius: SPACING.SMALL,
    backgroundColor: "#ffffffD0",
    borderWidth: 1,
    zIndex: 99999,
    borderColor: "firebrick",
    pointerEvents: "none",
  },
};
