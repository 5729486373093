import React, { memo, useState } from "react";
import { createStyleSheet, SPACING } from "~/styles";
import { Switch, makeStyles, useTheme } from "@rneui/themed";
import { Column, Text } from "~/components/elements";
import { usePreferences } from "~/hooks/usePreferences";

const ContentWarningSwitch: React.FC = () => {
  const { theme } = useTheme();
  const styles = useStyles();
  const [preferences, setPreferences] = usePreferences();
  const [value, setValue] = useState(preferences.showContentWarnings);

  const onChange = (v: boolean) => {
    if (preferences.showContentWarnings !== v) {
      setValue(v);
      setPreferences({
        ...preferences,
        showContentWarnings: v,
      });
    }
  };

  return (
    <Column style={[styles.settingsWrapper]} inverse>
      <Text inverse style={styles.switch}>
        Content Warnings:
      </Text>
      <Switch
        value={value}
        onValueChange={onChange}
        color={theme.colors.controls}
      />
    </Column>
  );
};

const useStyles = makeStyles(() => {
  return createStyleSheet({
    settingsWrapper: { gap: SPACING.SMALL },
    switch: {
      fontWeight: "bold",
      alignContent: "center",
      justifyContent: "center",
    },
  });
});

export default memo(ContentWarningSwitch);
