import React, { memo } from "react";
import { createStyleSheet, SPACING } from "~/styles";
import {
  makeStyles,
  Button as RNEButton,
  ButtonProps as RNEButtonProps,
} from "@rneui/themed";
import { StyleProp } from "react-native";
import Hoverable from "~/components/elements/Hoverable";
import HoverOverlay from "~/components/elements/HoverOverlay";
import { IconNode } from "@rneui/base";

const BUTTON_RADIUS = 3;
export type ButtonProps = {
  overlay?: boolean;
  style?: StyleProp<ViewStyle>;
  children?: React.ReactNode | ((isHovered: boolean) => React.ReactNode);
  hoverTitle?: string;
  hoverColor?: string;
  iconHover?: IconNode;
  iconRight?: boolean;
} & Omit<RNEButtonProps, "children">;

const Button: React.FC<ButtonProps> = ({
  overlay = false,
  type,
  style,
  buttonStyle,
  containerStyle,
  titleStyle,
  children,
  color,
  hoverColor,
  title,
  hoverTitle,
  icon,
  iconHover,
  iconRight,
  ...rest
}) => {
  const styles = useStyles({ overlay, color });
  let calculatedButtonStyle: StyleProp<ViewStyle> = styles.defaultButtonStyle;
  switch (type) {
    case "solid":
      calculatedButtonStyle = styles.solidButton;
      break;
    case "outline":
      calculatedButtonStyle = styles.outlineButton;
      break;
    case "clear":
      calculatedButtonStyle = styles.clearButton;
      break;
  }

  return (
    <Hoverable style={style}>
      {(isHovered) => {
        const displayTitle = isHovered && hoverTitle ? hoverTitle : title;
        return (
          <>
            <RNEButton
              type={type}
              title={displayTitle}
              buttonStyle={[
                styles.flex1,
                calculatedButtonStyle,
                buttonStyle,
                isHovered && hoverColor ? { color: hoverColor } : {},
              ]}
              icon={isHovered && iconHover ? iconHover : icon}
              iconRight={iconRight}
              containerStyle={[
                (type === "solid" || type === "outline") &&
                  styles.defaultContainerStyle,
                containerStyle,
              ]}
              style={[styles.flex1, styles.noPadding]}
              titleStyle={[
                styles.buttonTitle,
                (type === "outline" || type === "clear") && styles.title,
                type === "solid" && styles.titleSolid,
                titleStyle,
              ]}
              {...rest}
            >
              {typeof children === "function" ? children(isHovered) : children}
            </RNEButton>
            <HoverOverlay isHovered={isHovered} overlay={overlay} />
          </>
        );
      }}
    </Hoverable>
  );
};

const useStyles = makeStyles(
  (
    theme,
    {
      overlay,
      color,
    }: {
      overlay?: boolean;
      color?: RNEButtonProps["color"];
    }
  ) => {
    return createStyleSheet({
      defaultContainerStyle: { borderRadius: BUTTON_RADIUS },
      defaultButtonStyle: { borderRadius: BUTTON_RADIUS, gap: SPACING.SMALL },
      solidButton: {
        backgroundColor:
          color ||
          (overlay
            ? theme.colors.backgroundOverlay
            : theme.colors.backgroundReverse),
        borderWidth: 1,
      },
      outlineButton: {
        backgroundColor: theme.colors.background,
        padding: SPACING.BASE,
      },
      clearButton: {
        paddingHorizontal: SPACING.NONE,
      },

      title: {
        color: overlay ? theme.colors.overlay : theme.colors.primary,
      },
      titleSolid: {
        color:
          (color && theme.colors.primary) ||
          (overlay ? theme.colors.primaryDark : theme.colors.primaryReverse),
      },
    });
  }
);

export default memo(Button);
