import { Button, ButtonProps, makeStyles, useTheme } from "@rneui/themed";
import React from "react";
import { GestureResponderEvent } from "react-native";

import { createStyleSheet, SPACING, LAYOUT } from "~/styles";
import Hoverable from "~/components/elements/Hoverable";
import { IconNode, IconObject, IconProps } from "@rneui/base";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { DismissTooltipContext } from "~/components/elements/OverflowMenu";
import { StyleProp, ViewStyle, StyleSheet } from "react-native";
import HoverOverlay from "~/components/elements/HoverOverlay";

type IconNameType = keyof typeof Icon.glyphMap;
type OverflowMenuButtonProps = {
  children?: React.ReactNode | ((isHovered: boolean) => React.ReactNode);
  icon?: string | IconNode;
  color?: string;
  iconProps?: Partial<IconProps> & Partial<IconObject>;
  style?: StyleProp<ViewStyle>;
  onPress?: ((event: GestureResponderEvent) => void) | null;
  pending?: boolean;
  dismissOnClick?: boolean;
  triggerDismiss?: boolean;
  overlay?: boolean;
} & Omit<ButtonProps, "icon" | "children" | "color">;

const OverflowMenuButton: React.FC<OverflowMenuButtonProps> = ({
  title,
  icon,
  color,
  iconProps,
  onPress = null,
  children,
  style,
  titleStyle,
  dismissOnClick = true,
  triggerDismiss,
  overlay,
  ...restProps
}) => {
  const { theme } = useTheme();
  const renderIcon = () => {
    if (!icon) return null;

    let buttonColor = overlay
      ? theme.colors.primaryReverse
      : theme.colors.primary;
    if (color) {
      buttonColor = color;
    }
    if (typeof icon === "string") {
      return (
        <Icon
          {...iconProps}
          name={icon as IconNameType}
          size={20}
          color={buttonColor}
          style={!!title && styles.iconContainer}
        />
      );
    } else {
      return icon;
    }
  };
  const styles = useStyles({ overlay });

  return (
    <Hoverable style={styles.buttonHoverWrapper}>
      {(isHovered) => (
        <>
          <DismissTooltipContext.Consumer>
            {(dismissTooltip) => {
              if (triggerDismiss) {
                dismissTooltip();
              }
              const renderChildren = () => {
                if (typeof children === "function") {
                  return children(isHovered);
                }
                return children;
              };
              return (
                <Button
                  title={title}
                  containerStyle={styles.actionButton}
                  disabled={!onPress}
                  buttonStyle={StyleSheet.flatten([styles.buttonStyle, style])}
                  titleStyle={StyleSheet.flatten([styles.title, titleStyle])}
                  onPress={(e) => {
                    onPress && onPress(e);
                    if (dismissOnClick) {
                      dismissTooltip();
                    }
                  }}
                  type="clear"
                  color={"primary"}
                  icon={renderIcon() || undefined}
                  {...restProps}
                >
                  {renderChildren()}
                </Button>
              );
            }}
          </DismissTooltipContext.Consumer>
          <HoverOverlay isHovered={isHovered} overlay={overlay} />
        </>
      )}
    </Hoverable>
  );
};

const useStyles = makeStyles((theme, { overlay }: { overlay?: boolean }) => {
  return createStyleSheet({
    buttonHoverWrapper: { flex: 1, width: "100%" },
    actionButton: {
      flex: 1,
      paddingVertical: SPACING.SMALL,
      paddingHorizontal: SPACING.SMALL,
      gap: SPACING.BASE,
    },
    buttonStyle: {
      flex: 1,
      paddingHorizontal: SPACING.SMALL,
      gap: SPACING.BASE,
    },
    hoveredButton: {
      backgroundColor: overlay
        ? theme.colors.backgroundHoverOverlay
        : theme.colors.backgroundHoverSecondary,
    },
    title: {
      fontFamily: "Roboto_400Regular",
      fontSize: 16,
      color: overlay ? theme.colors.primaryReverse : theme.colors.primary,
      width: "100%",
      textAlign: "left",
      display: "flex",
      alignItems: "center",
      minHeight: LAYOUT.CARD_ACTIONS_BUTTON_HEIGHT,
    },
    hoveredTitle: {
      color: theme.colors.primary,
      backgroundColor: overlay
        ? theme.colors.backgroundHoverOverlay
        : theme.colors.backgroundHoverSecondary,
    },
    iconContainer: {
      textAlign: "center",
    },
  });
});

export default OverflowMenuButton;
