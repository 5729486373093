export enum NodeEnv {
  DEVELOPMENT = "development",
  PRODUCTION = "production",
}

export enum PaginationImpulse {
  TOP = "top",
  UP = "up",
  DOWN = "down",
  LEFT = "left",
  RIGHT = "right",
  BOTTOM = "bottom",
}

export enum PersistentStorageKeys {
  BEARER = "bearer",
  HOST = "host",
  CUID = "cuid_0", // bump to replace client ids for everyone
}

export enum ItemType {
  STATUS = "status",
  ARTICLE = "article",
  PHOTO = "photo",
  YOUTUBE = "youtube",
  GIFV = "gifv",
  AV = "av",
  FLIPBOARD_MAGAZINE = "flipboard-magazine",
  POLL = "poll",
  SOCIAL_ARTICLE = "social-article",
}

export enum GroupCategory {
  NEWS = "news",
  TECH = "tech",
  CULTURE = "culture",
  SCIENCE = "science",
  BEST = "best",
  WANDERLUST = "wanderlust",
  TASTIEST = "tastiest",
  GALLERY = "gallery",
  CATURDAY = "caturday",
  YOUTUBES = "youtubes",
  CW = "cw",
  FIRSTPARTYVIDEOS = "firstpartyvideos",
  TESTING_STUFF = "testing-stuff",
  ATOM = "atom",
}

export enum GroupPresentationType {
  GALLERY = "gallery",
}

export enum CARD_SIZE {
  FEATURE = "feature",
  WIDE = "wide",
  NORMAL = "normal",
}

export enum HOVER_DIRECTION {
  UP_LEFT = "up_left",
  UP_RIGHT = "up_right",
  DOWN_LEFT = "down_left",
  DOWN_RIGHT = "down_right",
  CENTER = "center",
}

export enum AttachmentType {
  IMAGE = "image",
  VIDEO = "video",
  GIFV = "gifv",
  AUDIO = "audio",
}

export enum CardType {
  VIDEO = "video",
  LINK = "link",
}

export enum ScreenSizeType {
  DESKTOP_WIDE = "desktop_wide",
  DESKTOP = "desktop",
  NARROW = "narrow",
  PHONE = "phone",
}

export enum GroupRequestType {
  HOME_TIMELINE = "home_timeline",
  FEED = "feed",
  TOPIC = "topic",
  HASHTAG = "hashtag",
  CUSTOM_FEED = "custom_feed",
  ACCOUNT = "ACCOUNT",
  CUSTOM_FEED_PREVIEW = "custom_feed_preview",
  RSS = "RSS",
  YOUTUBE = "youtube",
  SEARCH = "search",
}

export enum Visibility {
  PUBLIC = "public",
  UNLISTED = "unlisted",
  PRIVATE = "private",
  DIRECT = "direct",
}

export enum ImageAspectRatioType {
  ULTRA_WIDE = "ultra_wide",
  WIDE = "wide",
  LANDSCAPE = "landscape",
  SQUARE = "square",
  PORTRAIT = "portrait",
  TALL = "tall",
}

export enum BaseCardVariantType {
  FEATURE_GENERIC_GREY_LAYOUT = "feature_generic_grey_layout",
  FEATURE_GENERIC_WHITE_LAYOUT = "feature_generic_white_layout",
}

export enum SearchResultType {
  DIRECT = "DIRECT",
  RECENT = "recent",
  ACCOUNT = "account",
  HASHTAG = "hashtag",
  TOPIC = "topic",
  FAVORITE = "favorite",
  FEATURED = "featured",
  RSS = "rss",
  YOUTUBE = "YOUTUBE",
}

export enum CustomFeedFormType {
  CREATE = "create",
  EDIT = "edit",
}

export enum CardSize {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
  XLARGE = "xlarge",
  XXLARGE = "xxlarge",
}

export enum TextSize {
  XXS = "xxs",
  XS = "xs",
  S = "s",
  M = "m",
  L = "l",
  XL = "xl",
  XXL = "xxl",
}

export enum TextAttributionStyle {
  SPECIAL = "special",
  STANDARD_HEADLINE = "standard_headline",
  STANDARD_SUBTEXT = "standard_subtext",
  HOME_TILE = "home_tile",
}

export enum OverlayTitlePosition {
  TOP_LEFT = "TOP_LEFT",
  TOP_RIGHT = "TOP_RIGHT",
  BOTTOM_LEFT = "BOTTOM_LEFT",
  BOTTOM_RIGHT = "BOTTOM_RIGHT",
}

export enum ApplicationMode {
  BASE,
  CUSTOM_FEED_BUILDER,
}

export enum CustomFeedBuilderMode {
  CREATE = "create",
  EDIT = "edit",
}

export enum TextCharactersLengthRange {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

export enum ContentPosition {
  BOTTOM = "bottom",
  // TOP = "top",
  LEFT = "left",
  RIGHT = "right",
}

export enum FeedConfigType {
  CUSTOM = "custom",
}

export enum ItemService {
  MASTODON = "mastodon",
  BLUESKY = "bluesky",
  RSS = "rss",
}

export enum RSSFeedType {
  RSS = "rss",
  YOUTUBE = "youtube",
}

export enum DRAWER_SIDE {
  LEFT = "left",
  RIGHT = "right",
}

export enum FeatureFlags {
  DEBUG_FEED = "debug_feed",
  HOME_FILTER = "home_filter",
  MAX_PAGE_WIDTH = "max_page_width",
  DEBUG_DEV_PLAYGROUND = "debug_dev_playground",
  DEBUG_FILTER_LAYOUTS = "debug_filter_layouts",
  DEBUG_LAYERS = "debug_layers",
}

export enum GroupingType {
  POST = "post",
  GROUP = "group",
}
