const SMALL_TEXT_UPPER_BOUND = 300;
const MEDIUM_TEXT_UPPER_BOUND = 400;

const SHORTEN_MASTADON_URL_LENGTH = 30;
import { TextCharactersLengthRange } from "~/enums";

export const itemsContainsItem = (
  items: Array<FC.Item | undefined>,
  item: FC.Item
) => {
  return items.some((i) => i?.id === item.id);
};

export const removeDuplicates =
  (items: Array<FC.Item | undefined>) => (item: FC.Item) =>
    !itemsContainsItem(items, item);

const getCharacterCountFromContent = (item: FC.Item) => {
  const temp = document.createElement("div");
  temp.innerHTML = item.content;

  const urls = temp.innerText.match(/https?:\/\/[^\s]+/g) || [];

  const textWithoutUrl = temp.innerText.replace(/https?:\/\/[^\s]+/g, "");

  const result =
    textWithoutUrl.length + urls.length * SHORTEN_MASTADON_URL_LENGTH;

  return result;
};

export const textCharactersLengthWithinRange = (
  item: FC.Item,
  range: TextCharactersLengthRange
) => {
  const characterCount = getCharacterCountFromContent(item.reblog || item);
  if (
    characterCount >= 0 &&
    characterCount <= SMALL_TEXT_UPPER_BOUND &&
    range === TextCharactersLengthRange.SMALL
  ) {
    return true;
  } else if (
    characterCount > SMALL_TEXT_UPPER_BOUND &&
    characterCount <= MEDIUM_TEXT_UPPER_BOUND &&
    range === TextCharactersLengthRange.MEDIUM
  ) {
    return true;
  } else if (
    characterCount > MEDIUM_TEXT_UPPER_BOUND &&
    range === TextCharactersLengthRange.LARGE
  ) {
    return true;
  }
  return false;
};
