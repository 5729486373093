import React, { memo } from "react";
import BasicColumn from "~/components/pages/sublayouts/BasicColumn";
import { CardSize } from "~/enums";

const BasicPhone: FC.PageStrategyResult["Component"] = ({
  items,
  contentWidth,
}) => {
  return (
    <BasicColumn
      items={items}
      width={contentWidth}
      padding={0}
      cardSize={items.length === 1 ? CardSize.LARGE : CardSize.MEDIUM}
    />
  );
};

export default memo(BasicPhone);
