import React, { useState, useEffect } from "react";
import { ImageBackground, Pressable } from "react-native";
import { createStyleSheet, LAYOUT, SPACING } from "~/styles";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { isPhoneSelector, selectModalOpen } from "~/concepts/application";
import { useAppSelector } from "~/hooks";
import { BaseCardVariantType, TextSize } from "~/enums";
import { makeStyles, Button, useTheme } from "@rneui/themed";
import { Column, Text, View } from "~/components/elements";

import { isVideoAttachment, isAudioAttachment } from "~/predicates";

import AVPlayer from "~/components/AVPlayer";
import CardAuthor from "~/components/CardAuthor";

type AVCardProps = {
  item: FC.Item;
  isCondensed?: boolean;
  baseCardVariantType?: BaseCardVariantType;
};

const PLAY_ICON_SIZE = 42;
const CLOSE_ICON_SIZE = 24;

const AVCard: React.FC<AVCardProps> = ({
  item,
  isCondensed,
  baseCardVariantType,
}) => {
  const originalAttachment = item.attachments?.[0].meta.original;
  const aspectRatio =
    item?.image?.aspectRatio ||
    originalAttachment?.aspect ||
    (originalAttachment?.width &&
      originalAttachment?.height &&
      originalAttachment?.width / originalAttachment?.height) ||
    16 / 9;
  const styles = useStyles({ aspectRatio, baseCardVariantType });
  const [AV, setAV] = useState(false);
  const modalOpen = useAppSelector(selectModalOpen);
  const { theme } = useTheme();
  const isPhone = useAppSelector(isPhoneSelector);
  const { attachments } = item;

  const ALT_TEXT = "Video Preview image";

  useEffect(() => {
    if (modalOpen) {
      stopAV();
    }
  }, [modalOpen]);

  const videoContent = attachments?.find(isVideoAttachment);
  const audioContent = attachments?.find(isAudioAttachment);
  if (!videoContent && !audioContent) {
    return null;
  }
  const isAudio = audioContent ? true : false;

  let mediaSourceURL = isAudio ? audioContent?.url : videoContent?.url;
  let previewURL = isAudio
    ? audioContent?.preview_url
    : videoContent?.preview_url;

  if (!previewURL) previewURL = item.card?.image || null;

  const toggleAV = () => {
    setAV(!AV);
  };

  const stopAV = () => {
    setAV(false);
  };

  if (!mediaSourceURL) {
    return null;
  }

  const renderVideoDetails = () => {
    return (
      <Column style={!isPhone && styles.articleBody}>
        <Text header size={TextSize.M} overlay={!isPhone}>
          {videoContent?.description}
        </Text>

        <CardAuthor item={item} overlay={!isPhone} />
      </Column>
    );
  };

  if (isCondensed) {
    return (
      <View style={styles.condensedWrapper}>
        <AVPlayer
          item={item}
          mediaContent={
            attachments?.find(isAudioAttachment) ||
            attachments?.find(isVideoAttachment)
          }
          autoPlay
          style={styles.avPlayer}
        />
      </View>
    );
  }

  return (
    <View style={styles.avContainer}>
      {AV ? (
        <>
          <Button
            onPress={toggleAV}
            icon={
              <Icon
                name="window-close"
                size={CLOSE_ICON_SIZE}
                color={theme.colors.primary}
                buttonStyle={styles.playIcon}
              />
            }
            containerStyle={styles.buttonContainer}
            buttonStyle={styles.closeButtonWrapper}
          />
          <AVPlayer
            item={item}
            mediaContent={
              attachments?.find(isAudioAttachment) ||
              attachments?.find(isVideoAttachment)
            }
            style={styles.avPlayer}
          />
          <CardAuthor item={item} />
        </>
      ) : (
        <>
          <ImageBackground
            source={{
              uri: previewURL,
            }}
            resizeMode="cover"
            style={styles.imageBackgroundStyle}
            accessibilityLabel={item.card?.description || ALT_TEXT}
          >
            <Pressable onPress={toggleAV} style={styles.playClickHandler}>
              <Icon
                style={styles.playIcon}
                name="play-circle"
                size={PLAY_ICON_SIZE}
                color="#ffffff"
              />
            </Pressable>
            {!isPhone && renderVideoDetails()}
          </ImageBackground>
          {isPhone && renderVideoDetails()}
        </>
      )}
    </View>
  );
};

const useStyles = makeStyles(
  (
    theme,
    {
      aspectRatio,
      baseCardVariantType,
    }: { aspectRatio?: number; baseCardVariantType?: BaseCardVariantType }
  ) =>
    createStyleSheet({
      avContainer: {
        flex:
          baseCardVariantType ===
            BaseCardVariantType.FEATURE_GENERIC_GREY_LAYOUT ||
          baseCardVariantType ===
            BaseCardVariantType.FEATURE_GENERIC_WHITE_LAYOUT
            ? LAYOUT.FEATURE_GENERIC_IMAGE_HEIGHT_PERCENTAGE
            : 1,
        ...(baseCardVariantType ===
          BaseCardVariantType.FEATURE_GENERIC_WHITE_LAYOUT && {
          paddingHorizontal: SPACING.BASE5X,
          paddingTop: SPACING.BASE5X,
        }),
      },
      condensedWrapper: {
        flex: 1,
        justifyContent: "flex-end",
        width: "100%",
        aspectRatio,
      },
      playClickHandler: {
        position: "absolute",
        zIndex: 1,
        height: "100%",
        width: "100%",
      },
      videoWrapper: {
        justifyContent: "flex-end",
        flex: 1,
      },
      avPlayer: {
        flex: 1,
        height: "100%",
        width: "100%",
      },
      imageBackgroundStyle: {
        flex: 1,
        height: "100%",
        width: "100%",
      },
      articleBody: {
        position: "absolute",
        bottom: 0,
        zIndex: 2,
        width: "100%",
        // height: "fit-content",
        flexDirection: "column",
        justifyContent: "flex-end",
        gap: SPACING.BASE,
        padding: SPACING.SMALL,
      },
      buttonContainer: { backgroundColor: theme.colors.primaryReverse },
      closeButtonWrapper: { justifyContent: "flex-end" },
      playIcon: {
        display: "flex",
        lineHeight: 84,
        backgroundColor: theme.colors.videoBackground,
        justifyContent: "center",
        alignItems: "center",
        opacity: 0.75,
        height: "100%",
        width: "100%",
      },
    })
);

export default AVCard;
