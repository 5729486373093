import VigetThreeUpColumn from "~/components/pages/VigetThreeUpColumn";
import { isSocialArticleItem } from "~/predicates";

const vigetFourUpSocialArticles: FC.PagePresentationStrategy = ({ items }) => {
  const socialArticleItems = items.filter(isSocialArticleItem);

  if (socialArticleItems.length >= 4) {
    return {
      usedItems: socialArticleItems.slice(0, 4),
      Component: VigetThreeUpColumn,
    };
  }
  return null;
};

export default vigetFourUpSocialArticles;
