import React, { memo } from "react";
import { Pressable, View } from "react-native";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { Column, Row, Text } from "~/components/elements";
import CardActions from "~/components/CardActions";
import { Avatar, makeStyles, useTheme } from "@rneui/themed";
import AuthorTooltip from "~/components/elements/AuthorTooltip";
import TimeAgo from "~/components/TimeAgo";
import { createStyleSheet, SPACING } from "~/styles";
import AVCard from "~/components/cards/AVCard";
import ThreadPhotoCard from "~/components/cards/thread/ThreadPhotoCard";
import GifvCard from "~/components/cards/GifvCard";
import FlipboardMagazineCard from "~/components/cards/FlipboardMagazineCard";
import DisplayName from "~/components/elements/DisplayName";
import ReblogAttribution from "~/components/ReblogAttribution";

import { ItemType, Visibility } from "~/enums";
import Content from "~/components/Content";
import ReplyForm from "~/components/CardActions/ReplyForm";
import { A } from "@expo/html-elements";
import { useThreadItemNavigation } from "~/hooks/useThreadItemNavigation";
import { setFollowingThreadLink } from "~/concepts/thread";
import { useAppDispatch } from "~/hooks";
import { useFeedNavigation } from "~/hooks/useFeedNavigation";
import ThreadPollCard from "~/components/cards/thread/ThreadPollCard";
import { isFirstPartyVideo } from "~/predicates";
import ArticlePreview from "~/components/cards/ArticlePreview";

const visibilityIconMap = {
  [Visibility.PUBLIC]: "web" as const,
  [Visibility.UNLISTED]: "lock-open-variant-outline" as const,
  [Visibility.PRIVATE]: "lock-outline" as const,
  [Visibility.DIRECT]: "at" as const,
};
const AVATAR_SIZE = 48;
const VISIBILITY_ICON_SIZE = 18;
const ThreadItem: React.FC<{
  item: FC.Item;
  reblogger: FC.Item["reblogger"];
  rootContextItem: FC.Item;
  index: number;
  onPress: () => void;
}> = ({ item, reblogger, rootContextItem, index, onPress }) => {
  const dispatch = useAppDispatch();
  const { theme } = useTheme();
  const imageAttachmenMetaOriginal =
    item.type !== ItemType.ARTICLE
      ? item.attachments?.[0]?.meta?.original
      : undefined;
  const styles = useStyles({
    isReply: index !== 0,
    isFocused: !!item.focused,
    imageAttachmenMetaOriginal,
  });
  const { navigateToThreadItem } = useThreadItemNavigation();
  const { navigateToAccountFeed } = useFeedNavigation();

  const { attachments } = item;

  let condensedCardComponent;

  switch (item?.type) {
    case ItemType.PHOTO: {
      if (!attachments || attachments.length === 0) return null;

      condensedCardComponent = (
        <ThreadPhotoCard item={item} onPress={onPress} />
      );
      break;
    }
    case ItemType.YOUTUBE:
      condensedCardComponent = null;
      // condensedCardComponent = (
      //   <YouTubeCard item={item as FC.CardItem} isCondensed />
      // );
      break;
    case ItemType.AV:
      condensedCardComponent = isFirstPartyVideo(item) ? null : (
        <AVCard item={item} isCondensed />
      );
      break;
    case ItemType.GIFV:
      condensedCardComponent = <GifvCard item={item} isCondensed />;
      break;
    case ItemType.FLIPBOARD_MAGAZINE:
      condensedCardComponent = (
        <FlipboardMagazineCard item={item} isCondensed />
      );
      break;
    case ItemType.POLL:
      condensedCardComponent = <ThreadPollCard item={item as FC.PollItem} />;
      break;
    default:
      condensedCardComponent = null;
  }

  const renderArticlePreview = () => {
    if (
      ![ItemType.ARTICLE, ItemType.SOCIAL_ARTICLE].includes(item.type) ||
      !item.card
    )
      return null;
    return (
      <View style={styles.articlePreviewContainer}>
        <ArticlePreview flex item={item} showExtractText />
      </View>
    );
  };

  return (
    <Column
      flex
      style={[
        styles.item,
        item.focused ? styles.itemFocused : styles.itemUnfocused,
      ]}
    >
      <Pressable
        onPress={() => {
          navigateToThreadItem(item);
          onPress();
        }}
      >
        <ReblogAttribution reblogger={reblogger} />
        <Row style={styles.threadHeaderContainer}>
          <Column>
            {item.author.avatar && (
              <AuthorTooltip author={item.author}>
                <Avatar
                  rounded
                  size={AVATAR_SIZE}
                  source={{ uri: item.author.avatar }}
                />
              </AuthorTooltip>
            )}
          </Column>
          <Row flex>
            <Column flex>
              <A
                style={styles.anchorContainer}
                onPress={() => {
                  dispatch(setFollowingThreadLink(true));
                  navigateToAccountFeed(item.author);
                }}
              >
                <DisplayName
                  flex
                  textSize={SPACING.LARGE}
                  author={item.author}
                />
                <Text style={styles.authorAcct}>@{item.author.acct}</Text>
              </A>
            </Column>
            <Row noGap style={{ gap: SPACING.SMALL }}>
              <Icon
                name={visibilityIconMap[item.visibility]}
                size={VISIBILITY_ICON_SIZE}
                color={theme.colors.primary}
                style={styles.iconContainer}
              />
              <TimeAgo>{new Date(item.createdAt)}</TimeAgo>
            </Row>
          </Row>
        </Row>
        <Content
          item={item}
          style={styles.htmlText}
          shouldDecorateContent={false}
        />
        {condensedCardComponent && (
          <View style={styles.condensedCardComponentContainer}>
            {condensedCardComponent}
          </View>
        )}
        {renderArticlePreview()}

        <CardActions item={item} fromThread />
        <ReplyForm item={item} rootContextItem={rootContextItem} />
      </Pressable>
    </Column>
  );
};

const useStyles = makeStyles(
  (
    theme,
    {
      isReply,
      isFocused,
      imageAttachmenMetaOriginal,
    }: {
      isReply: boolean;
      isFocused: boolean;
      imageAttachmenMetaOriginal?: FC.MetaType["original"];
    }
  ) =>
    createStyleSheet({
      item: {
        padding: SPACING.MEDIUM,
        borderStyle: "solid",
        borderLeftWidth: 2,
        borderRightWidth: 2,
        display: "flex",
        ...(isFocused
          ? {
              borderTopWidth: 2,
              borderBottomWidth: 2,
              fontSize: 16,
              borderColor: theme.colors.dividerHighlight,
            }
          : {
              borderColor: theme.colors.dividerPrimary,
              borderBottomWidth: 1,
              borderTopWidth: 1,
              fontSize: 14,
            }),
      },
      threadHeaderContainer: {
        alignItems: "center",
      },
      anchorContainer: {
        display: "flex",
        flexDirection: "column",
      },
      authorAcct: { color: "#888888", fontSize: 14, fontWeight: "400" },
      htmlContainer: {
        marginVertical: SPACING.BASE3X,
      },
      htmlText: {
        marginTop: SPACING.LARGE,
        fontSize: isFocused ? 16 : 14,
        paddingLeft: isReply ? AVATAR_SIZE + SPACING.LARGE : 0,
      },
      articlePreviewContainer: {
        paddingLeft: isReply ? AVATAR_SIZE + SPACING.LARGE : 0,
        width: "100%",
      },
      condensedCardComponentContainer: {
        paddingLeft: isReply ? AVATAR_SIZE + SPACING.LARGE : 0,
        width: "100%",
        aspectRatio: imageAttachmenMetaOriginal
          ? imageAttachmenMetaOriginal?.width /
            imageAttachmenMetaOriginal?.height
          : undefined,
      },
      imageCard: {
        height: 300,
        minHeight: 300,
        maxWidth: "100%",
        marginVertical: SPACING.BASE3X,
      },
      iconContainer: {
        marginTop: SPACING.XSMALL,
      },
    })
);

export default memo(ThreadItem);
