import { makeStyles } from "@rneui/themed";
import React, { memo, useState, useEffect } from "react";
import { createStyleSheet } from "~/styles";
import Hoverable from "~/components/elements/Hoverable";
import { useAppSelector } from "~/hooks";
import { selectGlobalScrolling } from "~/concepts/pagination";
import { Row, Popover } from "~/components/elements";

export type AttributionTooltipPopoverComponent<T> =
  React.JSXElementConstructor<{
    popoverComponentProps: T;
    close: () => void;
  }>;

const AttributionTooltip = <T extends unknown>({
  children,
  PopoverComponent,
  popoverComponentProps,
  onHover,
}: React.PropsWithChildren<{
  PopoverComponent: React.JSXElementConstructor<{
    popoverComponentProps: T;
    close: () => void;
  }>;
  popoverComponentProps: T;
  onHover?: () => void;
}>): React.ReactElement => {
  const styles = useStyles();
  const [open, setOpen] = useState(false);
  const [didHover, setDidHover] = useState(false);
  const scrolling = useAppSelector(selectGlobalScrolling);

  useEffect(() => {
    if (scrolling) {
      setOpen(false);
    }
  }, [scrolling]);

  useEffect(() => {
    if (didHover && onHover) {
      onHover();
    }
  }, [onHover, didHover]);

  return (
    <Hoverable>
      {(hover) => {
        if (hover && !didHover) {
          setDidHover(true);
        }
        return (
          <Popover
            PopoverComponent={
              <Row style={styles.authorTooltipContainer}>
                <PopoverComponent
                  close={() => setOpen(false)}
                  popoverComponentProps={popoverComponentProps}
                />
              </Row>
            }
            open={open}
            setOpen={setOpen}
          >
            {children}
          </Popover>
        );
      }}
    </Hoverable>
  );
};

const useStyles = makeStyles((theme) =>
  createStyleSheet({
    authorTooltipContainer: {
      justifyContent: "flex-start",
      alignItems: "baseline",
      padding: 0,
      overflow: "hidden",
      backgroundColor: theme.colors.background,
      borderWidth: 1,
      borderColor: theme.colors.dividerTertiary,
      shadowOffset: { width: 10, height: 10 },
      shadowColor: "#000000",
      shadowOpacity: 0.188,
      shadowRadius: 16,
      zIndex: 1,
    },

    hovered: {
      backgroundColor: theme.colors.backgroundHover,
    },
    hoveredOverlay: {
      backgroundColor: theme.colors.backgroundHoverOverlay,
    },
  })
);
export default memo(AttributionTooltip) as typeof AttributionTooltip;
