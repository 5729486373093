import React from "react";
import { createStyleSheet, SPACING } from "~/styles";
import { CardSize } from "~/enums";
import { StyleProp } from "react-native";
import { makeStyles } from "@rneui/themed";
import { Column, Row } from "~/components/elements";
import Content from "~/components/Content";
import CardAuthor from "~/components/CardAuthor";
import AutoNumberOfLinesWrapper from "~/components/elements/AutoNumberOfLinesWrapper";

export const BOTTOM_CONTENT_HEIGHT = 210;

export type BasicMediaCardProps = {
  item: FC.Item;
  overlay?: boolean;
  cardSize?: CardSize;
  isHorizontal?: boolean;
  maxNumberTruncate?: number;
  MediaComponent: React.ReactNode;
  flex?: boolean;
  isMediaComponentTop?: boolean;
  mediaCardStyle?: StyleProp<ViewStyle>;
};

const BasicMediaCard: React.FC<BasicMediaCardProps> = ({
  item,
  cardSize,
  overlay,
  isHorizontal,
  maxNumberTruncate,
  MediaComponent,
  flex = false,
  isMediaComponentTop = false,
  mediaCardStyle = {},
}) => {
  const styles = useStyles({
    cardSize,
  });

  const WrapperComponent = isHorizontal ? Row : Column;
  return (
    <WrapperComponent
      flex
      gap={
        isHorizontal || cardSize === CardSize.XLARGE
          ? SPACING.BASE5X
          : SPACING.LARGE
      }
      style={[
        styles.card,
        styles.alignCenter,
        isHorizontal && styles.justifyCenter,
        mediaCardStyle,
      ]}
    >
      <Column
        flex={isHorizontal || flex}
        flexShrink={!isHorizontal && !flex}
        style={[
          styles.contentContainer,
          isHorizontal && styles.contentContainerHorizontal,
        ]}
      >
        {isMediaComponentTop && MediaComponent}
        <Column flexShrink style={isMediaComponentTop && styles.bottomContent}>
          <CardAuthor item={item} overlay={overlay} />
          <AutoNumberOfLinesWrapper
            flex={!isHorizontal}
            flexShrink={isHorizontal}
            maxNumberOfLines={maxNumberTruncate}
          >
            <Content cardSize={cardSize} item={item} overlay={overlay} />
          </AutoNumberOfLinesWrapper>
        </Column>
      </Column>
      {!isMediaComponentTop && MediaComponent}
    </WrapperComponent>
  );
};
const getMargin = (cardSize?: CardSize) => {
  switch (cardSize) {
    case CardSize.SMALL:
      return SPACING.BASE3X;
    case CardSize.MEDIUM:
      return SPACING.BASE4X;
    case CardSize.LARGE:
    case CardSize.XLARGE:
      return SPACING.BASE5X;
    default:
      return SPACING.LARGE;
  }
};

const useStyles = makeStyles((_, { cardSize }: { cardSize?: CardSize }) =>
  createStyleSheet({
    card: {
      width: "100%",
      maxWidth: `calc(100% - ${getMargin(cardSize) * 2}px)`,
      overflow: "hidden",
      margin: getMargin(cardSize),
    },
    contentContainer: {
      width: "100%",
      maxHeight: "100%",
    },
    contentContainerHorizontal: { height: "100%", justifyContent: "center" },
    bottomContent: { height: BOTTOM_CONTENT_HEIGHT },
  })
);
export default BasicMediaCard;
