import React, { memo } from "react";

import { Text } from "~/components/elements";
import DisplayName from "~/components/elements/DisplayName";
import { createStyleSheet } from "~/styles";
import { makeStyles } from "@rneui/themed";

const ToastMessageWithAuthor: React.FC<{
  author?: FC.Item["author"];
  preText: string;
  postText?: string;
}> = ({ author, preText, postText }) => {
  const styles = useStyles();

  if (!author) return null;

  return (
    <>
      <Text style={styles.text}>{`${preText}`}</Text>
      <DisplayName overlay author={author} />
      {postText && <Text style={styles.text}>{`${postText}`}</Text>}
    </>
  );
};

const useStyles = makeStyles((theme) =>
  createStyleSheet({
    text: {
      color: theme.colors.primaryReverse,
    },
  })
);
export default memo(ToastMessageWithAuthor);
