import React, { memo } from "react";
import { useTheme } from "@rneui/themed";
import Button from "~/components/elements/Button";
import { SPACING } from "~/styles";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { useNavigate } from "~/routing";
import { selectIsAuthenticated } from "~/concepts/auth";
import { useAppSelector } from "~/hooks";

const EditFeedsButton = () => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  return isAuthenticated ? (
    <Button
      title="Edit Feeds"
      titleStyle={{ paddingLeft: SPACING.BASE }}
      containerStyle={{ gap: SPACING.BASE }}
      onPress={() => navigate("/edit-feeds")}
      icon={
        <Icon
          name={"playlist-edit"}
          size={SPACING.BASE3X}
          color={theme.colors.primaryReverse}
        />
      }
    />
  ) : null;
};

export default memo(EditFeedsButton);
