import React from "react";
import { makeStyles } from "@rneui/themed";

import { Column, Row } from "~/components/elements";
import { createStyleSheet, SPACING } from "~/styles";
import CardAuthor from "~/components/CardAuthor";

import { CardSize } from "~/enums";
import Poll from "~/components/cardComponents/Poll";
import Content from "~/components/Content";

export type PollCardProps = {
  item: FC.Item;
  cardSize?: CardSize;
  overlay?: boolean;
  isHorizontal?: boolean;
};

const PollCard: React.FC<PollCardProps> = ({
  item,
  cardSize,
  overlay,
  isHorizontal = false,
}) => {
  const styles = useStyles({ cardSize });

  const WrapperComponent = isHorizontal ? Row : Column;
  return (
    <WrapperComponent flex style={styles.pollCard}>
      <Column flex={isHorizontal}>
        <CardAuthor item={item} overlay={overlay} />
        <Content item={item} cardSize={cardSize} overlay={overlay} />
      </Column>
      <Poll item={item} overlay={overlay} />
    </WrapperComponent>
  );
};

const calculateCardPadding = (cardSize?: CardSize) => {
  switch (cardSize) {
    case CardSize.SMALL:
      return SPACING.BASE3X;
    case CardSize.MEDIUM:
      return SPACING.BASE4X;
    case CardSize.LARGE:
      return SPACING.BASE5X;
    case CardSize.XLARGE:
      return SPACING.BASE5X;
    default:
      return SPACING.LARGE;
  }
};
const useStyles = makeStyles((_, { cardSize }: { cardSize?: CardSize }) =>
  createStyleSheet({
    pollCard: {
      padding: calculateCardPadding(cardSize),
    },
  })
);
export default PollCard;
