import { useLocation, useParams } from "~/routing";
import { useAppDispatch, useAppSelector } from "~/hooks";
import {
  getThreadItem,
  setCurrentThreadItem,
  setRequestCloseItemFocusDrawer,
} from "~/concepts/thread";
import { useEffect } from "react";
import { useLazyGetInstanceItemQuery } from "~/api";

export const usePathChangeMonitor = () => {
  const dispatch = useAppDispatch();
  const currentThreadItem = useAppSelector(getThreadItem);
  const location = useLocation();
  const params = useParams();
  const [getInstanceItemTrigger, getInstanceItemResponse] =
    useLazyGetInstanceItemQuery();

  // /status/:statusId

  // if we are at a status url but haven't loaded the item or different item
  useEffect(() => {
    if (
      (!currentThreadItem || currentThreadItem.source !== params.statusId) &&
      location.pathname.indexOf("/status/") > -1 &&
      params.statusId
    ) {
      getInstanceItemTrigger(params.statusId);
    }
  }, [
    getInstanceItemTrigger,
    location.pathname,
    params.statusId,
    currentThreadItem,
  ]);

  // the feed item associated with url has loaded so set currentThreadItem
  useEffect(() => {
    if (getInstanceItemResponse.data) {
      dispatch(setCurrentThreadItem(getInstanceItemResponse.data));
    }
  }, [dispatch, getInstanceItemResponse.data]);

  // user navigated away with browser?
  useEffect(() => {
    if (currentThreadItem && location.pathname.indexOf("/status") === -1) {
      dispatch(setRequestCloseItemFocusDrawer(true));
    }
  }, [dispatch, currentThreadItem, location.pathname]);
};
