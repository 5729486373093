import React from "react";
import { useAppSelector } from "~/hooks";
import { selectCredentials } from "~/concepts/auth";
import { useDeleteStatusMutation } from "~/api";
import { useToast } from "react-native-toast-notifications";

import OverflowMenuButton from "~/components/elements/OverflowMenuButton";

type DeleteStatusProps = {
  item: FC.Item;
};

const DeleteStatus: React.FC<DeleteStatusProps> = ({ item }) => {
  const toast = useToast();
  const [onDeleteStatus] = useDeleteStatusMutation();

  const credentials = useAppSelector(selectCredentials);
  if (!item) return null;
  const { author } = item;

  if (!credentials || author.id !== credentials.id || !item.statusContext) {
    return null;
  }
  const handleDeleteStatus = async () => {
    // TODO: Add a modal to confirm deletion
    try {
      await onDeleteStatus(item);
      toast.show("You deleted your thread comment", {
        type: "success",
      });
    } catch (e) {
      console.log(e);
      toast.show("Sorry, something went wrong", {
        type: "fail",
      });
    }
  };

  return (
    <OverflowMenuButton
      title={"Delete"}
      icon={"trash"}
      onPress={handleDeleteStatus}
    />
  );
};

export default DeleteStatus;
