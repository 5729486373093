import React, { memo, useState, useCallback, useEffect } from "react";
import { createStyleSheet, SPACING } from "~/styles";
import { makeStyles, useTheme, Divider } from "@rneui/themed";
import { NativeSyntheticEvent, TextInputChangeEventData } from "react-native";
import { useLazyReplyQuery, useLazyStatusContextQuery } from "~/api";
import { Column, Row, Text, Button, Input } from "~/components/elements";

import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import {
  getShownReplyItem,
  setShownReplyItem,
  getRequestedReplyItemChange,
  clearShownReplyItem,
  clearRequestReplyItemChange,
  getRequestCloseItemFocusDrawer,
  setRequestCloseItemFocusDrawer,
} from "~/concepts/thread";
import { useAppDispatch, useAppSelector } from "~/hooks";
import { TextSize } from "~/enums";
import ConfirmDialog from "~/components/ConfirmDialog";

const ICON_SIZE = SPACING.LARGE;

/** Reply Form itself isolated from the wrapper so that the component is unmounted ensuring the useLazyReplyQuery is a new instance
 * every time the form is opened.
 */
const ReplyForm: React.FC<{
  item: FC.Item;
  rootContextItem: FC.Item;
  visible: boolean;
  disabled?: boolean;
  dismissForm: () => void;
  onConfirmDismiss: () => void;
  setIsDirty: (isDirty: boolean) => void;
}> = ({
  item,
  rootContextItem,
  visible,
  disabled,
  dismissForm,
  onConfirmDismiss,
  setIsDirty,
}) => {
  const styles = useStyles();
  const { theme } = useTheme();

  // Before mounting, we want to preselect the mentions only once.
  const [initialMountBeforeSelection, setInitialMountBeforeSelection] =
    useState(true);

  let initialText = item && `@${item.author.acct} `;
  const selection = { start: initialText.length, end: initialText.length };

  if (item?.mentions?.length) {
    const mentions = item.mentions
      .filter((acc) => acc.acct !== item.author.acct)
      .map((mention) => `@${mention.acct} `)
      .join("");

    initialText = `${initialText}${mentions} `;

    const startIdx = initialText.indexOf(mentions);
    const endIdx = initialText.length - 1;

    selection.start = startIdx;
    selection.end = endIdx;
  }

  const [text, setText] = useState(initialText);

  const [replyTrigger, replyResponse] = useLazyReplyQuery();
  const [replyError, setReplyError] = useState<String | null>(null);
  const [statusContextQueryTrigger] = useLazyStatusContextQuery();

  useEffect(() => {
    if (initialMountBeforeSelection) {
      setInitialMountBeforeSelection(false);
      return;
    }
  }, [initialMountBeforeSelection]);

  const handleSubmit = () => {
    if (!text) {
      return;
    }
    replyTrigger({
      itemId: item.id,
      text,
    });
  };

  useEffect(() => {
    if (!visible) {
      return;
    }

    if (replyResponse?.isSuccess) {
      dismissForm();
      statusContextQueryTrigger(rootContextItem.id);
    } else if (replyResponse?.isError) {
      const errorResponse = replyResponse.error as {
        data: {
          error: String;
          status: number;
        };
      };
      setReplyError(errorResponse?.data?.error);
    }
  }, [
    visible,
    replyResponse,
    rootContextItem.id,
    dismissForm,
    statusContextQueryTrigger,
  ]);

  return (
    <Column style={styles.replyForm}>
      <Divider color={theme.colors.primary} />
      <Text header size={TextSize.M}>
        Reply to @{item.author.acct}
      </Text>
      <Input
        leftIcon={
          <Icon
            name={"reply-outline"}
            size={ICON_SIZE}
            solid={true}
            color={disabled ? theme.colors.grey3 : theme.colors.primary}
          />
        }
        onChange={(e: NativeSyntheticEvent<TextInputChangeEventData>) => {
          const currentText = e.nativeEvent.text;
          setText(currentText);
          if (currentText !== initialText) setIsDirty(true);
        }}
        multiline
        value={text}
        selection={initialMountBeforeSelection ? selection : undefined}
        onSubmitEditing={handleSubmit}
        autoFocus={true} // TODO: this doesn't work currently? Maybe a web problem?
        style={styles.replyInput}
      />
      {replyError && <Text error>{replyError}</Text>}
      <Row style={styles.replyActions}>
        <Button
          title="Reply"
          onPress={handleSubmit}
          color={"primary"}
          type="solid"
        />
        <Button
          title="Cancel"
          onPress={onConfirmDismiss}
          type="outline"
          color={"primary"}
        />
      </Row>
      {/* {renderConfirmDialog()} */}
    </Column>
  );
};

type ReplyFormWrapperProps = {
  item: FC.Item;
  rootContextItem: FC.Item;
  disabled?: boolean;
};
const ReplyFormWrapper: React.FC<ReplyFormWrapperProps> = ({
  item,
  rootContextItem,
  disabled = false,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const dispatch = useAppDispatch();
  const shownReplyItem = useAppSelector(getShownReplyItem);
  const requestReplyItemChange = useAppSelector(getRequestedReplyItemChange);
  const requestedCloseItemFocusDrawer = useAppSelector(
    getRequestCloseItemFocusDrawer
  );

  // const resetForm = () => {};
  /**
   * Cannot directly dismiss the form. If a request has been made, we should set that form
   * as the current form. Otherwise, we can clear the current form.
   */
  const dismissForm = useCallback(() => {
    if (requestReplyItemChange) {
      dispatch(setShownReplyItem(requestReplyItemChange));
    } else {
      dispatch(clearShownReplyItem());
    }
    setIsVisible(false);
  }, [dispatch, requestReplyItemChange]);

  const confirmDismissForm = useCallback(() => {
    if (isDirty) {
      setShowConfirmDialog(true);
    } else dismissForm();
  }, [dismissForm, isDirty]);

  useEffect(() => {
    if (
      isVisible &&
      requestReplyItemChange &&
      requestReplyItemChange !== item.id
    ) {
      // The form is visible and a different form has requested focus. Confirm and dismiss this form.
      confirmDismissForm();
    } else if (shownReplyItem === item.id && !isVisible) {
      // The form is not visible and this form has requested focus.
      setIsVisible(true);
    } else if (shownReplyItem !== item.id && !requestReplyItemChange)
      // A different or no form should have focus, and we are not currently processing a request.
      setIsVisible(false);
  }, [
    confirmDismissForm,
    shownReplyItem,
    item.id,
    isVisible,
    requestReplyItemChange,
  ]);

  useEffect(() => {
    if (requestedCloseItemFocusDrawer && shownReplyItem === item.id) {
      confirmDismissForm();
    }
  }, [
    requestedCloseItemFocusDrawer,
    confirmDismissForm,
    dispatch,
    item.id,
    shownReplyItem,
  ]);

  if (!item || !isVisible) {
    return null;
  }

  const renderConfirmDialog = () => {
    return (
      <ConfirmDialog
        title="Are you sure you want to dismiss the reply you have in progress?"
        onYes={dismissForm}
        onBackdropPress={() => setShowConfirmDialog(false)}
        onNo={() => {
          setShowConfirmDialog(false);
          dispatch(clearRequestReplyItemChange());
          dispatch(setRequestCloseItemFocusDrawer(false));
        }}
        visible={showConfirmDialog}
      />
    );
  };
  return (
    <>
      {isVisible && (
        <ReplyForm
          item={item}
          visible={isVisible}
          dismissForm={dismissForm}
          onConfirmDismiss={confirmDismissForm}
          disabled={disabled}
          setIsDirty={setIsDirty}
          rootContextItem={rootContextItem}
        />
      )}
      {renderConfirmDialog()}
    </>
  );
};

const useStyles = makeStyles((theme) => {
  return createStyleSheet({
    replyForm: {
      backgroundColor: theme.colors.background,
      padding: SPACING.LARGE,
    },
    replyInput: {
      minHeight: 100,
    },
    replyActions: { alignSelf: "flex-end" },
    confirmDialog: { width: "50vw" },
  });
});

export default memo(ReplyFormWrapper);
