import React, { memo, useEffect, useState } from "react";
import { GroupRequestType, TextAttributionStyle } from "~/enums";
import { Text } from "~/components/elements";
import { makeStyles } from "@rneui/themed";
import { createStyleSheet } from "~/styles";
import { useIsGroupOwner } from "~/hooks/useIsGroupOwner";
import { useLazyGetCustomFeedAuthorByUrlQuery } from "~/api";
import AutoNumberOfLinesWrapper from "~/components/elements/AutoNumberOfLinesWrapper";

const AttributionText: React.FC<{ children: string }> = ({ children }) => {
  const styles = useStyles();
  return (
    <AutoNumberOfLinesWrapper>
      <Text
        overlay
        attribution={TextAttributionStyle.HOME_TILE}
        style={styles.text}
      >
        {children}
      </Text>
    </AutoNumberOfLinesWrapper>
  );
};

const TileAttribution: React.FC<{ group: FC.Group }> = ({ group }) => {
  const isGroupOwner = useIsGroupOwner();
  const [attributionText, setAttributionText] = useState<null | string>(null);
  const [getCustomFeedAuthorByUrlTrigger, getCustomFeedAuthorByUrlResponse] =
    useLazyGetCustomFeedAuthorByUrlQuery();

  useEffect(() => {
    if (
      getCustomFeedAuthorByUrlResponse.data &&
      getCustomFeedAuthorByUrlResponse.isSuccess
    ) {
      setAttributionText(
        `by ${
          getCustomFeedAuthorByUrlResponse.data.display_name ||
          getCustomFeedAuthorByUrlResponse.data.acct
        }`
      );
    }
  }, [getCustomFeedAuthorByUrlResponse]);

  useEffect(() => {
    if (
      group.requestType === GroupRequestType.CUSTOM_FEED &&
      !isGroupOwner(group) &&
      !getCustomFeedAuthorByUrlResponse.data &&
      !getCustomFeedAuthorByUrlResponse.isError &&
      !getCustomFeedAuthorByUrlResponse.isLoading
    ) {
      if (group.ownerURL) {
        getCustomFeedAuthorByUrlTrigger(group.ownerURL);
      }
    }
  }, [
    isGroupOwner,
    group,
    getCustomFeedAuthorByUrlTrigger,
    getCustomFeedAuthorByUrlResponse,
  ]);

  if (attributionText) {
    return <AttributionText>{attributionText}</AttributionText>;
  }
  if (group.requestType === GroupRequestType.ACCOUNT && group.description) {
    return <AttributionText>{group.description}</AttributionText>;
  }

  if (group.requestType === GroupRequestType.HOME_TIMELINE) {
    return <AttributionText>Your home timeline</AttributionText>;
  }

  if (
    group.requestType === GroupRequestType.CUSTOM_FEED &&
    isGroupOwner(group)
  ) {
    return <AttributionText>Your custom feed</AttributionText>;
  }

  if (group.byline) {
    return <AttributionText>{`by ${group.byline}`}</AttributionText>;
  }

  if (group.requestType === GroupRequestType.TOPIC) {
    return <AttributionText>Flipboard Topic</AttributionText>;
  }
  if (group.requestType === GroupRequestType.HASHTAG) {
    return <AttributionText>{`${group.title} Hashtag`}</AttributionText>;
  }
  return null;
};

const useStyles = makeStyles((theme) =>
  createStyleSheet({
    text: {
      backgroundColor: "transparent",
      overflowWrap: "anywhere",
      textAlign: "left",
      textShadowColor: theme.colors.dividerPrimaryReverse,
      textShadowOffset: { width: 1, height: 1 },
      textShadowRadius: 2,
    },
  })
);

export default memo(TileAttribution);
