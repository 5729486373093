import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "~/store";

interface AuthState {
  isAuthenticated: boolean | null;
  credentials?: FC.Credentials;
  isOpenLoginModal?: boolean;
  showUnauthenticatedActionMsg?: boolean;
  bearerToken?: string;
}

const initialState: AuthState = {
  isAuthenticated: null,
  isOpenLoginModal: false,
  showUnauthenticatedActionMsg: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (
      state,
      { payload }: PayloadAction<FC.Credentials | undefined>
    ) => {
      state.credentials = payload;
    },
    setIsAuthenticated: (
      state,
      { payload: isAuthenticated }: PayloadAction<boolean>
    ) => {
      state.isAuthenticated = isAuthenticated;
    },
    setBearerToken: (
      state,
      { payload: bearerToken }: PayloadAction<string>
    ) => {
      state.bearerToken = bearerToken;
    },
    clearBearerToken: (state) => {
      delete state.bearerToken;
    },
    setOpenLoginModal: (
      state,
      {
        payload: { open, unauthenticatedAction = false },
      }: PayloadAction<{ open: boolean; unauthenticatedAction?: boolean }>
    ) => {
      state.isOpenLoginModal = open;
      state.showUnauthenticatedActionMsg = unauthenticatedAction;
    },
  },
});

export const {
  setBearerToken,
  clearBearerToken,
  setCredentials,
  setOpenLoginModal,
  setIsAuthenticated,
} = authSlice.actions;

export const selectIsAuthenticated = (state: RootState) =>
  state.auth.isAuthenticated;
export const selectCredentials = (state: RootState) => state.auth.credentials;
export const selectIsOpenLoginModal = (state: RootState) =>
  state.auth.isOpenLoginModal;

export const selectBearerToken = (state: RootState) => state.auth.bearerToken;

export const selectShowUnauthenticatedActionMsg = (state: RootState) =>
  state.auth.showUnauthenticatedActionMsg;

export default authSlice.reducer;
