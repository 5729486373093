import React, { memo } from "react";
import { Input as RNEInput, InputProps } from "@rneui/themed";
import DisableKeyboardNav from "~/components/DisableKeyboardNav";

const Input: React.FC<InputProps> = (props) => (
  <DisableKeyboardNav>
    <RNEInput {...props} />
  </DisableKeyboardNav>
);

export default memo(Input);
