import VigetThreeUpColumn from "~/components/pages/VigetThreeUpColumn";
import { isCommentary } from "~/predicates";

const vigetFourUpStatuses: FC.PagePresentationStrategy = ({ items }) => {
  const statusesItems = items.filter(isCommentary);

  if (statusesItems.length >= 4) {
    return {
      usedItems: statusesItems.slice(0, 4),
      Component: VigetThreeUpColumn,
    };
  }
  return null;
};

export default vigetFourUpStatuses;
