import React, { memo } from "react";
import { Column, Row } from "~/components/elements";
import BaseCard from "~/components/cards/BaseCard";
import { CardSize, ItemType } from "~/enums";
import { SPACING, createStyleSheet } from "~/styles";
import FlexibleImageCard from "~/components/cards/FlexibleImageCard";
import { makeStyles } from "@rneui/themed";
import { BOTTOM_CONTENT_HEIGHT } from "~/components/cards/BasicMediaCard";
import { FixDimensionType } from "~/components/ItemImage";
import { getClosestImageAspectRatio } from "~/utils/image";
import Loading from "~/components/Loading";
import FlexibleGifvCard from "~/components/cards/FlexibleGifvCard";
import FlexibleYoutubeCard from "~/components/cards/FlexibleYoutubeCard";
import FlexibleAVCard from "~/components/cards/FlexibleAVCard";
const PAGE_MARGIN = SPACING.BASE5X;
const VigetGalleryRow: FC.PageStrategyResult["Component"] = ({
  items,
  contentWidth,
  contentHeight,
}) => {
  const styles = useStyles();
  const usableContentWidth = contentWidth - SPACING.BASE5X * 2;
  const usableContentHeight = contentHeight - SPACING.BASE5X * 2;
  const maxWidth = usableContentWidth;

  const totalWidth = items.reduce((acc, item) => {
    const { image, attachments } = item;
    const attachmentImage =
      attachments?.[0].meta.original || attachments?.[0].meta;

    if (!image?.width && !attachmentImage?.width) return acc;
    const aspectRatio =
      image?.aspectRatio ||
      (image?.height && image?.width && image?.width / image?.height) ||
      attachmentImage?.aspect ||
      (attachmentImage?.width &&
        attachmentImage?.height &&
        attachmentImage?.width / attachmentImage?.height) ||
      1;
    /** We will crop the image at the closest aspect ratio to the current image  */
    const closetSupportedAspectRatio = getClosestImageAspectRatio(aspectRatio);
    return acc + usableContentHeight * closetSupportedAspectRatio;
  }, 0);

  const imageReductionRatio = maxWidth < totalWidth ? maxWidth / totalWidth : 1;

  const renderItem = (item: FC.Item, index: number) => {
    if (imageReductionRatio === null) return <Loading />;
    const { image, attachments } = item;

    const attachmentImage =
      attachments?.[0].meta.original || attachments?.[0].meta;
    if (!image?.width && !attachmentImage?.width) return null;
    const aspectRatio =
      image?.aspectRatio ||
      (image?.height && image?.width && image?.width / image?.height) ||
      attachmentImage?.aspect ||
      (attachmentImage?.width &&
        attachmentImage?.height &&
        attachmentImage?.width / attachmentImage?.height) ||
      1;

    /** We will crop the image at the closest aspect ratio to the current image  */
    const closetSupportedAspectRatio = getClosestImageAspectRatio(aspectRatio);

    /** Need to calculate the corresponding aspect ratio of the wrapping container,
     * accounting for the BOTTOM_CONTENT_HEIGHT footer.
     */
    const containerAspectRatio =
      (usableContentHeight * closetSupportedAspectRatio) /
      (usableContentHeight + BOTTOM_CONTENT_HEIGHT);

    /** Using the container's aspect ratio to bind the card, so that we ensure a fixed "height"
     * for the inside image, which we will scale as needed.
     */
    const containerStyle = {
      height: contentHeight * imageReductionRatio,
      width: contentHeight * containerAspectRatio * imageReductionRatio,
    };

    return (
      <Column key={`${item.id}-${index}`} style={containerStyle}>
        <BaseCard
          item={item}
          height={contentHeight * imageReductionRatio}
          cardSize={CardSize.LARGE}
          ComponentMap={{
            [ItemType.SOCIAL_ARTICLE]: FlexibleImageCard,
            [ItemType.ARTICLE]: FlexibleImageCard,
            [ItemType.PHOTO]: FlexibleImageCard,
            [ItemType.GIFV]: FlexibleGifvCard,
            [ItemType.YOUTUBE]: FlexibleYoutubeCard,
            [ItemType.AV]: FlexibleAVCard,
          }}
          componentProps={{
            maxNumberTruncate: 1,
            fixDimension: FixDimensionType.HEIGHT,
            height: Math.min(
              contentHeight * imageReductionRatio,
              contentHeight - PAGE_MARGIN * 2
            ),
            imageAspectRatio: closetSupportedAspectRatio,
            imageReductionRatio,
          }}
        />
      </Column>
    );
  };
  return (
    <Row flex noGap style={styles.card}>
      {items.map(renderItem)}
    </Row>
  );
};
const useStyles = makeStyles(() => {
  return createStyleSheet({
    card: {
      margin: SPACING.BASE5X,
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
  });
});

export default memo(VigetGalleryRow);
