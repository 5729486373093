import React, { memo, useState, useEffect, useCallback } from "react";
import api, { useGetCustomFeedQuery, useUpdateCustomFeedMutation } from "~/api";
import { useAppDispatch, useAppSelector } from "~/hooks";
import {
  replaceGroup,
  selectFeedGroupFromCustomFeed,
  setSelectedFeed,
} from "~/concepts/groups";
import { customFeedGroupFromCustomFeed } from "~/utils/groups";
import difference from "lodash-es/difference";
import { MutationActionCreatorResult } from "@reduxjs/toolkit/dist/query/core/buildInitiate";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  MutationDefinition,
} from "@reduxjs/toolkit/dist/query";
import { useToast } from "react-native-toast-notifications";
import CustomFeedForm from "~/components/CustomFeedForm";
import { CustomFeedFormType } from "~/enums";
import { useFeedNavigation } from "~/hooks/useFeedNavigation";
import ConfirmDialog from "~/components/ConfirmDialog";
import {
  selectCustomFeedBuilderTriggerSave,
  selectRequestCustomFeedEditSwitchToView,
  setCustomFeedBuilderTriggerSave,
  setRequestCustomFeedEditSwitchToView,
} from "~/concepts/customFeedBuilder";
import isEqual from "lodash-es/isEqual";

type UpdateItemRequests = MutationActionCreatorResult<
  MutationDefinition<
    {
      id: string;
      item: string;
    },
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
    never,
    void,
    "api"
  >
>;

const EditCustomFeed: React.FC<{
  formValue: FC.CustomFeedConfiguration;
  setFormValue: (arg: FC.CustomFeedConfiguration) => void;
  customFeed: FC.CustomFeed;
}> = ({ formValue, setFormValue, customFeed }) => {
  useGetCustomFeedQuery(customFeed.id);
  const toast = useToast();
  const { navigateToCustomFeed } = useFeedNavigation();

  const dispatch = useAppDispatch();
  const [updateIncludeItemsLoading, setUpdateIncludeItemsLoading] =
    useState(false);
  const [updateIncludeItemsError, setUpdateIncludeItemsError] = useState(false);
  const [initialFormValue, setInitialFormValue] =
    useState<FC.CustomFeedConfiguration | null>(null);
  const [dirty, setDirty] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const feedGroup = useAppSelector(selectFeedGroupFromCustomFeed(customFeed));
  const requestCustomFeedEditSwitchToView = useAppSelector(
    selectRequestCustomFeedEditSwitchToView
  );
  const triggerSave = useAppSelector(selectCustomFeedBuilderTriggerSave);
  const [trigger, { data: mutatedFeed, isLoading, isSuccess, isError, reset }] =
    useUpdateCustomFeedMutation();

  const onCancel = useCallback(() => {
    if (dirty) {
      setShowConfirmDialog(true);
    } else {
      navigateToCustomFeed(customFeed);
    }
  }, [dirty, setShowConfirmDialog, navigateToCustomFeed, customFeed]);

  useEffect(() => {
    setInitialFormValue(formValue);
    // just once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (requestCustomFeedEditSwitchToView) {
      dispatch(setRequestCustomFeedEditSwitchToView(false));
      onCancel();
    }
  }, [dispatch, requestCustomFeedEditSwitchToView, onCancel]);

  useEffect(() => {
    feedGroup && dispatch(setSelectedFeed(feedGroup.id));
  }, [dispatch, feedGroup]);

  useEffect(() => {
    if (isSuccess && mutatedFeed) {
      dispatch(replaceGroup(customFeedGroupFromCustomFeed(mutatedFeed)));
      toast.show(`Updated custom feed "${formValue.title}"`, {
        type: "success",
      });
      setInitialFormValue(formValue);
      reset();
    }
  }, [
    reset,
    mutatedFeed,
    dispatch,
    isSuccess,
    toast,
    initialFormValue,
    formValue,
    setInitialFormValue,
  ]);

  useEffect(() => {
    setDirty(!isEqual(initialFormValue, formValue));
  }, [initialFormValue, formValue]);

  const onSubmit = useCallback(() => {
    const includesToRemove = difference(
      customFeed.includes,
      formValue.includes
    );
    const includesToAdd = difference(formValue.includes, customFeed.includes);
    const excludesToRemove = difference(
      customFeed.excludes,
      formValue.excludes
    );
    const excludesToAdd = difference(formValue.excludes, customFeed.excludes);
    const requests: Array<UpdateItemRequests> = [];
    includesToRemove.forEach((item) => {
      requests.push(
        dispatch(
          api.endpoints.removeCustomFeedItem.initiate({
            id: customFeed.id,
            item,
          })
        )
      );
    });
    includesToAdd.forEach((item) => {
      requests.push(
        dispatch(
          api.endpoints.addCustomFeedItem.initiate({ id: customFeed.id, item })
        )
      );
    });
    excludesToRemove.forEach((item) => {
      requests.push(
        dispatch(
          api.endpoints.removeCustomFeedItemExclude.initiate({
            id: customFeed.id,
            item,
          })
        )
      );
    });
    excludesToAdd.forEach((item) => {
      requests.push(
        dispatch(
          api.endpoints.addCustomFeedItemExclude.initiate({
            id: customFeed.id,
            item,
          })
        )
      );
    });
    setUpdateIncludeItemsLoading(true);
    Promise.all(requests).then((result) => {
      setUpdateIncludeItemsLoading(false);

      if (
        result.some((r) => {
          return Object.hasOwn(r, "error");
        })
      ) {
        setUpdateIncludeItemsError(true);
        return;
      } else {
        setUpdateIncludeItemsError(false);
      }
      trigger({
        id: customFeed?.id,
        body: {
          title: formValue.title,
          description: formValue.description,
          image: formValue.image,
        },
      });
    });
  }, [dispatch, customFeed, formValue, trigger]);

  useEffect(() => {
    if (triggerSave) {
      dispatch(setCustomFeedBuilderTriggerSave(false));
      onSubmit();
    }
  }, [onSubmit, dispatch, triggerSave]);

  return (
    <>
      <ConfirmDialog
        title="You have unsaved changes, do you really want to leave?"
        onYes={() => navigateToCustomFeed(customFeed)}
        onNo={() => setShowConfirmDialog(false)}
        visible={showConfirmDialog}
      />
      <CustomFeedForm
        type={CustomFeedFormType.EDIT}
        isDisabled={!dirty}
        isLoading={isLoading || updateIncludeItemsLoading}
        formValue={formValue}
        setFormValue={setFormValue}
        isSuccess={isSuccess}
        isError={isError || updateIncludeItemsError}
        onSubmit={onSubmit}
        onCancel={onCancel}
        customFeed={customFeed}
        dirty={dirty}
        setDirty={setDirty}
      />
    </>
  );
};

export default memo(EditCustomFeed);
