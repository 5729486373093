import React, {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  LayoutChangeEvent,
  NativeScrollEvent,
  NativeSyntheticEvent,
  Platform,
  SafeAreaView,
  StyleProp,
} from "react-native";
import { Column, Row } from "~/components/elements";
import { createStyleSheet } from "~/styles";
import { makeStyles, useTheme } from "@rneui/themed";
import { useAppDispatch, useAppSelector } from "~/hooks";
import { selectModalOpen } from "~/concepts/application";
import CarouselKeyboardNavListener from "~/components/CarouselKeyboardNavListener";
import { PaginationImpulse } from "~/enums";
import Loading from "~/components/Loading";
import { setGlobalScrolling } from "~/concepts/pagination";
import throttle from "lodash-es/throttle";
import { ScrollView } from "react-native-gesture-handler";

export type RenderPageItemPropsType = {
  item: any;
  index: number;
  extraData?: any;
};
export enum PageControlStyleType {
  THUMBNAIL = "thumbnail",
  NUMBER = "number",
}
type CarouselProps = {
  containerStyle?: StyleProp<ViewStyle>;
  flatListStyle?: StyleProp<ViewStyle>;
  groupIndex?: number;
  pageItems: Array<any>;
  vertical: boolean;
  renderPageItem: (props: RenderPageItemPropsType) => React.ReactElement;
  renderThumb?: (pageItem: any, isCurrent: boolean) => ReactNode;
  controlStyle?: PageControlStyleType;
  showControls?: boolean;
  renderExternalControls?(
    index: number,
    goToPage: (pageIndex: number) => void
  ): ReactNode;
  showNextPrev?: boolean;
  hideBackground?: boolean;
  onEndReachedThreshold?: number;
  onEndReached?: () => void;
  onUpdateCurrentIndex?: (i: number) => void;
  headerComponent: React.ReactElement | null;
};

const handlePaginationImpulse =
  (
    handleJumpToGroup: (n: number) => void,
    modalOpen: boolean,
    currentIndex: number
  ) =>
  (paginationImpulse: PaginationImpulse) => {
    if (modalOpen) {
      return;
    }
    if (paginationImpulse === PaginationImpulse.DOWN) {
      handleJumpToGroup(currentIndex + 1);
    } else if (paginationImpulse === PaginationImpulse.UP) {
      handleJumpToGroup(currentIndex - 1);
    } else if (paginationImpulse === PaginationImpulse.TOP) {
      handleJumpToGroup(0);
    } else if (paginationImpulse === PaginationImpulse.BOTTOM) {
      handleJumpToGroup(-1);
    }
  };

const Carousel: React.FC<CarouselProps> = ({
  containerStyle,
  pageItems,
  renderPageItem,
  vertical = true,
  onEndReachedThreshold,
  onEndReached,
  onUpdateCurrentIndex,
  headerComponent,
}) => {
  const startIndex = headerComponent ? -1 : 0;
  const dispatch = useAppDispatch();
  const { theme } = useTheme();
  const [scrolling, setScrolling] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const [contentWidth, setContentWidth] = useState(0);
  const modalOpen = useAppSelector(selectModalOpen);
  const [currentIndex, setCurrentIndex] = useState(startIndex);
  const [scrollToIndex, setScrollToIndex] = useState(startIndex);
  const [headerHeight, setHeaderHeight] = useState(0);

  const styles = useStyles();
  const ref = React.useRef<ScrollView>(null);
  const numPages = pageItems.length;
  const pageSize = vertical ? contentHeight : contentWidth;

  const updateCurrentIndex = React.useCallback(
    (i: number) => {
      setCurrentIndex(i);
      onUpdateCurrentIndex && onUpdateCurrentIndex(i);
    },
    [onUpdateCurrentIndex]
  );
  useEffect(() => {
    if (scrollToIndex !== currentIndex) {
      updateCurrentIndex(scrollToIndex);
    }
  }, [currentIndex, scrollToIndex, updateCurrentIndex]);

  const handleJumpToGroup = useCallback(
    (i: number) => {
      let targetIndex = i;
      // moving up and down
      if (i < -1) {
        // at top moving up, do nothing
        return;
      } else if (i >= numPages) {
        return;
      }

      if (targetIndex === -1) {
        ref.current?.scrollTo({ animated: true, y: 0 });
        setScrollToIndex(headerComponent ? -1 : 0);
      } else if (targetIndex !== currentIndex) {
        // otherwise just go to the requested index
        ref.current?.scrollTo({
          y: contentHeight * targetIndex + headerHeight,
          animated: true,
        });
      }
    },
    [contentHeight, currentIndex, headerComponent, headerHeight, numPages]
  );

  const renderItem = React.useCallback(
    (renderItemProps: RenderPageItemPropsType) =>
      renderPageItem({
        ...renderItemProps,
        extraData: { contentHeight, contentWidth },
      }),
    [contentHeight, contentWidth, renderPageItem]
  );

  // hack to signal rest of app carousel is scrolling
  const updateGlobalScrolling = useMemo(
    () =>
      throttle(
        () => {
          dispatch(setGlobalScrolling(true));
          setTimeout(() => {
            dispatch(setGlobalScrolling(false));
          });
        },
        1000,
        {
          leading: true,
          trailing: false,
        }
      ),

    [dispatch]
  );

  const onScroll = (e: NativeSyntheticEvent<NativeScrollEvent>) => {
    const currentScrollHeight = e.nativeEvent.contentSize.height;

    const currentOffset = e.nativeEvent.contentOffset.y;

    const halfPageHeight = contentHeight / 2;

    // Using an somewhat arbitrary padding to calculate the modulo.
    const ARTBITRARY_OFFSET = contentHeight / 3;
    /**
     * Add the arbritrary offset  before calculating the modulo of
     * the page size. Then re-subtract it from the final value.
     * This ensures a small result for values that are just under the
     * page boundary. (ie: -1 means we are 1 under, +1 means we are 1 over).
     * We don't want the modulo, we want to know how far away it is from a page
     * boundary.
     */
    const distanceFromPageInterval = Math.abs(
      ((currentOffset - ARTBITRARY_OFFSET + halfPageHeight) % contentHeight) -
        ARTBITRARY_OFFSET
    );
    const SCROLL_PAGE_THRESHOLD = halfPageHeight - 1;

    if (currentOffset === 0 && headerComponent) setScrollToIndex(-1);
    else if (
      currentOffset >
      currentScrollHeight - contentHeight * (onEndReachedThreshold || 2)
    )
      onEndReached && onEndReached();
    else if (distanceFromPageInterval < SCROLL_PAGE_THRESHOLD) {
      setScrollToIndex(
        Math.round((currentOffset - headerHeight) / contentHeight)
      );
    }
    updateGlobalScrolling();
  };

  return (
    <>
      <Column flex style={[styles.carouselWrapper, containerStyle]}>
        <SafeAreaView
          style={styles.flex1}
          onLayout={(event: LayoutChangeEvent) => {
            const { height, width } = event.nativeEvent.layout;
            setContentHeight(height);
            setContentWidth(width);
          }}
        >
          {pageSize === 0 ? (
            <Loading />
          ) : (
            <ScrollView
              ref={ref}
              style={[
                styles.galleryCarousel,
                { width: contentWidth, height: contentHeight },
              ]}
              pagingEnabled
              snapToOffsets={Array.from(
                pageItems,
                (_, i) => i * contentHeight + headerHeight
              )}
              stickyHeaderHiddenOnScroll
              decelerationRate={"fast"}
              scrollEventThrottle={100}
              onScroll={onScroll}
              onScrollBeginDrag={() => {
                setScrolling(true);
              }}
              onScrollEndDrag={() => {
                setScrolling(false);
              }}
            >
              <Row
                onLayout={(event: LayoutChangeEvent) => {
                  const { height } = event.nativeEvent.layout;
                  if (height !== headerHeight) setHeaderHeight(height);
                }}
                style={{ backgroundColor: theme.colors.background }}
              >
                {headerComponent}
              </Row>
              {pageItems.map((item, index) => renderItem({ item, index }))}
            </ScrollView>
          )}
        </SafeAreaView>
      </Column>
      {Platform.OS === "web" && !scrolling && (
        <CarouselKeyboardNavListener
          handlePaginationImpulse={handlePaginationImpulse(
            handleJumpToGroup,
            modalOpen,
            currentIndex
          )}
        />
      )}
    </>
  );
};

const useStyles = makeStyles(() =>
  createStyleSheet({
    carouselWrapper: { width: "100%", height: "100%" },
    galleryCarousel: {
      flex: 1,
      width: "100%",
      height: "100%",
      overflow: "hidden",
    },
  })
);

export default memo(Carousel);
