import { useSetPreferencesMutation } from "~/api";
import { selectPreferences } from "~/concepts/application";
import { setPreferences } from "~/concepts/application";
import { selectIsAuthenticated } from "~/concepts/auth";
import { useAppSelector, useAppDispatch } from "~/hooks";
import { useSetPreferencesCookie } from "~/hooks/useCookies";

export const usePreferences = (): [
  FC.Preferences,
  (preferences: FC.Preferences) => void
] => {
  const dispatch = useAppDispatch();
  const preferences = useAppSelector(selectPreferences);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const setPreferencesCookie = useSetPreferencesCookie();
  const [setPreferencesMutation] = useSetPreferencesMutation();

  const setter = (fn: (p: FC.Preferences) => void) => (p: FC.Preferences) => {
    dispatch(setPreferences(p));
    fn(p);
  };

  if (isAuthenticated) {
    return [preferences, setter((p) => setPreferencesMutation(p))];
  }

  return [preferences, setter((p) => setPreferencesCookie(p))];
};
