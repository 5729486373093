import React, { memo } from "react";
import { HTMLText } from "~/components/elements";
import { HTMLTextWrapperProps } from "~/components/elements/HTMLText";

const Content: React.FC<
  {
    item: FC.Item;
  } & HTMLTextWrapperProps
> = ({ item, ...rest }) => {
  return <HTMLText {...rest} html={item.content} />;
};

export default memo(Content);
