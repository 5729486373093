import React, { memo } from "react";
import { Column, HTMLText, Row, Text } from "~/components/elements";
import { useGroupHeaderStyles } from "~/components/GroupHeader";
import { SPACING } from "~/styles";
import { selectIsAuthenticated } from "~/concepts/auth";
import { useAppSelector } from "~/hooks";
import FeedFavoriteButton from "~/buttons/FeedFavoriteButton";
import { TextSize } from "~/enums";

const FeedHeader: React.FC<{ feed: FC.Group }> = ({ feed }) => {
  const styles = useGroupHeaderStyles();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  return (
    <Column flex style={[styles.headerContainer]}>
      <Row style={[styles.headerControls]}>
        {isAuthenticated && (
          <FeedFavoriteButton feed={feed} showTitle type={"outline"} />
        )}
      </Row>
      <Column flex gap={SPACING.BASE3X} style={styles.headerContents}>
        <Text style={[styles.title, styles.headerText]}>{feed?.title}</Text>
        <Text size={TextSize.M} style={[styles.headerText]}>
          {feed?.byline}
        </Text>
        <HTMLText
          textSize={TextSize.S}
          html={feed?.description}
          style={[styles.headerText]}
          numberOfLines={3}
        />
      </Column>
    </Column>
  );
};
export default memo(FeedHeader);
