import React, { memo } from "react";
import { createStyleSheet, LAYOUT } from "~/styles";

import View, { ViewProps } from "~/components/elements/View";

interface RowProps extends ViewProps {
  flex?: boolean | number;
  flexShrink?: number | boolean;
  noGap?: boolean;
  gap?: number;
  overlay?: boolean;
  evenlySpaced?: boolean;
}

const Row: React.FC<RowProps> = ({
  flex,
  flexShrink,
  style,
  noGap,
  gap = LAYOUT.DEFAULT_GAP_SIZE,
  overlay,
  evenlySpaced = false,
  ...props
}) => {
  const flexStyle = typeof flex === "number" ? { flex } : flex && styles.flex1;
  const flexShrinkStyle =
    typeof flexShrink === "number"
      ? { flexShrink }
      : flexShrink && styles.flexShrink1;
  // TODO: can eliminiate the noGap param, but will follow-on in a separate PR
  // to not clutter this one.
  const gapStyle = !noGap ? { gap } : undefined;

  return (
    <View
      style={[
        flexStyle,
        flexShrinkStyle,
        styles.rowView,
        gapStyle,
        overlay && styles.noBackground,
        evenlySpaced && styles.evenlySpaced,
        style,
      ]}
      {...props}
    />
  );
};

const styles = createStyleSheet({
  rowView: {
    flexDirection: "row",
  },
});

export default memo(Row);
