import React, { memo } from "react";
import { createStyleSheet, SPACING } from "~/styles";
import { makeStyles } from "@rneui/themed";
import { Row, View } from "~/components/elements";
import ItemImage from "~/components/ItemImage";
import SimpleCarousel, {
  RenderPageItemPropsType,
} from "~/components/elements/SimpleCarousel";
import {
  isFirstPartyVideo as isFirstPartyVideoItem,
  isImageAttachment,
  isImageAttachmentItem,
  isVideoAttachment,
  isYouTube as isYoutubeItem,
} from "~/predicates";
import { useAppDispatch, useAppSelector } from "~/hooks";
import {
  getCurrentAttachmentIndex,
  getPreviewItem,
  setCurrentAttachmentIndex,
} from "~/concepts/thread";
import YoutubePlayer from "~/components/cardComponents/YoutubePlayer";
import AVPlayer from "~/components/AVPlayer";
import { ItemType } from "~/enums";
import { isPhoneSelector } from "~/concepts/application";

const ThreadMediaPreview: React.FC<{ isFullscreen?: boolean }> = ({
  isFullscreen = false,
}) => {
  const dispatch = useAppDispatch();
  const styles = useStyles();

  const isPhone = useAppSelector(isPhoneSelector);
  const item = useAppSelector(getPreviewItem);
  const currentAttachmentIndex = useAppSelector(getCurrentAttachmentIndex);
  if (!item) return null;

  const isImage = isImageAttachmentItem(item);
  const isYoutube = isYoutubeItem(item);
  const isFirstPartyVideo = isFirstPartyVideoItem(item);

  const renderCarouselPhoto = ({
    index,
    extraData,
  }: RenderPageItemPropsType) => {
    const { contentWidth, contentHeight } = extraData;

    const imageWrapperStyle = {
      width: contentWidth,
      height: contentHeight,
    };

    return (
      <View style={[imageWrapperStyle, styles.alignCenter]}>
        <ItemImage
          item={item}
          attachmentNumber={index}
          contentFit={"contain"}
          disableBlurBackground
        />
      </View>
    );
  };

  const renderPhotoPreview = () => {
    const imageAttachments = item?.attachments?.filter(isImageAttachment) || [];

    return (
      <SimpleCarousel
        pageItems={imageAttachments}
        renderPageItem={renderCarouselPhoto}
        vertical={false}
        showControls
        currentIndex={currentAttachmentIndex}
        onIndexChange={(index) => dispatch(setCurrentAttachmentIndex(index))}
      />
    );
  };

  const renderYoutubePreview = () => (
    <YoutubePlayer
      item={item}
      autoPlay
      style={styles.flex1}
      youtubeIframeOnly
    />
  );

  const renderFirstPartyVideoPreview = () => {
    const videoAttachments = item?.attachments?.filter(isVideoAttachment) || [];
    if (videoAttachments.length === 0) return null;
    return (
      <AVPlayer
        item={item}
        mediaContent={videoAttachments[0]}
        autoPlay
        style={styles.flex1}
      />
    );
  };
  let mediaPreviewContent;
  if (isImage) {
    mediaPreviewContent = renderPhotoPreview();
  }
  if (isYoutube && item.type === ItemType.YOUTUBE) {
    mediaPreviewContent = renderYoutubePreview();
  }
  if (isFirstPartyVideo && item.type === ItemType.AV) {
    mediaPreviewContent = renderFirstPartyVideoPreview();
  }

  return (
    <Row
      flex
      style={[
        styles.threadMediaWrapper,
        isFullscreen && styles.threadMediaWrapperFullscreen,
        (isYoutube || isFirstPartyVideo) &&
          isFullscreen &&
          styles.threadMediaWrapperYoutubeFullscreen,
        isPhone && styles.threadMediaWrapperPhone,
      ]}
      pointerEvents={"box-none"}
    >
      {mediaPreviewContent}
    </Row>
  );
};

const useStyles = makeStyles(() => {
  return createStyleSheet({
    threadMediaWrapper: {
      flex: 1,
      backgroundColor: "transparent",
      paddingVertical: SPACING.BASE4X,
      paddingHorizontal: SPACING.LARGE,
    },
    threadMediaWrapperFullscreen: {
      height: "100%",
      paddingVertical: 0,
      paddingHorizontal: 0,
      justifyContent: "center",
    },
    threadMediaWrapperPhone: {
      paddingVertical: 0,
      paddingHorizontal: 0,
    },
    threadMediaWrapperYoutubeFullscreen: {
      justifyContent: "center",
      margin: SPACING.SMALL,
    },
  });
});

export default memo(ThreadMediaPreview);
