import AsyncStorage from "@react-native-async-storage/async-storage";
import { PersistentStorageKeys } from "~/enums";

export const getBearerToken = () =>
  AsyncStorage.getItem(PersistentStorageKeys.BEARER);

export const setBearerToken = (value: string) =>
  AsyncStorage.setItem(PersistentStorageKeys.BEARER, value);

export const removeBearerToken = () =>
  AsyncStorage.removeItem(PersistentStorageKeys.BEARER);

export const getCUID = () => AsyncStorage.getItem(PersistentStorageKeys.CUID);

export const setCUID = (value: string) =>
  AsyncStorage.setItem(PersistentStorageKeys.CUID, value);

export const getHost = () => AsyncStorage.getItem(PersistentStorageKeys.HOST);

export const setHost = (value: string) =>
  AsyncStorage.setItem(PersistentStorageKeys.HOST, value);
