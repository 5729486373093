import VigetThreeUpColumn from "~/components/pages/VigetThreeUpColumn";
import { isCommentary } from "~/predicates";

const vigetThreeUpStatuses: FC.PagePresentationStrategy = ({ items }) => {
  const statusesItems = items.filter(isCommentary);

  if (statusesItems.length >= 3) {
    return {
      usedItems: statusesItems.slice(0, 3),
      Component: VigetThreeUpColumn,
    };
  }
  return null;
};

export default vigetThreeUpStatuses;
