import VigetGalleryRow from "~/components/pages/VigetGalleryRow";
import { isAVItem, isYoutubeShort } from "~/predicates";
import { removeDuplicates } from "~/utils/item";

const vigetGalleryAVTwoUp: FC.PagePresentationStrategy = ({ items }) => {
  let youtubeItems = items.filter(isAVItem);
  const nonShortYoutubes = youtubeItems.filter((i) => !isYoutubeShort(i));
  youtubeItems = youtubeItems.filter(removeDuplicates([nonShortYoutubes[0]]));
  if (nonShortYoutubes.length >= 1 && youtubeItems.length >= 1) {
    return {
      usedItems: [nonShortYoutubes[0], youtubeItems[0]],
      Component: VigetGalleryRow,
    };
  }
  return null;
};

export default vigetGalleryAVTwoUp;
