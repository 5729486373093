import React from "react";

import BasicLinkCard, {
  BasicLinkCardProps,
} from "~/components/cards/BasicLinkCard";

const BasicLinkCardHorizontal: React.FC<BasicLinkCardProps> = (props) => {
  return <BasicLinkCard {...props} isHorizontal />;
};
export default BasicLinkCardHorizontal;
