import React from "react";
import { createStyleSheet } from "~/styles";
import { CardSize } from "~/enums";
import { StyleProp } from "react-native";
import { makeStyles } from "@rneui/themed";
import { Row } from "~/components/elements";
import ItemImage, { FixDimensionType } from "~/components/ItemImage";
import BasicMediaCard from "~/components/cards/BasicMediaCard";
import { ImageContentFit } from "expo-image";

export type BasicImageCardProps = {
  item: FC.Item;
  overlay?: boolean;
  cardSize?: CardSize;
  isHorizontal?: boolean;
  maxNumberTruncate?: number;
  contentFit?: ImageContentFit;
  flex?: boolean;
  isMediaComponentTop?: boolean;
  customImageContainerStyle?: StyleProp<ViewStyle>;
  mediaCardStyle?: StyleProp<ViewStyle>;
  fixDimension?: FixDimensionType;
};

const BasicImageCard: React.FC<BasicImageCardProps> = (props) => {
  const { item, cardSize, isHorizontal, fixDimension } = props;
  const image = item?.image;

  const styles = useStyles({
    cardSize,
    isHorizontal,
    aspectRatio: image?.aspectRatio,
  });
  if (!image) return null;

  return (
    <Row flex>
      <BasicMediaCard
        {...props}
        MediaComponent={
          <Row
            flex={!isHorizontal}
            style={[styles.imageContainer, props.customImageContainerStyle]}
          >
            <ItemImage
              item={item}
              disableBlurBackground
              contentFit={props.contentFit || "scale-down"}
              contentPosition={"top left"}
              fixDimension={fixDimension}
            />
          </Row>
        }
      />
    </Row>
  );
};

const getMaxHeightSizing = (cardSize?: CardSize) => {
  switch (cardSize) {
    case CardSize.SMALL:
      return "40%";
    case CardSize.XLARGE:
      return "100%";
    default:
      return "60%";
  }
};

const useStyles = makeStyles(
  (
    _,
    {
      cardSize,
      isHorizontal,
      aspectRatio,
    }: {
      cardSize?: CardSize;
      isHorizontal?: boolean;
      aspectRatio?: number;
    }
  ) =>
    createStyleSheet({
      imageContainer: isHorizontal
        ? {
            height: "100%",
            aspectRatio: aspectRatio,
          }
        : {
            width: "100%",
            maxHeight: getMaxHeightSizing(cardSize),
            minHeight: "30%",
            aspectRatio: aspectRatio,
          },
    })
);
export default BasicImageCard;
