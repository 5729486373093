import React, { useEffect, memo, useState } from "react";
import { useToast } from "react-native-toast-notifications";
import { setOpenLoginModal } from "~/concepts/auth";
import { useAppDispatch } from "~/hooks";
import { UseMutation } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { GestureResponderEvent } from "react-native";
import { Button, OverflowMenuButton } from "~/components/elements";
import { SPACING, createStyleSheet } from "~/styles";
import { makeStyles } from "@rneui/themed";
import { QueryStatus } from "@reduxjs/toolkit/dist/query";
import { IconNode } from "@rneui/base";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";

type MutationArgument = FC.Item | FC.Author;

const ToggleButton: React.FC<{
  mutationArgument?: MutationArgument;
  value?: boolean | false;
  disabled?: boolean;
  onIcon?: IconNode | string;
  offIcon?: IconNode | string;
  color?: string;
  hoverColor?: string;
  onChange?: () => void;
  onSuccessToast: string | JSX.Element;
  offSuccessToast: string | JSX.Element;
  // hack to get types, not sure how to get them from the package
  onMutation: UseMutation<any>;
  offMutation: UseMutation<any>;
  overlay?: boolean;
  showButtonLabels?: boolean;
  title?: string;
  fromThread?: boolean;
  isButton?: boolean;
  onMutationTitle?: string;
  offMutationTitle?: string;
  offMutationHoverTitle?: string;
  error?: boolean;
  offIconHover?: IconNode;
  onIconHover?: IconNode;
  iconRight?: boolean;
}> = ({
  mutationArgument,
  value,
  disabled,
  onIcon,
  offIcon,
  color,
  hoverColor,
  onMutation,
  offMutation,
  onChange,
  onSuccessToast,
  offSuccessToast,
  title,
  showButtonLabels = false,
  fromThread = false,
  isButton = false,
  onMutationTitle,
  offMutationTitle,
  offMutationHoverTitle,
  error,
  overlay,
  offIconHover,
  onIconHover,
  iconRight,
}) => {
  const toast = useToast();
  const styles = useStyles({ value });
  const [pendingAction, setPendingAction] = useState(false);
  const [overrideValue, setOverrideValue] = useState<boolean | null>(null);

  const [
    onTrigger,
    {
      error: onError,
      data: onSuccess,
      isSuccess: onTriggerIsSuccess,
      isError: onTriggerIsError,
      status: onTriggerStatus,
    },
  ] = onMutation();
  const [
    offTrigger,
    {
      error: offError,
      data: offSuccess,
      isSuccess: offTriggerIsSuccess,
      isError: offTriggerIsError,
    },
  ] = offMutation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      onTriggerIsSuccess ||
      onTriggerIsError ||
      offTriggerIsSuccess ||
      offTriggerIsError
    ) {
      setOverrideValue(null);
    }
  }, [
    onTriggerIsSuccess,
    onTriggerIsError,
    offTriggerIsSuccess,
    offTriggerIsError,
  ]);

  useEffect(() => {
    if (onSuccess || onError) {
      if (onSuccess) {
        toast.show(onSuccessToast, {
          type: "success",
        });
      }
      if (onError) {
        toast.show("Sorry, something went wrong", {
          type: "fail",
        });
      }
    }
    // Added because onSuccessToast was causing duplicate toast message rendering
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onError, onSuccess, toast]);

  useEffect(() => {
    if (offSuccess || offError) {
      if (offSuccess) {
        toast.show(offSuccessToast, {
          type: "info",
        });
      }
      if (offError) {
        toast.show("Sorry, something went wrong", {
          type: "fail",
        });
      }
    }
    // Added because offSuccessToast was causing duplicate toast message rendering
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offError, offSuccess, toast]);

  useEffect(() => {
    if (onChange && (onSuccess || offSuccess)) {
      onChange();
    }
  }, [onChange, onSuccess, offSuccess]);

  // user clicked the action before we had the mutationArgument, but
  // we have it now, so set to not pending and attempt action
  if (
    pendingAction &&
    mutationArgument &&
    onTriggerStatus === QueryStatus.uninitialized
  ) {
    onTrigger(mutationArgument);
  }

  const buttonAction = (event: GestureResponderEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (error) {
      toast.show("Sorry, something went wrong", {
        type: "fail",
      });
      return;
    }
    if (disabled)
      return dispatch(
        setOpenLoginModal({ open: true, unauthenticatedAction: true })
      );
    if (value) {
      setOverrideValue(false);
    } else {
      setOverrideValue(true);
    }
    if (mutationArgument) {
      if (value) {
        offTrigger(mutationArgument);
      } else {
        onTrigger(mutationArgument);
      }
    } else {
      setPendingAction(true);
    }
  };

  if (isButton) {
    let icon = value ? offIcon : onIcon;
    if (typeof icon === "string")
      icon = (
        <Icon
          name={icon as keyof typeof Icon.glyphMap}
          size={12}
          color={value ? color : undefined}
        />
      );
    let hoverIcon = value ? offIconHover : onIconHover;
    return (
      <Button
        title={value ? offMutationTitle : onMutationTitle}
        hoverTitle={value ? offMutationHoverTitle : undefined}
        onPress={buttonAction}
        titleStyle={styles.buttonTitle}
        buttonStyle={styles.button}
        disabled={disabled}
        type={value ? "outline" : "solid"}
        color={color}
        hoverColor={hoverIcon ? hoverColor : undefined}
        icon={icon}
        iconHover={hoverIcon}
        iconRight={iconRight}
      />
    );
  }

  const getValue = () => (overrideValue === null ? value : overrideValue);

  return (
    <OverflowMenuButton
      title={showButtonLabels ? title : undefined}
      onPress={buttonAction}
      iconProps={{
        solid: value,
        size: fromThread ? 18 : 24,
      }}
      color={getValue() ? color : undefined}
      icon={getValue() ? onIcon : offIcon}
      style={fromThread && { paddingVertical: SPACING.BASE }}
      dismissOnClick={!!(mutationArgument && !pendingAction)}
      triggerDismiss={!!(pendingAction && onSuccess)}
      overlay={overlay}
    />
  );
};

const useStyles = makeStyles((theme, { value }: { value?: boolean }) => {
  return createStyleSheet({
    button: {
      backgroundColor: value
        ? theme.colors.primaryReverse
        : theme.colors.primary,
    },
    buttonTitle: {
      color: value ? theme.colors.primary : theme.colors.primaryReverse,
      paddingHorizontal: SPACING.BASE,
    },
  });
});

export default memo(ToggleButton);
