import { makeStyles } from "@rneui/themed";
import React, { memo, useCallback, useEffect, useState } from "react";
import { ScrollView } from "react-native";
import api, { useGetCustomFeedsQuery } from "~/api";
import { Button, Dialog, Column } from "~/components/elements";
import { ApplicationMode } from "~/enums";
import { createStyleSheet, SPACING } from "~/styles";
import CloseButton from "~/buttons/CloseButton";
import { isPhoneSelector, selectApplicationMode } from "~/concepts/application";
import { useAppDispatch, useAppSelector } from "~/hooks";
import { useToast } from "react-native-toast-notifications";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import CustomFeedAddToNewFeed from "~/components/CustomFeedAddToNewFeed";
import FeedDisplayName from "~/components/elements/FeedDisplayName";

const CustomFeedAddToForm: React.FC<{
  feed: FC.Group;
  onAdd?: () => void;
  type?: "outline" | "solid" | "clear";
  overlay?: boolean;
  showTitle?: boolean;
}> = ({ feed, onAdd, type = "outline", overlay, showTitle = true }) => {
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const isPhone = useAppSelector(isPhoneSelector);

  const toast = useToast();
  const applicationMode = useAppSelector(selectApplicationMode);
  const [customFeeds, setCustomFeeds] = useState<FC.CustomFeed[]>([]);
  const [visible, setVisible] = useState(false);
  const close = useCallback(() => setVisible(false), [setVisible]);

  const customFeedsResponse = useGetCustomFeedsQuery();

  useEffect(() => {
    if (customFeedsResponse.isSuccess && customFeedsResponse.data) {
      setCustomFeeds(customFeedsResponse.data);
    }
  }, [customFeedsResponse]);

  if (applicationMode !== ApplicationMode.BASE) return null;

  const onAddToFeed = async (id: string, title: string) => {
    if (!feed.customFeedValue) return;
    try {
      const response = await dispatch(
        api.endpoints.addCustomFeedItem.initiate({
          id,
          item: feed.customFeedValue,
        })
      );
      if ("error" in response) {
        throw new Error();
      } else {
        toast.show(`Added ${feed.title} to ${title}`, {
          type: "success",
        });
        close();
        onAdd?.();
      }
    } catch (e) {
      toast.show(`Error adding ${feed.title} to ${title}`, {
        type: "error",
      });
    }
  };

  const renderFeedRow = ({ item }: { item: FC.CustomFeed }) => {
    const { id, title } = item;
    return (
      <Button
        key={`${id}_${title}`}
        icon={
          <Icon name={"tray-arrow-down"} size={SPACING.BASE4X} solid={true} />
        }
        title={title}
        type={"clear"}
        onPress={() => onAddToFeed(id, title)}
        buttonStyle={[
          {
            gap: SPACING.BASE,
            borderRadius: SPACING.BASE,
          },
          styles.justifyLeft,
        ]}
        containerStyle={styles.addToFeedButton}
      />
    );
  };

  return (
    <>
      <Button
        overlay={overlay}
        type={type}
        title={showTitle ? "Add To Feed" : ""}
        onPress={() => {
          setVisible(true);
        }}
        icon={<Icon name="plus" size={12} />}
        iconRight
      />
      <Dialog isVisible={visible} onBackdropPress={close} fullScreen={isPhone}>
        <CloseButton onPress={close} style={styles.closeButton} />
        <Column flex>
          <Dialog.Title>
            {"Add "}
            <FeedDisplayName
              feed={feed}
              style={styles.dialogTitle}
              emojiSize={SPACING.LARGE}
            />
            {" to..."}
          </Dialog.Title>
          <Column flex style={styles.feedWrapper}>
            <ScrollView>
              <Column gap={1} style={styles.listScrollContents}>
                {customFeeds.map((customFeed) => {
                  return renderFeedRow({ item: customFeed });
                })}
              </Column>
            </ScrollView>
          </Column>
          <Dialog.Actions>
            <CustomFeedAddToNewFeed feed={feed} onSubmit={close} />
          </Dialog.Actions>
        </Column>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles((theme) =>
  createStyleSheet({
    closeButton: {
      position: "absolute",
      top: SPACING.BASE,
      right: SPACING.BASE,
      zIndex: 1,
    },
    feedWrapper: {
      borderRadius: SPACING.BASE,
      borderWidth: 1,
      borderColor: theme.colors.dividerPrimary,
      overflow: "hidden",
      height: "100%",
    },
    listScrollContents: {
      flexShrink: 1,
      backgroundColor: theme.colors.dividerPrimary,
    },

    addToFeedButton: {
      backgroundColor: theme.colors.background,
      justifyContent: "flex-start",
    },
  })
);

export default memo(CustomFeedAddToForm);
