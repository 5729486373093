import URL from "~/utils/url";
import { FLIPBOARD_MAGAZINE_URL } from "~/utils/reg-exp";
import {
  AttachmentType,
  CardType,
  ImageAspectRatioType,
  ItemType,
  TextCharactersLengthRange,
} from "~/enums";
import {
  imageWithinRatio,
  isFeatureable,
  isLandscapeHero,
  isPortraitHero,
  isSquare,
} from "~/utils/image";
import { textCharactersLengthWithinRange } from "~/utils/item";

export const isReallyAGroup = (arg: FC.Group | undefined): arg is FC.Group =>
  arg !== undefined;

export const isCommentary = (item: FC.Item): item is FC.CommentaryItem => {
  const thing = item.reblog || item;
  return !thing.card && !thing.attachments && !(thing.type === ItemType.POLL);
};

export const isMediaType = (item: FC.Item) =>
  (item.attachments?.length || 0) > 0;

export const isGallerySupportedType = (item: FC.Item) => {
  const toRender = item.reblog || item;
  return [
    ItemType.SOCIAL_ARTICLE,
    ItemType.ARTICLE,
    ItemType.PHOTO,
    ItemType.GIFV,
  ].includes(toRender.type);
};

export const supportedMediaTypes = (
  attachment: FC.MediaAttachment
): attachment is FC.SupportedMediaAttachment =>
  attachment.type === AttachmentType.IMAGE ||
  attachment.type === AttachmentType.VIDEO ||
  attachment.type === AttachmentType.GIFV ||
  attachment.type === AttachmentType.AUDIO;

export const isImageAttachment = (
  attachment: FC.SupportedMediaAttachment
): attachment is FC.PhotoAttachment => {
  return attachment.type === AttachmentType.IMAGE;
};
export const isVideoAttachment = (
  attachment: FC.SupportedMediaAttachment
): attachment is FC.VideoAttachment => {
  return attachment.type === AttachmentType.VIDEO;
};

export const isAudioAttachment = (
  attachment: FC.SupportedMediaAttachment
): attachment is FC.AudioAttachment => {
  return attachment.type === AttachmentType.AUDIO;
};

export const isGifvAttachment = (
  attachment: FC.SupportedMediaAttachment
): attachment is FC.GifvAttachment => {
  return attachment.type === AttachmentType.GIFV;
};

export const hasSupportedMediaTypes = (item: FC.Item) => {
  const itemToRender = item.reblog || item;
  return itemToRender?.attachments?.some(supportedMediaTypes) || false;
};

export const unknownHashtagFilter = (i: FC.Item) =>
  !isMediaType(i) ||
  (isMediaType(i) && hasSupportedMediaTypes(i)) ||
  isYouTube(i) ||
  isYoutubeShort(i);

export const groupTypeMixedFilter = (i: FC.Item) =>
  !!i.reblog &&
  (!isMediaType(i) ||
    (isMediaType(i) && hasSupportedMediaTypes(i)) ||
    isYouTube(i));

export const groupTypePhotoFilter = (i: FC.Item) => {
  const itemToRender = i.reblog || i;
  return isMediaType(itemToRender) && hasSupportedMediaTypes(itemToRender);
};

export const isYoutubeShort = (item: FC.Item) => {
  const toRender = item.reblog || item;

  const regexYoutubeShorts =
    /^https?:\/\/(www\.)?youtube\.com\/shorts\/[^\s/]+$/;

  return [regexYoutubeShorts].some(
    (re) => toRender.card?.url && re.test(toRender.card?.url)
  );
};

export const isYouTube = (item: FC.Item) => {
  const toRender = item.reblog || item;

  const regexYoutubeShortenURL = /^https:\/\/youtu\.be\/[^/]+$/;

  const youtubeProviders = ["youtu.be", "youtube.com", "YouTube"];

  return (
    (toRender?.card?.type === CardType.VIDEO &&
      youtubeProviders.includes(toRender.card?.provider_name)) ||
    [regexYoutubeShortenURL].some(
      (re) => toRender.card?.url && re.test(toRender.card?.url)
    )
  );
};

export const isFirstPartyVideo = (item: FC.Item) => {
  const toRender = item.reblog || item;
  return (
    (isMediaType(toRender) &&
      !isYouTube(toRender) &&
      !isYoutubeShort(toRender) &&
      hasSupportedMediaTypes(toRender) &&
      toRender.attachments &&
      toRender.attachments.some((x) => x.type === AttachmentType.VIDEO)) ||
    false
  );
};

export const isGifv = (item: FC.Item) => {
  const toRender = item.reblog || item;
  return (
    (isMediaType(toRender) &&
      hasSupportedMediaTypes(toRender) &&
      toRender.attachments &&
      toRender.attachments.some((x) => x.type === AttachmentType.GIFV)) ||
    false
  );
};

export const isAudio = (item: FC.Item) => {
  const toRender = item.reblog || item;
  return (
    (isMediaType(toRender) &&
      hasSupportedMediaTypes(toRender) &&
      toRender.attachments &&
      toRender.attachments.some((x) => x.type === AttachmentType.AUDIO)) ||
    false
  );
};

export const isFlipboardMagazine = (item: FC.Item) => {
  const toRender = item.reblog || item;
  const url = toRender.card?.url;
  if (!url) return false;
  const u = new URL(url);
  const isFlipboardMagazineURL = !!u.pathname.match(FLIPBOARD_MAGAZINE_URL);

  return toRender?.card?.type === CardType.LINK && isFlipboardMagazineURL;
};

export const isArticleItem = (i: FC.Item): i is FC.CardItem => {
  const toRender = i.reblog || i;
  return !!toRender && toRender.type === ItemType.ARTICLE;
};

export const isImageItem = (item: FC.Item) => {
  const toRender = item.reblog || item;
  return toRender.type === ItemType.PHOTO;
};

export const isItemWithImage = (item: FC.Item) => !!item.image;

export const isImageAttachmentItem = (item: FC.Item) => {
  const toRender = item.reblog || item;
  return toRender?.attachments?.some((a) => a.type === AttachmentType.IMAGE);
};

export const isGenericMediaItem = (item: FC.Item) => {
  const toRender = item.reblog || item;
  return toRender.type !== ItemType.STATUS;
};
export const hasMultipleImages = (item: FC.Item) => {
  const toRender = item.reblog || item;
  return toRender.attachments && toRender.attachments?.length > 1;
};

export const hasOneImage = (item: FC.Item) => {
  const toRender = item.reblog || item;
  return toRender.attachments && toRender.attachments?.length === 1;
};

export const isFirstPartyVideoItem = (item: FC.Item) => {
  const toRender = item.reblog || item;
  return !!toRender && toRender.type === ItemType.AV;
};

export const isAVItem = (item: FC.Item) => {
  return isVideo(item) || isAudio(item);
};

export const isVideo = (i: FC.Item): i is FC.CardItem => {
  const toRender = i.reblog || i;
  return !!toRender && [ItemType.YOUTUBE, ItemType.AV].includes(toRender.type);
};

export const isSquareImageItem = (item: FC.Item) => isSquare(item?.image);

//TODO: this logic is probably insufficient as images that people are putting directly
// in Mastodon almost never have hints I think.
export const isPhotoItem = (item: FC.Item) =>
  isItemWithImage(item) && item?.image && !!item?.image?.hints.photo;

export const isGraphicItem = (item: FC.Item) =>
  isImageItem(item) && item?.image && !item?.image?.hints.photo;

export const isSquarePhotoItem = (item: FC.Item) =>
  isPhotoItem(item) && isSquare(item.image);

export const isPortraitHeroItem = (item: FC.Item) =>
  isImageItem(item) && isPortraitHero(item.image);

export const isLandscapeHeroItem = (item: FC.Item) =>
  isImageItem(item) && isLandscapeHero(item.image);

export const isPortraitHeroPhotoItem = (item: FC.Item) =>
  isPhotoItem(item) && isPortraitHero(item.image);

export const isLandscapeHeroPhotoItem = (item: FC.Item) =>
  isPhotoItem(item) && isLandscapeHero(item.image);

export const isHeroImageItem = (item: FC.Item) =>
  isImageItem(item) &&
  (isLandscapeHero(item.image) || isPortraitHero(item.image));

export const isMultiImageItem = (item: FC.Item) =>
  isImageItem(item) && hasMultipleImages(item);

export const isStatusItem = (i: FC.Item) => {
  // TODO: Confirm where we might want to see replies.
  if (i.isReply && !i.reblog) return false;
  const toRender = i.reblog || i;
  return !!toRender && toRender.type === ItemType.STATUS;
};

export const isPoll = (i: FC.Item) => !!i.poll;

//------------------//

export const isItemWithPhotoImage = (item: FC.Item) =>
  isItemWithImage(item) && item?.image?.hints?.photo;

export const isItemWithoutDecentImage = (item: FC.Item) =>
  !item.image ||
  (item.image?.width &&
    item.image?.width < 300 &&
    item.image?.height &&
    item.image?.height < 300) ||
  isCommentary(item);

export const isFeatureableImageItem = (item: FC.Item) =>
  isItemWithImage(item) && isFeatureable(item.image);

export const isLandscapeOrTallerFeatureImageItem = (item: FC.Item) =>
  isItemWithImage(item) &&
  isFeatureable(item.image) &&
  imageWithinRatio(item.image, ImageAspectRatioType.LANDSCAPE);

export const isWideFeatureImageItem = (item: FC.Item) =>
  isItemWithImage(item) &&
  isFeatureable(item.image) &&
  imageWithinRatio(
    item.image,
    ImageAspectRatioType.ULTRA_WIDE,
    ImageAspectRatioType.LANDSCAPE
  );

export const isStandardFeatureImageItem = (item: FC.Item) =>
  isItemWithImage(item) &&
  isFeatureable(item.image) &&
  imageWithinRatio(
    item.image,
    ImageAspectRatioType.LANDSCAPE,
    ImageAspectRatioType.TALL
  );

export const isLandscapeOrTallerImageItem = (item: FC.Item) =>
  isItemWithImage(item) &&
  imageWithinRatio(item.image, ImageAspectRatioType.LANDSCAPE);

export const isPortraitImageItem = (item: FC.Item) =>
  isItemWithImage(item) &&
  imageWithinRatio(item.image, ImageAspectRatioType.PORTRAIT, null);

export const isNotPortraitItem = (item: FC.Item) =>
  isItemWithImage(item) &&
  imageWithinRatio(item.image, null, ImageAspectRatioType.SQUARE);

export const isLandscapeImageItem = (item: FC.Item) =>
  isItemWithImage(item) &&
  imageWithinRatio(item.image, null, ImageAspectRatioType.LANDSCAPE);

export const isFlipboardMagazineItem = (item: FC.Item) => {
  const toRender = item.reblog || item;
  return !!toRender && toRender.type === ItemType.FLIPBOARD_MAGAZINE;
};

export const isSocialArticleItem = (item: FC.Item) => {
  const toRender = item.reblog || item;
  return !!toRender && toRender.type === ItemType.SOCIAL_ARTICLE;
};

export const isGalleryItem = (item: FC.Item) => {
  const toRender = item.reblog || item;
  return (
    (hasOneImage(toRender) && isFeatureableImageItem(toRender)) ||
    isGifv(toRender)
  );
};

export const isSocialArticle = (item: FC.Item) => {
  if (item.card) {
    // remove the markup
    const tmp = document.createElement("DIV");
    tmp.innerHTML = item.content || "";
    const contentText = tmp.textContent || tmp.innerText || "";
    const simplifyStringForComparison = (string: string = "") =>
      string
        // naively remove urls
        .replace(/https?:\/\/[^\s]+/g, "")
        // remove hashtag bombs
        .replace(/#\w+/g, "")
        // replace non-words (helps catch things like ’ vs ')
        .replace(/[^\w\s]/g, "")
        // replace extra spaces
        .replace(/\s+/g, " ")
        .toLowerCase()
        .trim();
    const cleanedContentText = simplifyStringForComparison(contentText);
    const cleanedCardTitle = simplifyStringForComparison(item.card.title);

    if (cleanedContentText.length === 0) {
      return false;
    }

    if (
      // title does not appear in content
      cleanedContentText.indexOf(cleanedCardTitle) === -1 ||
      // content has title, but other stuff too
      cleanedContentText.length > cleanedCardTitle.length * 2
    ) {
      return true;
    }
  }
};

// featureItem priotize on finding feature image items (only one attachment), then media (not including images
// since is already handled by feature image items), and lastly commentary
export const isFeatureItem = (item: FC.Item) => {
  const toRender = item.reblog || item;
  return (
    (isStandardFeatureImageItem(toRender) && !hasMultipleImages(toRender)) ||
    (hasSupportedMediaTypes(item) &&
      !isImageItem(item) &&
      !isSocialArticleItem(item)) ||
    isCommentary(toRender)
  );
};

export const isCardItem = (item: FC.Item) => {
  const toRender = item.reblog || item;
  return !!toRender.card;
};

export const isNotSmallTextCharactersLength = (item: FC.Item) =>
  !textCharactersLengthWithinRange(item, TextCharactersLengthRange.SMALL);

export const isReboostOf = (itemA: FC.Item) => (itemB: FC.Item) =>
  itemB.reblog?.source === itemA.source;
