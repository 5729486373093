import { makeStyles } from "@rneui/themed";
import React, { memo } from "react";

import { createStyleSheet } from "~/styles";
import {
  Image as ExpoImage,
  ImageProps as ExpoImageProps,
  ImageContentFit,
  ImageContentPosition,
  ImageSource,
  ImageStyle,
} from "expo-image";
import View from "~/components/elements/View";
import { DimensionValue } from "react-native";

type ImageProps = {
  url: string;
  blurhash?: string;
  width?: DimensionValue;
  height?: DimensionValue;
  darken?: boolean;
  contentPosition?: ImageContentPosition;
  blurhashBackground?: boolean;
  style?: ImageStyle;
  lighten?: boolean;
  flex?: boolean;
  focusPercent?: { x: number; y: number };
  aspectRatio?: number;
  wrapperStyles?: boolean;
  isTopBorderRadius?: boolean;
  contentFit?: ImageContentFit;
} & Partial<ExpoImageProps>;
const Image: React.FC<ImageProps> = ({
  style = {},
  url,
  blurhash,
  width,
  height,
  contentPosition = "center",
  blurhashBackground = false,
  lighten,
  darken,
  flex,
  focusPercent,
  aspectRatio,
  wrapperStyles = true,
  contentFit,
  ...rest
}) => {
  const styles = useStyles();

  let imageStyle: ImageStyle = {
    flexBasis: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1,
    backgroundColor: "transparent",
    width: "100%",
  };
  if (flex) imageStyle.flex = 1;
  if (width) imageStyle.width = width;
  if (height) imageStyle.height = height;
  if (aspectRatio) imageStyle.aspectRatio = aspectRatio;

  const dimensions = { width: imageStyle.width, height: imageStyle.height };

  let blurhashBackgroundSource: ImageSource | null = null;
  if (blurhashBackground) {
    blurhashBackgroundSource = {
      blurhash: blurhash || "",
      height: 300,
      width: 300,
    };
  }

  const imageUrl = url;
  const mainImageSource = imageUrl ? { uri: imageUrl } : { blurhash };
  return (
    <View style={[wrapperStyles && styles.imageWrapper, dimensions]}>
      {blurhashBackground && blurhashBackgroundSource && (
        <View style={styles.blurhashBackground}>
          <ExpoImage style={styles.flex1} source={blurhashBackgroundSource} />
        </View>
      )}
      <ExpoImage
        {...rest}
        cachePolicy={"none"}
        source={mainImageSource}
        style={[imageStyle, style]}
        placeholder={blurhash || ""}
        contentFit={contentFit}
        contentPosition={
          focusPercent && contentFit === "cover"
            ? { left: `${focusPercent.x}%`, bottom: `${focusPercent.y}%` }
            : contentPosition
        }
      />
      {lighten && <View style={styles.lightenOverlay} />}
      {darken && <View style={styles.darkenOverlay} />}
    </View>
  );
};

const useStyles = makeStyles((theme) => {
  return createStyleSheet({
    imageWrapper: {
      // flex: 1,
      alignItems: "center",
      justifyContent: "center",
    },
    blurhashBackground: {
      flex: 1,
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
    },
    lightenOverlay: {
      position: "absolute",
      top: 0,
      left: 0,
      backgroundColor: theme.colors.overlay75,
      width: "100%",
      height: "100%",
      zIndex: 1,
    },
    darkenOverlay: {
      position: "absolute",
      top: 0,
      left: 0,
      backgroundColor: theme.colors.backgroundReverse50,
      width: "100%",
      height: "100%",
      zIndex: 1,
    },
  });
});
export default memo(Image);
