import React, { useEffect, useState } from "react";
import { Pressable, StyleProp } from "react-native";
import { ContentPosition } from "~/enums";
import { Column, Row, Text } from "~/components/elements";
import { FixDimensionType } from "~/components/ItemImage";
import {
  BOTTOM_CONTENT_HEIGHT,
  BasicMediaCardProps,
} from "~/components/cards/BasicMediaCard";
import CardAuthor from "~/components/CardAuthor";
import AutoNumberOfLinesWrapper from "~/components/elements/AutoNumberOfLinesWrapper";
import { makeStyles, useTheme } from "@rneui/themed";
import { SPACING, createStyleSheet } from "~/styles";
import Content from "~/components/Content";
import { GALLERY_FONT_SIZE } from "~/components/elements/Text";
import { selectModalOpen } from "~/concepts/application";
import { useAppSelector } from "~/hooks";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { secondsToMinutesString } from "~/utils/parse-time";
import AVPlayerPreview from "~/components/cardComponents/AVPlayerPreview";
import AVPlayer from "~/components/AVPlayer";
import { isAudioAttachment, isVideoAttachment } from "~/predicates";
import CardText from "~/components/cardComponents/CardText";
import { isEqual } from "lodash-es";
import Reboosts from "~/components/cardComponents/Reboosts";

const PLAYER_HEADER_HEIGHT = 50;
const PLAYER_FOOTER_HEIGHT = 50;

export interface FlexibleAVCardProps
  extends Omit<BasicMediaCardProps, "MediaComponent"> {
  maxNumberTruncate?: number;
  fixDimension?: FixDimensionType;
  height?: number;
  imageAspectRatio?: number;
  contentPosition?: ContentPosition;
  cardContentStyle?: StyleProp<ViewStyle>;
}

const FlexibleAVCard: React.FC<FlexibleAVCardProps> = ({
  item,
  overlay,
  cardSize,
  fixDimension,
  height,
  imageAspectRatio,
  contentPosition = ContentPosition.BOTTOM,
  cardContentStyle,
}) => {
  const styles = useStyles();
  const { theme } = useTheme();

  const { attachments } = item;

  const title = (item as FC.CardItem)?.card?.title || null;
  const uniqueContent = !title || !isEqual(title, item.content);

  const [avEmbeddedPlayer, setAVEmbeddedPlayer] = useState(false);
  const modalOpen = useAppSelector(selectModalOpen);

  const toggleYouTubeEmbeddedPlayer = () => {
    setAVEmbeddedPlayer(!avEmbeddedPlayer);
  };

  const stopYouTubeEmbeddedPlayer = () => {
    setAVEmbeddedPlayer(false);
  };
  useEffect(() => {
    if (modalOpen) {
      stopYouTubeEmbeddedPlayer();
    }
  }, [modalOpen]);

  const heightReduction =
    contentPosition === ContentPosition.BOTTOM ? BOTTOM_CONTENT_HEIGHT : 0;
  const playerHeight =
    fixDimension === FixDimensionType.HEIGHT && height
      ? height - heightReduction
      : undefined;
  const imageContainerStyle = {
    height: playerHeight,
    aspectRatio: imageAspectRatio,
    border: 0,
  };

  const WrapperComponent =
    contentPosition === ContentPosition.BOTTOM ? Column : Row;

  const CardContent = (
    <Column
      flexShrink
      style={[
        contentPosition === ContentPosition.BOTTOM
          ? styles.bottomContent
          : styles.sideContent,
        cardContentStyle,
      ]}
    >
      <CardAuthor item={item} overlay={overlay} />
      {uniqueContent && (
        <AutoNumberOfLinesWrapper flexShrink maxNumberOfLines={2}>
          <Content
            cardSize={cardSize}
            item={item}
            textSize={GALLERY_FONT_SIZE}
          />
        </AutoNumberOfLinesWrapper>
      )}
      <Reboosts item={item} />
    </Column>
  );

  const playerContainerStyle: StyleProp<ViewStyle> = {
    justifyContent: "center",
    height: playerHeight
      ? playerHeight + PLAYER_HEADER_HEIGHT + PLAYER_FOOTER_HEIGHT
      : undefined,
    backgroundColor: theme.colors.backgroundReverse,
    borderRadius: SPACING.BASE,
    overflow: "hidden",
  };

  const AVComponent = avEmbeddedPlayer ? (
    <Column flex noGap style={playerContainerStyle}>
      <AVPlayer
        item={item}
        mediaContent={
          attachments?.find(isAudioAttachment) ||
          attachments?.find(isVideoAttachment)
        }
        autoPlay
        style={styles.avPlayer}
      />
    </Column>
  ) : (
    <Column noGap style={playerContainerStyle}>
      <Row gap={SPACING.SMALL} style={styles.durationOverlay}>
        <Icon name="play" size={16} color="#ffffff" />
        {item.card?.duration && (
          <Text overlay>{secondsToMinutesString(item.card?.duration)}</Text>
        )}
      </Row>
      <Row flexShrink style={imageContainerStyle}>
        <Pressable
          onPress={toggleYouTubeEmbeddedPlayer}
          style={styles.playClickHandler}
        >
          <AVPlayerPreview
            item={item}
            style={[styles.justifyCenter, imageContainerStyle]}
          />
        </Pressable>
      </Row>
      <Row flexShrink style={styles.textOverlay}>
        {title && (
          <AutoNumberOfLinesWrapper flex maxNumberOfLines={1}>
            <CardText header overlay>
              {title}
            </CardText>
          </AutoNumberOfLinesWrapper>
        )}
      </Row>
    </Column>
  );
  return (
    <Column flex style={styles.justifyCenter}>
      <WrapperComponent
        flexShrink
        style={[styles.card, { height, minHeight: height }]}
      >
        {contentPosition === ContentPosition.LEFT ? (
          <>
            {CardContent}
            {AVComponent}
          </>
        ) : (
          <>
            {AVComponent}
            {CardContent}
          </>
        )}
      </WrapperComponent>
    </Column>
  );
};

const useStyles = makeStyles((theme) =>
  createStyleSheet({
    card: {
      width: "100%",
      maxWidth: `calc(100% - ${SPACING.BASE3X * 2}px)`,
      overflow: "hidden",
      margin: SPACING.BASE3X,
    },
    contentContainer: {
      width: "100%",
      maxHeight: "100%",
    },
    contentContainerHorizontal: { height: "100%", justifyContent: "center" },
    bottomContent: { maxHeight: BOTTOM_CONTENT_HEIGHT - 80 },
    sideContent: { flexShrink: 1, alignSelf: "flex-end", width: 350 },

    avPlayer: {
      flex: 1,
      height: "100%",
      width: "100%",
    },
    playClickHandler: {
      position: "absolute",
      zIndex: 1,
      height: "100%",
      width: "100%",
    },
    durationOverlay: {
      backgroundColor: theme.colors.backgroundReverse,
      padding: SPACING.MEDIUM,
      height: PLAYER_HEADER_HEIGHT,
      alignItems: "center",
    },
    textOverlay: {
      backgroundColor: theme.colors.backgroundReverse,
      padding: SPACING.MEDIUM,
      zIndex: 1,
      width: "100%",
      height: PLAYER_FOOTER_HEIGHT,
      alignItems: "flex-end",
    },
    playIcon: {
      display: "flex",
      lineHeight: 84,
      backgroundColor: theme.colors.videoBackground,
      justifyContent: "center",
      alignItems: "center",
      opacity: 0.75,
      height: "100%",
      width: "100%",
    },
  })
);

export default FlexibleAVCard;
