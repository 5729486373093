import { ScreenSizeType } from "~/enums";

const PHONE_MAX_WIDTH = 600;
const NARROW_MAX_WIDTH = 1024;
const DESKTOP_MAX_WIDTH = 1500;

export const calcPageSize = (width: number) => {
  if (width < PHONE_MAX_WIDTH) {
    return ScreenSizeType.PHONE;
  } else if (width < NARROW_MAX_WIDTH) {
    return ScreenSizeType.NARROW;
  } else if (width < DESKTOP_MAX_WIDTH) {
    return ScreenSizeType.DESKTOP;
  } else {
    return ScreenSizeType.DESKTOP_WIDE;
  }
};
