import React, { memo } from "react";
import { makeStyles } from "@rneui/themed";
import { createStyleSheet, SPACING } from "~/styles";

import { Anchor, Column, Text } from "~/components/elements";
import {
  CardSize,
  OverlayTitlePosition,
  TextAttributionStyle,
  TextSize,
} from "~/enums";
import AutoNumberOfLinesWrapper from "~/components/elements/AutoNumberOfLinesWrapper";
import { BlurView } from "expo-blur";
import { StyleProp } from "react-native";
import { cardSizeToHeaderSize } from "~/utils/fonts";
import { useAppSelector } from "~/hooks";
import { isPhoneSelector } from "~/concepts/application";

const BlurredOverlayTitle: React.FC<{
  item: FC.Item;
  position?: OverlayTitlePosition;
  distanceFromEdge?: number;
  cardSize?: CardSize;
  style?: StyleProp<ViewStyle>;
}> = ({
  item,
  position = OverlayTitlePosition.BOTTOM_RIGHT,
  distanceFromEdge = SPACING.BASE8X,
  cardSize = CardSize.MEDIUM,
  style,
}) => {
  const isPhone = useAppSelector(isPhoneSelector);
  const styles = useStyles({ cardSize, position, distanceFromEdge, isPhone });
  if (!item.card) return null;

  const cardTextProps: Record<string, any> = {};
  if (cardSize === CardSize.LARGE || cardSize === CardSize.XLARGE) {
    cardTextProps.size = TextSize.S;
  } else {
    cardTextProps.size = cardSizeToHeaderSize(cardSize);
  }

  let titleSize;
  if (cardSize === CardSize.XXLARGE) {
    titleSize = isPhone ? SPACING.BASE4X : SPACING.BASE6X;
  }
  const titleContents = (
    <Column flexShrink style={styles.content}>
      <AutoNumberOfLinesWrapper flexShrink>
        <Text
          style={styles.flex1}
          header
          {...cardTextProps}
          size={titleSize}
          overlay
        >
          {item.card?.title}
        </Text>
      </AutoNumberOfLinesWrapper>

      <Text attribution={TextAttributionStyle.SPECIAL} overlay>
        {item?.card?.flip?.publisherName || item?.card?.provider_name}
      </Text>
    </Column>
  );
  if (cardSize === CardSize.XXLARGE) {
    return (
      <Anchor
        href={item.card.url}
        style={[
          styles.flexShrink1,
          styles.floatingTitle,
          !isPhone && styles.xlTitleContainer,
          style,
        ]}
      >
        {titleContents}
      </Anchor>
    );
  }
  return (
    <Anchor style={styles.flexShrink1} href={item.card.url}>
      <BlurView
        style={[styles.floatingTitle, styles.blurredContainer, style]}
        tint={"dark"}
      >
        {titleContents}
      </BlurView>
    </Anchor>
  );
};

const getMaxHeight = (cardSize: CardSize) => {
  switch (cardSize) {
    case CardSize.LARGE:
      return 150;
    case CardSize.XLARGE:
      return 500;
    default:
      return 150;
  }
};
const useStyles = makeStyles(
  (
    theme,
    {
      cardSize,
      position,
      distanceFromEdge,
      isPhone,
    }: {
      cardSize: CardSize;
      position: OverlayTitlePosition;
      distanceFromEdge: number;
      isPhone?: boolean;
    }
  ) =>
    createStyleSheet({
      blurredContainer: {
        borderColor: theme.colors.overlayBorder,
        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: SPACING.BASE,
        maxWidth: isPhone
          ? `calc(100% - calc(${distanceFromEdge}px * 2))`
          : `min(40%, ${480}px)`,
        maxHeight: getMaxHeight(cardSize),
      },
      xlTitleContainer: {
        maxWidth: "50%",
        maxHeight: "50%",
      },
      floatingTitle: {
        position: "absolute",
        bottom:
          [
            OverlayTitlePosition.BOTTOM_RIGHT,
            OverlayTitlePosition.BOTTOM_LEFT,
          ].includes(position) && distanceFromEdge,
        right:
          [
            OverlayTitlePosition.BOTTOM_RIGHT,
            OverlayTitlePosition.TOP_RIGHT,
          ].includes(position) && distanceFromEdge,

        left:
          [
            OverlayTitlePosition.TOP_LEFT,
            OverlayTitlePosition.BOTTOM_LEFT,
          ].includes(position) && distanceFromEdge,
        top:
          [
            OverlayTitlePosition.TOP_LEFT,
            OverlayTitlePosition.TOP_RIGHT,
          ].includes(position) && distanceFromEdge,
      },
      content: { padding: SPACING.LARGE },
    })
);

export default memo(BlurredOverlayTitle);
