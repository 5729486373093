import MultiImageSingle from "~/components/pages/MultiImageSingle";
import { isMultiImageItem } from "~/predicates";

const multiImageSingle: FC.PagePresentationStrategy = ({ items }) => {
  const multiImageItem = items.find(isMultiImageItem);
  if (multiImageItem) {
    return {
      usedItems: [multiImageItem],
      Component: MultiImageSingle,
      repeat: true,
    };
  }
  return null;
};

export default multiImageSingle;
