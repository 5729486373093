import maxBy from "lodash-es/maxBy";
import reverse from "lodash-es/reverse";
import sortBy from "lodash-es/sortBy";
import { ItemType, ScreenSizeType } from "~/enums";
import groupPresentationStrategies, {
  validLayouts,
} from "~/lib/groupPresentationStrategies";

type GroupPage = {
  group: FC.Group;
  Component: FC.PageStrategyResult["Component"];
  strategyProps?: FC.PageStrategyResult["strategyProps"];
  items: FC.PageStrategyComponentProps["items"];
  strategyName: string;
  seed: string;
};

// this will probably get a lot fancier with strategies or something,
// but this does what we need for now :p
const reorderSpecialLayouts = (pages: Array<GroupPage>): Array<GroupPage> => {
  const specialLayouts = pages.filter(
    (page) => page.strategyName === "relatedTopicFeeds"
  );
  const otherLayouts = pages.filter(
    (page) => page.strategyName !== "relatedTopicFeeds"
  );
  // mutates!
  specialLayouts.forEach((l) => otherLayouts.splice(5, 0, l));
  return otherLayouts;
};

const sortPages = (pages: Array<GroupPage>): Array<GroupPage> =>
  reorderSpecialLayouts(
    reverse(
      sortBy(pages, (page) => {
        const allItems = [
          ...page.items,
          ...page.items.flatMap((item) => item.children),
        ];
        const mostRecentItem = maxBy(allItems, (item) =>
          new Date(item.createdAt).getTime()
        );
        return mostRecentItem
          ? new Date(mostRecentItem.createdAt).getTime()
          : -1;
      })
    )
  );

interface GetGroupPagesResult {
  groupPages: Array<GroupPage>;
  leftoverItems: Array<FC.Item>;
  filters: Array<(i: FC.Item) => boolean>;
}

export const getDevelopmentPages = ({
  group,
  items,
  filters = [],
  viewableContentSize,
  devItemFilter,
  strategyNames,
}: {
  group: FC.Group;
  items: Array<FC.Item>;
  filters: Array<(i: FC.Item) => boolean>;
  viewableContentSize: ScreenSizeType;
  devItemFilter: number | false;
  strategyNames: Array<string>;
}): GetGroupPagesResult => {
  const filteredItems = devItemFilter
    ? items.filter((i) => i.type === Object.values(ItemType)[devItemFilter])
    : items;
  const filteredLayouts = validLayouts.filter((l) =>
    strategyNames.includes(l.name)
  );

  return getGroupPages({
    group,
    items: filteredItems,
    filters,
    viewableContentSize,
    presentationStrategies:
      filteredLayouts.length > 0 ? filteredLayouts : undefined,
  });
};

const getGroupPages = ({
  group,
  items,
  filters = [],
  viewableContentSize,
  presentationStrategies,
  pages = [],
  feedDebug = false,
}: {
  group: FC.Group;
  items: Array<FC.Item>;
  filters: Array<(i: FC.Item) => boolean>;
  viewableContentSize: ScreenSizeType;
  presentationStrategies?: Array<FC.PagePresentationStrategy>;
  pages?: Array<GroupPage>;
  feedDebug?: boolean;
}): GetGroupPagesResult => {
  const seed = `${group.title}${items.map((i) => i.id)}${viewableContentSize}`;
  const strategies =
    presentationStrategies ||
    groupPresentationStrategies({
      group,
      seed,
      viewableContentSize,
      feedDebug,
    });
  const result = strategies.reduce<{
    result: FC.PageStrategyResult;
    strategy: FC.PagePresentationStrategy;
  } | null>((acc, strategy) => {
    if (acc) {
      return acc;
    }
    const strategyResult = strategy({ group, seed, items });
    if (strategyResult) {
      return {
        result: strategyResult,
        strategy,
      };
    }
    return null;
  }, null);
  // we have completed
  if (!result) {
    if (items.length > 0) {
      // intentional log to point out problems with slotting items too
      // many rules / not enough layout options
      console.log("***$ leftover items", items);
    }
    return {
      groupPages: feedDebug ? pages : sortPages(pages),
      leftoverItems: items,
      filters,
    };
  }
  const {
    strategy,
    result: {
      Component,
      strategyProps,
      usedItems,
      filters: strategyFilters,
      repeat,
    },
  } = result;
  // we have a result
  pages.push({
    strategyName: strategy.name,
    seed,
    group,
    Component,
    strategyProps,
    items: usedItems,
  });

  const remainingItems = items.filter(
    (i) => !usedItems.map((j) => j.id).includes(i.id)
  );

  const updatedFilters = [...(filters || []), ...(strategyFilters || [])];

  const filteredRemainingItems = updatedFilters.reduce((acc, filter) => {
    acc = acc.filter(filter);
    return acc;
  }, remainingItems);

  const otherStrategies = strategies.filter((s) => s !== strategy);

  let newStrategies: Array<FC.PagePresentationStrategy> = [];

  // leave out this strategy next time
  if (repeat === false) {
    newStrategies = otherStrategies;
  }
  // leave this strategy in line where it was
  if (repeat === true) {
    newStrategies = strategies;
  }
  // keep strategy, but move to end
  if (repeat === undefined) {
    newStrategies = [...otherStrategies, strategy];
  }

  return getGroupPages({
    group,
    items: filteredRemainingItems,
    filters: updatedFilters,
    viewableContentSize,
    presentationStrategies: newStrategies,
    pages,
    feedDebug,
  });
};

export default getGroupPages;
