import { useEffect, useState } from "react";
import { useToast } from "react-native-toast-notifications";
import {
  useLazyGetInstanceAuthorQuery,
  useLazyGetPublicCustomFeedQuery,
  useLazySearchTopicQuery,
  useLazyGetRSSFeedQuery,
} from "~/api";
import { selectApplicationMode } from "~/concepts/application";
import { selectIsAuthenticated } from "~/concepts/auth";
import { addGroups, selectGroups } from "~/concepts/groups";
import {
  authorFeedFromAuthor,
  customFeedGroupFromCustomFeed,
  hashtagGroupFromHashtag,
  searchFeedFromTerm,
} from "~/utils/groups";

import { GroupRequestType } from "~/enums";
import { useAppDispatch, useAppSelector } from "~/hooks";
import { useGetHostCookie } from "~/hooks/useCookies";
import { useNavigate } from "~/routing";
import {
  authorRequestKeyFromRouteFeedId,
  customFeedIdFromRouteFeedId,
  hashtagIdFromRouteFeedId,
  isHashtagFeedId,
  isRSSFeedId,
  isTopicFeedId,
  isUserFeedId,
  isSearchFeedId,
  searchIdFromRouteFeedId,
  topicRequestKeyFromRouteFeedId,
  isCustomFeedId,
} from "~/utils/id";

export const useLoadMissingFeed = () => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const applicationMode = useAppSelector(selectApplicationMode);
  const groups = useAppSelector(selectGroups);
  const getHostCookie = useGetHostCookie();
  const [getTopicTrigger, getTopicResponse] = useLazySearchTopicQuery();
  const [getInstanceAuthorTrigger, getInstanceAuthorResponse] =
    useLazyGetInstanceAuthorQuery();
  const [getPublicCustomFeedTrigger, getPublicCustomFeedResponse] =
    useLazyGetPublicCustomFeedQuery();
  const [getRSSFeedTrigger, getRSSFeedResponse] = useLazyGetRSSFeedQuery();
  const [instanceAuthorToLoad, setInstanceAuthorToLoad] = useState<
    string | null
  >(null);
  const [customGroupToLoad, setCustomGroupToLoad] = useState<string | null>(
    null
  );
  const [topicGroupToLoad, setTopicGroupToLoad] = useState<string | null>(null);
  const [rssFeedToLoad, setRSSFeedToLoad] = useState<string | null>(null);
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    if (topicGroupToLoad) {
      getTopicTrigger(topicGroupToLoad);
    }
  }, [getTopicTrigger, topicGroupToLoad]);

  useEffect(() => {
    if (rssFeedToLoad) {
      getRSSFeedTrigger(rssFeedToLoad);
    }
  }, [getRSSFeedTrigger, rssFeedToLoad]);

  useEffect(() => {
    if (instanceAuthorToLoad) {
      getInstanceAuthorTrigger({
        acct: decodeURIComponent(instanceAuthorToLoad),
      });
    }
  }, [getInstanceAuthorTrigger, instanceAuthorToLoad]);

  useEffect(() => {
    if (customGroupToLoad) {
      getPublicCustomFeedTrigger(customGroupToLoad);
    }
  }, [getPublicCustomFeedTrigger, customGroupToLoad]);

  useEffect(() => {
    if (
      getPublicCustomFeedResponse.isSuccess &&
      getPublicCustomFeedResponse.data
    ) {
      dispatch(
        addGroups([
          customFeedGroupFromCustomFeed(getPublicCustomFeedResponse.data),
        ])
      );
    }
  }, [getPublicCustomFeedResponse, dispatch]);

  useEffect(() => {
    if (getInstanceAuthorResponse.isSuccess && getInstanceAuthorResponse.data) {
      dispatch(
        addGroups([
          authorFeedFromAuthor(getInstanceAuthorResponse.data, getHostCookie()),
        ])
      );
    }
  }, [getHostCookie, getInstanceAuthorResponse, dispatch]);

  useEffect(() => {
    if (getTopicResponse.isSuccess && getTopicResponse.data) {
      const topicGroup = getTopicResponse.data[0];
      if (topicGroup) {
        dispatch(addGroups([topicGroup]));
      }
    }
  }, [dispatch, getTopicResponse]);

  useEffect(() => {
    if (getRSSFeedResponse.isSuccess && getRSSFeedResponse.data) {
      if (getRSSFeedResponse.data) {
        dispatch(addGroups([getRSSFeedResponse.data]));
      }
    }
  }, [dispatch, getRSSFeedResponse]);

  return (id: FC.Group["id"]) => {
    if (
      isUserFeedId(id) &&
      // you have to be logged in to resolve users
      isAuthenticated
    ) {
      const authorGroupIsLoaded = groups.find((g) => g.id === id);
      if (!authorGroupIsLoaded && !getInstanceAuthorResponse.isLoading) {
        setInstanceAuthorToLoad(authorRequestKeyFromRouteFeedId(id));
      }
    } else if (isRSSFeedId(id)) {
      const rssFeedIsLoaded = groups.find((g) => g.id === id);
      if (!rssFeedIsLoaded && !getRSSFeedResponse.isLoading) {
        setRSSFeedToLoad(id);
      }
    } else if (isCustomFeedId(id)) {
      const customFeedId = customFeedIdFromRouteFeedId(id);
      const customGroupIsLoaded = groups.find(
        (g) => g.requestKey === customFeedId
      );
      if (!customGroupIsLoaded) {
        setCustomGroupToLoad(customFeedId);
      }
    } else if (isSearchFeedId(id)) {
      dispatch(addGroups([searchFeedFromTerm(searchIdFromRouteFeedId(id))]));
    } else if (isHashtagFeedId(id)) {
      dispatch(
        addGroups([
          hashtagGroupFromHashtag(
            hashtagIdFromRouteFeedId(id),
            applicationMode
          ),
        ])
      );
    } else if (isTopicFeedId(id)) {
      const topicIsLoaded = groups.find(
        (g) => g.requestType === GroupRequestType.TOPIC && g.requestKey === id
      );
      if (!topicIsLoaded && !getTopicResponse.isLoading) {
        setTopicGroupToLoad(topicRequestKeyFromRouteFeedId(id));
      }
    } else {
      toast.show(`Unknown feed identifier: "${id}"`, { type: "fail" });
      navigate("/");
    }
  };
};
