import React from "react";
import { createStyleSheet, SPACING } from "~/styles";
import { makeStyles } from "@rneui/themed";

import { Column } from "~/components/elements";
import { CardSize } from "~/enums";
import CardAuthor from "~/components/CardAuthor";
import ArticlePreview from "~/components/cards/ArticlePreview";
import Reboosts from "~/components/cardComponents/Reboosts";

export type ArticlePreviewBasicLinkProps = {
  item: FC.Item;
  cardSize?: CardSize;
};

const ArticlePreviewBasicLink: React.FC<ArticlePreviewBasicLinkProps> = ({
  item,
  cardSize,
}) => {
  const styles = useStyles({ cardSize });
  if (!item.card) return null;

  return (
    <Column flex style={styles.container}>
      <CardAuthor item={item} />
      <ArticlePreview
        flexShrink
        item={item}
        cardSize={cardSize}
        showExtractText
      />
      <Reboosts item={item} />
    </Column>
  );
};

const cardMargin = (cardSize?: CardSize) => {
  switch (cardSize) {
    case CardSize.SMALL:
      return { margin: SPACING.BASE3X };
    case CardSize.MEDIUM:
      return { margin: SPACING.BASE4X };
    case CardSize.LARGE:
    default:
      return { margin: SPACING.BASE5X };
  }
};

const useStyles = makeStyles(
  (
    _theme,
    {
      cardSize,
    }: {
      cardSize?: CardSize;
    }
  ) =>
    createStyleSheet({
      container: {
        ...cardMargin(cardSize),
      },
      articleClickTarget: {
        width: "100%",
      },
    })
);

export default ArticlePreviewBasicLink;
