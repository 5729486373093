import React, { memo } from "react";
import Login from "~/components/Login";
import { Text, Column, Row } from "~/components/elements";
import { makeStyles } from "@rneui/themed";
import { createStyleSheet } from "~/styles";
import { selectIsAuthenticated } from "~/concepts/auth";
import { isPhoneSelector } from "~/concepts/application";
import { useAppSelector } from "~/hooks";
import Signout from "~/components/ProfileActions/Signout";

const BetaLoginGate: React.FC = () => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const isPhone = useAppSelector(isPhoneSelector);
  const styles = useStyles({ isPhone });

  return (
    <Column style={styles.betaLoginGate}>
      {isAuthenticated ? (
        <>
          <Text error>You do not have access</Text>
          <Row>
            <Signout overlay={false} />
          </Row>
        </>
      ) : (
        <Login />
      )}
    </Column>
  );
};

const useStyles = makeStyles((theme, { isPhone }: { isPhone: boolean }) => {
  return createStyleSheet({
    betaLoginGate: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      ...(!isPhone
        ? {
            marginTop: 100,
            marginBottom: 100,
            marginRight: 300,
            marginLeft: 300,
          }
        : {}),
    },
  });
});

export default memo(BetaLoginGate);
