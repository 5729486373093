import React from "react";
import OverflowMenuButton from "~/components/elements/OverflowMenuButton";
import { useAppSelector } from "~/hooks";
import { selectCredentials } from "~/concepts/auth";
import { idFromAuthorUrl } from "~/utils/id";
import { useNavigate } from "~/routing";

const MyPosts: React.FC<{ overlay: boolean; onPress?: () => void }> = ({
  overlay,
  onPress,
}) => {
  const credentials = useAppSelector(selectCredentials);
  const navigate = useNavigate();
  return credentials?.uri ? (
    <OverflowMenuButton
      title="My Posts"
      overlay={overlay}
      onPress={() => {
        navigate(`/feed/${idFromAuthorUrl(credentials.uri)}`);
        onPress?.();
      }}
    />
  ) : null;
};

export default MyPosts;
