import React, { memo } from "react";
import { COLORS } from "~/styles";
import {
  useFavoriteStatusMutation,
  useUnfavoriteStatusMutation,
  useBookmarkStatusMutation,
  useUnbookmarkStatusMutation,
  useReblogStatusMutation,
  useUnreblogStatusMutation,
} from "~/api";
import ToggleButton from "~/components/ToggleButton";
import { selectCredentials } from "~/concepts/auth";
import { useAppSelector } from "~/hooks";
import ToastMessageWithAuthor from "~/components/ToastMessageWithAuthor";

type PrimaryActionButtonsProps = {
  item?: FC.Item;
  replyAction?: (value: boolean) => void;
  fromThread?: boolean;
  overlay?: boolean;
  showButtonLabels?: boolean;
  error?: boolean;
  showCount?: boolean;
};
const toggleButtonCountTitle = (count: number | undefined) => {
  if (count !== undefined && count > 0) {
    return count.toString();
  }
  return undefined;
};
const PrimaryActionButtons: React.FC<PrimaryActionButtonsProps> = ({
  item,
  fromThread,
  overlay = false,
  showButtonLabels = false,
  error = false,
  showCount = false,
}) => {
  const authenticated = useAppSelector(selectCredentials);
  const disabled = !authenticated;

  return (
    <>
      {(!item || item?.capabilities.canFavorite) && (
        <ToggleButton
          showButtonLabels={showButtonLabels}
          title={
            showCount
              ? toggleButtonCountTitle(item?.counts.favorites)
              : "Favorite"
          }
          mutationArgument={item}
          onSuccessToast={
            <ToastMessageWithAuthor
              author={item?.author}
              preText={"You have favorited a post by "}
            />
          }
          offSuccessToast={
            <ToastMessageWithAuthor
              author={item?.author}
              preText={"You have unfavorited a post by "}
            />
          }
          onMutation={useFavoriteStatusMutation}
          offMutation={useUnfavoriteStatusMutation}
          onIcon="star"
          offIcon="star-outline"
          value={item?.isFavorited}
          color={COLORS.FAVORITE}
          disabled={disabled}
          overlay={overlay}
          fromThread={fromThread}
          error={error}
        />
      )}
      {(!item || item?.capabilities?.canBookmark) && (
        <ToggleButton
          showButtonLabels={showButtonLabels}
          title={showCount ? undefined : "Bookmark"}
          mutationArgument={item}
          onSuccessToast={
            <ToastMessageWithAuthor
              author={item?.author}
              preText="You have bookmarked a post by "
            />
          }
          offSuccessToast={
            <ToastMessageWithAuthor
              author={item?.author}
              preText="You have unbookmarked a post by "
            />
          }
          onMutation={useBookmarkStatusMutation}
          offMutation={useUnbookmarkStatusMutation}
          onIcon="bookmark"
          offIcon="bookmark-outline"
          value={item?.isBookmarked}
          color={COLORS.BOOKMARK}
          disabled={disabled}
          overlay={overlay}
          fromThread={fromThread}
          error={error}
        />
      )}
      {(!item || item?.capabilities?.canBoost) && (
        <ToggleButton
          showButtonLabels={showButtonLabels}
          title={
            showCount ? toggleButtonCountTitle(item?.counts.reblogs) : "Boost"
          }
          mutationArgument={item}
          onSuccessToast={
            <ToastMessageWithAuthor
              author={item?.author}
              preText="You have boosted a post by "
            />
          }
          offSuccessToast={
            <ToastMessageWithAuthor
              author={item?.author}
              preText="You have unboosted a post by "
            />
          }
          onMutation={useReblogStatusMutation}
          offMutation={useUnreblogStatusMutation}
          onIcon="repeat"
          offIcon="repeat"
          value={item?.isReblogged}
          color={COLORS.REBLOG}
          disabled={disabled}
          overlay={overlay}
          fromThread={fromThread}
          error={error}
        />
      )}
    </>
  );
};

export default memo(PrimaryActionButtons);
