export const parseExpirationDate = (isoDateString: string) => {
  const currentTime = new Date();
  const targetTime = new Date(isoDateString);
  const timeDifference = targetTime.getTime() - currentTime.getTime();

  const daysLeft = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hoursLeft = Math.floor(
    (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );

  let remainingTime = null;
  let timeUnit = "";

  if (Math.abs(daysLeft) > 0) {
    remainingTime = daysLeft;
    timeUnit = " days";
  } else if (Math.abs(hoursLeft) > 0) {
    remainingTime = hoursLeft;
    timeUnit = " hours";
  }

  return remainingTime !== null
    ? `${Math.abs(remainingTime)}${timeUnit} ${
        remainingTime > 0 ? "left" : "ago"
      }`
    : "";
};

export const secondsToMinutesString = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const secondsLeft = seconds % 60;

  return `${minutes}:${secondsLeft < 10 ? "0" : ""}${secondsLeft}`;
};
