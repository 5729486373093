import { makeStyles } from "@rneui/themed";
import React, { memo } from "react";
import { Platform, StyleProp, useWindowDimensions } from "react-native";
import * as Tooltip from "universal-tooltip";
import { DRAWER_SIDE } from "~/enums";
import { createStyleSheet } from "~/styles";
import Pressable from "~/components/elements/Pressable";
import Animated, {
  SlideInLeft,
  SlideInRight,
  SlideOutLeft,
  SlideOutRight,
} from "react-native-reanimated";

const Drawer: React.FC<{
  children: React.ReactNode;
  open: boolean;
  side?: DRAWER_SIDE;

  setOpen: (arg: boolean) => void;
  style?: StyleProp<ViewStyle>;
}> = ({ children, open, side = DRAWER_SIDE.RIGHT, setOpen }) => {
  const styles = useStyles();
  const dimensions = useWindowDimensions();
  return (
    <Tooltip.Root
      open={open}
      onOpenChange={() => setOpen(!open)}
      usePopover={Platform.OS === "web"}
    >
      <Tooltip.Portal>
        <Tooltip.Content
          onTap={() => {}}
          dismissDuration={500}
          presetAnimation="fadeIn"
        >
          <Pressable
            flex
            onPress={() => setOpen(false)}
            style={[
              styles.drawerBackdrop,
              {
                height: dimensions?.height,
                width: dimensions?.width,
              },
            ]}
          >
            <Animated.View
              style={[
                side === DRAWER_SIDE.LEFT ? styles.alignStart : styles.alignEnd,
                styles.drawerWrapper,
                {
                  width: dimensions?.width,
                },
              ]}
              pointerEvents={"box-none"}
              entering={side === DRAWER_SIDE.LEFT ? SlideInLeft : SlideInRight}
              exiting={side === DRAWER_SIDE.LEFT ? SlideOutLeft : SlideOutRight}
            >
              {children}
            </Animated.View>
          </Pressable>
        </Tooltip.Content>
      </Tooltip.Portal>
    </Tooltip.Root>
  );
};

export default memo(Drawer);

const useStyles = makeStyles((theme) =>
  createStyleSheet({
    drawerWrapper: {},
    drawerBackdrop: {
      position: "absolute",
      left: 0,
      right: 0,
      backgroundColor: theme.colors.backgroundReverse50,
    },
  })
);
