import React, { memo, useEffect } from "react";
import { createStyleSheet, SPACING, STYLE_VALUES } from "~/styles";
import { ButtonGroup, CheckBox, makeStyles, useTheme } from "@rneui/themed";
import {
  CollapsibleAnimatedView,
  Text,
  Column,
  Row,
  Divider,
  Button,
} from "~/components/elements";
import {
  useGetDebugCookie,
  useGetDevCardFilterCookie,
  useGetDevLayoutFilterCookie,
  useGetDevelopmentCookie,
  useGetFeatureFlagsCookie,
  useSetDebugCookie,
  useSetDevCardFilterCookie,
  useSetDevLayoutFilterCookie,
} from "~/hooks/useCookies";
import { FeatureFlags, ItemType } from "~/enums";
import { ScrollView } from "react-native";
import { validLayouts } from "~/lib/groupPresentationStrategies";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { useAppSelector } from "~/hooks";
import FeatureFlagControls from "~/components/FeatureFlagControls";
import { isEqual } from "lodash-es";
import Hoverable from "~/components/elements/Hoverable";
import { selectScreenDimensions } from "~/concepts/application";
import IconButton from "~/buttons/IconButton";
import { useSearchParams } from "~/routing";

const DebugControls: React.FC<{}> = () => {
  const styles = useStyles();
  const { theme } = useTheme();

  const [showDevControls, setShowDevControls] = React.useState(false);
  const [devPlaygroundOnly, setDevPlaygroundOnly] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(
    useGetDevCardFilterCookie()()
  );
  const [expandLayouts, setExpandLayouts] = React.useState(false);

  const dimensions = useAppSelector(selectScreenDimensions);
  const setDevCardFilterCookie = useSetDevCardFilterCookie();

  const getDebugCookie = useGetDebugCookie();
  const debugCookie = getDebugCookie();
  const setDebugCookie = useSetDebugCookie();
  const [searchParams] = useSearchParams();

  const featureFlags = useGetFeatureFlagsCookie()();
  const devMode = useGetDevelopmentCookie()();

  const selectedStrategies = useGetDevLayoutFilterCookie()();
  const setSelectedStrategies = useSetDevLayoutFilterCookie();
  const [debugPresentationStrategies, setDebugPresentationStrategies] =
    React.useState([] as Array<string>);

  const updateSelectedStrategies = (layout: string, checked: boolean) => {
    const updatedStrategies = checked
      ? debugPresentationStrategies.concat(layout)
      : debugPresentationStrategies.filter((l) => l !== layout);

    setSelectedStrategies(updatedStrategies);
  };

  useEffect(() => {
    if (searchParams.get("debug") !== null) {
      if (!debugCookie) {
        setDebugCookie("debuggin");
      }
    }
  }, [searchParams, debugCookie, setDebugCookie]);

  useEffect(() => {
    if (!isEqual(debugPresentationStrategies, selectedStrategies)) {
      setDebugPresentationStrategies(selectedStrategies);
    }
  }, [debugPresentationStrategies, selectedStrategies]);

  useEffect(() => {
    setDevPlaygroundOnly(featureFlags[FeatureFlags.DEBUG_DEV_PLAYGROUND]);
  }, [featureFlags]);

  const setFilter = (index: number) => {
    setSelectedIndex(index);
    setDevCardFilterCookie(index);
  };

  if (!debugCookie || !devMode) return null;

  return (
    <Hoverable style={styles.devControlsWrapper}>
      {(isHovered) => {
        if (isHovered) {
          setShowDevControls(true);
        } else {
          setTimeout(() => {
            setShowDevControls(false);
          }, 1000);
        }
        return (
          <Column
            style={[
              styles.devControls,
              showDevControls && { padding: SPACING.BASE },
            ]}
          >
            <Row
              style={[
                styles.alignCenter,
                styles.justifyCenter,
                styles.noBackground,
              ]}
            >
              <Icon
                name={showDevControls ? "eye" : "eye-plus-outline"}
                color={theme.colors.primary}
                size={24}
              />
            </Row>
            <CollapsibleAnimatedView show={showDevControls} fade nullOnHidden>
              <Column>
                <Column noGap>
                  <FeatureFlagControls />
                </Column>
                <Divider />
                {devPlaygroundOnly ? (
                  <Column noGap style={styles.borderedFormItem}>
                    <Text
                      style={[
                        {
                          paddingHorizontal: SPACING.BASE,
                        },
                        styles.formLabel,
                        styles.devText,
                      ]}
                    >
                      Filter Items:
                    </Text>
                    <ButtonGroup
                      buttons={Object.values(ItemType)}
                      selectedIndex={selectedIndex}
                      onPress={setFilter}
                      buttonContainerStyle={{ borderRadius: SPACING.LARGE }}
                      containerStyle={[
                        {
                          gap: SPACING.BASE,
                        },
                      ]}
                      innerBorderStyle={{ width: SPACING.NONE }}
                      buttonStyle={styles.filterButton}
                      selectedButtonStyle={styles.filterButtonSelected}
                      textStyle={[styles.filterButtonText, styles.devText]}
                    />
                  </Column>
                ) : (
                  featureFlags[FeatureFlags.DEBUG_FILTER_LAYOUTS] && (
                    <Column noGap style={styles.borderedFormItem}>
                      <Row
                        style={[
                          styles.evenlySpaced,
                          styles.alignCenter,
                          {
                            paddingHorizontal: SPACING.BASE,
                            backgroundColor: theme.colors.background,
                          },
                        ]}
                      >
                        <Text style={[styles.formLabel, styles.devText]}>
                          Filter Layouts:
                        </Text>
                        <Button
                          type={"clear"}
                          onPress={() => {
                            setSelectedStrategies([]);
                          }}
                        >
                          <Text style={[styles.devText]}>
                            Clear Filters (Show All)
                          </Text>
                        </Button>
                      </Row>
                      <Divider />
                      <ScrollView
                        style={[
                          styles.layoutList,
                          expandLayouts && {
                            maxHeight: dimensions.height / 2,
                            height: STYLE_VALUES.UNSET,
                          },
                        ]}
                      >
                        {validLayouts.map((layout) => {
                          const checked = debugPresentationStrategies.includes(
                            layout.name
                          );
                          return (
                            <CheckBox
                              key={`${layout.name}-checkbox`}
                              title={layout.name}
                              containerStyle={{
                                padding: SPACING.NONE,
                                marginVertical: SPACING.SMALL,
                              }}
                              textStyle={{ color: theme.colors.primary }}
                              checked={checked}
                              onPress={() =>
                                updateSelectedStrategies(layout.name, !checked)
                              }
                              checkedIcon={
                                <Icon name="checkbox-marked" solid size={16} />
                              }
                              uncheckedIcon={
                                <Icon name="checkbox-blank-outline" size={16} />
                              }
                            />
                          );
                        })}
                      </ScrollView>
                      <Divider />
                      <Row style={[styles.layoutListFooter]}>
                        <IconButton
                          icon={
                            expandLayouts
                              ? "arrow-collapse-vertical"
                              : "arrow-expand-vertical"
                          }
                          onPress={() => setExpandLayouts(!expandLayouts)}
                          style={[
                            styles.fullWidth,
                            { backgroundColor: theme.colors.background },
                          ]}
                        />
                      </Row>
                    </Column>
                  )
                )}
              </Column>
            </CollapsibleAnimatedView>
          </Column>
        );
      }}
    </Hoverable>
  );
};

const useStyles = makeStyles((theme) => {
  return createStyleSheet({
    devControlsWrapper: {
      height: "100%",
    },
    devControls: {
      position: "absolute",
      top: 0,
      right: 0,
      zIndex: 99999,
      paddingVertical: SPACING.XSMALL,
      paddingHorizontal: SPACING.BASE,
      borderWidth: 1,
      borderColor: theme.colors.debugPrimary,
      borderRadius: SPACING.BASE,
      backgroundColor: theme.colors.backgroundOverlay,
      overflow: "hidden",
    },
    devControlsDark: {
      backgroundColor: theme.colors.backgroundOverlayReverse,
    },
    borderedFormItem: {
      borderWidth: 1,
      borderColor: theme.colors.debugPrimary,
      borderRadius: SPACING.BASE,
      overflow: "hidden",
      backgroundColor: theme.colors.background,
    },
    closeButton: {
      position: "absolute",
      top: SPACING.NONE,
      right: SPACING.NONE,
      zIndex: 1,
      borderRadius: SPACING.BASE,
    },
    layoutList: {
      width: 400,
      height: 150,
      backgroundColor: theme.colors.background,
    },
    layoutListFooter: {
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    devText: { color: theme.colors.debugPrimary },
    devTextDark: { color: "tomato" },
    filterButton: {
      padding: SPACING.SMALL,
      minWidth: SPACING.BASE10X,
      height: SPACING.BASE3X,
      borderWidth: 1,
      borderColor: theme.colors.dividerPrimary,
      borderRadius: SPACING.BASE,
      backgroundColor: theme.colors.background,
    },
    filterButtonSelected: {
      backgroundColor: theme.colors.debugPrimary,
    },
    filterButtonText: { fontSize: SPACING.MEDIUM, textAlign: "center" },
  });
});

export default memo(DebugControls);
