import React, { memo } from "react";
import { createStyleSheet, SPACING, LAYOUT } from "~/styles";
import { Column } from "~/components/elements";
import { makeStyles, useTheme } from "@rneui/themed";
import Content from "~/components/Content";
import CardAuthor from "~/components/CardAuthor";
import AutoNumberOfLinesWrapper from "~/components/elements/AutoNumberOfLinesWrapper";
import { CardSize } from "~/enums";
import { BackgroundColorContext } from "~/contexts/BackgroundColorContext";
import Reboosts from "~/components/cardComponents/Reboosts";

export type StatusCardProps = {
  item: FC.Item;
  cardSize?: CardSize;
  overlay?: boolean;
  isJustifyTop?: boolean;
  margin?: number;
  padding?: number;
};

const StatusCard: React.FC<StatusCardProps> = ({
  item,
  cardSize,
  overlay,
  isJustifyTop = false,
  margin,
  padding,
}) => {
  const styles = useStyles({ margin, padding, cardSize, isJustifyTop });
  const { theme } = useTheme();

  return (
    <Column flex gap={SPACING.BASE} style={styles.statusCard}>
      <Column flex style={styles.statusCardInner}>
        <BackgroundColorContext.Provider
          value={theme.colors.backgroundSecondary}
        >
          <CardAuthor item={item} overlay={overlay} />
          <AutoNumberOfLinesWrapper style={styles.contentWrapperStyle}>
            <Content
              item={item}
              textSize={fontSizeFromCardSize(cardSize)}
              overlay={overlay}
            />
          </AutoNumberOfLinesWrapper>
        </BackgroundColorContext.Provider>
        <Reboosts item={item} />
      </Column>
    </Column>
  );
};

const fontSizeFromCardSize = (cardSize?: CardSize) => {
  switch (cardSize) {
    case CardSize.MEDIUM:
      return 20;
    case CardSize.LARGE:
      return 24;
    case CardSize.XLARGE:
      return 32;
    case CardSize.XXLARGE:
      return 36;
    default:
      return 16;
  }
};
const getCardPadding = (cardSize?: CardSize) => {
  switch (cardSize) {
    case CardSize.SMALL:
      return SPACING.LARGE;
    case CardSize.MEDIUM:
      return SPACING.BASE4X;
    case CardSize.LARGE:
      return SPACING.BASE5X;
    case CardSize.XLARGE:
      return SPACING.BASE6X;
    default:
      return SPACING.BASE3X;
  }
};

const useStyles = makeStyles(
  (
    theme,
    {
      margin,
      padding,
      cardSize,
      isJustifyTop,
    }: {
      margin?: number;
      padding?: number;
      cardSize?: CardSize;
      isJustifyTop: boolean;
    }
  ) =>
    createStyleSheet({
      statusCard: {
        margin: margin !== undefined ? margin : SPACING.BASE3X,
        justifyContent: isJustifyTop ? "flex-start" : "center",
        alignContent: "center",
      },
      statusCardInner: {
        borderRadius: SPACING.BASE,
        padding: padding !== undefined ? padding : getCardPadding(cardSize),
        justifyContent: "center",
        backgroundColor: theme.colors.backgroundSecondary,
      },
      statusOverlayCard: {
        width: LAYOUT.DEFAULT_MODAL_WIDTH,
        gap: SPACING.BASE,
        overflow: "hidden",
        padding: SPACING.BASE3X,
      },
      statusOverlay: {
        width: "fit-content",
        height: "fit-content",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "grey",
      },
      cardBody: {
        flex: 1,
        height: "100%",
        overflow: "hidden",
        gap: SPACING.BASE,
      },
      reblogCardStyle: {
        overflow: "hidden",
        height: "fit-content",
        padding: SPACING.SMALL,
        margin: SPACING.SMALL,
      },
      contentWrapperStyle: {
        ...(isJustifyTop && {
          paddingTop: SPACING.BASE3X,
        }),
      },
      reblogCardInnerStyle: {},
      overlayContents: {
        flex: 1,
        position: "absolute",
        bottom: 0,
        height: SPACING.BASE6X,
        width: "100%",
        zIndex: 11,
        justifyContent: "flex-end",
      },
    })
);
export default memo(StatusCard);
