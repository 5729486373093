import React, { memo, useCallback, useEffect, useState } from "react";
import { LAYOUT, SPACING, createStyleSheet } from "~/styles";
import { makeStyles } from "@rneui/themed";
import { useAppDispatch, useAppSelector } from "~/hooks";
import {
  isPhoneSelector,
  setModalOpen,
  selectModalOpen,
} from "~/concepts/application";
import { Column, Drawer, Row } from "~/components/elements";
import Thread from "~/components/Thread";
import {
  getRequestCloseItemFocusDrawer,
  getShownReplyItem,
  getRequestedReplyItemChange,
  setRequestCloseItemFocusDrawer,
  setCurrentAttachmentIndex,
  getPreviewItem,
  clearPreviewItem,
} from "~/concepts/thread";
import { ScaledSize, useWindowDimensions } from "react-native";
import CloseButton from "~/buttons/CloseButton";
import ThreadMediaPreview from "~/components/cardComponents/ThreadMediaPreview";
import {
  isFirstPartyVideo,
  isImageAttachmentItem,
  isYouTube,
} from "~/predicates";
import IconButton from "~/buttons/IconButton";

const CONTROL_BAR_HEIGHT = SPACING.BASE6X;
const CONVERSATION_DRAWER_WIDTH = "40%";
const CONVERSATION_DRAWER_MAX_WIDTH = 500;
import { useThreadItemNavigation } from "~/hooks/useThreadItemNavigation";
import useEscClose from "~/hooks/useEscClose";
import { DRAWER_SIDE } from "~/enums";

const ItemFocusDrawer: React.FC = () => {
  const dispatch = useAppDispatch();
  const modalOpen = useAppSelector(selectModalOpen);
  const currentPreviewItem = useAppSelector(getPreviewItem);
  const isPhone = useAppSelector(isPhoneSelector);
  const dimensions = useWindowDimensions();
  const { currentThreadItem, navigateAwayFromThread } =
    useThreadItemNavigation();
  const [fullScreenMedia, setFullScreenMedia] = useState(false);
  const [showPreviewPane, setShowPreviewPane] = useState(false);
  const [minimizePreviewPane, setMinimizePreviewPane] = useState(false);

  const requestedCloseItemFocusDrawer = useAppSelector(
    getRequestCloseItemFocusDrawer
  );
  const shownReplyItem = useAppSelector(getShownReplyItem);
  const requestedReplyItemChange = useAppSelector(getRequestedReplyItemChange);
  const open = useCallback(() => {
    dispatch(setModalOpen(true));
  }, [dispatch]);
  const close = useCallback(() => {
    if (!modalOpen) {
      return;
    }
    /**
     * Set a request to close the drawer if their is a shown reply item, to give that ReplyForm
     * the chance to confirm closure.
     */
    if (currentThreadItem && (shownReplyItem || requestedReplyItemChange)) {
      dispatch(setRequestCloseItemFocusDrawer(true));
    } else {
      if (currentThreadItem) {
        navigateAwayFromThread();
        dispatch(setRequestCloseItemFocusDrawer(false));
        dispatch(setModalOpen(false));
        dispatch(setCurrentAttachmentIndex(0));
      }
      if (currentPreviewItem) {
        dispatch(clearPreviewItem());
      }
    }
  }, [
    modalOpen,
    currentThreadItem,
    shownReplyItem,
    requestedReplyItemChange,
    dispatch,
    currentPreviewItem,
    navigateAwayFromThread,
  ]);
  useEscClose(close);

  useEffect(() => {
    if (currentThreadItem || currentPreviewItem) {
      open();
    } else {
      close();
    }
  }, [currentPreviewItem, currentThreadItem, close, open]);

  /**
   * If the drawer is open, and there is no shown reply item, close the drawer.
   * (Reply Forms have closed themselves)
   */
  useEffect(() => {
    if (
      requestedCloseItemFocusDrawer &&
      !shownReplyItem &&
      !requestedReplyItemChange
    ) {
      close();
    }
  }, [
    requestedCloseItemFocusDrawer,
    shownReplyItem,
    requestedReplyItemChange,
    close,
    dispatch,
  ]);

  useEffect(() => {
    const newShowPreviewPane =
      (currentPreviewItem &&
        (isImageAttachmentItem(currentPreviewItem) ||
          isYouTube(currentPreviewItem) ||
          isFirstPartyVideo(currentPreviewItem))) ||
      false;

    setShowPreviewPane(newShowPreviewPane);
    if (!currentThreadItem && newShowPreviewPane) {
      setFullScreenMedia(true);
    }
  }, [currentPreviewItem, currentThreadItem]);

  const styles = useStyles({ showPreviewPane, dimensions });
  if (!currentThreadItem && !currentPreviewItem) {
    return null;
  }

  const ContentsWrapper = isPhone ? Column : Row;

  const renderCloseButton = () => {
    return (
      <Row
        style={[
          styles.threadWrapperControls,
          !isPhone &&
            !fullScreenMedia && {
              width: `min(${CONVERSATION_DRAWER_WIDTH}, 
              ${CONVERSATION_DRAWER_MAX_WIDTH}px)`,
            },
        ]}
      >
        <CloseButton
          buttonStyle={{
            paddingVertical: SPACING.BASE,
            paddingHorizontal: SPACING.BASE,
          }}
          onPress={close}
          overlay={
            (isPhone || fullScreenMedia) &&
            !minimizePreviewPane &&
            showPreviewPane
          }
        />
      </Row>
    );
  };
  const renderControlBar = () => (
    <Row noGap style={styles.threadControlRow}>
      {showPreviewPane && currentThreadItem && (
        <Row
          style={[
            styles.threadWrapperControls,
            (!minimizePreviewPane || !isPhone) &&
              styles.threadWrapperControlsOverlay,
          ]}
        >
          {isPhone && (
            <IconButton
              buttonStyle={{
                paddingVertical: SPACING.BASE,
                paddingHorizontal: SPACING.BASE,
              }}
              onPress={() => {
                setMinimizePreviewPane(!minimizePreviewPane);
                setFullScreenMedia(false);
              }}
              icon={
                minimizePreviewPane ? "arrow-expand-down" : "arrow-collapse-up"
              }
              overlay={!minimizePreviewPane}
            />
          )}
          <IconButton
            buttonStyle={{
              paddingVertical: SPACING.BASE,
              paddingHorizontal: SPACING.BASE,
            }}
            onPress={() => {
              setMinimizePreviewPane(false);
              setFullScreenMedia(!fullScreenMedia);
            }}
            icon={fullScreenMedia ? "arrow-collapse" : "arrow-expand"}
            overlay={!minimizePreviewPane}
          />
          {(fullScreenMedia || isPhone) && renderCloseButton()}
        </Row>
      )}
    </Row>
  );

  const renderThreadContents = () => (
    <>
      {showPreviewPane && (
        <Column
          flex
          noGap
          style={[
            styles.threadMediaWrapper,
            isPhone && {
              minHeight:
                dimensions?.height * (fullScreenMedia ? 1 : 0.6) -
                LAYOUT.HEADER_HEIGHT -
                CONTROL_BAR_HEIGHT,
              paddingTop: CONTROL_BAR_HEIGHT,
            },
            minimizePreviewPane && styles.hidden,
          ]}
          pointerEvents={"box-none"}
        >
          {renderControlBar()}
          <ThreadMediaPreview isFullscreen={fullScreenMedia} />
        </Column>
      )}
      <Column
        flex
        style={[
          styles.commentThreadWrapper,
          isPhone && styles.commentThreadWrapperMobile,
          (!isPhone || !showPreviewPane || minimizePreviewPane) &&
            styles.commentThreadWrapperTopPadding,
          fullScreenMedia && styles.hidden,
        ]}
      >
        {isPhone ? (
          minimizePreviewPane && renderControlBar()
        ) : (
          <Row noGap style={[styles.threadControlRow]}>
            {renderCloseButton()}
          </Row>
        )}
        {currentThreadItem && (
          <Thread item={currentThreadItem} onClose={close} />
        )}
      </Column>
    </>
  );

  return (
    <Drawer
      side={DRAWER_SIDE.RIGHT}
      open={!!currentThreadItem || showPreviewPane}
      setOpen={(o) => (o ? open() : close())}
    >
      <ContentsWrapper
        noGap
        style={[
          isPhone ? styles.drawerContentsMobile : styles.drawerContentsDesktop,
          {
            height: dimensions?.height,
          },
        ]}
      >
        {renderThreadContents()}
      </ContentsWrapper>
    </Drawer>
  );
};

const useStyles = makeStyles(
  (
    theme,
    {
      showPreviewPane,
    }: {
      showPreviewPane: boolean;
      dimensions: ScaledSize;
    }
  ) =>
    createStyleSheet({
      drawerWrapper: {
        top: 0,
        right: 0,
      },
      drawerContentsDesktop: {
        width: showPreviewPane
          ? "100%"
          : `min(${CONVERSATION_DRAWER_WIDTH},${CONVERSATION_DRAWER_MAX_WIDTH}px)`,
        top: LAYOUT.HEADER_HEIGHT,
      },
      drawerContentsMobile: {
        width: "100%",
      },

      threadControlRow: {
        position: "absolute",
        top: 0,
        width: "100%",
        zIndex: 10,
        justifyContent: "flex-end",
      },
      threadWrapperControlsOverlay: {
        backgroundColor: theme.colors.backgroundReverse20,
      },
      threadWrapperControls: {
        justifyContent: "flex-end",
        backgroundColor: "transparent",
      },
      threadMediaWrapper: {
        backgroundColor: "transparent",
        height: `calc(100% - ${LAYOUT.HEADER_HEIGHT}px)`,
        margin: SPACING.BASE,
      },
      commentThreadWrapper: {
        width: showPreviewPane ? CONVERSATION_DRAWER_WIDTH : "100%",
        maxWidth: showPreviewPane ? CONVERSATION_DRAWER_MAX_WIDTH : "unset",
        backgroundColor: theme.colors.background,
        height: `calc(100% - ${LAYOUT.HEADER_HEIGHT}px)`,
        paddingTop: 0,
      },
      commentThreadWrapperMobile: {
        width: "100%",
        maxWidth: "unset",
      },
      commentThreadWrapperTopPadding: {
        paddingTop: CONTROL_BAR_HEIGHT,
      },
    })
);

export default memo(ItemFocusDrawer);
