import {
  // GroupPresentationType,
  ScreenSizeType,
} from "~/enums";
// import relatedTopicFeeds from "~/groupPagePresentationStrategies/relatedTopicFeeds";
// import basic from "~/groupPagePresentationStrategies/basic";
import basicPhone from "~/groupPagePresentationStrategies/basicPhone";
// import overUnderPhone from "~/groupPagePresentationStrategies/overUnderPhone";
// import catchAll from "~/groupPagePresentationStrategies/catchAll";
// import catchAllWide from "~/groupPagePresentationStrategies/catchAllWide";
import vigetTwoUp from "~/groupPagePresentationStrategies/vigetTwoUp";
import vigetTwoUpSymmetric from "~/groupPagePresentationStrategies/vigetTwoUpSymmetric";
import vigetTwoUpRestrictive from "~/groupPagePresentationStrategies/vigetTwoUpRestrictive";
// import featureVideoArticles from "~/groupPagePresentationStrategies/featureVideoArticles";
// import featureVideoArticleStatusWide from "~/groupPagePresentationStrategies/featureVideoArticleStatusWide";
// import featureAndTextArticles from "~/groupPagePresentationStrategies/featureAndTextArticles";
// import featureAndTextArticlesWide from "~/groupPagePresentationStrategies/featureAndTextArticlesWide";
// import featureLeftAndArticlesWide from "~/groupPagePresentationStrategies/featureLeftAndArticlesWide";
// import featureBigAndArticlesWide from "~/groupPagePresentationStrategies/featureBigAndArticlesWide";
// import featurePhotoAndArticlesWide from "~/groupPagePresentationStrategies/featurePhotoAndArticlesWide";
// import featurePhoto from "~/groupPagePresentationStrategies/featurePhoto";
// import imageStaggered from "~/groupPagePresentationStrategies/imageStaggered";
// import photoCardOneUpBlackBackground from "~/groupPagePresentationStrategies/photoCardOneUpBlackBackground";
// import articlesConversationWide from "~/groupPagePresentationStrategies/articlesConversationWide";
// import articlesConversation from "~/groupPagePresentationStrategies/articlesConversation";
// import featureSmallMixedWide from "~/groupPagePresentationStrategies/featureSmallMixedWide";
// import featureSmallMixed from "~/groupPagePresentationStrategies/featureSmallMixed";
// import basicWide from "~/groupPagePresentationStrategies/basicWide";
// import galleryPortraitCatchAllWide from "~/groupPagePresentationStrategies/galleryPortraitCatchAllWide";
// import galleryPortraitCatchAll from "~/groupPagePresentationStrategies/galleryPortraitCatchAll";
// import galleryDoubleLandscapeFeatureMiddleConversation from "~/groupPagePresentationStrategies/galleryDoubleLandscapeFeatureMiddleConversation";
// import galleryDoublePortraitFeatureMiddleConversation from "~/groupPagePresentationStrategies/galleryDoublePortraitFeatureMiddleConversation";
// import galleryDoublePortraitFeatureConversationWide from "~/groupPagePresentationStrategies/galleryDoublePortraitFeatureConversationWide";
// import galleryDoublePortraitFeatureMiddle from "~/groupPagePresentationStrategies/galleryDoublePortraitFeatureMiddle";
// import galleryBasic from "~/groupPagePresentationStrategies/galleryBasic";
import multiImageSingle from "~/groupPagePresentationStrategies/multiImageSingle";
import vigetGalleryThreeUp from "~/groupPagePresentationStrategies/vigetGalleryThreeUp";
import vigetGalleryOneUp from "~/groupPagePresentationStrategies/vigetGalleryOneUp";
// import galleryLargePortraitImagePhotoColumnConversation from "~/groupPagePresentationStrategies/galleryLargePortraitImagePhotoColumnConversation";
// import galleryLargePortraitImageGraphicColumnConversation from "~/groupPagePresentationStrategies/galleryLargePortraitImageGraphicColumnConversation";
// import vigetFiveUp from "~/groupPagePresentationStrategies/vigetFiveUp";
// import vigetThreeUp from "~/groupPagePresentationStrategies/vigetThreeUp";
import vigetThreeUpSocialArticles from "~/groupPagePresentationStrategies/vigetThreeUpSocialArticles";
import vigetThreeUpStatuses from "~/groupPagePresentationStrategies/vigetThreeUpStatuses";
import vigetThreeUpArticles from "~/groupPagePresentationStrategies/vigetThreeUpArticles";
// import vigetThreeUpMix from "~/groupPagePresentationStrategies/vigetThreeUpMix";
// import vigetOneUp from "~/groupPagePresentationStrategies/vigetOneUp";
import vigetOneUpBigImage from "~/groupPagePresentationStrategies/vigetOneUpBigImage";
import vigetGalleryTwoUp from "~/groupPagePresentationStrategies/vigetGalleryTwoUp";
import vigetGalleryOneUpWide from "~/groupPagePresentationStrategies/vigetGalleryOneUpWide";
import vigetGalleryTwoUpLandscape from "~/groupPagePresentationStrategies/vigetGalleryTwoUpLandscape";
import vigetFourUpSocialArticles from "~/groupPagePresentationStrategies/VigetFourUpSocialArticles";
import vigetFourUpStatuses from "~/groupPagePresentationStrategies/vigetFourUpStatuses";
// import vigetFourUpArticles from "~/groupPagePresentationStrategies/vigetFourUpArticles";
// import vigetTwoUpAV from "~/groupPagePresentationStrategies/vigetTwoUpAV";
// import vigetGalleryThreeUpSymmetric from "~/groupPagePresentationStrategies/vigetGalleryThreeUpSymmetric";
// import vigetSixUp from "~/groupPagePresentationStrategies/vigetSixUp";
// import vigetFourUp from "~/groupPagePresentationStrategies/vigetFourUp";
import conversationRollup from "~/groupPagePresentationStrategies/conversationRollup";
import socialArticleRollup from "~/groupPagePresentationStrategies/socialArticleRollup";
import socialArticleRollupOld from "~/groupPagePresentationStrategies/socialArticleRollupOld";
import vigetTwoUpSymmetricNarrow from "~/groupPagePresentationStrategies/vigetTwoUpSymmetricNarrow";
import vigetGalleryAVTwoUp from "~/groupPagePresentationStrategies/vigetGalleryAVTwoUp";
import vigetGalleryAVShortsThreeUp from "~/groupPagePresentationStrategies/vigetGalleryAVShortsThreeUp";
import vigetGalleryAVShortsTwoUp from "~/groupPagePresentationStrategies/vigetGalleryAVShortsTwoUp";
import vigetGalleryAVOneUp from "~/groupPagePresentationStrategies/vigetGalleryAVOneUp";
import topicArticleGrouping from "~/groupPagePresentationStrategies/topicArticleGrouping";
import devPlayground from "~/groupPagePresentationStrategies/devPlayground";
import feedDebugStrategy from "~/groupPagePresentationStrategies/feedDebugStrategy";

const phoneLayouts = [basicPhone];

const priorityLayoutsDesktop = [
  // relatedTopicFeeds,
  multiImageSingle,
  conversationRollup,
  socialArticleRollup,
  socialArticleRollupOld,
  topicArticleGrouping,
];

const priorityLayoutsPhone = [
  multiImageSingle,
  socialArticleRollup,
  socialArticleRollupOld,
];

const commonGalleryLayouts = [
  vigetGalleryAVOneUp,
  vigetGalleryOneUpWide,
  vigetGalleryTwoUp,
];

const narrowDesktopGalleryLayouts = [vigetGalleryAVShortsTwoUp];

const narrowDesktopLayouts = [
  vigetTwoUpSymmetric,
  vigetTwoUpRestrictive,
  vigetOneUpBigImage,
  vigetTwoUp,
  vigetTwoUpSymmetricNarrow,
];
const desktopGalleryLayouts = [
  vigetGalleryOneUpWide,
  vigetGalleryAVTwoUp,
  vigetGalleryAVShortsThreeUp,
  vigetGalleryThreeUp,
  vigetGalleryOneUp,
  vigetGalleryTwoUpLandscape,
];

const desktopLayouts = [
  vigetThreeUpSocialArticles,
  vigetThreeUpStatuses,
  vigetThreeUpArticles,
  vigetOneUpBigImage,
];
const wideDesktopGalleryLayouts = [...desktopGalleryLayouts];

const wideDesktopLayouts = [
  topicArticleGrouping,
  // vigetFourUpArticles,
  vigetThreeUpArticles,
  vigetFourUpSocialArticles,
  vigetFourUpStatuses,
  vigetOneUpBigImage,
];

/** Used for debug/development mode code */
export const validLayouts = Array.from(
  new Set([
    devPlayground,
    ...priorityLayoutsDesktop,
    ...commonGalleryLayouts,
    ...narrowDesktopGalleryLayouts,
    ...narrowDesktopLayouts,
    ...desktopGalleryLayouts,
    ...desktopLayouts,
    ...wideDesktopGalleryLayouts,
    ...wideDesktopLayouts,
    ...phoneLayouts,
    ...priorityLayoutsPhone,
  ])
);

interface GroupPresentationStrategiesOptions {
  group: FC.Group;
  seed: string;
  viewableContentSize: ScreenSizeType;
  feedDebug: boolean;
}
const groupPresentationStrategies = ({
  viewableContentSize,
  feedDebug,
}: GroupPresentationStrategiesOptions) => {
  if (feedDebug) {
    return [feedDebugStrategy];
  }

  const strategiesConfig = {
    [ScreenSizeType.PHONE]: [...priorityLayoutsPhone, ...phoneLayouts],
    [ScreenSizeType.NARROW]: [
      ...priorityLayoutsDesktop,
      ...commonGalleryLayouts,
      ...narrowDesktopGalleryLayouts,
      ...narrowDesktopLayouts,
    ],
    [ScreenSizeType.DESKTOP]: [
      ...priorityLayoutsDesktop,
      ...desktopGalleryLayouts,
      ...commonGalleryLayouts,
      ...desktopLayouts,
    ],
    [ScreenSizeType.DESKTOP_WIDE]: [
      ...priorityLayoutsDesktop,
      ...wideDesktopGalleryLayouts,
      ...commonGalleryLayouts,
      ...wideDesktopLayouts,
    ],
  };

  return strategiesConfig[viewableContentSize];
  // if (viewableContentSize === ScreenSizeType.PHONE) {
  //   return [basicPhone];
  // } else if (viewableContentSize === ScreenSizeType.NARROW) {
  //   return [
  //     // vigetTwoUpSymmetric,
  //     vigetTwoUpRestrictive,
  //     // vigetOneUpBigImage,
  //     vigetTwoUp, // aka prettyCatchall
  //     vigetOneUp,
  //   ];
  // }

  // // const galleryStrategies = ScreenSizeType.DESKTOP_WIDE
  // //   ? [
  // // multiImageSingle,
  // //       vigetGalleryThreeUp,
  // //       imageStaggered,
  // //       // galleryDoublePortraitFeatureConversationWide,
  // //       galleryDoublePortraitFeatureMiddle,
  // //       // galleryDoubleLandscapeFeatureMiddleConversation,
  // //       // galleryDoublePortraitFeatureMiddleConversation,
  // //       photoCardOneUpBlackBackground,
  // //       galleryPortraitCatchAllWide,
  // //       // galleryLargePortraitImagePhotoColumnConversation,
  // //       // galleryLargePortraitImageGraphicColumnConversation,
  // //       galleryBasic,
  // //       featurePhoto,
  // //     ]
  // //   : [
  // //       // galleryDoublePortraitFeatureConversationWide,
  // //       imageStaggered,
  // //       vigetGalleryThreeUp,
  // //       photoCardOneUpBlackBackground,
  // //       galleryPortraitCatchAll,
  // //       featurePhoto,
  // //     ];

  // // if (group.presentationType === GroupPresentationType.GALLERY) {
  // //   return galleryStrategies;
  // // }

  // const vigetGalleries = [
  //   multiImageSingle,
  //   // vigetGalleryThreeUpSymmetric,
  //   vigetGalleryOneUpWide,
  //   vigetGalleryThreeUp,
  //   vigetGalleryTwoUp,
  //   vigetGalleryOneUp,
  //   vigetGalleryTwoUpLandscape,
  // ];

  // const vigetFeatures = [
  //   // vigetSixUp,
  //   // vigetFiveUp,
  //   // vigetFourUp,
  //   vigetTwoUpSymmetric,
  //   vigetTwoUpRestrictive,
  //   vigetOneUpBigImage,
  //   // vigetThreeUp,
  //   vigetThreeUpSocialArticles,
  //   vigetThreeUpStatuses,
  //   vigetThreeUpArticles,
  //   vigetTwoUp, // aka prettyCatchall
  //   vigetOneUp,
  //   // vigetThreeUpMix, // mix of articles, commentary, and images
  // ];

  // // const featureLayouts =
  // //   viewableContentSize === ScreenSizeType.DESKTOP_WIDE
  // //     ? [
  // //         featureSmallMixedWide,
  // //         featureAndTextArticlesWide,
  // //         featureVideoArticleStatusWide,
  // //         featureBigAndArticlesWide,
  // //         featurePhotoAndArticlesWide,
  // //         featureLeftAndArticlesWide,
  // //         galleryDoublePortraitFeatureMiddle,
  // //         galleryDoubleLandscapeFeatureMiddleConversation,
  // //         galleryDoublePortraitFeatureMiddleConversation,
  // //         galleryDoublePortraitFeatureConversationWide,
  // //         galleryLargePortraitImagePhotoColumnConversation,
  // //         galleryLargePortraitImageGraphicColumnConversation,
  // //       ]
  // //     : [
  // //         featureBigAndArticlesWide,
  // //         featurePhotoAndArticlesWide,
  // //         featureSmallMixed,
  // //         featureAndTextArticles,
  // //         featureVideoArticles,
  // //       ];

  // // const general =
  // //   viewableContentSize === ScreenSizeType.DESKTOP_WIDE
  // //     ? [articlesConversationWide, basicWide, catchAllWide]
  // //     : [articlesConversation, basic, catchAll];
  // // const shuffledFeatures = shuffleSeed.shuffle(featureLayouts, seed);

  // return [
  //   // ...shuffledFeatures,
  //   ...vigetGalleries,
  //   ...vigetFeatures,
  //   // ...galleryStrategies,
  //   // ...general,
  // ];
};

export default groupPresentationStrategies;
