import React, { memo } from "react";
import { ApplicationMode } from "~/enums";

import { useAppSelector } from "~/hooks";
import { homeTimelineGroup, selectSelectedFeed } from "~/concepts/groups";
import { useIsGroupOwner } from "~/hooks/useIsGroupOwner";
import { selectApplicationMode } from "~/concepts/application";
import { selectCustomFeedBuilderPreviewFeed } from "~/concepts/customFeedBuilder";

import FeedFavoriteButton from "~/buttons/FeedFavoriteButton";

const SearchHeaderModalControls: React.FC = () => {
  const customFeedBuilderPreviewFeed = useAppSelector(
    selectCustomFeedBuilderPreviewFeed
  );
  const selectedFeed = useAppSelector(selectSelectedFeed);
  const applicationMode = useAppSelector(selectApplicationMode);
  const isGroupOwner = useIsGroupOwner();

  if (!selectedFeed && !customFeedBuilderPreviewFeed) {
    return null;
  }
  return (
    <>
      {selectedFeed &&
        !isGroupOwner(selectedFeed) &&
        applicationMode === ApplicationMode.BASE &&
        selectedFeed !== homeTimelineGroup && (
          <>
            <FeedFavoriteButton />
          </>
        )}
    </>
  );
};

export default memo(SearchHeaderModalControls);
