const TITLE_MAX_LENGTH = 80;
const SLUG_DELIMITER = "-";
export const getSlugTitle = (title: string, max = TITLE_MAX_LENGTH) =>
  title
    // lowercase
    .toLowerCase()
    // strip special characters
    .replace(/[^\w-]/g, " ")
    // trim whitespace
    .trim()
    // truncate
    .substr(0, max)
    // replace interior whitespace with delimiter
    .replace(/\s+/g, SLUG_DELIMITER);
