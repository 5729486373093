import { Switch, makeStyles, useTheme } from "@rneui/themed";
import React, { memo } from "react";
import {
  Column,
  Row,
  Text,
  Divider,
  CollapsibleAnimatedView,
  Button,
} from "~/components/elements";
import BaseCard from "~/components/cards/BaseCard";
import { CardSize, ItemType } from "~/enums";
import { SPACING, createStyleSheet } from "~/styles";
import SocialArticleCardHorizontal from "~/components/cards/SocialArticleCardHorizontal";
import BasicImageCard from "~/components/cards/BasicImageCard";
import BasicLinkCardHorizontal from "~/components/cards/BasicLinkCardHorizontal";
import BasicImageCardHorizontal from "~/components/cards/BasicImageCardHorizontal";
import SocialArticleCard from "~/components/cards/SocialArticleCard";
import PollCardHorizontal from "~/components/cards/PollCardHorizontal";
import BasicYoutubeCard from "~/components/cards/BasicYoutubeCard";
import BasicYoutubeCardHorizontal from "~/components/cards/BasicYoutubeCardHorizontal";
import BasicAVCard from "~/components/cards/BasicAVCard";
import BasicAVCardHorizontal from "~/components/cards/BasicAVCardHorizontal";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import ArticlePreviewBasicLink from "~/components/cards/ArticlePreviewBasicLink";

/**
 * Dummy card to show cards in different sizes.
 */
const DevPlayground: FC.PageStrategyResult["Component"] = ({
  items,
  contentWidth,
  contentHeight,
}) => {
  const styles = useStyles();
  const { theme } = useTheme();

  const [horizontalMode, setHorizontalMode] = React.useState(false);
  const [darkBackground, setDarkBackground] = React.useState(false);
  const [showDevControls, setShowDevControls] = React.useState(false);

  const rc = (i: number, size: CardSize, wRatio: number, hRatio: number) => {
    const w = contentWidth * wRatio;
    const h = contentHeight * hRatio;
    const item = items[i];
    const componentMap = horizontalMode
      ? {
          [ItemType.SOCIAL_ARTICLE]: SocialArticleCardHorizontal,
          [ItemType.ARTICLE]: BasicLinkCardHorizontal,
          [ItemType.PHOTO]: BasicImageCardHorizontal,
          [ItemType.POLL]: PollCardHorizontal,
          [ItemType.YOUTUBE]: BasicYoutubeCardHorizontal,
          [ItemType.AV]: BasicAVCardHorizontal,
        }
      : {
          [ItemType.SOCIAL_ARTICLE]: SocialArticleCard,
          [ItemType.ARTICLE]: ArticlePreviewBasicLink,
          [ItemType.PHOTO]: BasicImageCard,
          [ItemType.YOUTUBE]: BasicYoutubeCard,
          [ItemType.AV]: BasicAVCard,
        };

    return (
      <Row
        style={[
          styles.devCardWrapper,
          darkBackground && { borderColor: theme.colors.dividerOverlay },
          { height: h, width: w },
        ]}
      >
        <BaseCard
          item={item}
          width={w}
          height={h}
          cardSize={size}
          ComponentMap={componentMap}
          overlay={darkBackground}
        />
      </Row>
    );
  };

  return (
    <Row
      flex
      noGap
      style={[
        { width: contentWidth },
        darkBackground && { backgroundColor: theme.colors.backgroundReverse },
      ]}
    >
      {horizontalMode ? (
        <>
          <Column noGap flex={2}>
            {rc(0, CardSize.SMALL, 0.2, 0.2)}
            {rc(0, CardSize.MEDIUM, 0.3, 0.3)}
            {rc(0, CardSize.MEDIUM, 0.4, 0.4)}
          </Column>
          <Column noGap flex={3}>
            {rc(0, CardSize.LARGE, 0.6, 0.6)}
            {rc(0, CardSize.MEDIUM, 0.6, 0.4)}
          </Column>
        </>
      ) : (
        <>
          {/* <Column noGap>
            {rc(0, CardSize.SMALL, 0.2, 0.4)}
            {rc(0, CardSize.SMALL, 0.2, 0.6)}
          </Column> */}
          <Column noGap>
            {rc(0, CardSize.SMALL, 0.2, 0.3)}
            {rc(0, CardSize.MEDIUM, 0.2, 0.4)}
          </Column>
          {rc(0, CardSize.LARGE, 0.3, 0.75)}
          {rc(0, CardSize.XLARGE, 0.5, 1)}
        </>
      )}
      <Column
        style={[styles.devControls, darkBackground && styles.devControlsDark]}
      >
        <Button
          onPress={() => setShowDevControls(!showDevControls)}
          icon={
            <Icon
              name={showDevControls ? "eye-off" : "eye-plus"}
              color={
                darkBackground
                  ? theme.colors.primaryReverse
                  : theme.colors.primary
              }
              size={24}
            />
          }
          type="clear"
        />
        <CollapsibleAnimatedView show={showDevControls} fade nullOnHidden>
          <Row>
            <Text
              style={[styles.devText, darkBackground && styles.devTextDark]}
            >
              Vertical Mode
            </Text>
            <Switch
              value={horizontalMode}
              color={
                horizontalMode
                  ? theme.colors.controlsGreen
                  : theme.colors.controls
              }
              onValueChange={setHorizontalMode}
              trackColor={{
                false: theme.colors.grey4,
                true: theme.colors.grey4,
              }}
            />
            <Text
              style={[styles.devText, darkBackground && styles.devTextDark]}
            >
              Horizontal Mode
            </Text>

            <Divider vertical />
            <Switch
              value={darkBackground}
              color={
                darkBackground
                  ? theme.colors.controlsGreen
                  : theme.colors.controls
              }
              onValueChange={setDarkBackground}
              trackColor={{
                false: theme.colors.grey4,
                true: theme.colors.grey4,
              }}
            />

            <Text
              style={[styles.devText, darkBackground && styles.devTextDark]}
            >
              Dark Background
            </Text>
          </Row>
        </CollapsibleAnimatedView>
      </Column>
    </Row>
  );
};

const useStyles = makeStyles((theme) => {
  return createStyleSheet({
    devCardWrapper: {
      borderColor: theme.colors.debugPrimary,
      borderWidth: 1,
      position: "relative",
    },
    devControls: {
      minWidth: 100,
      borderWidth: 1,
      borderColor: theme.colors.debugPrimary,
      borderRadius: SPACING.BASE,
      backgroundColor: theme.colors.backgroundOverlay,
      padding: SPACING.BASE,
      position: "absolute",
      bottom: SPACING.LARGE,
      left: "50%",
      transform: "translate(-50%, 0)",
      alignItems: "center",
      zIndex: 99999,
    },
    devControlsDark: {
      backgroundColor: theme.colors.backgroundOverlayReverse,
    },
    devText: { color: theme.colors.debugPrimary },
    devTextDark: { color: "tomato" },
  });
});

export default memo(DevPlayground);
