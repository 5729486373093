import React, { memo, useEffect, useState } from "react";
import api, {
  useGetInBetaQuery,
  useGetPreferencesQuery,
  useGetCustomFeedsQuery,
} from "~/api";
import { defaultPreferences } from "~/apiTransforms/preferences";
import {
  selectApplicationMode,
  selectInBeta,
  setInBeta,
  setPreferences,
} from "~/concepts/application";
import { clearBearerToken, selectIsAuthenticated } from "~/concepts/auth";
import {
  developmentGroups,
  addGroups,
  selectHomeTimelineGroup,
  setHomeTimeline,
  selectGroups,
} from "~/concepts/groups";
import {
  hashtagGroupFromHashtag,
  customFeedGroupFromCustomFeed,
  authorFeedFromAuthor,
} from "~/utils/groups";
import { useConfigQuery } from "~/dynconfig";
import { FeedConfigType, GroupRequestType } from "~/enums";
import { useAppDispatch, useAppSelector } from "~/hooks";
import { useGetDevelopmentCookie, useGetHostCookie } from "~/hooks/useCookies";
import { usePreferences } from "~/hooks/usePreferences";
import Loading from "~/components/Loading";
import { removeBearerToken } from "~/lib/persistentStore";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { useFavorite } from "~/hooks/useFavorite";
import { isCustomFeedId } from "~/utils/id";
import {
  authorRequestKeyFromRouteFeedId,
  customFeedIdFromRouteFeedId,
  hashtagIdFromRouteFeedId,
  isHashtagFeedId,
  isRSSFeedId,
  isTopicFeedId,
  isUserFeedId,
  topicRequestKeyFromRouteFeedId,
} from "~/utils/id";

const LoadPrerequisites: React.FC<{ children: React.ReactElement }> = ({
  children,
}) => {
  const dispatch = useAppDispatch();
  const getHostCookie = useGetHostCookie();
  const { removeFavorite } = useFavorite();
  const [prerequisitesLoaded, setPrerequisitesLoaded] = useState(false);

  const [homeTimelineGroupAdded, setHomeTimelineGroupAdded] = useState(false);
  const [featuredGroupsAdded, setFeaturedGroupsAdded] = useState(false);
  const [authorFeedsAdded, setAuthorFeedsAdded] = useState(false);
  const [customFeedGroupsAdded, setCustomFeedGroupsAdded] = useState(false);
  const [othersCustomFeedsGroupsAdded, setOthersCustomFeedsGroupsAdded] =
    useState(false);
  const [topicGroupsAdded, setTopicGroupsAdded] = useState(false);
  const [rssFeedsAdded, setRSSFeedsAdded] = useState(false);

  const inBeta = useAppSelector(selectInBeta);
  const groups = useAppSelector(selectGroups);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const applicationMode = useAppSelector(selectApplicationMode);
  const homeTimeline = useAppSelector(selectHomeTimelineGroup);
  const devMode = useGetDevelopmentCookie()();
  const configResult = useConfigQuery();
  const [preferences] = usePreferences();

  const getPreferencesResponse = useGetPreferencesQuery(undefined, {
    skip: inBeta !== true,
  });

  const getCustomFeedsResponse = useGetCustomFeedsQuery(undefined, {
    skip: inBeta !== true,
  });

  const getInBetaResponse = useGetInBetaQuery(undefined, {
    skip: !isAuthenticated,
  });

  useEffect(() => {
    if (isAuthenticated && !homeTimeline) {
      dispatch(setHomeTimeline());
      setHomeTimelineGroupAdded(true);
    }
  }, [dispatch, homeTimeline, isAuthenticated]);

  useEffect(() => {
    if (getCustomFeedsResponse.isSuccess) {
      dispatch(
        addGroups(
          getCustomFeedsResponse.data.map(customFeedGroupFromCustomFeed)
        )
      );
      setCustomFeedGroupsAdded(true);
    }
  }, [dispatch, getCustomFeedsResponse.isSuccess, getCustomFeedsResponse.data]);

  useEffect(() => {
    if (!configResult.data) {
      return;
    }
    dispatch(
      addGroups([
        ...configResult.data
          .filter((c) => c.feedType !== FeedConfigType.CUSTOM)
          .map((r) => ({
            id: r.id,
            title: r.title,
            requestType: GroupRequestType.FEED,
            requestKey: r.id,
            image: r.image,
            description: r.description,
            byline: r.byline,
            featured: true,
          })),
        ...(devMode ? developmentGroups : []),
      ])
    );
    setFeaturedGroupsAdded(true);
  }, [dispatch, configResult, configResult.isSuccess, devMode]);

  useEffect(() => {
    if (getInBetaResponse.isSuccess) {
      if (inBeta !== true) {
        dispatch(setInBeta(true));
      }
    }
    if (getInBetaResponse.isError) {
      if (inBeta !== false) {
        dispatch(setInBeta(false));
      }
    }
  }, [
    dispatch,
    getInBetaResponse.isSuccess,
    getInBetaResponse.isError,
    inBeta,
  ]);

  useEffect(() => {
    if (getPreferencesResponse.isSuccess) {
      dispatch(
        setPreferences(getPreferencesResponse.data || defaultPreferences)
      );
    }
  }, [dispatch, getPreferencesResponse]);

  useEffect(() => {
    if (
      featuredGroupsAdded &&
      isAuthenticated &&
      getPreferencesResponse.isSuccess
    ) {
      const hashtagGroupIds = preferences.feeds.saved.filter((id) =>
        isHashtagFeedId(id)
      );
      dispatch(
        addGroups(
          hashtagGroupIds.map((hashtagId) =>
            hashtagGroupFromHashtag(
              hashtagIdFromRouteFeedId(hashtagId),
              applicationMode
            )
          )
        )
      );
    }
  }, [
    dispatch,
    isAuthenticated,
    featuredGroupsAdded,
    getPreferencesResponse.isSuccess,
    preferences,
    applicationMode,
  ]);

  useEffect(() => {
    if (
      featuredGroupsAdded &&
      isAuthenticated &&
      getPreferencesResponse.isSuccess
    ) {
      const accountFeedIds = preferences.feeds.saved.filter((id) =>
        isUserFeedId(id)
      );
      const requests = accountFeedIds.map((accountFeedId) =>
        dispatch(
          api.endpoints.getInstanceAuthor.initiate({
            acct: authorRequestKeyFromRouteFeedId(accountFeedId),
          })
        )
      );

      Promise.all(requests).then((results) => {
        results.forEach((result) => {
          if (result.data) {
            dispatch(
              addGroups([authorFeedFromAuthor(result.data, getHostCookie())])
            );
          }
        });
        setAuthorFeedsAdded(true);
      });
    }
  }, [
    getHostCookie,
    dispatch,
    isAuthenticated,
    featuredGroupsAdded,
    getPreferencesResponse.isSuccess,
    preferences,
  ]);

  useEffect(() => {
    if (isAuthenticated && getPreferencesResponse.isSuccess) {
      const rssFeedIds = preferences.feeds.saved.filter((id) =>
        isRSSFeedId(id)
      );
      const requests = rssFeedIds.map((rssFeedId) =>
        dispatch(api.endpoints.getRSSFeed.initiate(rssFeedId))
      );

      Promise.all(requests).then((results) => {
        results.forEach((result) => {
          if (result.data) {
            dispatch(addGroups([result.data]));
          }
        });
        setRSSFeedsAdded(true);
      });
    }
  }, [
    dispatch,
    isAuthenticated,
    getPreferencesResponse.isSuccess,
    preferences,
  ]);

  useEffect(() => {
    if (
      !topicGroupsAdded &&
      featuredGroupsAdded &&
      isAuthenticated &&
      getPreferencesResponse.isSuccess
    ) {
      const topicFeedIds = preferences.feeds.saved.filter((id) =>
        isTopicFeedId(id)
      );
      const requests = topicFeedIds.map((id) =>
        dispatch(
          api.endpoints.searchTopic.initiate(topicRequestKeyFromRouteFeedId(id))
        )
      );
      Promise.all(requests).then((results) => {
        results.forEach((result) => {
          const topicGroup = result.data?.find(
            (d) => d.requestKey === result.originalArgs
          );
          if (topicGroup) {
            dispatch(addGroups([topicGroup]));
          }
        });
        setTopicGroupsAdded(true);
      });
    }
  }, [
    dispatch,
    topicGroupsAdded,
    featuredGroupsAdded,
    preferences,
    isAuthenticated,
    getPreferencesResponse.isSuccess,
  ]);

  useEffect(() => {
    if (
      featuredGroupsAdded &&
      isAuthenticated &&
      configResult.data &&
      customFeedGroupsAdded &&
      getPreferencesResponse.isSuccess &&
      !othersCustomFeedsGroupsAdded
    ) {
      const othersCustomFeedsIds = preferences.feeds.saved.filter((id) => {
        if (isCustomFeedId(id)) {
          const ownCustomFeed = groups.find((g) => g.id === id);
          if (ownCustomFeed) {
            return false;
          }
          return true;
        }
        return false;
      });

      const featuredCustomFeedIds = configResult.data
        .filter((c) => {
          return c.feedType === FeedConfigType.CUSTOM;
        })
        .map((x) => x.id);

      const requests = [...othersCustomFeedsIds, ...featuredCustomFeedIds].map(
        (id) =>
          dispatch(
            api.endpoints.getPublicCustomFeed.initiate(
              customFeedIdFromRouteFeedId(id)
            )
          )
      );

      Promise.all(requests).then((results) => {
        results.forEach((result) => {
          if (result.data) {
            const customFeed = customFeedGroupFromCustomFeed(result.data);
            const group = {
              ...customFeed,
              featured: !!featuredCustomFeedIds.find(
                (id) => id === customFeed.id
              ),
            };
            dispatch(addGroups([group]));
          } else {
            if (
              result.originalArgs &&
              (result?.error as FetchBaseQueryError)?.status === 404
            ) {
              const savedPreferenceId = preferences.feeds.saved.find(
                (x) => x.indexOf(result.originalArgs) > 0
              );

              if (savedPreferenceId) {
                removeFavorite({ id: savedPreferenceId } as FC.Group);
              }
            }
          }
        });
        setOthersCustomFeedsGroupsAdded(true);
      });
    }
  }, [
    dispatch,
    removeFavorite,
    groups,
    isAuthenticated,
    configResult.data,
    featuredGroupsAdded,
    customFeedGroupsAdded,
    getPreferencesResponse.isSuccess,
    othersCustomFeedsGroupsAdded,
    preferences,
  ]);

  useEffect(() => {
    if (isAuthenticated === false) {
      removeBearerToken().then(() => dispatch(clearBearerToken()));
    }
  }, [dispatch, isAuthenticated]);

  // authenticated, in beta, everything is loaded, preload route
  useEffect(() => {
    if (
      isAuthenticated &&
      inBeta &&
      featuredGroupsAdded &&
      customFeedGroupsAdded &&
      othersCustomFeedsGroupsAdded &&
      topicGroupsAdded &&
      authorFeedsAdded &&
      rssFeedsAdded &&
      homeTimelineGroupAdded
    ) {
      setPrerequisitesLoaded(true);
    }
  }, [
    inBeta,
    isAuthenticated,
    featuredGroupsAdded,
    topicGroupsAdded,
    customFeedGroupsAdded,
    othersCustomFeedsGroupsAdded,
    homeTimelineGroupAdded,
    authorFeedsAdded,
    rssFeedsAdded,
  ]);

  // authenticated and not in beta
  useEffect(() => {
    if (isAuthenticated === true && inBeta) {
      setPrerequisitesLoaded(true);
    }
  }, [isAuthenticated, inBeta]);

  // unauthenticated
  useEffect(() => {
    if (isAuthenticated === false && featuredGroupsAdded) {
      setPrerequisitesLoaded(true);
    }
  }, [isAuthenticated, featuredGroupsAdded]);

  if (!prerequisitesLoaded) {
    return <Loading />;
  }

  return children;
};

export default memo(LoadPrerequisites);
