import VigetThreeUpColumn from "~/components/pages/VigetThreeUpColumn";
import { isSocialArticleItem } from "~/predicates";

const vigetThreeUpSocialArticles: FC.PagePresentationStrategy = ({ items }) => {
  const socialArticleItems = items.filter(isSocialArticleItem);

  if (socialArticleItems.length >= 3) {
    return {
      usedItems: socialArticleItems.slice(0, 3),
      Component: VigetThreeUpColumn,
    };
  }
  return null;
};

export default vigetThreeUpSocialArticles;
