const HERO_MINIMUM_SIZE = 500;

import { ImageAspectRatioType } from "~/enums";
const PORTRAIT_LANDSCAPE_REQUIRED_DIFFERENCE = 1.4;

const MATCH_THRESHOLD = 0.2;

export const ImageAspectRatioMap = {
  [ImageAspectRatioType.ULTRA_WIDE]: 21 / 9,
  [ImageAspectRatioType.WIDE]: 16 / 9,
  [ImageAspectRatioType.LANDSCAPE]: 4 / 3,
  [ImageAspectRatioType.SQUARE]: 1 / 1,
  [ImageAspectRatioType.PORTRAIT]: 3 / 4,
  [ImageAspectRatioType.TALL]: 9 / 16,
};

export const area = (image?: FC.ItemImage) => {
  if (!image || !image.height || !image.width) {
    return 0;
  }
  return image.height * image.width;
};

export const isLandscape = (image?: FC.ItemImage) =>
  !!image &&
  image.height &&
  image.width &&
  image.width > image.height * PORTRAIT_LANDSCAPE_REQUIRED_DIFFERENCE;

export const isPortrait = (image?: FC.ItemImage) =>
  !!image &&
  image.height &&
  image.width &&
  image.height > image.width * PORTRAIT_LANDSCAPE_REQUIRED_DIFFERENCE;

export const isLandscapeHero = (image?: FC.ItemImage) =>
  image && isLandscape(image) && image.width && image.width > HERO_MINIMUM_SIZE;

export const isFeatureableWide = (image?: FC.ItemImage) =>
  image && image.width && image.width > HERO_MINIMUM_SIZE;
export const isFeatureableTall = (image?: FC.ItemImage) =>
  image && image.height && image.height > HERO_MINIMUM_SIZE;

export const isPortraitHero = (image?: FC.ItemImage) =>
  image &&
  isPortrait(image) &&
  image.height &&
  image.height > HERO_MINIMUM_SIZE;

export const isFeatureable = (image?: FC.ItemImage) =>
  isFeatureableWide(image) || isFeatureableTall(image);

export const isSquare = (image?: FC.ItemImage) =>
  !!image &&
  image.height &&
  image.width &&
  image.height < image.width * 1.2 &&
  image.width < image.height * 1.2;

export const sortItemsByImageSize = (items: FC.Item[]) =>
  items.sort((a, b) => {
    const aImage = a.reblog?.image || a.image;
    const bImage = b.reblog?.image || b.image;
    if (area(aImage) > area(bImage)) {
      return -1;
    }
    return 1;
  });

export const imageWithinRatio = (
  image?: FC.ItemImage,
  widestRatio?: ImageAspectRatioType | null,
  tallestRatio?: ImageAspectRatioType | null
) => {
  if (!(image?.height && image?.width)) return false;
  const w = image.width;
  const h = image.height;
  const aspectRatio = w / h;
  const upperBound = widestRatio
    ? ImageAspectRatioMap[widestRatio] + MATCH_THRESHOLD
    : aspectRatio + MATCH_THRESHOLD;
  const lowerBound = tallestRatio
    ? ImageAspectRatioMap[tallestRatio] - MATCH_THRESHOLD
    : 0;
  return lowerBound <= aspectRatio && aspectRatio <= upperBound;
};

type ClosestType = { diff: number; ratio?: number };
export const getClosestImageAspectRatio = (aspectRatio: number) => {
  const closest = Object.entries(ImageAspectRatioMap).reduce(
    (result, [_, val]) => {
      const currDiff = Math.abs(aspectRatio - val);
      if (!result.ratio || currDiff < result.diff)
        return { diff: currDiff, ratio: val };
      return result;
    },
    { diff: Infinity } as ClosestType
  );
  return closest.ratio || aspectRatio;
};

export const imageAttachmentIsSquare = (
  attachment: FC.SupportedMediaAttachment
) => {
  const ratio =
    attachment.meta?.original?.width / attachment.meta?.original?.height;
  return ratio >= 0.9 && ratio <= 1.1;
};

export const imageAttachmentIsPortrait = (
  attachment: FC.SupportedMediaAttachment
) => {
  const ratio =
    attachment.meta?.original?.width / attachment.meta?.original?.height;
  return ratio < 0.9;
};

export const imageAttachmentIsLandscape = (
  attachment: FC.SupportedMediaAttachment
) => {
  const ratio =
    attachment.meta?.original?.width / attachment.meta?.original?.height;
  return ratio > 1.1;
};
