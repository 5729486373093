import BasicPhone from "~/components/pages/BasicPhone";

const basicPhone: FC.PagePresentationStrategy = ({ items }) => {
  if (items.length > 0)
    return {
      usedItems: items.slice(0, 1),
      Component: BasicPhone,
    };
  return null;
};

export default basicPhone;
