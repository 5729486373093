import VigetGalleryRow from "~/components/pages/VigetGalleryRow";
import { isYoutubeShort } from "~/predicates";

const vigetGalleryAVShortsTwoUp: FC.PagePresentationStrategy = ({ items }) => {
  const supportedTypes = items.filter(isYoutubeShort);

  if (supportedTypes.length >= 2) {
    return {
      usedItems: supportedTypes.slice(0, 2),
      Component: VigetGalleryRow,
      // repeat: true,
    };
  }
  return null;
};

export default vigetGalleryAVShortsTwoUp;
