import React, { memo } from "react";

import { ItemType, BaseCardVariantType, CardSize, FeatureFlags } from "~/enums";

import StatusCard from "~/components/cards/StatusCard";
import PhotoCard from "~/components/cards/PhotoCard";
import FlipboardMagazineCard from "~/components/cards/FlipboardMagazineCard";
import { makeStyles } from "@rneui/themed";
import PollCard from "~/components/cards/PollCard";
import ContentWarningFilter from "~/components/elements/ContentWarningFilter";
import { StyleProp } from "react-native";
import { SPACING, createStyleSheet } from "~/styles";
import { isGraphicItem } from "~/predicates";
import BasicImageCard from "~/components/cards/BasicImageCard";
import SocialArticleCard from "~/components/cards/SocialArticleCard";
import ItemFocusDrawerButton from "~/components/cardComponents/ItemFocusDrawerButton";
import BasicYoutubeCard from "~/components/cards/BasicYoutubeCard";
import BasicAVCard from "~/components/cards/BasicAVCard";
import ArticlePreviewBasicLink from "~/components/cards/ArticlePreviewBasicLink";
import FlexibleGifvCard from "~/components/cards/FlexibleGifvCard";
import { Pressable, View, Text } from "~/components/elements";
import { useAppDispatch } from "~/hooks";
import { setPreviewItem } from "~/concepts/thread";
import { useGetFeatureFlagsCookie } from "~/hooks/useCookies";

export const CardHoveredContext = React.createContext(false);
export const CardSizeContext = React.createContext<CardSize | undefined>(
  undefined
);

type BaseCardProps = {
  item: FC.Item;
  width?: number;
  height?: number | string;
  CardComponent?: FC.CardComponent;
  ComponentMap?: FC.CardComponentMap;
  style?: StyleProp<ViewStyle>;
  baseCardVariantType?: BaseCardVariantType;
  cardSize?: CardSize;
  overlay?: boolean;
  componentProps?: Record<string, any>;
};

const defaultCardComponentMap: FC.CardComponentMap = {
  [ItemType.ARTICLE]: ArticlePreviewBasicLink,
  [ItemType.STATUS]: StatusCard,
  [ItemType.YOUTUBE]: BasicYoutubeCard,
  [ItemType.PHOTO]: PhotoCard,
  [ItemType.AV]: BasicAVCard,
  [ItemType.GIFV]: FlexibleGifvCard,
  [ItemType.FLIPBOARD_MAGAZINE]: FlipboardMagazineCard,
  [ItemType.POLL]: PollCard,
  [ItemType.SOCIAL_ARTICLE]: SocialArticleCard,
};

const BaseCard: React.FC<BaseCardProps> = ({
  item,
  width,
  height,
  CardComponent,
  ComponentMap,
  style,
  baseCardVariantType,
  cardSize,
  overlay,
  componentProps,
}) => {
  const styles = useStyles({ height, width });
  const dispatch = useAppDispatch();

  const featureFlags = useGetFeatureFlagsCookie()();

  const [cardDimensions, setCardDimensions] = React.useState<{
    width?: number;
    height?: number;
  }>({});

  if (!item) return null;
  const itemToRender = item.reblog || item;
  let defaultCardComponent = defaultCardComponentMap[itemToRender.type];
  if (isGraphicItem(itemToRender)) defaultCardComponent = BasicImageCard;

  const Component =
    CardComponent || ComponentMap?.[itemToRender.type] || defaultCardComponent;

  if (!Component) {
    return null;
  }

  const renderContents = (isHovered: boolean) => (
    <CardHoveredContext.Provider value={isHovered}>
      <CardSizeContext.Provider value={cardSize}>
        <Component
          item={itemToRender}
          baseCardVariantType={baseCardVariantType}
          cardSize={cardSize}
          overlay={overlay}
          {...componentProps}
        />
      </CardSizeContext.Provider>
    </CardHoveredContext.Provider>
  );

  return (
    <>
      <ContentWarningFilter
        style={[styles.defaultCardStyles, style]}
        key={item.id}
        item={item}
        cardSize={cardSize}
        onLayout={
          featureFlags[FeatureFlags.DEBUG_LAYERS]
            ? (e) => {
                const { width: w, height: h } = e.nativeEvent.layout;
                setCardDimensions({ width: w, height: h });
              }
            : undefined
        }
      >
        {item.capabilities.canViewThread ? (
          <ItemFocusDrawerButton flex item={item} overlay={overlay}>
            {(isHovered) => renderContents(isHovered)}
          </ItemFocusDrawerButton>
        ) : (
          <Pressable
            style={styles.flex1}
            onPress={() => {
              dispatch(setPreviewItem(item));
            }}
          >
            {(isHovered: boolean) => renderContents(isHovered)}
          </Pressable>
        )}
      </ContentWarningFilter>
      {featureFlags[FeatureFlags.DEBUG_LAYERS] &&
        cardDimensions?.width &&
        cardDimensions?.height && (
          <View style={[styles.debugInfo, styles.debugLayer]}>
            <Text style={[styles.debugText]}>
              {cardSize} -{" "}
              {cardDimensions?.width ? Math.round(cardDimensions?.width) : "-"}{" "}
              x{" "}
              {cardDimensions?.height
                ? Math.round(cardDimensions?.height)
                : "-"}
            </Text>
          </View>
        )}
    </>
  );
};

const useStyles = makeStyles(
  (theme, { height, width }: { height?: number | string; width?: number }) => {
    return createStyleSheet({
      defaultCardStyles: {
        height: height
          ? `min(100%, ${height}${typeof height === "number" && "px"}`
          : "100%",
        width,
        margin: 0,
        padding: 0,
      },
      debugLayer: {
        minWidth: 100,
        bottom: SPACING.LARGE,
        right: SPACING.LARGE,
        alignItems: "center",
      },
    });
  }
);

export default memo(BaseCard);
