import React, { memo, useState } from "react";
import Button from "~/components/elements/Button";
import { SPACING } from "~/styles";
import ConfirmDialog from "~/components/ConfirmDialog";
import { useNavigate } from "~/routing";
import { useDeleteCustomFeedMutation } from "~/api";
import { useToast } from "react-native-toast-notifications";
import { useAppDispatch } from "~/hooks";
import { removeGroup } from "~/concepts/groups";
import { customFeedGroupFromCustomFeed } from "~/utils/groups";

const CustomFeedFormDeleteDialog: React.FC<{ customFeed: FC.CustomFeed }> = ({
  customFeed,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [trigger, response] = useDeleteCustomFeedMutation();
  const [visible, setVisible] = useState(false);
  const toast = useToast();
  return (
    <>
      <Button
        onPress={() => setVisible(true)}
        title="Delete"
        type={"outline"}
        buttonStyle={{
          gap: SPACING.BASE,
          borderRadius: SPACING.BASE,
        }}
        containerStyle={{ borderRadius: SPACING.BASE }}
      />
      <ConfirmDialog
        visible={visible}
        title="Really delete this custom feed?"
        loading={response.isLoading}
        disabled={response.isLoading}
        onNo={() => setVisible(false)}
        onYes={() =>
          trigger(customFeed.id).then(() => {
            toast.show(`Removed custom feed: ${customFeed.title}`, {
              type: "success",
            });
            dispatch(removeGroup(customFeedGroupFromCustomFeed(customFeed)));
            navigate("/");
          })
        }
      />
    </>
  );
};

export default memo(CustomFeedFormDeleteDialog);
