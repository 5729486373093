import React, { memo } from "react";
import { createStyleSheet } from "~/styles";
import { makeStyles } from "@rneui/themed";
import { A } from "@expo/html-elements";
import { LinkProps } from "@expo/html-elements/build/elements/Text.types";
import Hoverable from "~/components/elements/Hoverable";
import { StyleProp } from "react-native";
import HoverOverlay from "~/components/elements/HoverOverlay";

const Anchor: React.FC<
  {
    children: React.ReactNode | ((isHovered: boolean) => JSX.Element);
    flex?: boolean | number;
    overlay?: boolean;
    style?: StyleProp<ViewStyle>;
    anchorStyle?: StyleProp<ViewStyle>;
  } & Omit<LinkProps, "children">
> = ({ flex, children, overlay, style, anchorStyle, ...restProps }) => {
  const styles = useStyles();
  const flexStyle = typeof flex === "number" ? { flex } : flex && styles.flex1;

  return (
    <Hoverable style={[style, flexStyle]}>
      {(isHovered) => (
        <>
          <A target="_blank" {...restProps} style={[styles.flex1, anchorStyle]}>
            {typeof children === "function" ? children(isHovered) : children}
          </A>
          <HoverOverlay isHovered={isHovered} overlay={overlay} />
        </>
      )}
    </Hoverable>
  );
};

const useStyles = makeStyles(() => {
  return createStyleSheet({});
});

export default memo(Anchor);
