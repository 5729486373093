import React, { useState, useEffect } from "react";
import { ImageBackground, Pressable, StyleProp } from "react-native";
import { createStyleSheet } from "~/styles";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";

import { makeStyles, Button, useTheme } from "@rneui/themed";
import { Column } from "~/components/elements";
import { selectModalOpen } from "~/concepts/application";
import { useAppSelector } from "~/hooks";

import { getYouTubeID } from "~/utils/get-youtube-id";
const PLAY_ICON_SIZE = 42;
const CLOSE_ICON_SIZE = 24;

type YoutubePlayerProps = {
  item: FC.Item;
  autoPlay?: boolean;
  style?: StyleProp<ViewStyle>;
  youtubeIframeOnly?: boolean;
};

const YoutubePlayer: React.FC<YoutubePlayerProps> = ({
  item,
  autoPlay = false,
  style,
  youtubeIframeOnly = false,
}) => {
  const [youTubeEmbeddedPlayer, setYouTubeEmbeddedPlayer] = useState(false);
  const modalOpen = useAppSelector(selectModalOpen);
  const ALT_TEXT = "YouTube Preview image";

  const toggleYouTubeEmbeddedPlayer = () => {
    setYouTubeEmbeddedPlayer(!youTubeEmbeddedPlayer);
  };

  const stopYouTubeEmbeddedPlayer = () => {
    setYouTubeEmbeddedPlayer(false);
  };

  useEffect(() => {
    if (modalOpen) {
      stopYouTubeEmbeddedPlayer();
    }
  }, [modalOpen]);

  const { url, description } = (item as FC.CardItem).card;
  const image = item?.image;

  const { theme } = useTheme();
  const styles = useStyles({
    aspectRatio: image?.aspectRatio,
  });

  const youTubeID = getYouTubeID(url || "");
  if (!youTubeID) return null;

  const iFrameYoutubePlayer = (
    <iframe
      style={styles.playerWrapper}
      src={`https://www.youtube-nocookie.com/embed/${youTubeID}?autoplay=${
        autoPlay ? 1 : 0
      }`}
      allow={"autoplay;fullscreen"}
    />
  );
  if (youtubeIframeOnly) return iFrameYoutubePlayer;

  return (
    <Column noGap style={[styles.playerWrapper, style]}>
      {youTubeEmbeddedPlayer ? (
        <>
          <Button
            onPress={toggleYouTubeEmbeddedPlayer}
            icon={
              <Icon
                name="window-close"
                size={CLOSE_ICON_SIZE}
                color={theme.colors.primaryReverse}
                buttonStyle={styles.playIcon}
              />
            }
            containerStyle={styles.buttonContainer}
            buttonStyle={styles.closeButtonWrapper}
          />
          {iFrameYoutubePlayer}
        </>
      ) : (
        <ImageBackground
          source={{
            uri:
              item.image?.url ||
              "https://s3.amazonaws.com/s.flipboard.com/assets/webu/images/meta/flipboard-512.png",
          }}
          resizeMode="cover"
          style={styles.playerWrapper}
          accessibilityLabel={description || ALT_TEXT}
        >
          <Pressable
            onPress={toggleYouTubeEmbeddedPlayer}
            style={styles.playClickHandler}
          >
            <Icon
              style={styles.playIcon}
              name="youtube"
              size={PLAY_ICON_SIZE}
              color="#ffffff"
            />
          </Pressable>
        </ImageBackground>
      )}
    </Column>
  );
};

const useStyles = makeStyles(
  (theme, { aspectRatio }: { aspectRatio?: number }) =>
    createStyleSheet({
      playerWrapper: {
        maxHeight: "100%",
        maxWidth: "100%",
        width: "100%",
        aspectRatio,
      },
      playClickHandler: {
        position: "absolute",
        zIndex: 1,
        height: "100%",
        width: "100%",
      },
      buttonContainer: {
        backgroundColor: theme.colors.primaryReverse,
      },
      closeButtonWrapper: {
        justifyContent: "flex-end",
      },
      iframeContainer: {
        height: "100%",
      },
      playIcon: {
        display: "flex",
        lineHeight: 84,
        backgroundColor: theme.colors.videoBackground,
        justifyContent: "center",
        alignItems: "center",
        opacity: 0.75,
        height: "100%",
        width: "100%",
      },
    })
);

export default YoutubePlayer;
