import { rssFeedfromRSSFeed } from "~/utils/groups";
import { RSSFeedType } from "~/enums";

export interface RSSFeed {
  uid: string;
  title: string;
  description: string;
  domain: string;
  url?: string;
}

export const rssSearchToFeeds =
  (rssType: RSSFeedType) =>
  (data: RSSFeed | Array<RSSFeed>): Array<FC.Group> =>
    Array.isArray(data)
      ? data.map(rssFeedfromRSSFeed(rssType))
      : [rssFeedfromRSSFeed(rssType)(data)];
